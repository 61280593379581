import React from 'react'
import {useEffect} from 'react';
import {Link} from "react-router-dom";

import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert'
import AppsIcon from '@material-ui/icons/Apps'
import Popover from '@material-ui/core/Popover'
import Avatar from '@material-ui/core/Avatar'

import DocumentsMgmt from '../profile/documentsMgmt.js'

import AppBar from '@material-ui/core/AppBar';
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import Toolbar from '@material-ui/core/Toolbar';
import {Redirect,Route, Switch } from "react-router-dom";
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import SettingsIcon from '@material-ui/icons/Settings';
import HomeIcon from '@material-ui/icons/Home'
import Training from '../training/training.js'
import ModulesList from '../training/modulesList.js'
import Student from '../student/student.js'
import StudentTutor from '../student/studentTutor.js'
import StudentDashboard from '../student/studentDashboard.js'
import StudentProfile from '../student/studentProfile.js'
import ExaminatorProfile from '../examinator/examinatorProfile.js'
import AcceptDocuments from '../student/acceptDocuments.js'
import Tutor from '../tutor/tutor.js'
import Guest from '../guest/guest.js'
import Examinator from '../examinator/examinator.js'
import Exam from '../examinator/exam.js'
import Teacher from '../teacher/teacher.js'
import Profile from '../profile/profile.js'
import Documents from '../documents/documents.js'
import AdminDocuments from '../documents/adminDocuments.js'
import LinkedDocuments from '../documents/linkedDocuments.js'
import Videos from '../videos/videos.js'
import Settings from './settings.js'
import TutoringReportList from '../tutoringReport/tutoringReportList.js'
import AcquisitionReportList from '../tutoringReport/acquisitionReportList.js'
import PeriodicReportList from '../tutoringReport/periodicReportList.js'
import ELearningsEditor from '../eLearning/eLearningsEditor.js'

import axios from 'axios'

import OlindaMuiForm from '../components/form.js'
import OlindaMuiInfo from '../components/info.js'
import OlindaMuiMenu from '../components/menu.js'

import qualiopi_blanc from '../logos/qualiopi_b.png'
import logo_fae from '../logos/fae.png'

const useStyles = theme => ({
  root: {
    flexGrow: 1,
  },
  title: {
    flex: 1,
  },
});


class Formaction extends React.Component{
    constructor(props){
        super(props);
        this.state={
            index:0,
            connected:false,
            user:null,
            anchorEl: null,
            page:'profile',
            updatePassword:false,
            connectionError:false,
            unableToConnect:false,
            studentTrainingsList:[],
            studentTrainingFilter:undefined,
            userMenuOpened:false,
            userMenuAnchor:null,
            askConnection:false,
            askPassword:false,
            askPasswordError:false,
            windowWidth:window.innerWidth,
        };

        this.connexionField = [
            {id:'login', label:'Login', type:'mail', mandatory:true},
            {id:'password', label:'Mot de Passe', type:'password', mandatory:true},
            {id:'profil', label:'Profil', type:'select', value:1}
        ];
        
        this.forgotPasswordField = [
            {id:'login', label:'Login', type:'mail', mandatory:true},
            {id:'profil', label:'Profil', type:'select', value:1}
        ];

        this.profiles=['Administrateur', 'Formateur', 'Tuteur', 'Stagiaire', 'Invité', 'Jury'];

        this.handlePageSelection = this.handlePageSelection.bind(this); 
        this.onValidConnect = this.onValidConnect.bind(this);
        this.onCancelConnect = this.onCancelConnect.bind(this);
        this.onValidPassword = this.onValidPassword.bind(this);
        this.onCancelPassword = this.onCancelPassword.bind(this);
        this.onAskConnect = this.onAskConnect.bind(this);
        this.onAskPassword = this.onAskPassword.bind(this);
        this.onValidUpdatePassword = this.onValidUpdatePassword.bind(this);
        this.onCancelUpdatePassword = this.onCancelUpdatePassword.bind(this);
        this.onAcceptDocuments = this.onAcceptDocuments.bind(this);
        this.disconnect = this.disconnect.bind(this);
        this.backToHome = this.backToHome.bind(this);
        this.unableToConnect = this.unableToConnect.bind(this);
        this.onUnableToConnectOk = this.onUnableToConnectOk.bind(this);
        this.updateContact = this.updateContact.bind(this);
        this.setStudentTrainingFilter = this.setStudentTrainingFilter.bind(this);
        this.onCloseUserMenu = this.onCloseUserMenu.bind(this);
        this.mainMenuItems={
             1: [
                {label:'Accueil',  id:'', icon:<HomeIcon style={{color:'white'}}/>},
                {label:'Formations',  id:'training'},
                {label:'Documents', id:'docsList',
                    items:[
                        {label:'Cours', id:'documents'},
                        {label:'Administratifs', id:'admin-documents'}
                    ]
                },
                {label:'Stagiaires', id:'student'},
            ],
            2: [
                {label:'Accueil',  id:'', icon:<HomeIcon style={{color:'white'}}/>},
                {label:'Mes Stagiaires', id:'student'},
                {label:'Documents FAE', id:'documents'},
            ],
            3: [
                {label:'Accueil',  id:'', icon:<HomeIcon style={{color:'white'}}/>},
                {label:'Documents FAE', id:'documents'},
            ],            
            4: [
                {label:'Stagiaires', id:'student'}
            ],
            5: [
            ]

        }
        this.plusMenuItems={
             1: [
                {label:'Formateurs', id:'teacher'},
                {label:'Tuteurs', id:'tutor'},
                {label:'Invités', id:'guest'},
                {label:'Examinateurs', id:'examinator'},
                {label:'Evaluations Formatives', id:'tutoring-reports'},
                {label:'Evaluations Sommatives', id:'acquisition-reports'},
                {label:'Evaluations Periodiques', id:'periodic-reports'},
                {label:'Evaluations en Ligne', id:'eLearnings'},
                {label:'Paramètres', id:'settings'}
            ],
            3: [
                {label:'Formateurs', id:'teacher'},
            ],
            4: [
                {label:'Stagiaires', id:'student'}
            ],
            5: [
            ]

        }
        this.handleResize = this.handleResize.bind(this);
        window.addEventListener('resize', this.handleResize)
        
    }

    handleResize(){
        this.setState({windowWidth:window.innerWidth});
    }
    
    onAskPassword(){
        this.forgotPasswordField[0].value='';
        this.forgotPasswordField[1].value=1;
        this.setState({askPassword:true, askPasswordError:false});
    }

    onAskConnect(){
        this.connexionField[0].value='';
        this.connexionField[1].value='';
        this.connexionField[2].value=1;
        this.setState({askConnection:true, connectionError:false});
    }

    disconnect(){        
        axios.get(global.site + '/connexion/disconnect.php', {withCredentials:true})
            .then(res => {
                this.setState({connected:false,page:'profile', userMenuOpened:false});
            })
            .catch(function(error) {
                error.handleGlobally && error.handleGlobally();
        })
    }

    handlePageSelection(page){
        if(page === 'disconnect'){
            this.disconnect();
        }
        else if(page === 'password'){
            this.onUpdatePassword();
            
        }
    }

    isAbleTo(profile, page){
    }

    updateContact(fields){
        let datas = new FormData();
        fields.map((field)=>(
            datas.append(field.id, field.value)
        ))
        datas.append('id', this.state.user.id);
        datas.append('profile', this.state.user.profile);
        axios({
            withCredentials:true,
            method: 'post',
            url: global.site +'/user/updateContact.php',
            data: datas
        })
        .then(res =>  {
            if(res.data["success"]){
                let user = this.state.user;
                let organization = null;
                if(this.state.user.profile === 2){
                    organization = res.data["organization"];
                }
                user.phone = res.data["phone"];
                user.mail = res.data["mail"];
                user.lastName = res.data["lastName"];
                user.firstName = res.data["firstName"];
                user.organization = organization;
                this.setState({user:user});
            }
        })
        .catch(function (error) {
            error.handleGlobally && error.handleGlobally();
        });

    }

    buildTraining(edit){
        if( this.state.user.profile ===1 || this.state.user.profile === 5){
            return(
                <Training
                    userProfile={this.state.user.profile}
                    edit={edit}
                    running={!edit}
                    windowWidth={this.state.windowWidth}
                />
            );
        }
        else{
            return(
                <Redirect to="/"/>
            )
        }
    }

    buildDashboard(){
        if(this.state.user.profile == 1){
            if(this.state.windowWidth > global.smallWidth){
                return(
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={4}>
                            {this.buildProfile()}
                        </Grid>
                        <Grid item xs={12} sm={8}>
                            {this.buildTraining(false)}
                        </Grid>
                    </Grid>
                );
            }
            else{
                return(
                    this.buildTraining(false)
                );
            }            
        }
        else if(this.state.user.profile == 2){
            if(this.state.windowWidth > global.smallWidth){
                return(
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={4}>
                            {this.buildProfile()}
                        </Grid>
                        <Grid item xs={12} sm={8}>
                            {this.buildStudent(6)}
                        </Grid>
                    </Grid>
                );
            }
            else{
                return(
                    this.buildStudent(12)
                );
            }
        }
        else if(this.state.user.profile == 3){
            if(this.state.windowWidth > global.smallWidth){
                return(
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={3}>
                            {this.buildProfile()}
                        </Grid>
                        <Grid item xs={12} sm={9}>
                            {this.buildStudentProfile()}
                        </Grid>
                    </Grid>
                );
            }
            else{
                return(
                    this.buildStudentProfile()
                );
            }                              
        }
        else if(this.state.user.profile == 5 || this.state.user.profile==4){
            return(
                <div>
                    {this.buildProfile()}
                </div>
            );
        }
    }

    setStudentTrainingFilter(value){
        this.setState({studentTrainingFilter:value});
    }

    buildStudent(size=4){
        if( this.state.user.profile ==2){
            return(
                <div>
                    <Typography variant="h6">Mes Stagiaires</Typography>
                    <StudentTutor
                        tutorId={this.state.user.id}
                        userProfile={2}
                        size={size}
                        windowWidth={this.state.windowWidth}
                    />
                </div>
            );
        }
        else if( this.state.user.profile <= 4  || this.state.user.profile === 5){
            return(
                <Student 
                    userProfile={this.state.user.profile}
                    userId={this.state.user.id}
                    readOnly={this.state.user.profile !== 1}
                    studentId={this.state.user.profile === 3 ? this.state.user.id : undefined}
                    trainingFilter = {this.state.studentTrainingFilter}
                    studentTrainings = {this.state.studentTrainingsList}
                    setStudentTrainingFilter={this.setStudentTrainingFilter}
                    windowWidth={this.state.windowWidth}
                />
            );
        }
        else{
            return(
                <Redirect to="/"/>
            );
        }
    }
    
    backToHome(){
        this.setState({backToHome:true});
    }

    unableToConnect(site, connect){
        this.setState({unableToConnect:connect, site:site});
    }

    buildTutor(){
        if(this.state.user.profile === 1){
            return(
                <div>
                <Tutor
                    userProfile={this.state.user.profile}
                    userId={this.state.user.id}
                />
                </div>
            );
        }
        else{
            return(<Redirect to="/"/>);
        }
    }
   
    buildExam(){
        if(this.state.user.profile === 1 || this.state.user.profile === 5){
            return(
                <Exam
                    userProfile={this.state.user.profile}
                />
            )
        }
        else{
            return(<Redirect to="/"/>);
        }
    }

    buildExaminator(){
        if(this.state.user.profile === 1){
            return(
                <div>
                <Examinator
                    userProfile={this.state.user.profile}
                />
                </div>
            );
        }
        else{
            return(<Redirect to="/"/>);
        }
    }
    
    buildGuest(){
        if(this.state.user.profile === 1){
            return(
                <div>
                <Guest
                    userProfile={this.state.user.profile}
                />
                </div>
            );
        }
        else{
            return(<Redirect to="/"/>);
        }
    }

    buildTutoringReport(){
        return(
            <div>
            <Typography variant="h5">Evaluations Formatives</Typography>
            <TutoringReportList
                userProfile={this.state.user.profile}
            />
            </div>
        );
    }
    
    buildAcquisitionReport(){
        return(
            <div>
            <Typography variant="h5">Evaluations Sommatives</Typography>
            <AcquisitionReportList
                userProfile={this.state.user.profile}
            />
            </div>
        );
    }
    
    buildPeriodicReport(){
        return(
            <div>
            <Typography variant="h5">Bilans Périodiques</Typography>
            <PeriodicReportList
                userProfile={this.state.user.profile}
            />
            </div>
        );
    }
    
    buildELearning(){
        return(
            <div>
            <Typography variant="h5">Evaluations En Ligne</Typography>
            <ELearningsEditor
                userProfile={this.state.user.profile}
            />
            </div>
        );
    }

    buildTeacher(){
        if(this.state.user.profile <= 3){
            return(
                <Teacher
                    userProfile={this.state.user.profile}
                />
            );
        }
        else{
            return(<Redirect to="/"/>);
        }
    }

    buildSettings(){
        if(this.state.user.profile === 1){
            return(
                <Settings/>
            );
        }
        else{
            return(<Redirect to="/"/>);
        }
    }

    onShowCgu(){
        window.open("https://plateforme-fae.fr//media/documents//admin/CGU.pdf", "_blank");
    }


    onUpdatePassword(){ 
        this.passwordFields = [
            {id:'old', label:'Mot de passe actuel', type:'password', mandatory:true},
            {id:'new1', label:'Nouveau mot de passe', type:'password', mandatory:true},
            {id:'new2', label:'Confirmer', type:'password', mandatory:true}
        ];
        this.setState({updatePassword:true, userMenuOpened:false});
    }

    onValidUpdatePassword(fields)
    {
        let datas = new FormData();
        fields.map((field)=>(
            datas.append(field.id, field.value)
        ))
        datas.append('profile', this.state.user.profile);
        datas.append('id', this.state.user.id);
        axios({
            withCredentials:true,
            method: 'post',
            url: global.site + '/user/updatePassword.php',
            data: datas
        })
        .then(res =>  {
            if(res.data["success"]){
                this.setState({updatePassword:false})
            }
        })
        .catch(function (error) {
            error.handleGlobally && error.handleGlobally();
        });

    }
    
    onCancelUpdatePassword()
    {
        this.setState({updatePassword:false});
    }
   
    buildMyDocuments(){
        if(this.state.user == null){
            return null;
        }
        let documentsDir = '';
        if(this.state.user.profile === 1){
            documentsDir = '/teacher/teacher-';
        }
        else if(this.state.user.profile === 2){
            documentsDir = '/tutor/tutor-';
        }
        
        if(this.state.use !== null && this.state.user.profile <3){
            return(
                 <Grid container>
                    <Grid item xs={12} sm={6}>
                        <Typography variant="h5">Documents</Typography>
                            <DocumentsMgmt
                                userId={this.state.user.id}
                                readOnly={false}
                                type={this.state.user.profile===1?'teacher' : 'tutor'}
                                directory={documentsDir}
                            />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Typography variant="h5">Signature</Typography>
                            <DocumentsMgmt
                                userId={this.state.user.id}
                                readOnly={false}
                                picture={true}
                                type={this.state.user.profile===1?'teacher' : 'tutor'}
                                directory={documentsDir}
                                signature={true}
                        />
                    </Grid>
                </Grid>
            );
        }
    }

    buildDocuments(){
        if( this.state.user.profile ===1){
            return(
                <Grid container spacing={6}>
                    <Grid item xs={12} sm={12}>
                        <Typography variant="h5">Listes des documents</Typography>
                        <Documents
                            type='teaching-documents'
                            directory='/documents/teaching'
                            del={true}
                            add={true}
                        />
                    </Grid>
                </Grid>
            );
        }
        else if(this.state.user.profile === 2){
            return(
                <div>
                <LinkedDocuments
                    type='tutors-docs'
                    directory='/documents/admin'
                    del={false}
                    add={false}
                />
                </div>
            );
        }        
        else if(this.state.user.profile === 3){
            return(
                <div>
                <LinkedDocuments
                    type='students-docs'
                    directory='/documents/admin'
                    del={false}
                    add={false}
                />
                </div>
            );
        }        
    }
   
    buildAdminDocuments(){
        if(this.state.user.profile===1){
            return(
                <AdminDocuments
                    type='admin-documents'
                    directory='/documents/admin'
                    del={true}
                    add={true}
                />
            );
        }
    }

    buildStudentProfile(){
            return(
                <StudentProfile
                    studentId={this.state.user.id}
                    student={this.state.user}
                    tutors={[]}
                    trainings={[]}
                    readOnlyInfo={false}
                    userProfile={3}
                    userId={this.state.user.id}
                    updateContact={this.updateContact}
                    windowWidth={this.state.windowWidth}
                />
            );
    }

    buildProfile(){
        if(this.state.user.profile === 5){
            return(
                <ExaminatorProfile
                    examinatorId={this.state.user.id}
                />
            );
        }
        else{
            return(
                <Profile
                    user = {this.state.user}
                    readOnly={false}
                    type={this.state.user.profile===1 ?'teacher' : this.state.user.profile===2 ?  'tutor' : 'student'}
                    updateContact={this.updateContact}
                />
            );
        }
    }

    buildModules(){
        return(
            <ModulesList
                trainingId={this.state.user.trainingId}
                userProfile={this.state.user.profile}
            />
        );
    }

    getTrainingsList()
    {
        axios.get(global.site +'/training/list.php')
            .then(res => {
                const trainings = [{name:'Toutes', id:0}].concat(res.data["trainings"]);
                this.setState({studentTrainingsList:trainings});
             })
             .catch(function(error) {
        })
    }

    checkConnection(){
        axios.get(global.site + '/connexion/connexion.php', {withCredentials:true})
            .then(res => {              
                if(res.data["connected"]){
                    this.setState({connected:true, user:res.data["user"]});
                    if(res.data["user"].profile == 1){
                        this.getTraningsList();
                    }
                }
                else{
                    this.setState({connected:false});
                }
             })
             .catch(function(error) {
                error.handleGlobally && error.handleGlobally();
        })
    }

    componentDidMount(){
        axios.get(global.site + '/connexion/connexion.php', {withCredentials:true})
            .then(res => {              
                if(res.data["connected"]){
                    this.setState({connected:true, user:res.data["user"]});
                    if(res.data["user"].profile == 1){
                        this.getTrainingsList();
                    }
                }
                else{
                    this.setState({connected:false});
                }
             })
             .catch(function(error){
                error.handleGlobally && error.handleGlobally();
        })
    }
    
    onCancelPassword(){
        this.setState({askPassword:false, askPasswordError:false});
    }
    
    onValidPassword(){
        let datas = new FormData();
        this.forgotPasswordField.map((field)=>(
            datas.append(field.id, field.value)
        ))
        axios({
            withCredentials:true,
            method: 'post',
            url: global.site + '/connexion/forgot.php',        
            data: datas
        })
        .then(res =>  {
            if(res.data["success"]){
                this.setState({askPassword:false, askPasswordError:false})
            }
            else{
                this.setState({askPasswordError:true})
            }
        })
        .catch(function (error) {
            error.handleGlobally && error.handleGlobally();
        });
    }

    onCancelConnect(){
        this.setState({askConnection:false, connectionError:false});
    }

    onValidConnect(){
        let datas = new FormData();
        this.setState({connectionError:false});
        this.connexionField.map((field)=>(
            datas.append(field.id, field.value)
        ))
        axios({
            withCredentials:true,
            method: 'post',
            url: global.site + '/connexion/connect.php',        
            data: datas
        })
        .then(res =>  {
            if(res.data["success"]){
                this.setState({connected:true, user:res.data, askConnection:false})
                this.getTrainingsList();
            }
            else{
                this.setState({connected:false, connectionError:true});
            }
        })
        .catch(function (error) {
            error.handleGlobally && error.handleGlobally();
        });
    }

    onAcceptDocuments(){
        let datas = new FormData();
        datas.append('id', this.state.user.id);
        axios({
            withCredentials:true,
            method: 'post',
            url: global.site + '/student/accept.php',        
            data: datas
        })
        .then(res =>  {
            if(res.data["success"]){
                let user = this.state.user;
                user.valid=true;
                this.setState({user:user})
            }
        })
        .catch(function (error) {
            error.handleGlobally && error.handleGlobally();
        });
    
    }

    createLogo(){
        return(
            <img src={qualiopi_blanc} alt="Qualiopi" width={400} height={130}/>
        );
    }
    createApp(){
        const {classes} = this.props;       
        let wWidth = window.innerWidth;
        return(
            <Box sx={{display:'flex', overflow:'auto'}}>
                <AppBar position="sticky">
                        {this.state.windowWidth > global.smallWidth ?
                    <Toolbar>
                            <img src={qualiopi_blanc} alt="Qualiopi" width={169} height={55}/>                      
                            <div className={classes.title}>
                                <OlindaMuiMenu 
                                    items={this.mainMenuItems[this.state.user.profile]} 
                                    plusItems={this.plusMenuItems[this.state.user.profile]} 
                                    profile={this.state.user !== null ? this.state.user.profile : -1}
                                />
                            </div>
                            <img src={logo_fae} alt="Qualiopi" width={150} height={55}/>                      
                            <div>                                
                                <div style={{display:'flex', alignItems:'center'}}>
                                {this.state.user !== null ?
                                    this.state.user.avatar !== undefined && this.state.user.avatar.length > 0?
                                        <Avatar
                                            src={this.state.user.avatar[0].link}
                                        />
                                        :
                                        <Avatar>
                                            {this.state.user.firstName[0]+this.state.user.lastName[0]}
                                        </Avatar>
                                        
                                    :
                                    null
                                }
                                <IconButton
                                    aria-label="home"
                                    aria-controls="home-appbar"
                                    aria-haspopup="true"
                                    color="inherit"
                                    style={{padding:'0px'}}
                                    onClick={(event)=>this.onOpenUserMenu(event)}
                                >
                                    <MoreVertIcon/>
                                </IconButton>
                                </div>
                                <Typography>
                                    {this.state.user !== null ? this.profiles[this.state.user.profile]: null}
                                </Typography>
                            </div>
                    </Toolbar>
                            :
                            <div>                                
                                <div style={{display:'flex', alignItems:'center'}}>
                                <IconButton
                                    aria-label="home"
                                    aria-controls="home-appbar"
                                    aria-haspopup="true"
                                    color="inherit"
                                    style={{padding:'0px', marginRight:'20px'}}
                                    onClick={(event)=>this.onOpenUserMenu(event)}
                                >
                                    <AppsIcon/>
                                </IconButton>
                                <img src={qualiopi_blanc} alt="Qualiopi" width={169} height={55}/>                      
                                <img src={logo_fae} alt="Qualiopi" width={150} height={55}/>                      
                                </div>
                            </div>
                        }
                </AppBar>
                <div>
                    <Switch>
                        <Route exact path="/">
                            {this.buildDashboard()}
                        </Route>
                        <Route path="/teacher/:teacherId?">
                            {this.buildTeacher()}
                        </Route>
                        <Route path="/tutor/:tutorId?">
                            {this.buildTutor()}
                        </Route>
                        <Route path="/examinator/:examinatorId?">
                            {this.buildExaminator()}
                        </Route>
                        <Route path="/exam/:examId?">
                            {this.buildExam()}
                        </Route>
                        <Route path="/guest/:guestId?">
                            {this.buildGuest()}
                        </Route>
                        <Route path="/training/:trainingId?">{this.buildTraining(true)}</Route>
                        <Route path="/documents">
                            {this.buildDocuments()}
                        </Route>
                        <Route path="/admin-documents">
                            {this.buildAdminDocuments()}
                        </Route>
                        <Route path="/my-documents">
                            {this.buildMyDocuments()}
                        </Route>
                        <Route exact path="/student/:studentId?">
                            {this.buildStudent(this.state.windowWidth > 700 ? 4 : 12)}
                        </Route>
                        <Route exact path="/student/:studentId/training/:trainingId?">{this.buildStudent()}</Route>
                        <Route exact path="/student/:studentId/training/:trainingId/module/:moduleId?">{this.buildStudent()}</Route>
                        <Route exact path="/student/:studentId/training/:trainingId/:page?">{this.buildStudent()}</Route>
                        <Route path="/tutoring-reports/:reportId?">{this.buildTutoringReport()}</Route>
                        <Route path="/acquisition-reports/:reportId?">{this.buildAcquisitionReport()}</Route>
                        <Route path="/periodic-reports/:reportId?">{this.buildPeriodicReport()}</Route>
                        <Route path="/eLearnings/">{this.buildELearning()}</Route>
                        <Route path="/dashboard">{this.buildDashboard()}</Route>
                        <Route path="/settings">
                            {this.buildSettings()}
                        </Route>
                        <Route path="/modules/:moduleId?">{this.buildModules()}</Route>
                    </Switch>
                </div>
                {this.state.user.profile === 3 && !this.state.user.valid ?
                    <AcceptDocuments
                        type={'mandatory-docs'}
                        trainingId={this.state.user.trainingId}
                        onAcceptDocuments = {this.onAcceptDocuments}
                    />
                    :
                    null
                }

            </Box>
        );
    }

    onUnableToConnectOk(){
        this.setState({unableToConnect:false});
    }

    createConnectionPage(){
        return(
            <div>
            <Toolbar>
                <Button color="primary" onClick={()=>this.onAskConnect()}>Se connecter</Button>
                <Button color="primary" onClick={()=>this.onAskPassword()}>Identifiants oubliés</Button>
            </Toolbar>
            <Grid container>
                <Grid item xs={12} sm={4}>
                    <img src={logo_fae} alt="Qualiopi" width={300} height={111}/>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <img src={qualiopi_blanc} alt="Qualiopi" width={400} height={130}/>
                </Grid>
            </Grid>
            </div>
        )
    }

    onOpenUserMenu(event){
        this.setState({userMenuOpened:true, userMenuAnchor:event.currentTarget});
    }

    onCloseUserMenu(){
        this.setState({userMenuOpened:false});
    }

    createMobileMenu(){
        let items=[];
        let plusItems = [];
        if(this.state.user !== null){
            items = this.mainMenuItems[this.state.user.profile];
        }
        return(
            <Popover
                anchorEl={this.state.userMenuAnchor}
                open={this.state.userMenuOpened}
                onClose={()=>this.onCloseUserMenu()}
            >
                <OlindaMuiMenu 
                    items={items} 
                    plusItems={plusItems} 
                    profile={this.state.user !== null ? this.state.user.profile : -1}
                    mobile={true}
                    close={this.onCloseUserMenu}
                />
                {this.state.user !== null ? this.buildProfile() : null}
                    <MenuItem button onClick={()=>this.onUpdatePassword()}>
                        {'Changer mon mot de passe'}
                    </MenuItem>
                    <MenuItem button onClick={()=>this.disconnect()}>
                        {'Déconnexion'}
                    </MenuItem>
                    <MenuItem button onClick={()=>this.onShowCgu()}>
                        {'CGU'}
                    </MenuItem>
            </Popover>
        )
    }

    render(){
        global.disconnectedHandle = this.disconnect;
        global.backToHomeHandle = this.backToHome;
        global.unableToConnect = this.unableToConnect;
        return(
            <div>
            {this.state.askConnection ?
                <OlindaMuiForm
                    fields={this.connexionField}
                    onValid={this.onValidConnect}
                    onCancel={this.onCancelConnect}
                    error={this.state.connectionError ? 'Identifiants invalides' :  null}
                    profil={[{id:1, name:'Formateur'}, {id:2, name:'Tuteur'}, {id:3, name:'Stagiaire'}, {id:4, name:'Invité'}, {id:5, name:'Jury'}]}
                />
                :
                null
            }
            {this.state.askPassword ?
                <OlindaMuiForm
                    fields={this.forgotPasswordField}
                    onValid={this.onValidPassword}
                    onCancel={this.onCancelPassword}
                    error={this.state.askPasswordError ? 'Login invalide avec le profil choisi' :  null}
                    profil={[{id:1, name:'Formateur'}, {id:2, name:'Tuteur'}, {id:3, name:'Stagiaire'}, {id:4, name:'Invité'}, {id:5, name:'Jury'}]}
                />
                :
                null
            }
            <Box>
                {this.state.connected ? this.createApp() :
                    <div>
                    {this.createConnectionPage()}
                    </div>
                }
                {this.state.unableToConnect?
                    <OlindaMuiInfo
                        text={'Impossible de se connecter au site ' + this.state.site}
                        onOk = {this.onUnableToConnectOk}
                    />
                        :
                        null
                }
                {this.state.updatePassword ?
                    <OlindaMuiForm
                        title="Changement du mot de passe"
                        onValid={this.onValidUpdatePassword}
                        onCancel={this.onCancelUpdatePassword}
                        fields = {this.passwordFields}
                    />
                        :
                    null
                }
                {this.state.backToHome ?
                    <Redirect to='/'/>
                        :
                        null
                }
            </Box>
            {this.state.windowWidth > global.smallWidth ?
                <Menu
                    anchorEl={this.state.userMenuAnchor}
                    open={this.state.userMenuOpened}
                    onClose={()=>this.onCloseUserMenu()}
                >
                    <MenuItem button onClick={()=>this.onUpdatePassword()}>
                        {'Changer mon mot de passe'}
                    </MenuItem>
                    <MenuItem button onClick={()=>this.disconnect()}>
                        {'Déconnexion'}
                    </MenuItem>
                    <MenuItem button onClick={()=>this.onShowCgu()}>
                        <Typography variant="caption">{'CGU'}</Typography>
                    </MenuItem>
                </Menu>
                :
                this.createMobileMenu()
            }
            </div>
        ); 
    }



}

export default withStyles(useStyles)(Formaction)
