import React from 'react'
import axios from 'axios'

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

class OlindaMuiDownload extends React.Component{
    constructor(props){
        super(props);
        this.state={
            linkReady:false,
            link:''
        }
        this.onValid = this.onValid.bind(this);
        this.onCancel = this.onCancel.bind(this);

    }

    componentDidMount(){
        let datas = new FormData();
        for ( const [key, value] of Object.entries(this.props.datas)){
            datas.append(key, value)
        }

        axios({
            method: 'post',
            url: global.site + this.props.url,
            data: datas
        })
        .then(res =>  {
            if(res.data["success"]){
                this.setState({linkReady:true, link:res.data['link']});
            }
        })
        .catch(function (error) {
            error.handleGlobally && error.handleGlobally();
        });

    }

    onCancel() {
        this.props.onCancel();
    };

    onValid() {
        this.props.onCancel();
    };

    render(){
        return (
        <div>
        <Dialog fulWidth fullHeight open={true} onClose={()=>this.onCancel()} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">{this.props.title}</DialogTitle>
            <DialogContent>
                {this.state.linkReady ?
                    'Cliquer sur Télécharger'
                    :
                    'Création du fichier en cours'
                }
            </DialogContent>
            <DialogActions>
            <Button onClick={()=>this.onCancel()} color="primary">
                Annuler
            </Button>
            {this.state.linkReady ?
                <Button onClick={()=>this.onValid()} href={this.state.link} color="primary">
                    Télécharger
                </Button>
                :
                null
            }
            </DialogActions>
        </Dialog>
        </div>
  );
  }
}


export default OlindaMuiDownload;
