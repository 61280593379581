import React from 'react'
import Button from '@material-ui/core/Button';
import OlindaMuiConfirm from './confirm.js'

class OlindaMuiButton extends React.Component{
    constructor(props){
        super(props);
        this.state={
            showConfirm:false
        }
        this.onValid = this.onValid.bind(this);
        this.onCancel = this.onCancel.bind(this);
    }

    onButtonClicked(){
        this.setState({showConfirm:true});
    }

    onCancel() {
        this.setState({showConfirm:false})
    };

    onValid() {
        this.props.onValid();
        this.setState({showConfirm:false});
    };

    render(){
        return (
            <div>
                {this.state.showConfirm ?
                    <OlindaMuiConfirm
                        title={this.props.confirmTitle}
                        text={this.props.confirmText}
                        onValid = {this.onValid}
                        onCancel = {this.onCancel}
                    />
                    :
                    null
                }
                <Button 
                    color="primary" 
                    variant="contained" 
                    onClick={()=>this.onButtonClicked()}
                >
                    {this.props.buttonText}
                </Button>
            </div>
        );
  }
}


export default OlindaMuiButton;
