import React from 'react'
import axios from 'axios'
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActionArea from '@material-ui/core/CardActionArea';
import Button from '@material-ui/core/Button'
import { withRouter } from "react-router-dom";
import {Link} from "react-router-dom";

import OlindaMuiForm from '../components/form'
import OlindaMuiButton from '../components/button';

import qualiopi_blanc from '../logos/qualiopi_b.png'
import logo_fae from '../logos/fae.png'
import cpne from '../logos/cpne.png'
import ministere from '../logos/ministere.jpg'
import sport_occitanie from '../logos/sport_occitanie.jpg'
import ffe from '../logos/ffe.jpg'
import hippothese from '../logos/hippothese.jpg'
import ouspins from '../logos/ouspins.png'



class TrainingItem extends React.Component{
    constructor(props){
        super(props);
        this.state={
            generateDailyReports:false,
            addStudents:false,
            students:[]
        }
        this.formLogos={"bp":[{logo:ministere, width:135, height:75}],
                        "cqp":[{logo:cpne, width:220, height:75}],
                        "ae":[{logo:ffe, width:90, height:75},
                             {logo:hippothese, width:120, height:75}],
                        "ae-ouspins":[{logo:ffe, width:90, height:75},
                             {logo:ouspins, width:187, height:75}]
                        }


        this.addStudentsFields=[
            {id:"students", type:"table", 
             columns:[{id:'firstName', title:'Prénom'},
                     {id:'lastName', title:'Nom'}], 
             values:[],
             callback:this.onSelectStudent
            }
        ]
        this.onValidAddStudents = this.onValidAddStudents.bind(this);
        this.onCancelAddStudents = this.onCancelAddStudents.bind(this);
        this.onGenerateDailyReports = this.onGenerateDailyReports.bind(this);

    }

    formatDate(value){
         const date = new Date(value);
         return date.toLocaleDateString('fr-fr');
    }

    onSelectStudent(id, checked){
        console.log(id);
        console.log(checked);
    }

    onGenerateDailyReports(){
         let datas = new FormData();
         datas.append('trainingId', this.props.training.id);
         axios({
             withCredentials:true,
             method: 'post',
             url: global.site +'/weeklyReport/generate.php',
             data: datas
         })
         .then(res =>  {
             if(res.data["success"]){
             }
         })
         .catch(function (error) {
             error.handleGlobally && error.handleGlobally();
         });
    }

    getStudents(){
       axios.get(global.site +'/student/getStudentsOutOfTraining.php?trainingId='+ this.props.training.id)
            .then(res => {
                if(res.data["success"]){
                    let students=res.data["students"]
                    this.addStudentsFields[0].values = [];
                    this.setState({addStudents:true, students:students});
                }
             })
             .catch(function(error) {
                error.handleGlobally && error.handleGlobally();
        })
        
    }

    onAddStudents(){
        this.getStudents();
    }

    onValidAddStudents(fields){
        console.log(fields);
    }

    onCancelAddStudents(){
        this.setState({addStudents:false});
    }


    render(){
        var lTraining = this.props.training.name.toLowerCase();
        const keys = Object.keys(this.formLogos)
        var formLogos = undefined

        if(lTraining.includes("bp")){
            formLogos = this.formLogos["bp"];
        }
        else if(lTraining.includes("cqp")){
            formLogos = this.formLogos["cqp"];
        }
        else if(lTraining.includes("ae") && lTraining.includes("ous pins")){
            formLogos = this.formLogos["ae-ouspins"]
        }
        else if(lTraining.includes("ae")){
            formLogos = this.formLogos["ae"];
        }

        let endDate = new Date(this.props.training.endDate);
        let now = new Date();
        let diffDate = now - endDate;
        let createGenerate = diffDate < 0;


        return(
            <div>
            {
                this.state.addStudents ?
                    <OlindaMuiForm
                        title= "Ajouter des stagiaires"
                        fields={this.addStudentsFields}
                        onCancel={this.onCancelAddStudents}
                        onValid={this.onValidAddStudents}
                        students={this.state.students}
                    />
                    :
                    null
            }
            <Card>
            <CardActionArea
                component={Link}
                to={'/training/' + this.props.training.id}
            >
            <CardHeader
                title={this.props.training.name}
                subheader={'du ' + this.formatDate(this.props.training.startDate) + ' au ' + this.formatDate(this.props.training.endDate)}
            />
            {formLogos !== undefined ?
                <div style={{margin:'2%'}}>
                {formLogos.map((logo)=>(
                    <img
                        src={logo.logo}
                        width={logo.width}
                        height={logo.height}
                    />
                ))}
                </div>
                :
                null
            }
            </CardActionArea>
            <CardContent>
                {createGenerate ?
                    <OlindaMuiButton
                        confirmTitle={this.props.training.name}
                        confirmText={'Générer les bilans quotidiens?'}
                        buttonText={'Bilans Quotidiens'}
                        onValid={this.onGenerateDailyReports}
                    />
                    :
                    null
                }
            </CardContent>
        </Card>
        </div>
        );
    }
}

export default withRouter(TrainingItem)

