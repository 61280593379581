import React from 'react'


import OlindaMuiMenu from '../components/menu.js'
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Toolbar';

class TrainingMenu extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            opened:false
        };
        this.state={
            items:[
                    {label:'Info', id:'info'},
                    {label:'Documents',  id:'documents',
                        items:[
                            {label:'Cours', id:'teachingDocs'},
                            {label:'Administratif', id:'adminDocs'}
                        ]
                    }, 
                    {label:'Stagiaires', id:'students'},
                    {label:'Emploi du temps', id:'scheduler'},
                    {label:'Paramètres', id:'settings'}                
            ],
            itemsSmall:[
                    {label:'Info',  id:'info'},
                    {label:'Emploi du temps', id:'scheduler'}
            ],
            plusItems:[
                    {label:'Cours', id:'teachingDocs'},
                    {label:'Stagiaires', id:'students'},
            ]
        };
    }

    render(){
        return(
            <Toolbar style={{background:'blue'}}>
            <OlindaMuiMenu
                items={this.props.windowWidth > global.smallWidth ? this.state.items: this.state.itemsSmall}
                handlePage = {this.props.handlePage}
                plusItems={this.props.windowWidth > global.smallWidth ? undefined: this.state.plusItems}
            />
            </Toolbar>
        );
    
    }



}

export default TrainingMenu
