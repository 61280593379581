import React from 'react'
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import LinkedDocuments from '../documents/linkedDocuments.js';

class AcceptDocuments extends React.Component{
    constructor(props){
        super(props);
    }

    onValid() {
        this.props.onAcceptDocuments();
    };
    
    render(){
        return (
            <div>
            <Dialog fulWidth fullHeight open={true}  aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">{'Documents à lire'}</DialogTitle>
            <DialogContent>
                <LinkedDocuments
                    type={this.props.type}
                    userId={this.props.trainingId}
                    add={false}
                    del={false}
                />
            </DialogContent>
            <DialogActions>
            <Button onClick={()=>this.onValid()} color="primary">
                OK
            </Button>
            </DialogActions>
        </Dialog>
        </div>
  );
    }
}


export default AcceptDocuments;
