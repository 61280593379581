import React from 'react'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Typography from '@material-ui/core/Typography'
import ColorPicker from 'material-ui-color-picker'
import CircularProgress from '@material-ui/core/CircularProgress';
import OlindaMuiTable from './table.js'

class OlindaMuiForm extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            fields:this.props.fields,
            processing:false
        }

        this.handleChange = this.handleChange.bind(this);
        this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
    }

    onCancel() {
        this.props.onCancel();
    };

    checkMail(value){
        var re = /\S+@\S+\.\S+/;
        return re.test(value);
    }
    componentDidUpdate(prevProps){
        if(this.props.error !== prevProps.error){
            this.setState({processing:false});
        }
    }

    checkField(field){
        let validField = true;
        if(field.mandatory === undefined || !field.mandatory){
            return true;
        }
        if(field.type !== 'check-box' && (field.value === undefined || field.value === null)){
            field.error = 'Le champs est vide';
            return false;
        }
        if(field.type === 'select'){
            validField = field.value > 0;
            if(!validField){
                field.error = 'Le champs est vide';
            }
            else{
                field.error = undefined;
            }
        }
        else if(field.type === 'file'){
            validField = field.file !== null;
            if(!validField){
                field.error = 'Aucun fichier choisi';
            }
            else{
                field.error = undefined;
            }
        }
        else if(field.type === 'check-box'){
            validField = field.values.length > 0;
            if(!validField){
                field.error = 'Choix vide';
            }
            else{
                field.error = undefined;
            }
        }
        else{
            validField = field.value.trim().length > 0;
            if(!validField){
                field.error = 'Le champs est vide';
            }
            else{
                if(field.type === 'mail'){
                    validField = this.checkMail(field.value);
                    if(!validField){
                        field.error = 'Mail invalide';                        
                    }
                    else{
                        field.error = undefined;
                    }
                }
                else{
                    field.error = undefined;
                }
            }
        }
        return validField;
    }

    onValid() {
        let fields = this.state.fields.slice();
        let valid = true;
        for(let index = 0; index < fields.length; index++){
            valid = this.checkField(fields[index]) && valid;   
        }
        if(valid){
            this.props.onValid(this.state.fields);
            this.setState({processing:true});
        }
        else{
            this.setState({fields:fields});
        }

    };

    onDelete(){
        this.props.onDelete();
    }

    handleChange(e){

        const target = e.target;
        var value = target.value;
        const id = target.id;
        let fields = this.state.fields.slice();
        let index = -1;
        for(index = 0;index < fields.length; index++){
            if(fields[index].id == id){
                if(fields[index].type === 'file'){
                    fields[index].file = target.files[0];
                }
                fields[index].value = value.trimStart();
                fields[index].error = undefined;
                break;
            }
        }
        this.setState({fields:fields});
    }

    handleCheckboxChange(e){
        const target = e.target;
        var checked = target.checked;
        const id = target.id;
        const name = parseInt(target.name, 10);
        let fields = this.state.fields.slice();
        let index = -1;
        for (index = 0; index < fields.length; index++){
            if(fields[index].id == id){
                const selected = fields[index].values.indexOf(name);
                if(checked){
                    if(selected === -1){
                        fields[index].values.push(name);
                    }
                }
                else{
                    if(selected !== -1){
                        fields[index].values.splice(selected, 1)
                    }
                }
            }
        }
        this.setState({fields:fields});
    }

    handleChangeColor(color, id){
        let fields = this.state.fields.slice();
        let index = -1;
        for(index = 0;index < fields.length; index++){
            if(fields[index].id === id){
                fields[index].value = color;
                break;
            }
        }
        this.setState({fields:fields});
    }

    createError(field){
        if(field.error !== undefined && field.error.length > 0){
            let error = field.error;
            field.error= '';
            return(
                <Typography>{error}</Typography>              
            );
        }
        else{
            return(null);
        }
    }

    createField(field){
        if(!field.hide){           
            if(field.type === 'input' || field.type==='date' || field.type==='time' 
                || field.type==='password' || field.type === 'file' || field.type==='mail')
            {
                return(                    
                    <div>
                    <TextField
                        margin="dense"
                        label={field.label}
                        value={field.value}
                        format={field.format}
                        id={field.id}
                        fullWidth
                        type = {field.type}
                        onChange={this.handleChange}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        InputProps={{
                            readOnly:this.props.readOnly,
                        }}
                        error = {field.error !== undefined}
                        helperText={field.error}
                    />
                    </div>
                );
            }
            else if(field.type === 'color'){
                return(
                    <ColorPicker
                        name='color'
                        defaultValue='#000'
                        id={field.id}
                        fullWidth
                        value={field.value}
                        onChange={color => this.handleChangeColor(color, field.id)}
                    />
                );
            }
            else if(field.type === 'multiline'){
                return(
                    <TextField
                        margin="dense"
                        label={field.label}
                        value={field.value !== null && field.value !== 'null' ? field.value : ''}
                        format={field.format}
                        id={field.id}
                        fullWidth
                        multiline
                        rows={4}
                        onChange={this.handleChange}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        InputProps={{
                            readOnly:this.props.readOnly,
                        }}
                    />
                );
            }
            else if(field.type === 'file'){
                return(
                    <FormControl
                        fullWidth={true}
                    >                    
                        <input
                            native
                            value={field.value}
                            id={field.id}
                            type='file'
                            onChange={this.handleChange}
                        />
                    </FormControl>
                );
            }
            else if(field.type === 'table'){
                return(
                    <OlindaMuiTable
                        elements={this.props[field.id]}
                        columns={field.columns}
                        edit={false}
                        onSelect={field.callback}
                    />
                );
            }
            else if(field.type === 'select'){
                return(
                    <FormControl
                        fullWidth={true}
                    >
                    
                        <InputLabel htmlFor="age-native-simple">{field.label}</InputLabel>
                        <Select
                            native
                            value={field.value}
                            id={field.id}
                            onChange={this.handleChange}
                            disabled={this.props.readOnly}
                            error = {field.error !== undefined}
                        >
                        {this.props[field.id].map((option)=>(
                            <option value={option.id}>{option.name!==undefined?option.name:option.title}</option>
                        ))}
                        </Select>
                        {field.error !== undefined ?
                            <FormHelperText>{field.error}</FormHelperText>
                            :
                            null
                        }
                    </FormControl>
                );
            }
            else if(field.type === 'common-select'){
                return(
                    <FormControl
                        fullWidth={true}
                    >
                    
                        <InputLabel htmlFor="age-native-simple">{field.label}</InputLabel>
                        <Select
                            native
                            value={field.value}
                            id={field.id}
                            onChange={this.handleChange}
                            disabled={this.props.readOnly}
                        >
                        {this.props.selectValues.map((option)=>(
                            <option value={option.id}>{option.name!==undefined?option.name:option.title}</option>
                        ))}
                        </Select>
                    </FormControl>
                );
            }
            else if(field.type === 'check-box'){
                return(
                    <Box>
                        <FormControl>
                            <FormLabel>{field.label}</FormLabel>
                            <FormGroup>
                                {this.props[field.id].map((check)=>(
                                    <FormControlLabel
                                        control=<Checkbox id={field.id} name={check.id} checked={field.values.includes(check.id)} onChange={this.handleCheckboxChange}/>
                                        label={check.name}
                                    />
                                ))}
                            </FormGroup>
                        </FormControl>
                    </Box>
                );
            }
        }
    }

    render(){
        return (
            <div>
            <Dialog 
                fulWidth 
                fullHeight 
                open={true} 
                onClose={()=>this.onCancel()} 
                aria-labelledby="form-dialog-title"
                scroll={'paper'}
            >
            <DialogTitle id="form-dialog-title">{this.props.title}</DialogTitle>
            <DialogContent>
            {this.props.subtitle !== undefined && this.props.subtitle !== null && this.props.subtitle.length > 0?
                <DialogContentText>
                    {this.props.subtitle}
                </DialogContentText>
                :
                null
            }
            {this.state.processing?
                    <CircularProgress/>
                    :
                    this.state.fields.map((field) => this.createField(field))
            }
            {this.props.error !== undefined && this.props.error !== null && this.props.error.length >0 ? <Typography>{this.props.error}</Typography> : null}
            </DialogContent>
            {this.props.readOnly ?
                <DialogActions>
                    <Button onClick={()=>this.onCancel()} color="primary">
                        Ok
                    </Button>
                </DialogActions>
                :
                <DialogActions>
                    
                    {this.props.buttons !== undefined ? this.props.buttons.map((button)=>(
                            <Button onClick={()=>button.action()} color="primary">
                                {button.label}
                            </Button>
                        )):null
                    }

                    <Button 
                            onClick={()=>this.onValid()} 
                            color="primary"
                            disabled={this.state.processing}
                        >
                        {this.props.modify ? "Modifier" : "Valider"}
                    </Button> 
                    {this.props.del ?
                        <Button 
                            onClick={()=>this.onDelete() } 
                            color="primary"
                            disabled={this.state.processing}
                            >
                            Supprimer
                        </Button>
                        : 
                        null
                    }
                    <Button 
                        onClick={()=>this.onCancel()} 
                        color="primary"
                        disabled={this.state.processing}
                    >
                        Annuler
                    </Button>
                </DialogActions>
            }
        </Dialog>
        </div>
  );
    }
}


export default OlindaMuiForm;
