import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from "react-router-dom";
import Formaction from './formaction/formaction.js';

import './global.js'
// ========================================


const appElement = document.getElementById('app');
if (appElement) {
  ReactDOM.render(
      <BrowserRouter>
          <Formaction/>
      </BrowserRouter>,
      
      appElement); 
}
