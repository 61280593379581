import React from 'react'
import axios from 'axios'

import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';


import { withRouter, Link } from "react-router-dom";
import Typography from '@material-ui/core/Typography';
import TutoringReport from './tutoringReport'


class TutoringReportList extends React.Component{
    constructor(props){
        super(props);
        this.state={
            hideTeacher:false,
            hideTutor:false
        };
        this.hideTutor=this.hideTutor.bind(this);
        this.hideTeacher=this.hideTeacher.bind(this);
        this.showAll=this.showAll.bind(this);
    }

    hideTutor(){
        this.setState({hideTutor:true});
    }

    hideTeacher(){
        this.setState({hideTeacher:true});
    }

    showAll(){
        this.setState({hideTeacher:false, hideTutor:false});
    }

    render(){
        return(
            <div>
                {this.state.hideTeacher ? null:
                    <Card>
                    <CardHeader
                        title={'Centre de Formation'}
                    />
                    <CardContent>
                        <TutoringReport
                            reportProfile={1}
                            reportId = {this.props.match.params.reportId}
                            studentId={this.props.studentId}
                            trainingId={this.props.trainingId}
                            userProfile={this.props.userProfile}
                            studentLastName = {this.props.studentLastName}
                            studentFirstName = {this.props.studentFirstName}
                            onEdit = {this.hideTutor}
                            onBack={this.showAll}
                        />
                    </CardContent>
                    </Card>
                }
                {this.state.hideTutor ? null:
                    <Card style={{marginTop:'10px'}}>
                        <CardHeader
                            title={'Tuteur'}
                        />
                        <CardContent>
                        <TutoringReport
                            reportProfile={2}
                            userProfile={this.props.userProfile}
                            reportId = {this.props.match.params.reportId}
                            studentId={this.props.studentId}
                            trainingId={this.props.trainingId}
                            userProfile={this.props.userProfile}
                            studentLastName = {this.props.studentLastName}
                            studentFirstName = {this.props.studentFirstName}           
                            onEdit = {this.hideTeacher}
                            onBack={this.showAll}
                        />
                        </CardContent>
                    </Card>
                }
            </div>
        );
    
    }



}

export default withRouter(TutoringReportList)

