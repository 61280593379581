import React from 'react'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import axios from 'axios'
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import OlindaMuiConfirm from '../components/confirm.js'
import OlindaMuiForm from '../components/form.js'

function formatDate(value){
    const date = new Date(value);
    return date.toLocaleDateString('fr-fr');
}



class TutorVisit extends React.Component{

    constructor(props){
        super(props);
        this.state={
            del:false,
            selected:0,
            selectedList:[],
            visits:[],
            visitId:0,
            readOnly:false,
            updateDate:false
        }
        this.notes = [
            {id:1, name:'Très Insuffisant'},
            {id:2, name:'Insuffisant'},
            {id:3, name:'Correct'},
            {id:4, name:'Satisfaisant'},
            {id:5, name:'Supérieur'}
        ]
        
        this.visitFields = [
            {id:'assiduity', label:'Assiduité - Ponctualité', type:'select', value:1},
            {id:'involvement', label:'Investissement', type:'select', value:1},
            {id:'initiative', label:'Initiative - Autonomie', type:'select', value:1},
            {id:'integration', label:'Intégration au collectif', type:'select',value:1},
            {id:'description', label:'Description', type:'multiline',value:''},
            {id:'mission', label:'Mission', type:'multiline',value:''},
            {id:'communication', label:'Communication', type:'multiline',value:''},
            {id:'project', label:'Projet', type:'multiline',value:''}
        ]
       
        this.dateFields = [
            {id:'date', label:'Date', type:'date'}
        ]

        this.onSelected = this.onSelected.bind(this);
        this.onCheck = this.onCheck.bind(this);
        this.onCheckAll = this.onCheckAll.bind(this);
        this.onDelete = this.onDelete.bind(this);
        this.onValidDelete = this.onValidDelete.bind(this);
        this.onCancelDelete = this.onCancelDelete.bind(this);
        this.onAdd = this.onAdd.bind(this);
        this.onEditVisit = this.onEditVisit.bind(this);
        this.onValidVisit = this.onValidVisit.bind(this);
        this.onCancelVisit = this.onCancelVisit.bind(this);
        this.getVisits = this.getVisits.bind(this);
        this.getVisit = this.getVisit.bind(this);

        this.onValidUpdateDate = this.onValidUpdateDate.bind(this);
        this.onCancelUpdateDate = this.onCancelUpdateDate.bind(this);
    }

    componentDidMount(){
        this.getVisits();
    }

    onUpdateDate(id){
       this.setState({visitId:id, updateDate:true}); 
    }

    onValidUpdateDate(fields){
        let datas = new FormData();
        datas.append("date", fields[0].value);
        datas.append("visitId", this.state.visitId);
        datas.append("studentId", this.props.studentId);
        datas.append("trainingId", this.props.trainingId);
        axios({
            withCredentials:true,
            method: 'post',
            url: global.site +'/tutorVisit/updateDate.php',
            data: datas
        })  
        .then(res =>  {
            if(res.data["success"]){
                let visits=res.data["visits"];
                this.setState({visitId:0, visits:visits, updateDate:false});
            }   
        })  
        .catch(function (error) {
            error.handleGlobally && error.handleGlobally();
        });     
    }

    onCancelUpdateDate(){
        this.setState({updateDate:false});
    }

    onEdit(id){
        window.open(global.site + '/tutorVisit/edit.php?id='+id, "_blank");
        axios.get(global.site +'/tutorVisit/edit.php?id=' + id )
            .then(res => {
                if(res.data["success"]){
                }
             })
             .catch(function(error){
                error.handleGlobally && error.handleGlobally();
        })

    }

    getVisits(){
        axios.get(global.site +'/tutorVisit/list.php?studentId=' + this.props.studentId +'&trainingId=' + this.props.trainingId )
            .then(res => {
                if(res.data["success"]){
                    const visits = res.data["visits"];
                    this.setState({visits:visits});
                }
             })
             .catch(function(error) {
                error.handleGlobally && error.handleGlobally();
        })
    }
    
    
    onValidVisit(fields){
        let datas = new FormData();
        this.visitFields.map((field) =>(
            datas.append(field.id, field.value)
        ))
        datas.append("id", this.state.visitId);
        axios({
            withCredentials:true,
            method: 'post',
            url: global.site +'/tutorVisit/update.php',
            data: datas
        })
        .then(res =>  {
            if(res.data["success"]){
                this.setState({visitId:0});
            }
        })
        .catch(function (error) {
            error.handleGlobally && error.handleGlobally();
        });
        
    }

    onCancelVisit(){
        this.setState({visitId:0});
    }

    onSelected(id){
    }

    onDelete(){
        this.setState({del:true});
    }

    onValidDelete(){
         this.setState({del:false});
         let datas = new FormData();
         datas.append("trainingId", this.props.trainingId);
         datas.append("studentId", this.props.studentId);
         for(let i = 0; i < this.state.selectedList.length; i++){
            datas.append('ids[]', this.state.selectedList[i]);
        }

         axios({
             method: 'post',
             url: global.site +'/tutorVisit/delete.php',
             data: datas
         })
         .then(res =>  {
             if(res.data["success"]){
                 let visits = res.data["visits"]
                 this.setState({visits:visits,visitId:0});
             }
         })
         .catch(function (error) {
             error.handleGlobally && error.handleGlobally();
         });

        this.setSelected([]);
    }

    getVisit(id){
        axios.get(global.site +'/tutorVisit/get.php?id=' + id )
        .then(res => {
            if(res.data["success"]){
                this.visitFields.map((field)=>(
                    field.value = res.data[field.id] === null ? 1 : res.data[field.id]
                ))
                this.setState({visitId:id})
            }
            
         })
         .catch(function(error){
            error.handleGlobally && error.handleGlobally();
        })
    }


    onCancelDelete(){
        this.setState({del:false});
    }

    onCancelSign(){
        this.setState({confirmSign:false});
    }

    onAdd(){
        let datas = new FormData();
        datas.append("studentId", this.props.studentId);
        datas.append("trainingId", this.props.trainingId);
        axios({
            withCredentials:true,
            method: 'post',
            url: global.site +'/tutorVisit/create.php',
            data: datas
        })
        .then(res =>  {
            if(res.data["success"]){
                let visits = res.data["visits"]
                this.setState({visits:visits});
            }
        })
        .catch(function (error) {
            error.handleGlobally && error.handleGlobally();
        });
    }

    onEditVisit(id){
        this.getVisit(id);
    }

    setSelected(ids){
        this.setState({selectedList:ids});
    }

    onCheckAll(event){
        if(event.target.checked){
            const newSelecteds = this.state.visits.map((element) => element.id);
            this.setSelected(newSelecteds);
            return;
        }
        this.setSelected([]);
    }

    onCheck(event){
        let target = event.target;
        const id = parseInt(target.id);
        const selectedIndex = this.state.selectedList.indexOf(id);
        let newSelected = this.state.selectedList.slice();
        if(target.checked){
            if(selectedIndex === -1)
            {
                newSelected.push(id);
            }
        }
        else{
            if(selectedIndex !== -1){
                newSelected.splice(selectedIndex,1);
            }

        }
        this.setState({selectedList:newSelected})
    }

    createToolbar(){
        return(
            <Toolbar>
                <div>
                {
                    this.state.selectedList.length > 0 ? (                    
                        <IconButton aria-label="delete">
                            <DeleteIcon  onClick={this.onDelete}/>
                        </IconButton>
                    )
                    :
                    null
                }
                {this.props.add ?
                    <IconButton aria-label="delete">
                        <AddIcon  onClick={this.onAdd}/>
                    </IconButton>
                 : null}
                </div>
            </Toolbar>
        );
    }

    createHeader(){
        return(
            <TableHead>
                <TableRow>
                    {this.props.del ?
                    <TableCell padding="checkbox">
                        <Checkbox                            
                            onChange={this.onCheckAll}
                            checked = {this.state.visits.length > 0 && this.state.selectedList.length === this.state.visits.length}
                            indeterminate = {this.state.selectedList.length > 0 && this.state.selectedList.length < this.state.visits.length}
                        />
                    </TableCell>
                        :null
                    }
                    <TableCell>Date</TableCell>
                    {this.props.userProfile === 1 ? <TableCell/> : null}
                    <TableCell></TableCell>
                    <TableCell padding="checkbox"/>
                </TableRow>
            </TableHead>
        );
    }
    
    createBody(){
        return(
            <TableBody>
                {this.state.visits.map((element) => (
                    <TableRow key={element.id} role="checkbox">
                        {this.props.del ?
                            <TableCell padding="checkbox">
                                <Checkbox
                                    id = {element.id}
                                    onChange={this.onCheck}
                                    checked={this.state.selectedList.indexOf(element.id) >= 0}
                                />
                            </TableCell>:
                            null
                        }
                        <TableCell component="th" scope="row">                            
                            {formatDate(element.date)}
                        </TableCell>
                        {this.props.userProfile === 1 && false?
                            <TableCell component="th" scope="row">                            
                                <Button color="primary" variant="contained" onClick={()=>this.onUpdateDate(element.id)}>
                                    Changer la date
                                </Button>
                            </TableCell>
                            :
                            null
                        }
                        <TableCell component="th" scope="row">                            
                            <Button color="primary" variant="contained" onClick={()=>this.onEditVisit(element.id)}>
                                {this.props.userProfile ===1 ?
                                    "Editer"
                                    :
                                    "Voir"
                                }
                            </Button>
                        </TableCell>
                        <TableCell padding="checkbox">
                            <IconButton>
                               <EditIcon
                                    id = {element.id}
                                    onClick={()=>this.onEdit(element.id)}
                                />
                            </IconButton>
                        </TableCell>
                    </TableRow>
                ))}
            </TableBody>
        );
    }


    render(){
          return (
            <div>
            {this.props.del || this.props.add ? this.createToolbar(): null}
            <TableContainer>
            <Table aria-label="simple table">
              {this.createHeader()}
              {this.createBody()}
            </Table>
            </TableContainer>   
            {this.state.del ?
                <OlindaMuiConfirm
                    title="Supression"
                    text={"Supprimer les élèments sélectionnés, êtes-vous sûr?"}
                    onValid = {this.onValidDelete}
                    onCancel = {this.onCancelDelete}
                />
                :null
            }
            {this.state.visitId !==0 && !this.state.updateDate ?
                <OlindaMuiForm
                    fields={this.visitFields}
                    title={"Visite Tuteur"}
                    subtitle={''}
                    assiduity={this.notes}
                    involvement={this.notes}
                    initiative={this.notes}
                    integration={this.notes}
                    onCancel={this.onCancelVisit}
                    onValid={this.onValidVisit}                    
                    readOnly={this.state.readOnly}
                />
                :null
            }
            {this.state.updateDate ?
                <OlindaMuiForm
                    fields={this.dateFields}
                    title={"Mise à jour de la date"}
                    onValid={this.onValidUpdateDate}
                    onCancel={this.onCancelUpdateDate}
                />
                :
                null
            }
            </div>
        );

    }

}

export default TutorVisit;
