import React from 'react'
import axios from 'axios'

import Button from '@material-ui/core/Button'


import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import OlindaMuiForm from '../components/form'
import OlindaMuiConfirm from '../components/confirm'
import OlindaMuiTable from '../components/table'

class ELearningEditor extends React.Component{
    constructor(props){
        super(props);
        this.state={
            addTitleItem:false,
            addQuestion:false,
            addQuestionItem:false,
            parentId:0,
            itemId:0,
            eLearning:null
        }
        this.questionFields = [
            {id:'title', label:'Titre', type:'input', value:'', mandatory:true}
        ];
        
        this.questionItemFields = [
            {id:'title', label:'Titre', type:'input', value:'', mandatory:true},
        ];
        this.onUpdateAnswerCorrect = this.onUpdateAnswerCorrect.bind(this)

        this.answersColumns=[
            {id:'title', title:'Titre'},
            {id:'isCorrect',title:'Bonne réponse', type:'check', cb:this.onUpdateAnswerCorrect}
        ]

        this.onAddTitle = this.onAddTitle.bind(this);
        this.onAddQuestion = this.onAddQuestion.bind(this);
        this.onAddQuestionItem = this.onAddQuestionItem.bind(this);
        
        this.onValidTitleItem = this.onValidTitleItem.bind(this)
        this.onCancelTitleItem = this.onCancelTitleItem.bind(this)
        
        this.onValidQuestionItem = this.onValidQuestionItem.bind(this)
        this.onCancelQuestionItem = this.onCancelQuestionItem.bind(this)
        
        this.onValidQuestion = this.onValidQuestion.bind(this)
        this.onCancelQuestion = this.onCancelQuestion.bind(this)

        this.onEditItem = this.onEditItem.bind(this);
        this.getItem = this.getItem.bind(this);
        this.onDeleteItems = this.onDeleteItems.bind(this);
        this.onDeleteItem = this.onDeleteItem.bind(this);


    }

    componentDidMount(){
        this.getELearning(this.props.eLearningId);
    }

    onEditTitle(){}

    onAddTitle(){
        this.setState({addTitleItem:true});
    }

    onEditItem(id){
        this.getItem(id);
    }

    onDeleteItems(ids){
        let datas = new FormData();
        datas.append('eLearningId', this.state.eLearning.id)
        for(let index = 0; index < ids.length; index++){
            datas.append('ids[]', ids[index]);
        }
        axios({
            method: 'post',
            url: global.site +'/elearning/deleteItem.php',
            data: datas
        })
        .then(res =>  {
            if(res.data["success"]){
                let eLearning = res.data["eLearning"];
                this.setState({addQuestionItem:false, itemId:0, parentId:0, eLearning:eLearning});
            }
        })
        .catch(function (error) {
            error.handleGlobally && error.handleGlobally();
        });        
    }

    onDeleteItem(id){
        let datas = new FormData();
        datas.append('eLearningId', this.state.eLearning.id)
        datas.append('ids[]', id);
        axios({
            method: 'post',
            url: global.site +'/elearning/deleteItem.php',
            data: datas
        })
        .then(res =>  {
            if(res.data["success"]){
                let eLearning = res.data["eLearning"];
                this.setState({addQuestionItem:false, itemId:0, parentId:0, eLearning:eLearning});
            }
        })
        .catch(function (error) {
            error.handleGlobally && error.handleGlobally();
        });
    }


    getItem(id){
        let request = global.site + '/elearning/getItem.php?id='+id;
        axios.get(request)
            .then(res => {
                if(res.data["success"]){
                    let item = res.data["item"];
                    let title = item["title"];
                    let addQuestion = false;
                    let addQuestionItem = false;
                    if(item["isQuestion"] == 1){
                        this.questionFields[0].value = title;
                        addQuestion = true;
                    }
                    else if(item["isChoice"] == 1){
                        this.questionItemFields[0].value=title;
                        addQuestionItem = true;
                    }
                    this.setState({itemId:id, addQuestion:addQuestion, addQuestionItem:addQuestionItem})
                }
             })
             .catch(function(error) {
                error.handleGlobally && error.handleGlobally();
        })
    }


    getELearning(id){
        let request = global.site + '/elearning/get.php?id='+id;
        axios.get(request)
            .then(res => {
                if(res.data["success"]){
                    let eLearning = res.data["eLearning"];
                    this.setState({eLearning:eLearning});
                }
             })
             .catch(function(error) {
                error.handleGlobally && error.handleGlobally();
        })
    }

    onUpdateAnswerCorrect(event){
        let target = event.target
        let datas = new FormData();
        datas.append('isCorrect', target.checked); 
        datas.append('itemId', target.id);
        datas.append('eLearningId', this.state.eLearning.id)
        axios({
            method: 'post',
            url: global.site +'/elearning/updateAnswerCorrect.php',
            data: datas
        })  
        .then(res =>  {
            if(res.data["success"]){
                let eLearning = res.data["eLearning"];
                this.setState({addQuestionItem:false, parentId:0, eLearning:eLearning});
            }   
        })  
        .catch(function (error) {
            error.handleGlobally && error.handleGlobally();
        }); 
        
    }


    onAddQuestion(parentId){
        this.setState({addQuestion:true, parentId:parentId})
    }

    onAddQuestionItem(parentId){
        this.setState({addQuestionItem:true, parentId:parentId})
    }

    onValidTitleItem(){
        let datas = new FormData();
        datas.append('isChoice', false);
        datas.append('isTitle', true);
        datas.append('isQuestion', false);
        datas.append('title', this.questionFields[0].value);
        datas.append('parentId', 0);
        datas.append('eLearningId', this.state.eLearning.id)
        if(this.state.itemId !== 0){
            datas.append('id', this.state.itemId);
        }
        axios({
            method: 'post',
            url: global.site +'/elearning/createItem.php',
            data: datas
        })
        .then(res =>  {
            if(res.data["success"]){
                let eLearning = res.data["eLearning"];
                this.setState({addTitleItem:false, itemId:0, parentId:0, eLearning:eLearning});
            }
        })
        .catch(function (error) {
            error.handleGlobally && error.handleGlobally();
        });

    }

    onCancelTitleItem(){
        this.setState({addTitleItem:false})
    }


    onValidQuestionItem(){
        let datas = new FormData();
        datas.append('isChoice', true);
        datas.append('isTitle', false);
        datas.append('isQuestion', false);
        datas.append('title', this.questionItemFields[0].value);
        datas.append('parentId', this.state.parentId);
        datas.append('eLearningId', this.state.eLearning.id)
        if(this.state.itemId !== 0){
            datas.append('id', this.state.itemId);
        }
        axios({
            method: 'post',
            url: global.site +'/elearning/createItem.php',
            data: datas
        })
        .then(res =>  {
            if(res.data["success"]){
                let eLearning = res.data["eLearning"];
                this.setState({addQuestionItem:false, itemId:0, parentId:0, eLearning:eLearning});
            }
        })
        .catch(function (error) {
            error.handleGlobally && error.handleGlobally();
        });

    }

    onCancelQuestionItem(){
        this.setState({addQuestionItem:false})
    }


    onValidQuestion(){
        let datas = new FormData();
        datas.append('isChoice', false);
        datas.append('isTitle', false);
        datas.append('isQuestion', true);
        datas.append('title', this.questionFields[0].value);
        datas.append('isCorrect', false);
        datas.append('parentId', this.state.parentId);
        datas.append('eLearningId', this.state.eLearning.id)
        if(this.state.itemId !== 0){
            datas.append('id', this.state.itemId);
        }
        axios({
            method: 'post',
            url: global.site +'/elearning/createItem.php',
            data: datas
        })
        .then(res =>  {
            if(res.data["success"]){
                let eLearning = res.data["eLearning"];
                this.setState({addQuestion:false, parentId:0, eLearning:eLearning});
            }
        })
        .catch(function (error) {
            error.handleGlobally && error.handleGlobally();
        });

    }

    onCancelQuestion(){
        this.setState({addQuestion:false, parentId:0})
    }

    createAddTitle(){
        return(
            <Button color="primary" variant="contained" onClick={()=>this.onAddTitle()}>{'Ajouter une section'}</Button>
        )
    }

    createAddQuestion(parentId){
        return(
            <Button color="primary" variant="contained" onClick={()=>this.onAddQuestion(parentId)}>{'Ajouter une question'}</Button>
        )
    }

    createAddQuestionItem(parentId){
        return(
            <Button color="primary" variant="contained" onClick={()=>this.onAddQuestionItem(parentId)}>{'Ajouter une réponse'}</Button>
        )
    }

    createAnswersList(answers, parentId){
        return(
            <OlindaMuiTable
                elements={answers}
                columns={this.answersColumns}
                del={true}
                force={true}
                add={true}
                onEdit={this.onEditItem}
                onDelete = {this.onDeleteItems}
                onAdd = {this.onAddQuestionItem}
                addParameters={parentId}
            />
        )
    }

    createItem(item){
        if(item.isTitle){
            return(
                <Card>
                    <CardHeader title={item.title}
                       action={
                                <div>
                                <IconButton aria-label="settings">
                                    <AddIcon  onClick={()=>this.onAddQuestion(item.id)}/>
                                </IconButton>
                                <IconButton aria-label="settings">
                                    <DeleteIcon  onClick={()=>this.onDeleteItem(item.id)}/>
                                </IconButton>
                                <IconButton aria-label="settings">
                                    <EditIcon  onClick={()=>this.onEditItem(item.id)}/>
                                </IconButton>
                                </div>
                        }
                    />
                    <CardContent>        
                        {item.items.map((item)=>(
                            this.createItem(item)
                        ))
                        }
                    </CardContent>
                </Card>
            )
        }
        else if(item.isQuestion){
            return(
               <div>
                    <Typography>
                        {item.title}
                    </Typography>
                    <div>
                        <IconButton aria-label="settings">
                            <DeleteIcon  onClick={()=>this.onDeleteItem(item.id)}/>
                        </IconButton>
                        <IconButton aria-label="settings">
                            <EditIcon  onClick={()=>this.onEditItem(item.id)}/>
                         </IconButton>
                    </div>
                    {this.createAnswersList(item.items, item.id)}
                </div>
            )
        }
    }


    createELearning(){
        return(
            <div>
                <Typography>{this.state.eLearning.title}</Typography>
                {this.createAddTitle()}
                {this.createAddQuestion(0)}
                {this.state.eLearning.items.map((item)=>(
                    this.createItem(item,0)
                ))
                }
            </div>
        )
    }

    render(){
        return(
            <div>
                {this.state.eLearning !== null ?
                    this.createELearning()
                    :
                    null
                }
                {this.state.addQuestionItem ?
                    <OlindaMuiForm
                        title="Ajouter une réponse"
                        fields={this.questionItemFields}
                        onValid={this.onValidQuestionItem}
                        onCancel={this.onCancelQuestionItem}
                        isCorrect={[{name:'Bonne réponse', id:1}]}
                    />
                    :
                    null
                }
                {this.state.addQuestion ?
                    <OlindaMuiForm
                        title="Ajouter une question"
                        fields={this.questionFields}
                        onValid={this.onValidQuestion}
                        onCancel={this.onCancelQuestion}
                    />
                    :
                    null
                }
                {this.state.addTitleItem ?
                    <OlindaMuiForm
                        title="Ajouter une section"
                        fields={this.questionFields}
                        onValid={this.onValidTitleItem}
                        onCancel={this.onCancelTitleItem}
                    />
                    :
                    null
                }
            </div>
        );
    
    }



}

export default ELearningEditor

