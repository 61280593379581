import React from 'react'
import axios from 'axios'
import {Link} from "react-router-dom";

import { Redirect, withRouter } from "react-router-dom";
import LinkedDocuments from '../documents/linkedDocuments'
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActionArea from '@material-ui/core/CardActionArea';
import Grid from '@material-ui/core/Grid';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import OlindaMuiForm from '../components/form.js'

import StudentProfile from './studentProfile'
import TutoringReport from '../tutoringReport/tutoringReport.js'

import qualiopi_blanc from '../logos/qualiopi_b.png'
import logo_fae from '../logos/fae.png'
import cpne from '../logos/cpne.png'
import ministere from '../logos/ministere.jpg'
import sport_occitanie from '../logos/sport_occitanie.jpg'
import ffe from '../logos/ffe.jpg'
import hippothese from '../logos/hippothese.jpg'
import ouspins from '../logos/ouspins.png'


class StudentTutorItem extends React.Component{
    constructor(props){
        super(props);
        this.state={
            reports:[],
            tutoringReports:[],
            reportId:0,
            tutoringReportId:0
        }
        this.formLogos={"bp":[{logo:ministere, width:135, height:75}],
                        "cqp":[{logo:cpne, width:220, height:75}],
                        "ae":[{logo:ffe, width:90, height:75},
                             {logo:hippothese, width:120, height:75}],
                        "ae-ouspins":[{logo:ffe, width:90, height:75},
                             {logo:ouspins, width:180, height:75}]
                        }


        this.reportNotes = [
            {id:0, name:''},
            {id:1, name:'Très Insuffisant'},
            {id:2, name:'Insuffisant'},
            {id:3, name:'Correct'},
            {id:4, name:'Satisfaisant'},
            {id:5, name:'Supérieur'}
        ]



        this.reportPartFields = [
            {id:'assiduity', label:'Assiduité', type:'select', value:1, 'mandatory':true},
            {id:'involvement', label:'Investissement', type:'select', value:1, 'mandatory':true},
            {id:'initiative', label:'Initiative', type:'select', value:1, 'mandatory':true},
            {id:'insertion', label:'Insertion', type:'select',value:1, 'mandatory':true},
            {id:'global', label:'Global', type:'select',value:1, 'mandatory':true},
            {id:'comment', label:'Commentaire', type:'multiline',value:''}
        ]


        this.getReport = this.getReport.bind(this);
        this.onValidReport = this.onValidReport.bind(this);
        this.onCancelReport = this.onCancelReport.bind(this);

        this.getTutoringReport = this.getTutoringReport.bind(this);
        this.onCloseTutoringReport = this.onCloseTutoringReport.bind(this);
    }

    dateToMonth(value){
        const date = new Date(value);
        return date.toLocaleDateString('fr-fr', {month:'long', year:'numeric'});

    }

    componentDidMount(){
        this.getUnsignedList();
    }

    getReport(id){
        axios.get(global.site +'/report/get.php?id=' + id )
            .then(res => {
                if(res.data["success"]){
                    this.reportPartFields.map((field)=>(
                        field.value = res.data[field.id] === null && field.id !== "comment" ? 1 : res.data[field.id]
                    ))
                    this.reportPartFields.map((field)=>(
                        field.error = undefined
                    ))
                    this.setState({reportId:id})
                }
             })
             .catch(function(error){
            error.handleGlobally && error.handleGlobally();
            })    
    }

    getTutoringReport(id){
        console.log(id);
        this.setState({tutoringReportId:id});
    }

    onCloseTutoringReport(){
        this.setState({tutoringReportId:0});
    }

    onValidReport(){
        let datas = new FormData();
        this.reportPartFields.map((field) =>(
            datas.append(field.id, field.value)
        ))  
        datas.append("sign", true);
        datas.append("id", this.state.reportId);
        datas.append("firstName", this.props.studentFirstName);
        datas.append("lastName", this.props.studentLastName);
        datas.append("trainingId", this.props.trainingId);
        datas.append("studentId", this.props.studentId);
        axios({
            withCredentials:true,
            method: 'post',
            url: global.site +'/report/update.php',
            data: datas
        })  
        .then(res =>  {
            if(res.data["success"]){
                let reports = res.data["reports"] 
                this.setState({reportId:0, reports:reports});
            }   
        })  
        .catch(function (error) {
            error.handleGlobally && error.handleGlobally();
        });     
    }


    onCancelReport(){
        this.setState({reportId:0});
    }

    getUnsignedList(){
        let request = global.site + '/student/unsignedList.php?trainingId='+this.props.trainingId+'&studentId='+this.props.studentId;
        axios.get(request)
            .then(res => {
                if(res.data["success"]){
                    const reports = res.data["reports"];
                    const tutoringReports = res.data["tutoringReports"];
                    this.setState({reports:reports, tutoringReports:tutoringReports});
                }
             })
             .catch(function(error) {
                 error.handleGlobally && error.handleGlobally();
             })

    }

    addLogos(training){
        var lTraining = training.toLowerCase();
        const keys = Object.keys(this.formLogos)
        var formLogos = undefined

        if(lTraining.includes("bp")){
            formLogos = this.formLogos["bp"];
        }
        else if(lTraining.includes("cqp")){
            formLogos = this.formLogos["cqp"];
        }
        else if(lTraining.includes("ae") && lTraining.includes("ous pins")){
            formLogos = this.formLogos["ae-ouspins"];
        }
        else if(lTraining.includes("ae")){
            formLogos = this.formLogos["ae"];
        }
        if(formLogos !== undefined){
            return(
                <div style={{margin:'2%'}}>
                {formLogos.map((logo)=>(
                    <img
                        src={logo.logo}
                        width={logo.width}
                        height={logo.height}
                    />
                ))}
                </div>
            );
        }
        else{
            return(null);
        }

    }

    createUnsignedReports(reports){
       if(reports.length>0){
            return(         
                <div>
                <Typography variant="h6">Bilans Mensuels</Typography>
                {reports.map((report)=>(
                    <Button variant="contained" onClick={()=>this.getReport(report.tutorPartId)}>{this.dateToMonth(report.date)}</Button>
                ))
                
                }
                </div>
            )
       } 
    }
    
    createUnsignedTutoringReports(reports){
       if(reports.length>0){
            return(         
                <div>
                <Typography variant="h6">Evaluations Formatives</Typography>
                {reports.map((report)=>(
                    <div>
                    <Button variant="contained" onClick={()=>this.getTutoringReport(report.id)}>{report.title}</Button>
                    </div>
                ))
                
                }
                </div>
            )
       } 
    }

    render(){
        return(
            <div>
                {this.state.reportId > 0 ?
                    <OlindaMuiForm
                        fields={this.reportPartFields}
                        title={"Bilan Mensuel Tuteur"}
                        assiduity={this.reportNotes}
                        involvement={this.reportNotes}
                        initiative={this.reportNotes}
                        insertion={this.reportNotes}
                        global={this.reportNotes}
                        onCancel={this.onCancelReport}
                        onValid={this.onValidReport}
                    />

                    :
                    null
                }
                {this.state.tutoringReporId !== 0 ?
                    <TutoringReport
                        reportProfile={2}
                        reportId={this.state.tutoringReportId}
                        studentId={this.props.studentId}
                        onClose = {this.onCloseTutoringReport}
                        userProfile = {2}
                     />

                        :
                        null
                }
                <Card>
                    <CardActionArea
                        component={Link}
                        to={'/student/' + this.props.studentId + '/training/' + this.props.trainingId}
                     >
                     <CardHeader
                        title={this.props.firstName + ' ' + this.props.lastName}
                        subheader={this.props.training}
                        avatar={this.props.avatar !== undefined && this.props.avatar.length > 0 && this.props.avatar[0].link !== undefined?
                        <Avatar
                            src={this.props.avatar[0].link}
                        />
                        :
                        <Avatar>
                            {this.props.firstName[0] + this.props.lastName[0]}
                        </Avatar>
                    }

                     />
                     </CardActionArea>
                     <CardContent>
                        {this.addLogos(this.props.training)}
                        {this.createUnsignedReports(this.state.reports)}
                        {this.createUnsignedTutoringReports(this.state.tutoringReports)}
                     </CardContent>
                 </Card>
            </div>
        );
    
    }

}

export default withRouter(StudentTutorItem)
