import React from 'react'
import axios from 'axios'


import OlindaMuiForm from '../components/form'
import OlindaMuiMediaList from '../components/mediaList'


class Videos extends React.Component{
    constructor(props){
        super(props);
        this.state={
            videos:[],
            addVideo:false,
        }

        /*Training part */
        this.formFields = [
            {id:'name', label:'Nom', type:'input', value:''},
            {id:'link', label:'Lien', type:'input', value:''}
        ];
        this.onAddVideo = this.onAddVideo.bind(this);
        this.onDeleteVideo = this.onDeleteVideo.bind(this);
        this.onCancelAddVideo = this.onCancelAddVideo.bind(this);
        this.onValidAddVideo = this.onValidAddVideo.bind(this);
    }

    /*Click on create button*/
    onAddVideo()
    {
        this.formFields = [
            {id:'name', label:'Nom', type:'input', value:''},
            {id:'link', label:'Lien', type:'input', value:''}
        ];
        this.setState({addVideo:true});
    }
    
    /*Valid the creation or the edition of a training*/
    onValidAddVideo(fields)
    {

        let links = fields[1].value.split('=');
        let link = fields[1].value;
        if(links.length >= 2){
            link = 'https://www.youtube.fr/embed/'+links[1];
            fields[1].value = link;
        }

        let datas = new FormData();
        fields.map(field=>{
            datas.append(field.id, field.value)
        })
        datas.append('type', this.props.type);
        datas.append('id', this.props.userId);
        axios({
            method: 'post',
            url: global.site +'/videos/add.php',
            data: datas
        })
        .then(res =>  {
            if(res.data["success"]){
                const videos = res.data['videos']
                this.setState({addVideo:false, videos:videos});
            }
        })
        .catch(function (error) {
            error.handleGlobally && error.handleGlobally();
        });
    }

    /*Cancel the creation or edition of a training*/
    onCancelAddVideo(){
        this.setState({addVideo:false});
    }
    
    onDeleteVideo(id)
    {
        let datas = new FormData();
        datas.append('type', this.props.type);
        datas.append('video', id);
        datas.append('id', this.props.userId);
        axios({
            method: 'post',
            url: global.site +'/videos/delete.php',
            data: datas
        })
        .then(res =>  {
            if(res.data["success"]){
                const videos = res.data['videos']
                this.setState({addVideo:false, videos:videos});
            }
        })
        .catch(function (error) {
            error.handleGlobally && error.handleGlobally();
        });
    }

    /* Valid the deletion of the training */
    onValidDeleteVideo()
    {
    }
    
    componentDidMount()
    {
        this.getVideos();
    }

    getVideos()
    {
        axios.get(global.site +'/videos/list.php?type=' + this.props.type + '&id=' + this.props.userId)
            .then(res => {
                const videos = res.data["videos"];
                this.setState({videos:videos});
             })
             .catch(function(error) {
                error.handleGlobally && error.handleGlobally();
        })
    }
    
    render(){
        return(
            <div>
                <OlindaMuiMediaList
                    elements = {this.state.videos}
                    read = {false}
                    add = {true}
                    del={this.props.del}
                    edit={false}
                    field = 'name'
                    link = {true}
                    check={this.props.check}
                    onToggleCheck={this.props.onToggleCheck}
                    onAdd = {this.onAddVideo}
                    onShow = {this.onEditVideo}
                    onDelete = {this.onDeleteVideo}
                />

                {this.state.addVideo ? 
                    <OlindaMuiForm 
                        fields={this.formFields}
                        title="Ajouter un document"
                        onCancel={this.onCancelAddVideo} 
                        onValid={this.onValidAddVideo}/> 
                    : null}
            </div>
        );
    
    }



}

export default Videos

