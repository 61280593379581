import React from 'react'

import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';

import Documents from '../documents/documents'

class DocumentsMgmt extends React.Component{
    constructor(props){
        super(props);
    }


    createDocuments(){
        return(
            <Card>
                <CardHeader
                    title="Mes documents"
                />
                <CardContent>
                    <Documents 
                        userId={this.props.user.id}
                        type={this.props.type}
                    />
                </CardContent>
            </Card>
        );
    }

    render(){
        return(
            <Documents 
                userId={this.props.userId}
                add={!this.props.readOnly}
                del={!this.props.readOnly}
                edit={false}
                type={this.props.type}
                signature={this.props.signature}
                picture={this.props.picture}
                directory={this.props.directory}
            />
        ); 
    }

}

export default DocumentsMgmt
