import React from 'react'
import axios from 'axios'

import { withRouter, Link } from "react-router-dom";

import OlindaMuiForm from '../components/form'
import OlindaMuiTable from '../components/table'
import TutoringReportDetails from './tutoringReportDetails.js'
import TutoringReportForm from './tutoringReportForm.js'


class TutoringReport extends React.Component{
    constructor(props){
        super(props);
        this.state={
            reports:[],
            reportsList:[],
            createReport:false,
            studentId:0,
            studentReport:null,
            reportId:0
        }
        this.reportFields = [
            {id:'title', label:'Titre', type:'input', value:'', mandatory:true}
        ];

        this.studentReportFields = [
            {id:'id', label: 'Evaluation', type:'select', value:'', mandatory:true}
        ]

       this.reportColumns = [
            {id:'title', title:'Titre'}
        ];
        this.nbUpdate = 0;
        this.nbUpdated = 0;
        this.onCreateReport = this.onCreateReport.bind(this);
        this.onValidCreateReport = this.onValidCreateReport.bind(this);
        this.onCancelCreateReport = this.onCancelCreateReport.bind(this);
        this.onDeleteReport = this.onDeleteReport.bind(this);
        this.onEditReport = this.onEditReport.bind(this);
        this.onViewReport = this.onViewReport.bind(this);

        this.onValidForm = this.onValidForm.bind(this);
        this.onCancelForm = this.onCancelForm.bind(this);
        this.saveReport = this.saveReport.bind(this);
        if(this.props.studentId !== undefined){
            this.reportColumns = [
                {id:'title', title:'Type'}
            ];
            this.reportColumns.push({id:'date', title:'Date', type:'date'});
            this.reportColumns.push({id:'pdf', title:'PDF', type:'pdf', cb:this.onViewReport});
        }
    }

    componentDidUpdate(props){
        if(this.props.reportId !== props.reportId){
            if(this.props.reportId === undefined || this.props.reportId === 0){
                this.setState({reportId:0});
            }
            else{
                if(this.props.studentId === undefined && this.props.reportId > 0){
                    this.getReport(this.props.reportId);
                }
                else if(this.props.reportId > 0){
                    this.getReportForStudent(this.props.reportId, this.props.studentId);
                }
            }
        }
    }

    componentDidMount()
    {
        let studentId = this.props.studentId;
        let reportId = this.props.reportId;
        if(reportId !== undefined && studentId !== undefined && reportId > 0){
            this.getReportForStudent(reportId, studentId);
        }
        else{
            if(this.props.userProfile === this.props.reportProfile || this.props.userProfile === 1){
                this.getReports();
            }
            if(studentId !== undefined && this.props.trainingId !== undefined){
                this.getReportsForStudent();
            }
        }
        if(studentId === undefined){
            this.setState({studentId:undefined});
        }
    }

    onEditReport(id){
        if(this.props.studentId === undefined){
            this.getReport(id);
            this.props.onEdit();
        }
        else{
            this.getReportForStudent(id, this.props.studentId);
        }
    }

    onViewReport(id){
         window.open(global.site + '/tutoringReport/edit.php?id='+id, "_blank");
    }

    getReports(){
        let request = global.site + '/tutoringReport/list.php?type=report&profile=' + this.props.reportProfile;
        axios.get(request)
            .then(res => {
                const reports = res.data["reports"];
                if(this.props.studentId === undefined){
                    this.setState({reports:reports, reportId:0});
                }
                else{
                    if(reports.length > 0){
                        this.studentReportFields[0].value=reports[0].id;
                    }
                    this.setState({reportsList:reports, reportId:0});
                }
             })
             .catch(function(error) {
                error.handleGlobally && error.handleGlobally();
        }) 
    }
    
    getReportsForStudent(){
        let request = global.site + '/tutoringReport/list.php?type=report&studentId=' + this.props.studentId+'&trainingId=' + this.props.trainingId + '&profile=' + this.props.reportProfile;
        axios.get(request)
            .then(res => {
                const reports = res.data["reports"];
                this.setState({reports:reports, reportId:0});
             })
             .catch(function(error) {
                error.handleGlobally && error.handleGlobally();
        }) 
    }

    getReportForStudent(id, studentId){
        let request = global.site + '/tutoringReport/get.php?type=report&id='+id + '&studentId=' + studentId;
        axios.get(request)
            .then(res => {
                if(res.data["success"]){
                    this.setState({studentReport:res.data, reportId:id})
                }
             })
             .catch(function(error) {
                error.handleGlobally && error.handleGlobally();
        }) 

    }

    getReport(id){
        let request = global.site + '/tutoringReport/get.php?type=report&id='+id;
        axios.get(request)
            .then(res => {
                if(res.data["success"]){
                    this.setState({reportTitle: res.data["title"], reportId:id})
                }
             })
             .catch(function(error) {
                error.handleGlobally && error.handleGlobally();
        }) 
    }

    onValidCreateReport(fields){
        let datas = new FormData();
        fields.map((field)=>(
            datas.append(field.id, field.value)
        ))
        datas.append('type', 'report');
        if(this.props.studentId !== undefined){
            datas.append('studentId', this.props.studentId);
        }
        if(this.props.trainingId !== undefined){
            datas.append('trainingId', this.props.trainingId);
        }
        if(this.props.reportProfile !== undefined){
            datas.append('profile', this.props.reportProfile);
        }
        axios({
            method: 'post',
            url: global.site +'/tutoringReport/create.php',
            data: datas
        })
        .then(res =>  {
            if(res.data["success"]){
                let reports = res.data["reports"];
                this.setState({createReport:false, reports:reports});
            }
        })
        .catch(function (error) {
            error.handleGlobally && error.handleGlobally();
        });
        
    }

    updateSubItem(item, hasComment){
        let datas = new FormData();
        datas.append('id', item.id);
        datas.append('type', 'item');
        this.nbUpdate = this.nbUpdate + 1;
        if(this.props.reportProfile < 4 || this.props.userProfile <3 || this.props.reportProfile === 6){
            datas.append('result', item.result);
            if(hasComment){
                datas.append('comment', item.comment);
                if(this.props.reportProfile === 4 || this.props.reportProfile === 5){
                    datas.append('improveComment', item.improveComment);            
                }
            }
        }
        else if (this.props.userProfile === 3 && this.props.reportProfile>=4){
            datas.append('studentComment', item.studentComment);            
        }
        axios({
            method: 'post',
            url: global.site+ '/tutoringReport/update.php',
            data:datas
        })
        .then(res =>{
            this.nbUpdated = this.nbUpdated + 1;
            if(this.nbUpdated == this.nbUpdate){               
                this.updateReport()
            }
        })
        .catch(function (error) {
            error.handleGlobally && error.handleGlobally();
        });
    }

    updateItem(item){
        let datas = new FormData();
        datas.append('id', item.id);
        datas.append('comment', item.comment);
        datas.append('type', 'item');
        axios({
            method: 'post',
            url: global.site+ '/tutoringReport/update.php',
            data:datas
        })
        .then(res =>{
            if(res.data["success"]){
                item.items.map((subItem)=>(
                    this.updateSubItem(subItem, false)
                ))
                this.nbUpdated = this.nbUpdated + 1;
                if(this.nbUpdated == this.nbUpdate){               
                    this.updateReport();
                }
            }
        })
        .catch(function (error) {
            error.handleGlobally && error.handleGlobally();
        });
    }

    updateReport(){
        this.nbUpdate = 0;
        this.nbUpdated = 0;
        let datas = new FormData();
        let id = this.state.studentReport.id;
        datas.append('reportId', this.state.studentReport.id);
        datas.append('type', 'report');
        axios({
            method: 'post',
            url: global.site+ '/tutoringReport/update.php',
            data:datas
        })
        .then(res =>{
            if(this.props.trainingId !== undefined){
                this.getReportsForStudent();
            }
            this.saveReport(id)
            if(this.props.onClose !== undefined){
                this.props.onClose();
            }
        }) 
        .catch(function (error) {
            error.handleGlobally && error.handleGlobally();
        });
    }

    saveReport(id){
        let datas = new FormData();
        datas.append('reportId', id);
        datas.append('studentId', this.props.studentId);
        datas.append('trainingId', this.props.trainingId);
        
        axios({
            method: 'post',
            url: global.site+ '/tutoringReport/save.php',
            data:datas
        })
        .then(res =>{
        }) 
        .catch(function (error) {
            error.handleGlobally && error.handleGlobally();
        });
    }

    countItem(item){
        if(item.items == undefined){
            return;
        }
        item.items.map((it)=>(
            this.nbUpdate += this.nbUpdate,
            this.countItem(it)
        ));
    }

    onValidForm(report){
        report.items.map((item)=>(
            this.countItem(item),
            this.nbUpdate = this.nbUpdate + 1
        ));
        if(report.profile >= 4 && report.profile < 6){
            report.items.map((item)=>(
                this.updateSubItem(item, true)
             ));
        }
        else{
            report.items.map((item)=>(
                this.updateItem(item)
             ));
        }
        if(this.props.userProfile !== 3){
            this.updateReport();
        }
        else{
            this.setState({reportId:0});
        }
    }

    
    onCancelForm(fields){
        this.setState({reportId:0});
        if(this.props.onClose !== undefined){
            this.props.onClose();
        }
    }

    onCreateReport(){
        this.setState({createReport:true});
    }

    onCancelCreateReport(){
        this.setState({createReport:false});
    }
    
    onDeleteReport(ids){
        let datas = new FormData();
        for(let i = 0; i < ids.length; i++){
            datas.append('ids[]', ids[i]);
        }
        datas.append('type', 'report');
        datas.append('profile', this.props.reportProfile);
        if(this.props.studentId !== undefined){
            datas.append('studentId', this.props.studentId);
            datas.append('trainingId', this.props.trainingId);
        }

        axios({
            method: 'post',
            url: global.site +'/tutoringReport/delete.php',
            data: datas
        })
        .then(res =>  {
            if(res.data["success"]){
                let reports = res.data["reports"]
                this.setState({reports:reports, reportId:0, reportTitle:''});
            }
        })
        .catch(function (error) {
            error.handleGlobally && error.handleGlobally();
        });
        
    }

    onBackToList(){
        if(this.props.studentId === undefined){
            this.getReports();
            this.props.onBack();
        }
    }

    createDetails(){
        let readOnly = false;
        if(this.props.reportProfile === 1 || this.props.reportProfile ===2){
            readOnly = this.props.reportProfile !== this.props.userProfile;
        }
        else if(this.props.reportProfile === 3){
            readOnly = this.props.userProfile !== 1;
        }
        else if(this.props.reportProfile === 4){
            readOnly = this.props.userProfile !== 1 && this.props.userProfile !== 3; 
        }
        else if(this.props.reportProfile === 6){
            readOnly = this.props.userProfile !== 1 && this.props.userProfile !== 5; 
        }
        else{
            readOnly = this.props.userProfile !== 2 && this.props.userProfile !== 3; 
        }
        if(this.state.reportId !== 0 ){
            if(this.state.studentReport !== null && this.props.reportProfile !== 1 && this.props.userProfile !== 1){
                let reportDate = new Date(this.state.studentReport["date"]);
                let now = new Date();
                let diffDate = now - reportDate;
                readOnly = this.state.studentReport["author"] != null;
                //readOnly = diffDate > 172800000;

            }
            if(this.props.studentId !== undefined){
                return(
                    <TutoringReportForm
                        report={this.state.studentReport}
                        studentId={this.props.studentId}
                        readOnly={readOnly}
                        onCancel={this.onCancelForm}
                        onValid={this.onValidForm}
                        userProfile = {this.props.userProfile}
                        reportProfile = {this.props.reportProfile}
                    />
                )
            }
            else{
                return(
                    <div>
                    <Link onClick={()=>this.onBackToList()}>Liste des évaluations</Link>
                    <TutoringReportDetails
                        reportId={this.state.reportId}
                        studentId={this.props.studentId}
                        result={this.state.result}
                        reportTitle={this.state.reportTitle}
                        userProfile={this.props.userProfile}
                        reportProfile={this.props.reportProfile}
                    />
                    </div>
                );
            }
        }
    }

    render(){
        return(
            <div>
                {this.state.createReport ?
                    <OlindaMuiForm
                        title="Evaluation Formative"
                        fields={this.props.studentId===undefined?this.reportFields:this.studentReportFields}
                        onValid={this.onValidCreateReport}
                        onCancel={this.onCancelCreateReport}
                        id={this.state.reportsList}
                    />
                    :null
                }

                {this.state.reportId !== 0 ?
                    <div>
                        {this.createDetails()}
                    </div>
                    :
                       null
                }
                {(this.state.reportId === 0 && (this.props.reportId === undefined)) ?
                    <div>
                    <OlindaMuiTable
                        elements={this.state.reports}
                        columns={this.reportColumns}
                        del={this.props.userProfile === 1}
                        force={true}
                        add={this.props.userProfile === 1}
                        readOnly={this.props.userProfile !== 1}
                        onAdd={this.onCreateReport}
                        onDelete={this.onDeleteReport}
                        onEdit={this.onEditReport}
                    />
                    </div>
                        :
                        null
                }
                
            </div>
        );
    
    }



}

export default withRouter(TutoringReport)

