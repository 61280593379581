import React from 'react'
import axios from 'axios'
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';

import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import TextField from '@material-ui/core/TextField';

import PeriodicItemTable from './periodicItemTable'

class PeriodicReportFormItem extends React.Component{
    constructor(props){
        super(props);
        this.state={
            item:this.props.item,
            itemId:0
        }
        this.itemFields = [
            {id:'title', label:'Titre', type:'input', value:''}
        ];

        this.validationFields={title:'Résultats', values:[{id:1, title:'Acquis'},{id:2, title:'Non-Acquis'}], readOnly:this.props.userProfile ===1};

        this.results=[{id:0, name:''}, {id:1, name:'Acquis'}, {id:2, name:'Non Acquis'}];

        this.itemColumns = [
            {id:'title', title:'Titre'},
            {id:'result', title:'Résultat'}
        ];
    }

    onSetNote(event){
        const itemId = parseInt(event.target.name);
        let result = parseInt(event.target.value);
        if(isNaN(result)){
            result = null;
        }
        let item = this.state.item;
        item.result = result;
        this.setState({item:item})
    }

    onUpdateComment(){
    }

    handleChange(event){
        const target = event.target;
        const id = target.id;
        var value = target.value;
        let item=this.state.item;
        item[id] = value;
        this.setState({item:item});

    }


    createRadio(element){
        return(
            <RadioGroup row name={element.id} value={element.result} onChange={(event)=>this.onSetNote(event)}>
                    <FormControlLabel
                        value={1}
                        name={element.id}
                        disabled={this.props.userProfile === 3 || (this.props.reportProfile === 4 && this.props.userProfile === 2)|| (this.props.reportProfile === 5 && this.props.userProfile == 1) || this.props.userProfile === 4}
                        control={<Radio />}
                        label={'A (Très Satisfaisant)'}
                    />
                    <FormControlLabel
                        value={2}
                        name={element.id}
                        disabled={this.props.userProfile === 3 || (this.props.reportProfile === 4 && this.props.userProfile === 2)|| (this.props.reportProfile === 5 && this.props.userProfile == 1) || this.props.userProfile === 4}
                        control={<Radio />}
                        label={'B (Satisfaisant)'}
                    />
                    <FormControlLabel
                        value={3}
                        name={element.id}
                        disabled={this.props.userProfile === 3 || (this.props.reportProfile === 4 && this.props.userProfile === 2)|| (this.props.reportProfile === 5 && this.props.userProfile == 1) || this.props.userProfile === 4}
                        control={<Radio />}
                        label={'C (Insuffisant)'}
                    />
                    <FormControlLabel
                        value={4}
                        name={element.id}
                        disabled={this.props.userProfile === 3 || (this.props.reportProfile === 4 && this.props.userProfile === 2)|| (this.props.reportProfile === 5 && this.props.userProfile == 1) || this.props.userProfile === 4}
                        control={<Radio />}
                        label={'D (Très Insuffisant)'}
                    />
            </RadioGroup>
        );
    }


    createComment(comment, label, readOnly, field){
        return(
            <TextField
                margin="dense"
                label={label}
                value={comment !== 'null' ? comment: ''}
                id={field}
                fullWidth
                multiline
                rows={4}
                onChange={(event)=>this.handleChange(event)}
                InputLabelProps={{
                    shrink: true,
                }}
                InputProps={{
                    readOnly:readOnly,
                }}
            />
        );
    }

    render(){
        let readOnlyComment = this.props.userProfile === 4 ||this.props.userProfile === 3 || (this.props.reportProfile === 4 && this.props.userProfile === 2) || (this.props.reportProfile ===5 && this.props.userProfile===1);
        let readOnlyCommentStudent = this.props.userProfile !== 3;
        return(
                <div>
                    <Card>
                       <CardHeader title={this.state.item.title} subheader={this.state.item.description}/>
                        <CardContent>
                            <PeriodicItemTable
                                items={this.state.item.items}
                                columns={this.itemColumns}
                                onChange = {this.onChangeResult}
                                readOnly={this.props.readOnly}
                                onSetNote={(event)=>this.onSetNote(event)}
                            />
                            {this.createRadio(this.state.item)}
                            {this.createComment(this.state.item.comment, this.props.reportProfile===4?'Formateur':'Tuteur', readOnlyComment, 'comment')}
                            {this.createComment(this.state.item.improveComment, 'Amélioration', readOnlyComment, 'improveComment')}
                            {this.createComment(this.state.item.studentComment, 'Stagiaire', readOnlyCommentStudent, 'studentComment')}
                        </CardContent>
                    </Card>
                </div>
        );
    
    }



}

export default PeriodicReportFormItem

