import React from 'react'
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import ColorPicker from 'material-ui-color-picker'
import OlindaMuiForm from './form.js'

class OlindaMuiFlatForm extends React.Component{
    constructor(props){
        super(props);
    }

    createField(field){
        if(!field.hide){
            return(
                <TextField
                    margin="dense"
                    label={field.label}
                    value={field.value}
                    format={field.format}
                    id={field.id}
                    fullWidth
                    type = {field.type}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    InputProps={{
                        readOnly:true,
                    }}
                    error = {field.error !== undefined}
                    helperText={field.error}              
                />
            );
        }
    }

    render(){
        
        return (
            <div style={{background:'transparent'}}>
                {this.props.fields.map((field) => this.createField(field))}
                {this.props.readOnly ?
                 null:
                 <div>
                    <Button color="primary" variant="contained" onClick={()=>this.props.onModify()}>{this.props.buttonTitle}</Button>
                 </div>
                }
            </div>
        );
    }
}


export default OlindaMuiFlatForm;
