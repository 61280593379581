import React from 'react'
import axios from 'axios'

import Breadcrumbs from '@material-ui/core/Breadcrumbs'

import Typography from '@material-ui/core/Typography';

import { Link,withRouter } from "react-router-dom";

import OlindaMuiForm from '../components/form'
import OlindaMuiTable from '../components/table'
import OlindaMuiCardList from '../components/cardList.js'
import Profile from '../profile/profile.js'

class User extends React.Component{
    constructor(props){
        super(props);
        this.state={
            users:[],
            userId:0,
            user:null,
            error:'',
            createUser:false
        }

        /*Training part */
        this.userFields = [
            {id:'firstName', label:'Prénom', type:'input', value:'', mandatory:true},
            {id:'lastName', label:'Nom', type:'input', value:'', mandatory:true},
            {id:'phone', label:'Téléphone', type:'input', value:''},
            {id:'mail', label:'Mail', type:'input', value:'', mandatory:true}
        ];
       
        this.links=['teacher', 'tutor', 'student', 'guest', 'examinator'];
        this.title=['Formateurs', 'Tuteurs', 'Stagiaires', 'Invités', "Examinateurs"];

        this.userColumns = [
            {id:'firstName', title:'Prénom'},
            {id:'lastName', title:'Nom'},
            {id:'lastConnection', title:'Dernière Connexion', type:'date'}
        ];

        this.onCreateUser = this.onCreateUser.bind(this);
        this.onValidCreateUser = this.onValidCreateUser.bind(this);
        this.onCancelCreateUser = this.onCancelCreateUser.bind(this);
        this.onDeleteUser = this.onDeleteUser.bind(this);
        this.onSendPassword = this.onSendPassword.bind(this);
        if (this.props.userProfile === 1){
            this.userColumns.push({id:'sendpass', title:'Mot de passe', type:'button', cb:this.onSendPassword});
        }
        this.trainings=[{id:0, name:''}];
        if(this.props.profile === 3 && this.props.trainings !== undefined){
            this.userFields.push({id:'training', type:'select', label:'Formation', value:0});
            for (let index = 0; index < this.props.trainings.length; index++){
                let training=this.props.trainings[index];
                this.trainings.push({id:training.id, name:training.name});
            }
        }
    }

    /*Edit the current training*/
    getUser(id){
        axios.get(global.site +'/user/get.php?id='+ id + '&profile=' + this.props.profile)
            .then(res => {
                if(res.data["success"]){
                    this.setState({user:res.data, userId:id})
                }
                else{
                    global.handleRequestError(res.data["error"]);
                }
             })
             .catch(function(error) {
                error.handleGlobally && error.handleGlobally();
        })

    }

    onSendPassword(id){
        let datas = new FormData();
        datas.append("userId", id);
        datas.append('profile', this.props.profile);
        axios({
            withCredentials:true,
            method: 'post',
            url: global.site +'/user/sendPassword.php',
            data: datas
        })
        .catch(function (error) {
            error.handleGlobally && error.handleGlobally();
        });
    }
    
    /*Click on create button*/
    onCreateUser()
    {
        this.userFields = [
            {id:'firstName', label:'Prénom', type:'input', value:'', mandatory:true},
            {id:'lastName', label:'Nom', type:'input', value:'', mandatory:true},
            {id:'phone', label:'Téléphone', type:'input', value:''},
            {id:'mail', label:'Mail', type:'mail', value:'', mandatory:true}
        ];
        if(this.props.profile === 3){
            this.userFields.push({id:'training', type:'select', label:'Formation', value:0});
        }
        this.setState({createUser:true, userId:0, error:''});
    }
    
    /*Valid the creation or the edition of a training*/
    onValidCreateUser(fields)
    {
        let datas = new FormData();
        fields.map((field)=>(
            datas.append(field.id, field.value)
        ))
        if(this.state.userId !== 0){
            datas.append("id", this.state.userId);
        }
        datas.append('profile', this.props.profile);
        axios({
            withCredentials:true,
            method: 'post',
            url: global.site +'/user/create.php',
            data: datas
        })
        .then(res =>  {
            if(res.data["success"]){
                let users = res.data["users"]
                this.setState({createUser:false, users:users, error:false});
            }
            else{
                var index;
                for(index = 0; index< this.userFields.length; index++){
                    const id = this.userFields[index].id;
                    if(res.data[id] !== undefined){
                        this.userFields[index].error = res.data[id]
                    }
                }
                this.setState({error:true});
            }
        })
        .catch(function (error) {
            error.handleGlobally && error.handleGlobally();
        });
    }

    /*Cancel the creation or edition of a training*/
    onCancelCreateUser(){
        this.setState({createUser:false});
    }
    
    /*Delete the current training*/
    onDeleteUser(ids)
    {
        let datas = new FormData();
        for(let i = 0; i < ids.length; i++){
            datas.append('ids[]', ids[i]);
        }
        datas.append('profile', this.props.profile);
        axios({
            method: 'post',
            url: global.site +'/user/delete.php',
            data: datas
        })
        .then(res =>  {
            if(res.data["success"]){
                let users = res.data["users"]
                this.setState({users:users});
            }
            else{
                global.handleRequestError(res.data["error"]);
            }
        })
        .catch(function (error) {
            error.handleGlobally && error.handleGlobally();
        });


    }

    componentDidMount(){
        if(this.props.match.params.userId === undefined){
            this.getUsersList();
        }
    }    

    componentDidUpdate(props){
        if(this.props.userId != props.userId && this.props.userId !== undefined){
            this.getUser(this.props.userId);
        }
        else if(this.props.trainingId != props.trainingId){
            this.getUsersList();
        }
    }

    getUsersList()
    {
        let request = global.site + '/user/list.php?profile=' + this.props.profile;
        if(this.props.trainingId !== undefined && this.props.trainingId > 0){
            request += '&trainingId=' + this.props.trainingId;
        }
        else if(this.props.tutorId !== undefined){
            request += '&tutorId=' + this.props.tutorId;
        }
        axios.get(request)
            .then(res => {
                if(res.data["success"]){
                    const users = res.data["users"];
                    for(let index = 0; index < users.length; index++){
                        users[index]['title'] = users[index]['firstName'] + ' ' + users[index]['lastName'];
                    }
                    this.setState({users:users, userId:0, user:null});
                }
             })
             .catch(function(error) {
                 error.handleGlobally && error.handleGlobally();
             })
    }

    backToList(){
        this.setState({userId:0, user:null});
    }
    
    render(){
        return(
            <div>
                {this.props.userId !== undefined && this.state.user !== null && this.props.userId == this.state.user.id?
                    <div>
                    <Breadcrumbs style={{marginBottom:'10px'}}>
                        <Link to={"/" + this.links[this.props.profile - 1]}>{this.title[this.props.profile-1]}</Link>
                    </Breadcrumbs>
                    <Profile
                        user={this.state.user}
                        readOnly={this.props.userProfile !== 1 && this.props.currentId !== this.props.userId}
                        readOnlySignature={this.props.currentId !== this.props.userId}
                        type={this.links[this.props.profile - 1]}
                    />
                    </div>
                    :
                    <div>
                    <Typography variant="h5">Liste des {this.title[this.props.profile - 1]}</Typography>
                    <OlindaMuiCardList                    
                        elements={this.state.users}
                        profile={this.props.profile}
                        columns={this.userColumns}
                        del={this.props.userProfile === 1}
                        add={this.props.userProfile === 1}                    
                        onAdd={this.onCreateUser}
                        onEdit={this.onEditUser}
                        onDelete={this.onDeleteUser}
                        link={this.props.trainingId !== undefined && this.props.trainingId != 0}
                        href={"/" + this.links[this.props.profile - 1]}
                        extraLink = {this.props.extraLink}
                        searchColumn={'lastName'}
                        filter={this.props.filter}
                    />                
                    </div>
                }
                {this.state.createUser ?
                    <OlindaMuiForm
                        title={this.title[this.props.profile - 1]}
                        fields={this.userFields}
                        onValid={this.onValidCreateUser}
                        onCancel={this.onCancelCreateUser}
                        error={this.state.error}
                        training={this.trainings}
                    />
                    :null
                
                }
            </div>
        );
    
    }

}

export default withRouter(User)
