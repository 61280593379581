import React from 'react'

import OlindaMuiFlatForm from '../components/flatForm.js'

class FormationInfo extends React.Component{
    constructor(props){
        super(props);

        this.fields=[
            {id:'training', label:'Formation', type:'input', value:this.props.training},
            {id:'start', label:'Début', type:'input', value:this.props.start},
            {id:'end', label:'Fin', type:'input', value:this.props.end},
            {id:'tutor', label:'Tuteur', type:'input', value:this.props.tutor}
        ]

    }

    render(){
        return(
            <div>
            <OlindaMuiFlatForm
                fields={this.fields}
                readOnly={true}
            />
            </div>
        ); 
    }

}

export default FormationInfo
