import React from 'react'
import axios from 'axios'

import OlindaMuiForm from '../components/form'
import OlindaMuiTable from '../components/table'

class EmploymentsList extends React.Component{
    constructor(props){
        super(props);
        this.state={
            employments:[],
            employmentId:0,
            createEmployment:false
        }
        
        this.employmentFields = [
              {id:'name', label:'Nom', type:'input', value:'', mandatory:true},
              {id:'start', label:'Début', type:'date', value:'', mandatory:true},
              {id:'end', label:'Fin', type:'date', value:''}
          ];
        
        this.employmentColumns = [
            {id:'name', title:'Nom'},
            {id:'start', title:'Début'},
            {id:'end', title:'Fin'},
        ];

        this.onCreateEmployment = this.onCreateEmployment.bind(this);
        this.onValidCreateEmployment = this.onValidCreateEmployment.bind(this);
        this.onCancelCreateEmployment = this.onCancelCreateEmployment.bind(this);
        this.onEditEmployment = this.onEditEmployment.bind(this);
        this.onDeleteEmployment = this.onDeleteEmployment.bind(this);

    }

    componentDidMount(){
        this.getEmployments();
    }

    onCreateEmployment(){
        this.setState({createEmployment:true, employmentId:0});
    }

    onValidCreateEmployment(fields){
         let datas = new FormData();
         fields.map((field)=>(
             datas.append(field.id, field.value)
         ))
         if(this.props.id !== 0){
             datas.append("studentId", this.props.studentId);
         }
         if(this.state.employmentId !== 0){
             datas.append("id", this.state.employmentId);
         }
         axios({
             withCredentials:true,
             method: 'post',
             url: global.site +'/employment/create.php',
             data: datas
         })
         .then(res =>  {
             if(res.data["success"]){
                 let employments = res.data["employments"]
                 this.setState({createEmployment:false, employments:employments});
             }
         })
         .catch(function (error) {
             error.handleGlobally && error.handleGlobally();
         });
    }

    onCancelCreateEmployment(){
        this.setState({createEmployment:false, employmentId:0});
    }

    onEditEmployment(id){
        this.getEmployment(id);
    }

    onDeleteEmployment(ids){
        let datas = new FormData();
        for(let i = 0; i < ids.length; i++){
            datas.append('ids[]', ids[i]);
        }
        datas.append('studentId', this.props.id);
        axios({
            method: 'post',
            url: global.site +'/employment/delete.php',
            data: datas
        })
        .then(res =>  {
            if(res.data["success"]){
                let employments = res.data["employments"];
                this.setState({createEmployment:false, employments:employments});
            }
        })
        .catch(function (error) {
            error.handleGlobally && error.handleGlobally();
        });

    }

    getEmployment(id){
        axios.get(global.site +'/employment/get.php?id=' + id)
            .then(res => {
                if(res.data["success"]){
                    this.employmentFields.map((field) => (
                        field.value = res.data[field.id]
                    ))
                    this.setState({createEmployment:true, employmentId:id});
                }
             })
             .catch(function(error) {
                error.handleGlobally && error.handleGlobally();
        })
    }

    getEmployments(){
        axios.get(global.site +'/employment/list.php?studentId=' + this.props.id)
            .then(res => {
                if(res.data["success"]){
                    const employments = res.data["employments"];
                    this.setState({employments:employments});
                }
             })
             .catch(function(error) {
                error.handleGlobally && error.handleGlobally();
        })
    }

    render(){
        return(
            <div>
                <OlindaMuiTable
                    elements={this.state.employments}
                    columns = {this.employmentColumns}
                    del={true}
                    add={true}
                    onEdit = {this.onEditEmployment}
                    onAdd = {this.onCreateEmployment}
                    onDelete = {this.onDeleteEmployment}
                />
            {this.state.createEmployment ?
                    <OlindaMuiForm
                        fields={this.employmentFields}
                        title="Suivi"
                        onCancel={this.onCancelCreateEmployment}
                        onValid={this.onValidCreateEmployment}
                    />
                    :null
            }
            </div>
        );
    }
}

export default EmploymentsList
