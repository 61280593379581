import React from 'react'
import axios from 'axios'
import {Link} from "react-router-dom";

import { Redirect, withRouter } from "react-router-dom";
import LinkedDocuments from '../documents/linkedDocuments'
import Grid from '@material-ui/core/Grid';
import OlindaMuiForm from '../components/form.js'
import StudentTutorItem from './studentTutorItem.js'
import StudentProfile from './studentProfile'


class StudentTutor extends React.Component{
    constructor(props){
        super(props);
        this.state={
            students:[]
        }

    }

    componentDidMount(){
        this.getStudentsList();
    }

    getStudentsList(){
        let request = global.site + '/user/list.php?profile=3&tutorId='+this.props.tutorId;
        axios.get(request)
            .then(res => {
                if(res.data["success"]){
                    const students = res.data["users"];
                    this.setState({students:students});
                }
             })
             .catch(function(error) {
                 error.handleGlobally && error.handleGlobally();
             })

    }

    render(){
        let studentId = this.props.match.params.studentId;
        if(studentId === undefined){
            studentId = this.props.studentId;
        }
        if(this.props.userProfile === 3 && studentId != this.props.userId){
            return(
                 <Redirect to="/"/>
            )
        }
        return(
            <div>
                {this.state.reportId > 0 ?
                    <OlindaMuiForm
                        fields={this.reportPartFields}
                        title={"Bilan Mensuel Tuteur"}
                        assiduity={this.reportNotes}
                        involvement={this.reportNotes}
                        initiative={this.reportNotes}
                        insertion={this.reportNotes}
                        global={this.reportNotes}
                        onCancel={this.onCancelReport}
                        onValid={this.onValidReport}
                    />

                    :
                    null
                }
                {studentId !== undefined ?
                    <div>
                    <StudentProfile
                        studentId={studentId}
                        tutors={this.state.tutors}
                        financals={this.state.financals}
                        trainings={this.state.trainings}
                        readOnlyInfo={true}
                        userProfile={this.props.userProfile}
                        userId={this.props.userId}
                        trainingId={this.props.match.params.trainingId}
                        moduleId={this.props.match.params.moduleId}
                        windowWidth={this.props.windowWidth}
                    />
                    </div>
                    :
                    <Grid container spacing={1} style={{marginTop:'5px', marginLeft:'5px'}}>
                        {this.state.students.map((student)=>(
                            <Grid item xs={this.props.size} sm={this.props.size}>
                                <StudentTutorItem
                                    studentId={student.id}
                                    trainingId={student.trainingId}
                                    training={student.training}
                                    firstName={student.firstName}
                                    lastName={student.lastName}
                                    avatar={student.avatar}
                                />
                            </Grid>
                        ))}
                    </Grid>
                }
            </div>
        );
    
    }

}

export default withRouter(StudentTutor)
