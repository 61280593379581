import React from 'react'
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

class TutoringForm extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            fields:this.props.fields,
            selectedDays:this.props.fields[2].value
        }
        this.onCheck = this.onCheck.bind(this);

        this.handleChange = this.handleChange.bind(this);
    }

    onCancel() {
        this.props.onCancel();
    };

    onValid() {
        this.props.onValid(this.state.fields, this.state.selectedDays);
    };

    handleChange(e){
        const target = e.target;
        var value = target.value;
        const id = target.id;
        let fields = this.state.fields.slice();
        let index = -1;
        for(index = 0;index < fields.length; index++){
            if(fields[index].id === id){
                fields[index].value = value;
                break;
            }
        }
        this.setState({fields:fields});
    }

    createDate(label, value, id){
        return(
            <TextField
                autoFocus
                margin="dense"
                label={label}
                value={value}
                id={id}
                fullWidth
                type = {'date'}
                onChange={this.handleChange}
                InputLabelProps={{
                    shrink: true,
                }}
            />
        );
    }

    onCheck(event){
        let target = event.target;
        let id = target.id;
        const selectedIndex = this.state.selectedDays.indexOf(id);
        let newSelected = this.state.selectedDays.slice();
        if(target.checked){
            if(selectedIndex === -1)
            {
                newSelected.push(id);
            }
        }
        else{
            if(selectedIndex !== -1){
                newSelected.splice(selectedIndex,1);
            }

        }
        this.setState({selectedDays:newSelected})
    }


    render(){
        return (
            <div>
            <Dialog fulWidth fullHeight open={true} onClose={()=>this.onCancel()} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Tutorat</DialogTitle>
            <DialogContent>
                {this.createDate('Début', this.state.fields[0].value, 'start')}
                {this.createDate('Fin', this.state.fields[1].value, 'end')}
            </DialogContent>
            <DialogActions>
            <Button onClick={()=>this.onCancel()} color="primary">
                Annuler
            </Button>
            <Button onClick={()=>this.onValid()} color="primary">
                Valider
            </Button>
            </DialogActions>
        </Dialog>
        </div>
  );
    }
}


export default TutoringForm;
