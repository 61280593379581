import React from 'react'
import axios from 'axios'

import Typography from '@material-ui/core/Typography';


import OlindaMuiFlatForm from '../components/flatForm.js'
import OlindaMuiForm from '../components/form.js'

class InfoMgmt extends React.Component{
    constructor(props){
        super(props);
        
        this.state={
            firstName:this.props.firstName,
            lastName:this.props.lastName,
            phone:this.props.phone,
            mail:this.props.mail,
            organization:this.props.organization
        }

        this.fields=[
            {id:'firstName', label:'Prénom', type:'input', value:this.props.firstName, mandatory:true},
            {id:'lastName', label:'Nom', type:'input', value:this.props.lastName, mandatory:true},
            {id:'mail', label:'Mail', type:'input', value:this.props.mail, mandatory:true},            
            {id:'phone', label:'Téléphone', type:'input', value:this.props.phone}
        ]

        this.state={
            modify:false
        }

        if(this.props.profile === 2){
            this.fields.push({id:'organization', label:'Structure', type:'input', value:this.props.organization});
        }
        this.onModify = this.onModify.bind(this)
        this.onCancel = this.onCancel.bind(this)
        this.handleUpdateContact = this.handleUpdateContact.bind(this);
    }

    onModify(){
        this.setState({modify:true});
    }
    
    onCancel(){
        this.setState({modify:false});
    }

    updateContact(fields){
        let datas = new FormData();
        fields.map((field)=>(
            datas.append(field.id, field.value)
        ))  
        datas.append('id', this.props.id);
        datas.append('profile', this.props.profile);
        axios({
            withCredentials:true,
            method: 'post',
            url: global.site +'/user/updateContact.php',
            data: datas
        })  
        .then(res =>  {
            if(res.data["success"]){
                let user = this.state.user;
                let organization = null;
                if(this.props.profile === 2){ 
                    this.fields[4].value = res.data["organization"];
                }   
                this.fields[0].value = res.data['firstName'];
                this.fields[1].value = res.data['lastName'];
                this.fields[2].value = res.data['mail'];
                this.fields[3].value = res.data['phone'];
                this.setState({modify:false});
            }   
        })  
        .catch(function (error) {
            error.handleGlobally && error.handleGlobally();
        }); 

    }   


    handleUpdateContact(){
        this.updateContact(this.fields);
    }

    render(){
        return(
            <div>
            <OlindaMuiFlatForm
                fields={this.fields}
                buttonTitle="Modifier"
                onModify={this.onModify}
                readOnly={this.props.readOnly}
            />
            {this.state.modify?
                <OlindaMuiForm
                    fields = {this.fields}
                    onCancel={this.onCancel}
                    onValid={this.handleUpdateContact}
                />
                :
                null
            }
            </div>
        ); 
    }

}

export default InfoMgmt
