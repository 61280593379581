import React from 'react'
import axios from 'axios'
import { withRouter } from "react-router-dom";

import { withStyles } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';

import OlindaMuiTable from '../components/table'
import TutoringReport from '../tutoringReport/tutoringReport.js'


const useStyles = theme => ({
  root: {
    flexGrow: 1,
  },
  title: {
    background:'red',
    flex: 1,
  },
});

class Exam extends React.Component{
    constructor(props){
        super(props);
        this.state={
            students:[],
            editReport:false,
            reportId:0,
            studentId:0,
            exam:null
        }
        this.columns=[
            {id:'fName', title:'Prénom', type:'input'},
            {id:'lName', title:'Nom', type:'input'},
        ];
        this.onEdit = this.onEdit.bind(this);
        this.onClose = this.onClose.bind(this);
    }

    componentDidMount(){
        this.getStudents(this.props.match.params.examId);
        this.getExam();
    }

    componentUpdated(props){
        if(props.match.params.examId !== this.props.match.params.examId){
            this.getStudent(this.props.examId);
            this.getExam();
        }
    }

    getExam(){
        axios.get(global.site +'/exam/get.php?id='+ this.props.match.params.examId)
            .then(res => {
                if(res.data["success"]){
                    this.setState({exam:res.data["exam"]})
                }
             })
             .catch(function(error) {
                error.handleGlobally && error.handleGlobally();
        })
    
    }

    onEdit(id){
        let index = 0;
        let studentId= 0;
        for(index = 0; index < this.state.students.length; index++){
            if(this.state.students[index]['id'] == id){
                studentId = this.state.students[index]['userId'];
            }
        }
        this.setState({editReport:true, reportId:id, studentId:studentId});
    }

    onClose(){
        this.setState({editReport:false, reportId:0, studentId:0});
    }

    formatDate(value){
         const date = new Date(value);
         return date.toLocaleDateString('fr-fr');
    }

    createExams(){
        return(
            <div>
            <Typography variant="h5">{this.state.exam !== null ? this.state.exam.name : null}</Typography>
            <Typography variant="h5">{this.state.exam !== null ? this.formatDate(this.state.exam.date) : null}</Typography>
            <OlindaMuiTable
                elements={this.state.students}
                del={false}
                add={false}
                columns={this.columns}
                onEdit={this.onEdit}
            />
            </div>
        );
    }
    
    getStudents(id){
        axios.get(global.site +'/exam/list.php?examId='+ id)
            .then(res => {
                if(res.data["success"]){
                    this.setState({students:res.data["exams"]})
                }
             })
             .catch(function(error) {
                error.handleGlobally && error.handleGlobally();
        })
    }


    render(){
        const {classes}=this.props;
        return(
            <div>
                {this.createExams()}
                    <TutoringReport
                        reportProfile={6}
                        reportId={this.state.reportId}
                        studentId={this.state.studentId}
                        onClose = {this.onClose}
                        userProfile = {this.props.userProfile}
                    />
            </div>
        ); 
    }

}

export default withRouter(withStyles(useStyles)(Exam))
