import React from 'react'
import axios from 'axios'

import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TutoringReportFormItem from './tutoringReportFormItem';
import PeriodicReportFormItem from './periodicReportFormItem';

import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import OlindaMuiInfo from '../components/info.js'

class TutoringReportForm extends React.Component{
    constructor(props){
        super(props);
        this.state={
            report:this.props.report,
            missingNotes:false
        }
        

        this.validationFields = [
            {id:'succeed', title:'Acquis'},
            {id:'failed', title:'Non-Acquis'},
        ]

       this.itemColumns = [
            {id:'title', title:'Titre'}
        ];
        
        this.onValidForm = this.onValidForm.bind(this);
        this.onCancelForm = this.onCancelForm.bind(this);
        this.onValidMissing = this.onValidMissing.bind(this);
    }

    checkItem(item, level){
        let childs = item.items;
        let check = true;
        if(childs.length === 0 || (level == 0 && (this.props.reportProfile == 4 || this.props.reportProfile == 5))){           
            check = item.result !== null;
        }
        else{
            for(let index = 0; index < childs.length; index++){
                check = check && this.checkItem(childs[index], level + 1);
            }
        }
        return check;
    }

    onValidMissing(){
        this.setState({missingNotes:false})
    }

    onValidForm(fields){
        let check = true;
        let childs = this.state.report.items;
        for(let index = 0; index < childs.length; index++){
            check = check && this.checkItem(childs[index], 0);
        }
        if(check){
            this.setState({missingNotes:false});
            this.props.onValid(this.state.report);
        }
        else{
            this.setState({missingNotes:true})
        }
    }

    onCancelForm(){
        this.props.onCancel();
    }

    createItem(item){
        return(
            this.props.reportProfile >3 && this.props.reportProfile <6 ?                
            <PeriodicReportFormItem
                item={item}
                studentId={this.props.studentId}
                reportId={this.props.reportId}
                readOnly={this.props.readOnly}
                userProfile = {this.props.userProfile}
                reportProfile = {this.props.reportProfile}
            />
             :
            <TutoringReportFormItem
                item={item}
                studentId={this.props.studentId}
                reportId={this.props.reportId}
                readOnly={this.props.readOnly}
            />            
        );
    }

    render(){
        return(
            <div>
                <Dialog
                    fullWidth={true}
                    maxWidth={'md'}

                    fullHeight
                    open={true}
                    onClose={()=>this.onCancelForm()}
                    aria-labelledby="form-dialog-title"
                    scroll={'paper'}
                >
                <DialogTitle id="form-dialog-title" subtitle={this.props.report.title}>{this.props.report.name}</DialogTitle>
                <DialogContent>

                {this.state.report.items.map((item) =>(
                    this.createItem(item)
                ))
                }
                </DialogContent>
                <DialogActions>
                    {
                        this.props.readOnly === false?
                        <div>
                        <Button onClick={()=>this.onCancelForm()} color="primary">
                            Annuler
                        </Button>
                        <Button onClick={()=>this.onValidForm()} color="primary">
                            Valider
                        </Button>
                        </div>
                        :
                        <Button onClick={()=>this.onCancelForm()} color="primary">
                            OK
                        </Button>
                    }
                </DialogActions>

                </Dialog>
                {this.state.missingNotes ?
                    <OlindaMuiInfo
                        title={'Validation des items'}
                        text={'Tous les items ne sont pas remplis'}
                        onOk = {this.onValidMissing}
                    />
                    :
                    null
                }
            </div>
        );
    
    }



}

export default TutoringReportForm

