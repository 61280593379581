import React from 'react'
import axios from 'axios'

import Typography from '@material-ui/core/Typography';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import ScheduleIcon from '@material-ui/icons/Schedule';
import CommentIcon from '@material-ui/icons/Comment';
import AssessmentIcon from '@material-ui/icons/Assessment';
import Button from '@material-ui/core/Button'

import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Card from '@material-ui/core/Card';


import Documents from '../documents/documents'
import LinkedDocuments from '../documents/linkedDocuments'
import AcceptDocuments from './acceptDocuments.js'
import OlindaMuiForm from '../components/form'
import OlindaMuiFlatForm from '../components/flatForm'
import OlindaMuiScheduler from '../components/scheduler/scheduler'
import Tutoring from '../tutoring/tutoring.js';
import Grid from '@material-ui/core/Grid';
import Report from '../report/report'
import TutorVisit from '../tutorVisit/tutorVisit'
import TutoringReportList from '../tutoringReport/tutoringReportList.js'
import AcquisitionReportList from '../tutoringReport/acquisitionReportList.js'
import CertificationReportList from '../tutoringReport/certificationReportList.js'
import PeriodicReportList from '../tutoringReport/periodicReportList.js'
import ELearnings from '../eLearning/eLearnings.js'
import CommentsList from './commentsList.js'
import ModulesList from '../training/modulesList.js'
import WeeklyReport from '../weeklyReport/weeklyReport'
import MonthlyForm from '../monthlyForm/monthlyForm'
import OlindaMuiInfo from '../components/info.js'
import TrainingMenu from './trainingMenu.js'


import sesame from '../logos/sesame.png'
import vivea from '../logos/vivea.jpg' 
import cpne from '../logos/cpne.png'
import ministere from '../logos/ministere.jpg'
import sport_occitanie from '../logos/sport_occitanie.jpg'
import region from '../logos/region_europe.jpg'
import cpf from '../logos/cpf.png'
import cfa_fede from '../logos/cfa_fede.png'
import ocapiat from '../logos/ocapiat.jpg'
import ffe from '../logos/ffe.jpg'
import hippothese from '../logos/hippothese.jpg'
import pole_emploi from '../logos/pole_emploi.png'




function TabPanel(props){
    const { children, value, index} = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-force-tabpanel-${index}`}
            aria-labelledby={`scrollable-force-tab-${index}`}
        >
            {value === index && (
                children
            )}
        </div>
    );
}

class StudentDashboard extends React.Component{
    constructor(props){
        super(props);
        this.state={
            trainingId:0,
            financalId:0,
            financal:'',
            date:new Date(),
            startDate:'',
            endDate:'',
            events:[],
            modules:[],
            trainingStartDate:'',
            trainingEndDate:'',
            training:'',
            tutor:'',
            showInfo:false,
            infoText:'',
            page:'home',

        }
        if(this.props.userProfile == 2){
            this.state.page='reports';
        }
        this.reasons=[{id:0, name:''},{id:1, name:'Abandon'}, {id:2, name:'Certification'}, {id:3, name:'Non Acquis'}];

        
        this.logos={"CPF":{logo:cpf, width:100, height:100},
                    "Vivéa":{logo:vivea, width:200, height:100},
                    "Région":{logo:region, width:450, height:100},
                    "CFA Sport Occitanie":{logo:sport_occitanie, width:166, height:100},
                    "CFA Fédéral":{logo:cfa_fede, width:166, height:100},          
                    "Ocapiat":{logo:ocapiat, width:200, height:100},
                    "Sésame":{logo:sesame, width:400, height:100},
                    "Pole Emploi":{logo:pole_emploi, width:200, height:100}          
                    }

        this.formLogos={"bp":[{logo:ministere, width:178, height:100}],
                        "cqp":[{logo:cpne, width:294, height:100}],
                        "ae":[{logo:ffe, width:123, height:100},
                             {logo:hippothese, width:157, height:100}]

        }

        this.sendReportFields=[
            {id:'mail', label:'Envoyer à', type:'mail', mandatory:true}
        ]

        this.onDateSelected = this.onDateSelected.bind(this);
        this.getPlanning = this.getPlanning.bind(this);

        
        this.onAcceptDocuments = this.onAcceptDocuments.bind(this);

        this.handlePage = this.handlePage.bind(this);

        this.onSendReport = this.onSendReport.bind(this);
        this.okSendReport = this.okSendReport.bind(this);
        this.cancelSendReport = this.cancelSendReport.bind(this);
        
        this.onSendiZipReport = this.onSendZipReport.bind(this);
        this.okSendZipReport = this.okSendZipReport.bind(this);
        this.cancelSendZipReport = this.cancelSendZipReport.bind(this);
    }

    onDateSelected(date){
        this.setState({date:date});
    }

    componentDidMount(){
        this.getTrainingInfo();
    }

    onSendReport(){
        this.setState({sendReport:true});
    }
    
    onSendZipReport(){
        this.setState({sendZip:true});
    }

    cancelSendReport(){
        this.setState({sendReport:false});
    }
    
    cancelSendZipReport(){
        this.setState({sendZip:false});
    }

    okSendReport(fields){
        let datas = new FormData();
        this.trainingEditFields.map((field)=>(
            datas.append(field.id, field.value)
        ))
        datas.append("studentId", this.props.studentId);
        datas.append("trainingId", this.props.trainingId);
        datas.append("mail", fields[0].value);
        
        axios({
            method: 'post',
            url: global.site +'/student/sendReport.php',
            data: datas
        })
        .then(res =>  {
            if(res.data["success"]){
                this.setState({sendReport:false});
            }
        })
        .catch(function (error) {
            error.handleGlobally && error.handleGlobally();
        });
    }
    
    okSendZipReport(fields){
        let datas = new FormData();
        this.trainingEditFields.map((field)=>(
            datas.append(field.id, field.value)
        ))
        datas.append("studentId", this.props.studentId);
        datas.append("trainingId", this.props.trainingId);
        datas.append("mail", fields[0].value);
        
        axios({
            method: 'post',
            url: global.site +'/student/sendZipReport.php',
            data: datas
        })
        .then(res =>  {
            if(res.data["success"]){
                this.setState({sendZip:false});
            }
        })
        .catch(function (error) {
            error.handleGlobally && error.handleGlobally();
        });
    }

    handlePage(page){
        this.setState({page:page});
    }
    
    getTrainingInfo(id){
        axios.get(global.site +'/student/currentTrainingInfo.php?studentId='+this.props.studentId)
            .then(res => {
                if(res.data["success"]){
                    let trainingId = res.data["trainingId"];
                    let training = res.data["trainingName"];
                    let tutor = res.data["tutor"];
                    let startDate = res.data["startDate"];
                    let endDate = res.data["endDate"];
                    let tutorId = res.data["tutorId"];
                    let modules = res.data["modules"];
                    let valid = res.data["valid"];                 
                    let reasonId = parseInt(res.data["reason"]);
                    if (isNaN(reasonId)){
                        reasonId = 0;
                    }
                    let reason = this.reasons[reasonId].name;
                    let financalId = res.data["financalId"];
                    let financal = res.data["financal"];
                    this.setState({trainingId:trainingId, 
                                   valid:valid, training:training, 
                                   startDate:startDate, endDate:endDate, 
                                   tutorId:tutorId, tutor:tutor, modules:modules, 
                                   reason:reason, reasonId: reasonId, 
                                   financalId:financalId, financal:financal});
                    
                }
             })
             .catch(function(error) {
                error.handleGlobally && error.handleGlobally();
        })

    }

    createLogo(logo, width, height){
        return(
            <Grid item xs={12} sm="auto">
                <img src={logo} alt="Qualiopi" width={width} height={height}/>
            </Grid>
        );
    }

    createTeachingDocuments()
    {   
        return(
            <div>
            <Typography variant="h5">Cours</Typography>
            <LinkedDocuments
                type='teaching-training'
                docType='teaching-documents'
                directory='/documents/teaching'
                userId= {this.state.trainingId}
                add={false}
                del={false}
            />
            </div>
        );
    }
    
    createAdminDocuments()
    {   
        return(
            <div>
            <Typography variant="h5">Documents Administratifs</Typography>
            {this.props.userProfile === 1 ?
                <Button variant="contained" color="primary" onClick={()=>this.onOpenDrive()}>
                    Drive
                </Button>
                :
                null
            }
            <LinkedDocuments
                type='admin-training'
                docType='admin-documents'
                directory='/documents/admin'
                userId= {this.state.trainingId}
                financal= {this.state.financal}
                add={false}
                del={false}
            />        
            </div>
        );
    }

    createReportDocuments(){
        if(this.state.trainingId == 0){
            return null;
        }
        return(
            <div>
            <Typography variant="h5">Livret de Formation</Typography>
            <Documents
                userId={this.props.studentId}
                trainingId={this.state.trainingId}
                directory={'report-' + this.state.trainingId}
                type="student-report"
                add={this.props.userProfile === 1}
                del={this.props.userProfile === 1}
            />
            </div>
        );
    }

    createDocuments(){
        if(this.state.trainingId == 0){
            return null;
        }
        return(
            <div>
            <Typography variant="h5">Documents de suivi</Typography>
            <Documents
                userId={this.props.studentId}
                trainingId={this.state.trainingId}
                directory={'training-' + this.state.trainingId}
                type="student"
                add={this.props.userProfile === 1}
                del={this.props.userProfile === 1}
            />
            </div>
        );
    }

    createTrainingDocuments(){
        return(
            <Grid container spacing = {10} style={{background:'transparent'}}>
                <Grid item xs={12} sm={6}>
                    {this.createReportDocuments()}
                </Grid>
                <Grid item xs={12} sm={6}>
                    {this.createDocuments()}
                </Grid>
            </Grid>
        
        );
    }
    
    createReports(){
        return(
            <div>
                <Typography variant="h5">Bilans mensuels</Typography>
                <Report
                    studentId={this.props.studentId}
                    trainingId={this.props.trainingId}
                    studentLastName = {this.props.studentLastName}
                    studentFirstName = {this.props.studentFirstName}
                    userProfile={this.props.userProfile}
                    del={this.props.userProfile === 1}
                    add={this.props.userProfile === 1}
                />
            </div>
        );
    }

    createTutorVisits(){
        return(
            <div>
                <Typography variant="h5">Visites Tuteurs</Typography>
                <TutorVisit
                    studentId={this.props.studentId}
                    trainingId={this.props.trainingId}
                    userProfile={this.props.userProfile}
                    del={this.props.userProfile === 1}
                    add={this.props.userProfile === 1}
                />
            </div>
        );
    }
    
    createWeeklyReports(){
        return(
            <div>
                <Typography variant="h5">Bilans quotidiens</Typography>
                <WeeklyReport
                    trainingId={this.props.trainingId}
                    studentId={this.props.studentId}
                    userProfile={this.props.userProfile}
                />
            </div>
        );
    }

    createMonthlyForm(){
        return(
            <div>
                <Typography variant="h5">Fiche Navette</Typography>
                <Typography variant="h7">Centre de Formation</Typography>
                <MonthlyForm
                    trainingId={this.props.trainingId}
                    studentId={this.props.studentId}
                    formProfile={1}
                    userProfile={this.props.userProfile}
                />
                <Typography variant="h5">Fiche Navette</Typography>
                <Typography variant="h7">Tutorat</Typography>
                <MonthlyForm
                    trainingId={this.props.trainingId}
                    studentId={this.props.studentId}
                    formProfile={2}
                    userProfile={this.props.userProfile}
                />
            </div>
        );
    }
    
    createELearnings(){
        return(
            <div>
                <Typography variant="h5">Evaluation en Ligne</Typography>
                <ELearnings
                    studentId={this.props.studentId}
                    trainingId={this.props.trainingId}
                    userProfile={this.props.userProfile}
                />
            </div>
        );

    }

    createTutoringReports(){
        return(
            <div>
                <Typography variant="h5">Evaluation Formatives</Typography>
                <TutoringReportList
                    studentId={this.props.studentId}
                    trainingId={this.props.trainingId}
                    userProfile={this.props.userProfile}
                    studentLastName = {this.props.studentLastName}
                    studentFirstName = {this.props.studentFirstName}
                />
            </div>
        );

    }
    
    createPeriodicReports(){
        return(
            <div>
                <Typography variant="h5">Bilans Périodiques</Typography>
                <PeriodicReportList
                    studentId={this.props.studentId}
                    trainingId={this.props.trainingId}
                    userProfile={this.props.userProfile}
                    studentLastName = {this.props.studentLastName}
                    studentFirstName = {this.props.studentFirstName}
                />
            </div>
        );

    }
    
    createCertificationReports(){
        return(
            <div>
                <Typography variant="h5">Evaluation Certificatives</Typography>
                <CertificationReportList
                    studentId={this.props.studentId}
                    trainingId={this.props.trainingId}
                    userProfile={this.props.userProfile}
                    studentLastName = {this.props.studentLastName}
                    studentFirstName = {this.props.studentFirstName}
                />
            </div>
        );
    }
    
    createAcquisitionReports(){
        return(
            <div>
                <Typography variant="h5">Evaluation Sommatives</Typography>
                <AcquisitionReportList
                    studentId={this.props.studentId}
                    trainingId={this.props.trainingId}
                    userProfile={this.props.userProfile}
                    studentLastName = {this.props.studentLastName}
                    studentFirstName = {this.props.studentFirstName}
                />
            </div>
        );
    }
    
    createTutorings(){
        return(
            <Tutoring
                id={this.props.studentId}
                training={this.props.trainingId}
                type={'student'}
            />
        );
    }

    createAlerts(){
        return(
            <div>
                <Typography variant="h5">Alertes</Typography>
                <CommentsList
                    studentId={this.props.studentId}
                    trainingId={this.props.trainingId}
                    studentLastName = {this.props.studentLastName}
                    studentFirstName = {this.props.studentFirstName}
                />
            </div>
        );
    }

    getPlanning(startDate, endDate){                                                                                                                                                          
        this.setState({startDate:startDate, endDate:endDate});
        axios.get(global.site +'/event/list.php?type=student&trainingId='+this.props.trainingId+'&id=' + this.props.studentId + '&startDate=' + startDate + '&endDate=' + endDate)
            .then(res => {
                const events = res.data["events"];
                this.setState({events:events});
             })
             .catch(function(error) {
                error.handleGlobally && error.handleGlobally();
        })

    }


    createPlanning(){
        return(
            <div>
                {this.props.userProfile === 2 ? this.createTutorings() : null}
                <Typography variant="h5">Planning</Typography>
                <OlindaMuiScheduler 
                    onDateSelected={this.onDateSelected} 
                    date={this.state.date} 
                    getEvents={this.getPlanning} 
                    events={this.state.events}
                    onSelect = {this.onEditCourse}
                />
            </div>
        );
    }

    createInfo(){
        return(
            <Card>
                <CardHeader
                    title={this.state.training}
                    subheader={' du ' + this.state.startDate + ' au ' + this.state.endDate}
                />
                <CardContent>
                    <Typography>{'Tuteur : ' + this.state.tutor}</Typography>                    
                    <Typography>{'Financement : ' + this.state.financal}</Typography>                    
                </CardContent>
            </Card>
        );
    }


    onEditReport(){
        window.open(global.site + '/student/report.php?id='+this.props.studentId + '&trainingId=' + this.props.trainingId, "_blank");
    }
    

    onAcceptDocuments(){
        let datas = new FormData();
        datas.append('id', this.props.trainingId);
        datas.append('studentId', this.props.studentId);
        axios({
            withCredentials:true,
            method: 'post',
            url: global.site + '/student/acceptTrainingDocs.php',
            data: datas
        })
        .then(res =>  {
            if(res.data["success"]){
                this.setState({valid:1})
            }
        })
        .catch(function (error) {
            error.handleGlobally && error.handleGlobally();
        });

    }

    render(){
        const {classes}=this.props;
        var logoFinancal = this.logos[this.state.financal]
        var logoForm = undefined
        var formLogos = undefined
        if(this.state.training !== undefined){
            var lTraining = this.state.training.toLowerCase();

            const keys = Object.keys(this.formLogos)
            if(lTraining.includes("bp")){
                formLogos = this.formLogos["bp"];
            }
            else if(lTraining.includes("cqp")){
                formLogos = this.formLogos["cqp"];
            }
            else if(lTraining.includes("ae")){
                formLogos = this.formLogos["ae"];
            }
        }
        return(
           <div>
               <Grid container spacing={3}>
               {
                    formLogos != undefined ?
                        formLogos.map((value, index)=>
                            this.createLogo(value.logo, value.width, value.height)
                        )
                        : 
                        null
                }
                {
                    logoFinancal !== undefined ?
                        this.createLogo(logoFinancal.logo, logoFinancal.width, logoFinancal.height)
                        :
                        null
                }
                    <Grid item xs={12} sm={3}>
                        {this.createInfo()}
                    </Grid>
                </Grid>
                <Grid container spacing={3}>
                </Grid>
                {this.state.page === 'home' ? this.createTrainingDocuments() : null}
                {this.state.page === 'reportsDocs' ? this.createTrainingDocuments() : null}
                {this.state.page === 'teachingDocs' ? this.createTeachingDocuments() : null}
                {this.state.page === 'adminDocs' ? this.createAdminDocuments() : null}
                {this.state.page === 'modules' ? this.createModules() : null}
                {this.state.page === 'weeklyReports' ? this.createWeeklyReports() : null}
                {this.state.page === 'reports' ? this.createReports() : null}
                {this.state.page === 'tutorVisits' ? this.createTutorVisits() : null}
                {this.state.page === 'tutoringReports' ? this.createTutoringReports() : null}
                {this.state.page === 'acquisitionReports' ? this.createAcquisitionReports() : null}
                {this.state.page === 'certificationReports' ? this.createCertificationReports() : null}
                {this.state.page === 'periodicReports' ? this.createPeriodicReports() : null}
                {this.state.page === 'elearnings' ? this.createELearnings() : null}
                {this.state.page === 'monthlyReports' ? this.createMonthlyForm() : null}
                {this.state.page === 'comments' ? this.createAlerts() : null}
                {this.state.page === 'scheduler' ? this.createPlanning() : null}
                {this.state.valid !== 1 && this.props.userProfile===3?
                    <AcceptDocuments
                        type={'mandatory-training-docs'}
                        trainingId={this.state.trainingId}
                        studentId={this.props.studentId}
                        onAcceptDocuments = {this.onAcceptDocuments}
                    />
                    :
                    null
                }
                {this.state.sendReport ?
                    <OlindaMuiForm
                        fields = {this.sendReportFields}
                        title="Envoyer le rapport"
                        onCancel={this.cancelSendReport}
                        onValid={this.okSendReport}
                    />
                    :
                    null                
                }
                {this.state.modifyInfo?
                    <OlindaMuiForm
                        fields={this.trainingEditFields}
                        title="Formation"
                        onCancel={this.onCancelModifyInfo}
                        onValid = {this.onValidModifyInfo}
                        tutorId={this.props.tutors}
                        trainingId={this.props.trainings}
                        reason={[{id:0, name:''},{id:1, name:'Abandon'}, {id:2, name:'Certification'}, {id:3, name:'Non Acquis'}]}
                        financalId={this.props.financals}
                    />
                    :
                    null
                }
                
                {this.state.sendZip ?
                    <OlindaMuiForm
                        fields = {this.sendReportFields}
                        title="Envoyer le livret"
                        onCancel={this.cancelSendZipReport}
                        onValid={this.okSendZipReport}
                    />
                    :
                    null
                }

                {this.state.showInfo ?
                    <OlindaMuiInfo
                        title={"Description"}
                        text={this.state.infoText}
                        onOk = {this.onOkInfo}
                    />
                    :
                    null
                }

            </div>            
        ); 
    }

}

export default StudentDashboard
