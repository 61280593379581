import React from 'react'
import axios from 'axios'

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';



import Button from '@material-ui/core/Button'

import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import OlindaMuiTable from '../components/table'

class ELearningForm extends React.Component{
    constructor(props){
        super(props);
        this.state={
            eLearning:null,
            readOnly:false
        }
        
        this.onUpdateAnswer = this.onUpdateAnswer.bind(this)

        this.answersColumns=[
            {id:'selected',title:'', type:'check', cb:this.onUpdateAnswer, readOnly:false},
            {id:'title', title:'Titre'},
        ]

    }

    componentDidMount(){
        this.getELearning(this.props.eLearningId);
    }

    onAddTitle(){
    }

    getELearning(id){
        let request = global.site + '/elearning/getForStudent.php?id='+id;
        axios.get(request)
            .then(res => {
                if(res.data["success"]){
                    let eLearning = res.data["eLearning"];
                    let readOnly = eLearning["doneDate"] !== null || this.props.readOnly;
                    this.answersColumns[0].readOnly=readOnly;
                    this.setState({eLearning:eLearning, readOnly:readOnly});
                }
             })
             .catch(function(error) {
                error.handleGlobally && error.handleGlobally();
        })
    }

    onUpdateAnswer(event){
        let target = event.target
        let datas = new FormData();
        datas.append('selected', target.checked); 
        datas.append('itemId', target.id);
        datas.append('eLearningId', this.state.eLearning.id)
        axios({
            method: 'post',
            url: global.site +'/elearning/updateAnswer.php',
            data: datas
        })  
        .then(res =>  {
            if(res.data["success"]){
                let eLearning = res.data["eLearning"];
                this.setState({eLearning:eLearning});
            }   
        })  
        .catch(function (error) {
            error.handleGlobally && error.handleGlobally();
        }); 
        
    }


    createAnswersList(answers){
        return(
            <OlindaMuiTable
                elements={answers}
                columns={this.answersColumns}
                edit={false}
            />
        )
    }

    createItem(item){
        if(item.isTitle){
            return(
                <Card>
                    <CardHeader title={item.title}/>                    
                    <CardContent>        
                        {item.items.map((item)=>(
                            this.createItem(item)
                        ))
                        }
                    </CardContent>
                </Card>
            )
        }
        else if(item.isQuestion){
            return(
               <div>
                    <Typography>
                        {item.title}
                    </Typography>
                    {this.createAnswersList(item.items)}
                </div>
            )
        }
    }


    createELearning(){
        return(
            <div>
                {this.state.eLearning.items.map((item)=>(
                    this.createItem(item,0)
                ))
                }
            </div>
        )
    }

    render(){
        return(
            <div>
            <Dialog
                fullWidth={true}
                maxWidth={'md'}
                fullHeight
                open={true}
                aria-labelledby="form-dialog-title"
                scroll={'paper'}
            >
            <DialogTitle id="form-dialog-title">{this.state.eLearning !== null ? this.state.eLearning["title"] : null}</DialogTitle>
            <DialogContent>            
                {this.state.eLearning !== null ?
                    this.createELearning()
                    :
                    null
                }
            </DialogContent>
                <DialogActions>
                    {this.state.readOnly ?
                        <Button onClick={()=>this.props.onCancel()} color="primary">
                            OK
                        </Button>
                        :
                        <Button onClick={()=>this.props.onValid()} color="primary">
                            Valider
                        </Button>
                    }
                </DialogActions>
            
            </Dialog>
            </div>
        );
    
    }



}

export default ELearningForm

