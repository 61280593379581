import React from 'react'
import { withRouter } from "react-router-dom";
import {Link} from "react-router-dom";


import OlindaMuiMenu from '../components/menu.js'
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Toolbar';

class TrainingMenu extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            opened:false
        };
        this.state={
            items:{
                1: [
                    {label:'Documents',  id:'documents',
                        items:[
                            {label:'Livret de Formations', id:'reportsDocs'},
                            {label:'Cours', id:'teachingDocs'},
                            {label:'Administratif', id:'adminDocs'}
                        ]
                    }, 
                    {label:'Bilans', id:'reports',
                        items:[
                            {label:'Bilans Quotidiens', id:'weeklyReports'},
                            {label:'Bilans Mensuels', id:'reports'}
                        ]
                    },
                    {label:'Evaluations',id:'evals', 
                        items:[{label:'Evaluations Formatives', id:'tutoringReports'},
                               {label:'Evaluations Certificatives', id:'certificationReports'},
                               {label:'Evaluations En Lignes', id:'elearnings'}]
                    },
                    {label:'Visites', id:'visits',
                        items:[
                            {label:'Tuteur', id:'tutorVisits'},
                            {label:'Régulations', id:'regulVisits'}                        
                        ]
                    },
                    {label:'Commentaires', id:'comments'},
                    {label:'Emploi du temps', id:'scheduler'},
                ],
                2: [
                    {label:'Bilans Mensuels', id:'reports'},
                    {label:'Evaluations Formatives', id:'tutoringReports'},
                    {label:'Documents',  id:'documents',
                        items:[
                            {label:'Livret de Formation', id:'reportsDocs'},
                            {label:'Cours', id:'teachingDocs'},
                            {label:'Kit Tuteurs', id:'adminDocs'}
                        ]
                    }, 
                    {label:'Commentaires', id:'comments'},
                    {label:'Emploi du temps', id:'scheduler'},
                ],
                3: [
                    {label:'Documents',  id:'documents',
                        items:[
                            {label:'Livret de Formations', id:'reportsDocs'},
                            {label:'Cours', id:'teachingDocs'},
                            {label:'Administratif', id:'adminDocs'}
                        ]
                    }, 
                    {label:'Bilans', id:'reports',
                        items:[
                            {label:'Bilans Quotidiens', id:'weeklyReports'},
                            {label:'Bilans Mensuels', id:'reports'}
                        ]
                    },
                    {label:'Evaluations',id:'evals', 
                        items:[{label:'Evaluations Formatives', id:'tutoringReports'},
                               {label:'Evaluations Certificatives', id:'certificationReports'},
                               {label:'Evaluations En Lignes', id:'elearnings'}]
                    },
                    {label:'Visites Tuteur', id:'tutorVisits'},
                    {label:'Emploi du temps', id:'scheduler'},
                ],
                4:[
                    {label:'Documents',  id:'documents',
                        items:[
                            {label:'Livret de Formations', id:'reportsDocs'},
                            {label:'Cours', id:'teachingDocs'},
                            {label:'Administratif', id:'adminDocs'}
                        ]
                    }, 
                    {label:'Bilans', id:'reports',
                        items:[
                            {label:'Bilans Quotidiens', id:'weeklyReports'},
                            {label:'Bilans Mensuels', id:'reports'}
                        ]
                    },
                    {label:'Evaluations',id:'evals', 
                        items:[{label:'Evaluations Formatives', id:'tutoringReports'},
                               {label:'Evaluations Certificatives', id:'certificationReports'},
                               {label:'Evaluations En Lignes', id:'elearnings'}]
                    },
                    {label:'Visites', id:'visits',
                        items:[
                            {label:'Tuteur', id:'tutorVisits'},
                            {label:'Régulations', id:'regulVisits'}                        
                        ]
                    }
                ],
                5:[
                    {label:'Evaluations Certificatives', id:'certificationReports'}
                ]
            },
            itemsSmall:{
                1: [
                    {label:'Livret',  id:'reportsDocs'},
                    {label:'Bilans Quotidiens', id:'weeklyReports'}
                ],
                2: [
                    {label:'Bilans Mensuels', id:'reports'},
                    {label:'Evaluations Formatives', id:'tutoringReports'},
                    {label:'Kit Tuteur',  id:'adminDocs'}
                ],
                3: [
                    {label:'Livret',  id:'reportsDocs'},
                    {label:'Cours',  id:'teachingDocs'},
                    {label:'Emploi du temps', id:'scheduler'}
                ],
                4:[
                    {label:'Bilans Quotidiens', id:'weeklyReports'},
                    {label:'Bilans Mensuels', id:'reports'},
                    {label:'Evaluations Formatives', id:'tutoringReports'},
                    {label:'Evaluations Certificatives', id:'certificationReports'},
                    {label:'Fiches Navettes', id:'monthlyReports'},
                    {label:'Visites Tuteur', id:'tutorVisits'},
                ],
                5:[
                    {label:'Evaluations Certificatives', id:'certificationReports'}
                ]
            },
            plusItems:{
                1:[
                    {label:'Bilans Mensuels', id:'reports'},
                    {label:'Evaluations Formatives', id:'tutoringReports'},
                    {label:'Livret',  id:'reportsDocs'},
                    {label:'Fiches Navettes', id:'monthlyReports'},
                    {label:'Visites Tuteur', id:'tutorVisits'}
                ],
                2:[
                    {label:'Commentaires', id:'comments'},
                    {label:'Livret',  id:'reportsDocs'},
                    {label:'Emploi du temps', id:'scheduler'},
                    {label:'Cours', id:'teachingDocs'}
                ],
                3:[
                    {label:'Bilans Mensuels', id:'reports'},
                    {label:'Evaluations Formatives', id:'tutoringReports'},
                    {label:'Evaluations Certificatives', id:'certificationReports'},
                ]
            }
        };
    }
    
    componentDidMount(){
        let periodic = {label:'Carnet de Bord', id:'periodics'};
        if(this.props.trainingName.startsWith("AE")){
            let items = this.state.items;
            items[1].splice(1, 0, periodic);
            items[2].splice(1, 0, periodic);
            items[3].splice(1, 0, periodic);
            items[4].splice(1, 0, periodic);
            this.setState({items:items});
        }
        else if(this.props.trainingName !== ""){
            let monthly = {label:'Fiches Navettes', id:'monthlyReports'}
            let items = this.state.items;
            items[1].splice(2, 0, monthly);
            items[2].splice(2, 0, monthly);
            items[3].splice(2, 0, monthly);
            items[4].splice(2, 0, monthly);
            let plusItems = this.state.plusItems;
            plusItems[1].splice(1, 0, monthly);
            plusItems[2].splice(1, 0, monthly);
            plusItems[3].splice(1, 0, monthly);
            this.setState({items:items, plusItems:plusItems});
        }
    }


    componentDidUpdate(prevProps){
        let periodic = {label:'Carnet de Bord', id:'periodics'};
        if(this.props.trainingName !== prevProps.trainingName){
            if(this.props.trainingName.startsWith("AE")){
                let items = this.state.items;
                items[1].splice(1, 0, periodic);
                items[2].splice(1, 0, periodic);
                items[3].splice(1, 0, periodic);
                items[4].splice(1, 0, periodic);
                this.setState({items:items});
                
                let itemsSmall = this.state.itemsSmall;
                itemsSmall[1].splice(1, 0, periodic);
                itemsSmall[2].splice(1, 0, periodic);
                itemsSmall[3].splice(1, 0, periodic);
                itemsSmall[4].splice(1, 0, periodic);
                this.setState({items:items, itemsSmall:itemsSmall});
            }
            else if(this.props.trainingName !== ""){
                let monthly = {label:'Fiches Navettes', id:'monthlyReports'}
                let items = this.state.items;
                items[1].splice(2, 0, monthly);
                items[2].splice(2, 0, monthly);
                items[3].splice(2, 0, monthly);
                items[4].splice(2, 0, monthly);
                
                let plusItems = this.state.plusItems;
                plusItems[1].splice(1, 0, monthly);
                plusItems[2].splice(1, 0, monthly);
                plusItems[3].splice(1, 0, monthly);
                this.setState({items:items, plusItems:plusItems});
            }
        }
    }

    render(){
        return(
            <Toolbar style={{background:'blue'}}>
            <div style={{flexGrow:1}}>
                {this.props.profile != 3  ?
                    <Link
                        to={"/student/" + this.props.studentId}             
                        style={{color:'white'}}
                    >
                        {this.props.firstName + ' ' + this.props.lastName}
                    </Link>
                :
                null
                }
                <div style={{marginTop:'10px'}}>
                <Link variant="h2" style={{color:'White'}}
                    onClick={()=>this.props.handlePage('home')}
                >
                    {this.props.trainingName}
                </Link>
                </div>
            </div>
            <OlindaMuiMenu
                items={this.props.windowWidth > global.smallWidth ? this.state.items[this.props.profile] : this.state.itemsSmall[this.props.profile]}
                handlePage = {this.props.handlePage}
                baseRef = {'/student/' + this.props.studentId + '/training/' + this.props.trainingId}
                plusItems={this.props.windowWidth > global.smallWidth ? undefined: this.state.plusItems[this.props.profile]}
            />
            </Toolbar>
        );
    
    }



}

export default withRouter(TrainingMenu)
