import React from 'react'
import axios from 'axios'

import { withRouter, Link } from "react-router-dom";
import Button from '@material-ui/core/Button'

import OlindaMuiForm from '../components/form'
import OlindaMuiTable from '../components/table'
import ELearningEditor from './eLearningEditor.js'

class ELearningsEditor extends React.Component{
    constructor(props){
        super(props);
        this.state={
            eLearningsList:[],
            createELearning:false,
            eLearningId:0,
            editELearning:false
        }
        this.eLearningFields = [
            {id:'title', label:'Titre', type:'input', value:'', mandatory:true}
        ];


       this.onManageELearning = this.onManageELearning.bind(this);
       this.eLearningColumns = [
            {id:'title', title:'Titre'},
            {id:'', title:"Gestion", type:'button', cb:this.onManageELearning}
        ];
        this.onCreateELearning = this.onCreateELearning.bind(this);
        this.onValidCreateELearning = this.onValidCreateELearning.bind(this);
        this.onCancelCreateELearning = this.onCancelCreateELearning.bind(this);
        this.onEditELearning = this.onEditELearning.bind(this);
        this.onBackToList = this.onBackToList.bind(this);

    }

    componentDidMount()
    {
        this.getELearnings();
    }

    onBackToList(){
        this.setState({eLearningId:0, createELearning:false});
    }

    onManageELearning(id){
        this.setState({eLearningId:id});
    }

    onEditELearning(id){
        let request = global.site + '/elearning/get.php?id='+id;
        axios.get(request)
            .then(res => {
                if(res.data["success"]){
                    let eLearning = res.data["eLearning"];
                    this.eLearningFields[0].value = eLearning["title"];
                    this.setState({eLearningId:id, createELearning:true});
                }
             })
             .catch(function(error) {
                error.handleGlobally && error.handleGlobally();
        })
        
    }

    getELearnings(){
        let request = global.site + '/elearning/list.php';
        axios.get(request)
            .then(res => {
                const eLearnings = res.data["eLearnings"];
                this.setState({eLearningsList:eLearnings, eLearningId:0});
             })
             .catch(function(error) {
                error.handleGlobally && error.handleGlobally();
        }) 
    }
    
    onValidCreateELearning(fields){
        let datas = new FormData();
        fields.map((field)=>(
            datas.append(field.id, field.value)
        ))
        if(this.state.eLearningId > 0){
            datas.append('id', this.state.eLearningId);
        }
        axios({
            method: 'post',
            url: global.site +'/elearning/create.php',
            data: datas
        })
        .then(res =>  {
            if(res.data["success"]){
                let eLearnings = res.data["eLearnings"];
                this.setState({createELearning:false, eLearningsList:eLearnings, eLearningId:0});
            }
        })
        .catch(function (error) {
            error.handleGlobally && error.handleGlobally();
        });        
    }

    onCreateELearning(){
        this.setState({createELearning:true});
    }

    onCancelCreateELearning(){
        this.setState({createELearning:false, eLearningId:0});
    }
    
    render(){
        return(
            <div>
                {this.state.createELearning ?
                    <OlindaMuiForm
                        title="Evaluation En Ligne"
                        fields={this.eLearningFields}
                        onValid={this.onValidCreateELearning}
                        onCancel={this.onCancelCreateELearning}
                    />
                    :null
                }
                {this.state.eLearningId == 0 || this.state.createELearning ?
                    <OlindaMuiTable
                        elements={this.state.eLearningsList}
                        columns={this.eLearningColumns}
                        del={this.props.userProfile === 1}
                        force={false}
                        add={this.props.userProfile === 1 || (this.props.userProfile === this.props.reportProfile && this.props.userProfile !== 4)}
                        readOnly={this.props.userProfile !== 1 && this.props.userProfile !== this.props.reportProfile}
                        onAdd={this.onCreateELearning}
                        onEdit={this.onEditELearning}
                    />
                        :
                    <div>
                    <Button color="primary" variant="contained" onClick={()=>this.onBackToList()}>{'Fermer'}</Button>
                    <ELearningEditor
                        eLearningId = {this.state.eLearningId}
                    />
                    </div>
                }
                
            </div>
        );
    
    }

}

export default withRouter(ELearningsEditor)

