import React from 'react'

import Typography from '@material-ui/core/Typography';


import {withRouter} from "react-router-dom";

import LinkedDocuments from '../documents/linkedDocuments'
import User from '../user/user.js'

class Tutor extends React.Component{
    constructor(props){
        super(props);
    }

    
    render(){
        return(
            <User
                profile={2}
                userProfile={this.props.userProfile}
                currentId={this.props.userId}
                userId={this.props.match.params.tutorId}
                
            />                
        );
    
    }

}

export default withRouter(Tutor)
