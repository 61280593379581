import React from 'react'
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';


import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import Typography from '@material-ui/core/Typography'
import ColorPicker from 'material-ui-color-picker'

class OlindaMuiTableForm extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            fields:this.props.elements,
            headerFields:this.props.headerFields
        }

        this.handleChange = this.handleChange.bind(this);
    }

    onCancel() {
        this.props.onCancel();
    };

    onValid() {
        this.props.onValid(this.state.fields);
    };

    onDelete(){
        this.props.onDelete();
    }

    handleChange(e, fieldName){
        const target = e.target;
        var value = target.value;
        const id = target.id;
        let fields = this.state.fields.slice();
        let index = -1;
        for(index = 0;index < fields.length; index++){
            if(fields[index].id == id){
                if(fields[index].type === 'file'){
                    fields[index].file = target.files[0];
                }
                fields[index][fieldName] = value;
                break;
            }
        }
        this.setState({fields:fields});
    }
    
    handleChangeHeaderFields(e, fieldName){
        const target = e.target;
        var value = target.value;
        const id = target.id;
        let fields = this.state.headerFields.slice();
        let index = -1;
        for(index = 0;index < fields.length; index++){
            if(fields[index].id == id){
                if(fields[index].type === 'file'){
                    fields[index].file = target.files[0];
                }
                fields[index][fieldName] = value;
                break;
            }        
        }
        this.setState({headerFields:fields});
    }

    handleChangeColor(color, id){
        let fields = this.state.fields.slice();
        let index = -1;
        for(index = 0;index < fields.length; index++){
            if(fields[index].id === id){
                fields[index].value = color;
                break;
            }
        }
        this.setState({fields:fields});
    }

    createError(field){
        if(field.error !== undefined && field.error.length > 0){
            let error = field.error;
            field.error= '';
            return(
                <Typography>{error}</Typography>              
            );
        }
        else{
            return(null);
        }
    }

    createField(id, value, type, fieldName, readOnly, header=false){
        if(true){
            if(type === 'label'){
                return(
                    value
                );
            }
            else if(type === 'input' || type==='date' || type==='time')
            {
                return(                    
                    <div>
                    <TextField
                        margin="dense"
                        value={value !== 'null' ? value: ''}
                        id={id}
                        fullWidth
                        type = {type}
                        onChange={header ? (e)=>this.handleChangeHeaderFields(e, fieldName) : (e)=>this.handleChange(e,fieldName)}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        InputProps={{
                            readOnly:this.props.readOnly || readOnly,
                        }}
                    />
                    </div>
                );
            }
            else if(type === 'color'){
                return(
                    <ColorPicker
                        name='color'
                        defaultValue='#000'
                        id={id}
                        fullWidth
                        value={value}
                        onChange={color => this.handleChangeColor(color, id)}
                    />
                );
            }
            else if(type === 'multiline'){
                return(
                    <TextField
                        margin="dense"
                        value={value !== 'null' ? value : ''}
                        id={id}
                        fullWidth
                        multiline
                        onChange={(e)=>this.handleChange(e,fieldName)}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        InputProps={{
                            readOnly:this.props.readOnly || readOnly,
                        }}
                    />
                );
            }
            else if(type === 'file'){
                return(
                    <FormControl
                        fullWidth={true}
                    >                    
                        <input
                            native
                            value={value}
                            id={id}
                            type='file'
                            onChange={(e)=>this.handleChange(e,fieldName)}
                        />
                    </FormControl>
                );
            }
            else if(type === 'select'){
                return(
                    <FormControl
                        fullWidth={true}
                    >
                    
                        <Select
                            native
                            value={value}
                            id={id}
                            onChange={(e)=>this.handleChange(e,fieldName)}
                            disabled={this.props.readOnly || readOnly}
                        >
                        {this.props[id].map((option)=>(
                            <option value={option.id}>{option.name!==undefined?option.name:option.title}</option>
                        ))}
                        </Select>
                    </FormControl>
                );
            }
            else if(type === 'common-select'){
                return(
                    <FormControl
                        fullWidth={true}
                    >
                        <Select
                            native
                            value={value}
                            id={id}
                            onChange={(e)=>this.handleChange(e,fieldName)}
                            disabled={this.props.readOnly || this.props.readOnly}
                        >
                        {this.props.selectValues.map((option)=>(
                            <option value={option.id}>{option.name!==undefined?option.name:option.title}</option>
                        ))}
                        </Select>
                    </FormControl>
                );
            }
        }
    }

    createHeaderFields(){
        return(
            this.state.headerFields !== undefined ?
                this.state.headerFields.map((field) => (
                    this.createField(field.id, field.value, field.type, 'value', field.readOnly, true)
                ))
            :
            null
        );
    }

    createHeader(){
        return(
            <TableHead>
                <TableRow>
                    {this.props.columns.map((column) => (
                        <TableCell>{column.title}</TableCell>
                    ))}
                </TableRow>
            </TableHead>
        );
    }

    createBody(){
        let elements = this.state.fields;
        return(
            <TableBody>
                {elements.map((element) => (
                    <TableRow key={element.id} role="checkbox" style={{background:element.color}}>
                        {this.props.columns.map((column) => (
                            <TableCell component="th" scope="row">
                                {this.createField(element.id,element[column.id], column.type, column.id, column.readOnly)}
                            </TableCell>
                        ))}
                    </TableRow>
                ))}
            </TableBody>
        );
    }

    render(){
        return (
            <div>
            <Dialog 
                fulWidth 
                fullHeight
                open={true} 
                onClose={()=>this.onCancel()} 
                aria-labelledby="form-dialog-title"
                scroll={'paper'}
            >
            <DialogTitle id="form-dialog-title">{this.props.title}</DialogTitle>
            <DialogContent>
            {this.props.subtitle !== undefined && this.props.subtitle !== null && this.props.subtitle.length > 0?
                <DialogContentText>
                    {this.props.subtitle}
                </DialogContentText>
                :
                null
            }
            {this.createHeaderFields()}
            {this.createHeader()}
            {this.createBody()}
            </DialogContent>
            {this.props.readOnly ?
                <DialogActions>
                    <Button onClick={()=>this.onCancel()} color="primary">
                        Ok
                    </Button>
                </DialogActions>
                :
                <DialogActions>
                    
                    {this.props.buttons !== undefined ? this.props.buttons.map((button)=>(
                            <Button onClick={()=>button.action()} color="primary">
                                {button.label}
                            </Button>
                        )):null
                    }

                    <Button onClick={()=>this.onValid()} color="primary">
                        {this.props.modify ? "Modifier" : "Valider"}
                    </Button> 
                    {this.props.del ?
                        <Button onClick={()=>this.onDelete() } color="primary">
                            Supprimer
                        </Button>
                        : 
                        null
                    }
                    <Button onClick={()=>this.onCancel()} color="primary">
                        Annuler
                    </Button>
                </DialogActions>
            }
        </Dialog>
        </div>
  );
    }
}


export default OlindaMuiTableForm;
