import React from 'react'
import axios from 'axios'

import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import TextField from '@material-ui/core/TextField';

import ItemTable from './itemTable'

class TutoringReportFormItem extends React.Component{
    constructor(props){
        super(props);
        this.state={
            item:this.props.item,
            itemId:0
        }
        this.itemFields = [
            {id:'title', label:'Titre', type:'input', value:''}
        ];

        this.validationFields={title:'Résultats', values:[{id:1, title:'Acquis'},{id:2, title:'Non-Acquis'}], readOnly:this.props.userProfile ===1};

        this.results=[{id:0, name:''}, {id:1, name:'Acquis'}, {id:2, name:'Non Acquis'}];

        this.itemColumns = [
            {id:'title', title:'Titre'},
            {id:'result', title:'Résultat'}
        ];
    }

    onSetNote(event){
        const itemId = parseInt(event.target.name);
        let result = parseInt(event.target.value);
        if(isNaN(result)){
            result = null;
        }
        let item = this.state.item;
        let items = item.items.slice();
        for (let item of items){
            if(item.id === itemId){
                item.result = result;
                break;
            }
        }
        this.setState({item:item})
    }

    onUpdateComment(){
    }

    handleChange(event){
        const target = event.target;
        var value = target.value;
        let item=this.state.item;
        item.comment = value;
        this.setState({item:item});

    }

    createComment(item){
        return(
            <TextField
                margin="dense"
                label={'Commentaire'}
                value={item.comment !== 'null' ? item.comment : ''}
                id={item.id}
                fullWidth
                multiline
                rows={4}
                onChange={(event)=>this.handleChange(event)}
                InputLabelProps={{
                    shrink: true,
                }}
                InputProps={{
                    readOnly:this.props.readOnly,
                }}
            />
        );
    }

    render(){
        return(
                <div>
                    <Card>
                       <CardHeader title={this.props.item.title} subheader={this.props.item.description}/>
                        <CardContent>
                            <ItemTable
                                elements={this.props.item.items}
                                columns={this.itemColumns}
                                onChange = {this.onChangeResult}
                                readOnly={this.props.readOnly}
                                onSetNote={(event)=>this.onSetNote(event)}
                            />
                            {this.createComment(this.props.item)}
                        </CardContent>
                    </Card>
                </div>
        );
    
    }



}

export default TutoringReportFormItem

