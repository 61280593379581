import React from 'react'
import axios from 'axios'

import AddIcon from '@material-ui/icons/Add';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import OlindaMuiForm from '../components/form'
import OlindaMuiFlatForm from '../components/flatForm'

import TutoringReportItem from './tutoringReportItem.js'


class TutoringReportDetails extends React.Component{
    constructor(props){
        super(props);
        this.state={
            items:[],
            createItem:false,
            itemId:0
        }
        this.itemFields = [
            {id:'title', label:'Titre', type:'input', value:'', mandatory:true},
            {id:'description', label:'Description', type:'multiline', value:''}
        ];
        
        this.reportFields = [
            {id:'title', label:'Titre', type:'input', value:this.props.reportTitle}
        ];

        this.validationFields = [
            {id:'succeed', title:'Acquis'},
            {id:'failed', title:'Non-Acquis'},
        ]

       this.itemColumns = [
            {id:'title', title:'Titre'}
        ];
        this.onCreateItem = this.onCreateItem.bind(this);
        this.onValidCreateItem = this.onValidCreateItem.bind(this);
        this.onCancelCreateItem = this.onCancelCreateItem.bind(this);
        this.onDeleteItem = this.onDeleteItem.bind(this);
        this.onEditItem = this.onEditItem.bind(this);
        this.onUpdateReport = this.onUpdateReport.bind(this);
        this.onModifyTitle = this.onModifyTitle.bind(this);
        this.onCancelModifyTitle = this.onCancelModifyTitle.bind(this);

    }

    componentDidMount()
    {
        this.getItems();
    }

    getItems(){
        let request = global.site + '/tutoringReport/list.php?type=items&reportId=' + this.props.reportId;
        if(this.props.studentId !== undefined && this.props.studentId > 0){
            request += '&studentId=' + this.props.studentId;
        }
        axios.get(request)
            .then(res => {
                const items = res.data["items"];
                this.setState({items:items});
             })
             .catch(function(error) {
                error.handleGlobally && error.handleGlobally();
        })
        
    }

    getItem(id){
        axios.get(global.site +'/tutoringReport/get.php?type=item&id='+ id)
            .then(res => {
                if(res.data["success"]){
                    this.itemFields[0].value = res.data["title"];
                    this.itemFields[1].value = res.data["description"];
                    this.setState({createItem:true , itemId:id})
                }
             })
             .catch(function(error) {
                error.handleGlobally && error.handleGlobally();
        })
    
    }

    onEditItem(id){
        this.getItem(id);
    }

    onValidCreateItem(fields){
        let datas = new FormData();
        fields.map((field)=>(
            datas.append(field.id, field.value)
        ))
        datas.append('type', 'item');
        datas.append('reportId', this.props.reportId);
        if(this.state.itemId !== 0){
            datas.append('id', this.state.itemId);
        }
        axios({
            method: 'post',
            url: global.site +'/tutoringReport/create.php',
            data: datas
        })
        .then(res =>  {
            if(res.data["success"]){
                let items = res.data["items"];
                this.setState({createItem:false, items:items, itemId:0});
            }
        })
        .catch(function (error) {
            error.handleGlobally && error.handleGlobally();
        });
        
    }
    onCreateItem(){
        this.itemFields[0].value='';
        this.itemFields[1].value='';
        this.setState({createItem:true});
    }

    onCancelCreateItem(){
        this.setState({createItem:false, itemId:0});
    }
    
    onDeleteItem(id){
        let datas = new FormData();
        datas.append('reportId', this.props.reportId);
        datas.append('ids[]', id);
        datas.append('type', 'item');
        axios({
            method: 'post',
            url: global.site +'/tutoringReport/delete.php',
            data: datas
        })
        .then(res =>  {
            if(res.data["success"]){
                let items = res.data["items"]
                this.setState({items:items, itemId:0});
            }
        })
        .catch(function (error) {
            error.handleGlobally && error.handleGlobally();
        });
    }

    onPrintReport(){
        window.open(global.site + '/tutoringReport/edit.php?id='+this.props.reportId, "_blank");
    }


    onUpdateReport(){
        let datas = new FormData();
        
        datas.append('title', this.reportFields[0].value)
        datas.append('type', 'report');
        datas.append('profile', this.props.reportProfile);
        datas.append('id', this.props.reportId);
        axios({
            method: 'post',
            url: global.site +'/tutoringReport/create.php',
            data: datas
        })
        .then(res =>  {
            if(res.data["success"]){
                this.setState({modifyTitle:false});
            }
        })
        .catch(function (error) {
            error.handleGlobally && error.handleGlobally();
        });
    
    }

    onModifyTitle(){
        this.setState({modifyTitle:true});
    }

    onCancelModifyTitle(){
        this.setState({modifyTitle:false});
    }

    render(){
        return(
            <div>
                <OlindaMuiFlatForm
                    fields={this.reportFields}
                    onModify = {this.onModifyTitle}
                    buttonTitle = "Modifier"
                    readOnly={ this.props.userProfile === 2 || this.props.studentId !== undefined}
                />
                {
                    this.props.studentId !== undefined?
                        <Button variant="contained" color="primary" onClick={()=>this.onPrintReport()}>
                            Imprimer
                        </Button>
                    :
                    null
                }
                {this.state.modifyTitle ?
                    <OlindaMuiForm
                        fields={this.reportFields}
                        title={"Modifier le titre"}
                        onValid={this.onUpdateReport}
                        onCancel={this.onCancelModifyTitle}
                    />
                    :
                    null
                }

                {this.state.createItem ?
                    <OlindaMuiForm
                        title="Item d'évaluation"
                        fields={this.itemFields}
                        onValid={this.onValidCreateItem}
                        onCancel={this.onCancelCreateItem}
                    />
                    :null
                }
                <div>
                    <Typography variant="h5">{'Items d\'évaluation'}</Typography>
                    {this.props.userProfile === 1 && this.props.studentId === undefined?
                        <IconButton aria-label="settings">
                            <AddIcon  onClick={()=>this.onCreateItem()}/>
                        </IconButton>
                        :
                        null
                    }            
                    {this.state.items.map((item) =>(
                        <TutoringReportItem
                            item={item}
                            studentId={this.props.studentId}
                            reportId={this.props.reportId}
                            onDeleteItem = {this.onDeleteItem}
                            onEditItem = {this.onEditItem}
                            userProfile = {this.props.userProfile}
                            reportProfile = {this.props.reportProfile}
                            deep = {this.props.reportProfile > 3 && this.props.reportProfile < 6 ? 1 : 0}
                            hasNote = {this.props.reportProfile > 3 && this.props.reportProfile < 6 ? false : true}
                        />
                    ))
                    }
                </div>
            </div>
        );
    
    }



}

export default TutoringReportDetails

