import React from 'react'
import axios from 'axios'

import Typography from '@material-ui/core/Typography';

import OlindaMuiTable from '../components/table'
import OlindaMuiForm from '../components/form'

class DocSubject extends React.Component{
    constructor(props){
        super(props);
        this.state={
            subjects:[],
            subjectId:0,
            createSubject:false
        }

        this.createSubject = this.createSubject.bind(this);
        this.deleteSubject = this.deleteSubject.bind(this);
        this.validCreateSubject = this.validCreateSubject.bind(this);
        this.cancelCreateSubject = this.cancelCreateSubject.bind(this);
        this.editSubject = this.editSubject.bind(this);

        this.subjectFields=[{id:'title', label:'Titre', type:'input', value:'', mandatory:true}];
        this.subjectColumns=[{id:'title', title:'Titre'}];
    }


    componentDidMount(){
        this.getSubjectsList();
    }

    createSubject(){
        this.setState({createSubject:true, subjectId:0});
    }
    
    cancelCreateSubject(){
        this.setState({createSubject:false, sujectId:0});
    }

    editSubject(id){
        this.getSubject(id);
    }

    validCreateSubject(fields){
         let datas = new FormData();
         let url = 'createDocSubject.php'
         fields.map((field)=>(
             datas.append(field.id, field.value)
         ))
         if(this.state.subjectId !== 0){
             datas.append("id", this.state.subjectId);
             url='updateDocSubject.php'
         }
         axios({
             method: 'post',
             url: global.site +'/docSubject/' + url,
             data: datas
         })
         .then(res =>  {
             if(res.data["success"]){
                 let subjects = res.data["subjects"]
                 this.setState({createSubject:false, subjects:subjects, subjectId:0});
             }
         })
         .catch(function (error) {
             error.handleGlobally && error.handleGlobally();
         });
        
    }

    deleteSubject(ids){
        let datas = new FormData();
        for(let i = 0; i < ids.length; i++){
            datas.append('ids[]', ids[i]);
        }
        axios({
            method: 'post',
            url: global.site +'/docSubject/deleteDocSubject.php',
            data: datas
        })
        .then(res =>  {
            if(res.data["success"]){
                let subjects = res.data["subjects"]
                this.setState({subjects:subjects});
            }
        })
        .catch(function (error) {
            error.handleGlobally && error.handleGlobally();
        });    
    }

    getSubject(id){
        axios.get(global.site +'/docSubject/getDocSubject.php?id='+ id, {withCredentials:true})
            .then(res => {
                if(res.data["success"]){
                    this.subjectFields[0].value=res.data["title"]
                    this.setState({createSubject:true, subjectId:id})
                }
             })
             .catch(function(error) {
                error.handleGlobally && error.handleGlobally();
        })
    
    }

    getSubjectsList(){
        axios.get(global.site +'/docSubject/docSubjectsList.php', {withCredentials:true})
            .then(res => {
                const subjects = res.data["subjects"];
                this.setState({subjects:subjects, subjectId:0});
             })
             .catch(function(error) {
                error.handleGlobally && error.handleGlobally();
        })
    }
    
    render(){
        return(
           <div style={{padding:'50px'}}>
               <Typography variant="h6">{"Liste des thème de documents"}</Typography>
               <OlindaMuiTable
                    elements={this.state.subjects}
                    columns = {this.subjectColumns}
                    del={true}
                    add={true}
                    onAdd={this.createSubject}
                    onDelete={this.deleteSubject}
                    onEdit={this.editSubject}
                />
               {this.state.createSubject ?
                <OlindaMuiForm
                    fields={this.subjectFields}
                    title={"Thème"}
                    modify = {this.state.subjectId !== 0}
                    onCancel={this.cancelCreateSubject}
                    onValid={this.validCreateSubject}
                />
                :
                null
               }
           </div>            
        ); 
    }

}

export default DocSubject
