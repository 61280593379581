import React from 'react'
import axios from 'axios'

import { Redirect, withRouter } from "react-router-dom";
import LinkedDocuments from '../documents/linkedDocuments'

import Typography from '@material-ui/core/Typography';
import User from '../user/user'
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import StudentProfile from './studentProfile'

class Student extends React.Component{
    constructor(props){
        super(props);
        this.state={
            trainings:[],
            tutors:[],
            financals:[],
            studentId:0,
            student:null,
            trainingEditId:0,
            trainingId: this.props.trainingId,
            trainingFilter: this.props.trainingFilter
        }
        this.handleFilter=this.handleFilter.bind(this);
    }

    componentDidMount(){
        this.getTutorsList();
        this.getTrainingsList();
        this.getFinancalsList();
    }

    componentDidUpdate(prevProps){
        if(this.props.match.params.studentId !== undefined && this.props.match.params.studentId !== this.state.studentId){
            this.getStudent(this.props.match.params.studentId);
        }
        else if(this.props.match.params.studentId === undefined && this.state.studentId != 0){
            this.setState({studentId:0});
        }
    }

    getTutorsList()
    {
        axios.get(global.site +'/user/list.php?profile=2&byName=true')
            .then(res => {
                const tutors = [{name:'', id:0}].concat(res.data["users"]);
                this.setState({tutors:tutors});
             })
             .catch(function(error) {
                error.handleGlobally && error.handleGlobally();
        })
    }
    
    getFinancalsList()
    {
        axios.get(global.site +'/financal/financalItemsList.php')
            .then(res => {
                const financals = [{name:'', id:0}].concat(res.data["items"]);
                this.setState({financals:financals});
             })
             .catch(function(error) {
                error.handleGlobally && error.handleGlobally();
        })
    }

    getTrainingsList()
    {
        if(this.props.userProfile !== 1 && this.props.userProfile !==4){
            return;
        }
        axios.get(global.site +'/training/list.php')
            .then(res => {
                this.setState({trainings:res.data["trainings"]});
             })
             .catch(function(error) {
        })
    }

    getStudent(id){
        axios.get(global.site +'/user/get.php?profile=3&id='+ id)
            .then(res => {
                if(res.data["success"]){
                    this.setState({student:res.data, studentId:id})
                }
             })
             .catch(function(error) {
                error.handleGlobally && error.handleGlobally();
        })

    }

    handleFilter(e){
        const target = e.target;
        var value = target.value;
        const id = target.id;
        this.props.setStudentTrainingFilter(value);
        this.setState({trainingFilter:value});
    }
    
    render(){
        if(this.props.userProfile === 3 && this.props.studentId != this.props.userId){
            return(
                 <Redirect to="/"/>
            )
        }
        return(
            <div>
                {this.state.studentId !== 0 && this.props.match.params.studentId !== undefined && this.props.match.params.studentId !== 0?
                    <div>
                    <StudentProfile
                        studentId={this.state.studentId}
                        student={this.state.student}
                        tutors={this.state.tutors}
                        financals={this.state.financals}
                        trainings={this.state.trainings}
                        readOnlyInfo={this.props.userProfile !== 1}
                        userProfile={this.props.userProfile}
                        userId={this.props.userId}
                        trainingId={this.props.match.params.trainingId}
                        moduleId={this.props.match.params.moduleId}
                        page={this.props.match.params.page}
                        windowWidth={this.props.windowWidth}
                    />
                    </div>
                    :
                    <div style={{padding:'0px'}}>
                        <User
                            profile={3}
                            userProfile={this.props.userProfile}
                            trainingId={this.props.trainingId == undefined ? this.props.trainingFilter : this.props.trainingId}
                            tutorId={this.props.userProfile === 2 ? this.props.userId : undefined}
                            filter={this.props.trainingId === undefined && (this.props.userProfile === 1 || this.props.userProfile === 4) ? {label:'Filtre par formation',defaultalue:0, values:this.props.studentTrainings, callback:this.handleFilter, value:this.state.trainingFilter}: undefined}
                            extraLink={(this.props.trainingId !== undefined && this.props.trainingId >0) || 
                                       (this.props.trainingFilter !== undefined && this.props.trainingFilter >0) ||
                                       (this.props.userProfile === 2)
                                     ? {key:'training', value:'trainingId'} : undefined}
                            trainings={this.props.studentTrainings}
                        />
                        {this.props.userProfile === 1 ?
                            <div>
                            <Typography variant="h5">Documents</Typography>
                            <LinkedDocuments
                                type='students-docs'
                                del={false}
                                add={false}
                            />   
                            </div>
                            :
                            null
                        }
                    </div>
                }
            </div>
        );
    
    }

}

export default withRouter(Student)
