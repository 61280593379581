import React from 'react'

import Typography from '@material-ui/core/Typography';


import {withRouter} from "react-router-dom";

import User from '../user/user.js'

class Guest extends React.Component{
    constructor(props){
        super(props);
    }

    
    render(){
        return(
            <div>
                <User
                    profile={4}
                    userProfile={this.props.userProfile}
                    userId={this.props.match.params.guestId}
                />                
            </div>
        );
    
    }

}

export default withRouter(Guest)
