import React from 'react'
import axios from 'axios'

import TrainingPeriod from './trainingPeriod.js'
import Documents from '../documents/documents'
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import OlindaMuiDownload from '../components/downloadDialog.js'


class TrainingPeriodList extends React.Component{
    constructor(props){
        super(props);
        this.state={
            periods:[],
            sendZip:false
        }
        this.onCancelDownload = this.onCancelDownload.bind(this);
    }
   
    formatDate(value){
        const date = new Date(value);
        return date.toLocaleDateString('fr-fr');
    }

    /*Edit the current training*/
    getPeriods(){
         axios.get(global.site +'/tutoring/list.php?type=tutoring&id=' + this.props.trainingId)
            .then(res => {
                const tutorings = res.data["tutorings"];
                this.setState({periods:tutorings});
             })
             .catch(function(error) {
                error.handleGlobally && error.handleGlobally();
        })

    }

    componentDidMount(){
        this.getPeriods();
    }    
    
    onDownloadBoard(){

        this.setState({sendZip:true});
        /*
        let datas = new FormData();
        datas.append("studentId", this.props.studentId);
        datas.append("trainingId", this.props.studentTrainingId);

        axios({
            method: 'post',
            url: global.site +'/student/downloadBoard.php',
            data: datas
        })  
        .then(res =>  {
            if(res.data["success"]){
            }                      
        })  
        .catch(function (error) {
            error.handleGlobally && error.handleGlobally();
        });*/ 
        
    }

    onCancelDownload(){
        this.setState({sendZip:false});
    }

    createPeriod(start, end){
        return(

            <TrainingPeriod
                start={start}
                end={end}
                trainingId={this.props.studentTrainingId}
                studentId={this.props.studentId}
                userProfile={this.props.userProfile}
            />
        );
    }

    createReportDocuments(){
        return(
            <div>
            <Typography variant="h5">Fiches de Suivi</Typography>
            <Button
                variant="contained"
                color="primary"
                onClick={()=>this.onDownloadBoard()}
            >
            {'Télécharger'}
            </Button>
            <Documents
                userId={this.props.studentId}
                trainingId={this.props.studentTrainingId}
                directory={'board-' + this.props.studentTrainingId}
                type="student-board"
                add={this.props.userProfile === 3}
                del={this.props.userProfile === 3}
                edit={false}
            />
            </div>
        );
    }


    render(){
        return(
            <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                    {this.state.periods.map((period)=>(
                        this.createPeriod(period.start, period.end)
                    ))}
                </Grid>
                <Grid item xs={12} sm={6}>
                    {this.createReportDocuments()}
                </Grid>
            {this.state.sendZip ?
                    <OlindaMuiDownload
                        title="Télécharger le carnet de bord"
                        datas={{"studentId":this.props.studentId, "trainingId":this.props.studentTrainingId}}
                        url={'/student/downloadBoard.php'}
                        onCancel={this.onCancelDownload}
                    />
                    :
                    null
            }
            </Grid>

        );
    }
}

export default TrainingPeriodList
