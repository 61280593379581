import React from 'react'
import IconButton from '@material-ui/core/IconButton';
import Box from '@material-ui/core/Box';

import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import {Link} from "react-router-dom";
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import MenuList from '@material-ui/core/MenuList'
import MoreVertIcon from '@material-ui/icons/MoreVert'

class OlindaMuiMenu extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            opened:false,
            anchorEl:null
        };

        this.handleClose = this.handleClose.bind(this);
        this.handleOpen = this.handleOpen.bind(this);
        this.closeMenu = this.closeMenu.bind(this);
    }

    handleClose(){
        this.setState({opened:false});
    }

    handleOpen(event){
        this.setState({opened:true, anchorEl:event.currentTarget}); 
    }

    handleSelect(event, id, title){
        this.setState({opened:false});
        if (this.props.handlePage !== undefined){
            this.props.handlePage(id, title);
        }
    }

    handleSelectSubMenu(menuId, itemId){
        if(this.props.handlePage !== undefined){
            this.props.handlePage(itemId);
        }
        this.setState({[menuId]:{opened:false}});
    }

    openMenu(event, id){
        this.setState({[id]:{opened:true, anchorEl:event.currentTarget}}); 
    }

    closeMenu(id){
        this.setState({[id]:{opened:false}}); 
    }

    buildSubMenu(items, id){
        return(
            <Menu
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
                }}
                anchorEl={this.state[id] !== undefined ? this.state[id].anchorEl : null}
                open={this.state[id] !== undefined ? this.state[id].opened : false}
                onClose={()=>this.closeMenu(id)}
            >
            {items.map((item)=>(
                this.props.handlePage === undefined ?
                    <MenuItem button key={item.id} id={item.id} onClick={()=>this.handleSelectSubMenu(id, item.id)}>
                         <Link to={this.props.baseRef !== undefined ? this.props.baseRef + "/" + item.id : "/" + item.id}>{item.label}</Link>
                    </MenuItem>
                    :
                    <MenuItem button key={item.id} id={item.id} onClick={()=>this.handleSelectSubMenu(id,item.id)}>
                         <Typography>{item.label}</Typography>
                    </MenuItem>                
            ))}
            </Menu>
        );
    }

    createSubIconButton(item){
        return(
            <IconButton
                ref={this.state[item.id]!== undefined ? this.state[item.id].anchorEl:null}
                onClick={(event)=>this.openMenu(event, item.id)}
            >
                {item.icon !== undefined ? item.icon : null}
                <Typography 
                    style={{color:'white'}}
                >
                {item.label}
                </Typography>
            </IconButton>
        )
    }

    createMobileSubMenu(item){
        return(
           <div>
           <Divider/>
            <Typography style={{fontSize:'12px'}}>{item.label}</Typography>
            {item.items.map((menuItem)=>(
               this.createMobileButton(menuItem)
           ))}
           <Divider/>
           </div>
        );
    }
    
    createIconButton(item){
        if(this.props.handlePage === undefined){
            return(
                <IconButton
                    component={Link}
                    to={this.props.baseRef !== undefined ? this.props.baseRef + "/" + item.id : "/" + item.id}
                    onClick={this.props.close !== undefined ? ()=>this.props.close() : null}
                >
                    {item.icon !== undefined ? item.icon : null}
                    <Typography 
                        style={{color:'white'}}
                    >
                    {item.label}
                    </Typography>
                </IconButton>
            )
        }
        else{
            return(
                <IconButton
                    onClick={()=>this.props.handlePage(item.id)}
                >
                    {item.icon !== undefined ? item.icon : null}
                    <Typography 
                        style={{color:'white'}}
                    >
                    {item.label}
                    </Typography>
                </IconButton>
            )
        }
        
    }
    
    createMobileButton(item){
        return(
            <Button
                style={{borderRadius:'3px', border:'1px solid grey', margin:'10px', background:'white', color:'grey', fontSize:'9px'}}
                component={Link}
                to={this.props.baseRef !== undefined ? this.props.baseRef + "/" + item.id : "/" + item.id}
                onClick={this.props.close !== undefined ? ()=>this.props.close() : null}
            >
                {item.label}
            </Button>
        );
        
    }


    buildMobile(){
        const itemList = this.props.items;
        return(
            <Box style={{background:'blue'}}>
                {itemList.map((item)=>(
                    item.items!==undefined && item.items.length > 0?
                    this.createMobileSubMenu(item)
                    :
                    this.createMobileButton(item)
                ))}
            </Box>
        );        
    }

    buildList(){
        const itemList = this.props.items;
        return(
            <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                {itemList.map((item)=>(
                    item.items!==undefined && item.items.length > 0?
                    this.createSubIconButton(item)
                    :
                    this.createIconButton(item)
                ))}
                {this.props.plusItems !== undefined && this.props.plusItems.length > 0 ?
                    <IconButton
                        aria-label="home"
                        aria-controls="home-appbar"
                        aria-haspopup="true"
                        style={{color:'white'}}
                        onClick={this.handleOpen}
                    >
                        <MoreVertIcon/>
                    </IconButton>
                    :
                    null
                }
            </Box>
        );        
    }

    buildPlusList(){
        const itemList = this.props.plusItems;
        return(
            itemList.map((item)=>(
                <MenuItem button key={item.id} id={item.id} onClick={(event)=>this.handleSelect(event, item.id)}>
                     {this.props.handlePage == undefined ?
                         <Link to={this.props.baseRef !== undefined ? this.props.baseRef + "/" + item.id : "/" + item.id}>{item.label}</Link>
                         :
                         item.label
                     }
                </MenuItem>
            ))
        );
        
    }
    
    render(){
        if(this.props.mobile){
            return(
                <div>
                    {this.buildMobile()}
                </div>
            );
        }
        else{
            return(
                <div>
                {this.buildList()}
                <Menu 
                    anchorEl={this.state.anchorEl} 
                    open={this.state.opened} 
                    onClose={this.handleClose}
                >
                {this.props.plusItems !== undefined && this.props.plusItems.length >0 ?
                    this.buildPlusList()
                    :
                    null
                }
                </Menu>
                {this.props.items.map((item)=>(
                    item.items !== undefined && item.items.length >0 ? this.buildSubMenu(item.items, item.id) : null
                )) 
                }
                </div>
            );
        } 
    }



}

export default OlindaMuiMenu
