import React from 'react'
import axios from 'axios'

import Breadcrumbs from '@material-ui/core/Breadcrumbs'

import Typography from '@material-ui/core/Typography';

import { Link,withRouter } from "react-router-dom";

import OlindaMuiForm from '../components/form'
import OlindaMuiTable from '../components/table'
import OlindaMuiCardList from '../components/cardList.js'
import TutoringReport from '../tutoringReport/tutoringReport.js'
import MonthlyForm from '../monthlyForm/monthlyForm.js'

class TrainingPeriod extends React.Component{
    constructor(props){
        super(props);
        this.state={
            evals:[],
            elementType:'',
            elementId:'',
            elementProfile:0
        }
        this.evalsColumns=[
            {id:'title', label:'Documents'},
            {id:'date', label:'Date', type:'date'}
        ];

        this.onEditElement = this.onEditElement.bind(this);
        this.onCloseElement = this.onCloseElement.bind(this);
    }
   
    formatDate(value){
        const date = new Date(value);
        return date.toLocaleDateString('fr-fr');
    }

    /*Edit the current training*/
    getEvals(){
         axios.get(global.site +'/tutoring/evals.php?trainingId='+this.props.trainingId+'&start='+this.props.start+'&end='+this.props.end)
            .then(res => {
                const evals = res.data["evals"];
                this.setState({evals:evals});
             })
             .catch(function(error) {
                error.handleGlobally && error.handleGlobally();
        })

    }

    componentDidMount(){
        this.getEvals();
    }    

    onEditElement(id, type, profile){
        this.setState({elementId:id, elementType:type, elementProfile:profile});
    }

    onCloseElement(){
        this.getEvals();
        this.setState({elementId:0, elementType:"", elementProfile:0}); 
    }

    render(){
        return(
            <div>
                <Typography variant="h5" style={{marginTop:'50px', marginBottom:'0px'}}>
                    {'du ' + this.formatDate(this.props.start) + ' au ' + this.formatDate(this.props.end)}
                </Typography>
                <OlindaMuiTable
                    columns={this.evalsColumns}
                    elements={this.state.evals}
                    onEdit={this.onEditElement}
                    useType={true}
                />
                {this.state.elementType.localeCompare("tutoringReport") == 0 ?
                    <TutoringReport
                        studentId={this.props.studentId}
                        trainingId={this.props.trainingId}
                        reportId={this.state.elementId}
                        userProfile={this.props.userProfile}
                        reportProfile={this.state.elementProfile}
                        onClose={this.onCloseElement}
                    />
                    :
                    null
                }
                {this.state.elementType.localeCompare("monthlyForm") == 0 ?
                    <MonthlyForm
                        reportId={this.state.elementId}
                        userProfile={this.props.userProfile}                        
                        formProfile={this.state.elementProfile}
                        onClose={this.onCloseElement}
                    />
                    :
                    null
                }
            </div>
        );
    }

}

export default TrainingPeriod
