import React from 'react'
import axios from 'axios'

import Typography from '@material-ui/core/Typography';
import OlindaMuiTable from '../components/table'
import OlindaMuiTableForm from '../components/tableForm'
import OlindaMuiForm from '../components/form.js'

class MonthlyForm extends React.Component{
    constructor(props){
        super(props);
        this.state={
            reports:[],
            reportId:0,
            updateReport:false,
            updateDate:false
        }

        this.itemFields = [];
        this.itemColumns = [{id:'title', title:'Titre', type:'label'}, 
                            {id:'sObs', title:'Stagiaire', type:'multiline', readOnly:this.props.userProfile !== 3}, 
                            {id:'tObs', title: this.props.formProfile == 1 ? 'Formateur' : 'Tuteur', type:'multiline', 
                             readOnly:this.props.formProfile !== this.props.userProfile}];
        this.updateReport = this.updateReport.bind(this);
        this.cancelUpdateReport = this.cancelUpdateReport.bind(this);
        this.deleteReport = this.deleteReport.bind(this);
        this.editReport = this.editReport.bind(this);
        this.addReport = this.addReport.bind(this);
        this.onViewForm = this.onViewForm.bind(this);
        this.onUpdateDate = this.onUpdateDate.bind(this);
        this.onValidUpdateDate = this.onValidUpdateDate.bind(this);
        this.onCancelUpdateDate = this.onCancelUpdateDate.bind(this);



        this.reportColumns=[
            {id:'date', title:'Date', type:'date'},
            {id:'changeDate', title:'Changer la date', type:'button', cb:this.onUpdateDate},
            {id:'pdf', title:'PDF', type:'button', cb:this.onViewForm},
            ];
        this.dateFields = [
            {id:'date', label:'Date', type:'date'}
        ]

    }

    onUpdateDate(id){
       this.setState({reportId:id, updateDate:true});
    }

    onValidUpdateDate(fields){
        let datas = new FormData();
        datas.append("date", fields[0].value);
        datas.append("formId", this.state.reportId);
        datas.append("studentId", this.props.studentId);
        datas.append("trainingId", this.props.trainingId);
        datas.append("formProfile", this.props.formProfile);
        axios({
            withCredentials:true,
            method: 'post',
            url: global.site +'/monthlyForm/updateDate.php',
            data: datas
        })
        .then(res =>  {
            if(res.data["success"]){
                let reports = res.data["forms"]
                this.setState({reports:reports,reportId:0, updateDate:false});
                if(this.props.onClose !== undefined){
                    this.props.onClose();
                }
            }
        })
        .catch(function (error) {
            error.handleGlobally && error.handleGlobally();
        });
    }

    onCancelUpdateDate(){
        this.setState({updateDate:false});
    }



    componentDidMount(){
        if(this.props.reportId !== undefined && this.props.reportId >0){
            this.getReport(this.props.reportId);
        }
        else{
            this.getReports();
        }
    }
    componentDidUpdate(props){
        if(this.props.reportId !== undefined && this.props.reportId > 0 && props.reportId !== this.props.reportId){
            this.getReport(this.props.reportId);
        }
    }

    editReport(id){
        this.getReport(id);
    }
    
    cancelUpdateReport(){
        this.setState({updateReport:false});
        if(this.props.onClose !== undefined){
            this.props.onClose();
        }
    }

    addReport(){
         let datas = new FormData();
         datas.append("trainingId", this.props.trainingId);
         datas.append("studentId", this.props.studentId);
         datas.append("formProfile", this.props.formProfile);
         axios({
             method: 'post',
             url: global.site +'/monthlyForm/create.php',
             data: datas
         })
         .then(res =>  {
             if(res.data["success"]){
                 let reports = res.data["forms"]
                 this.setState({reports:reports,reportId:0});
             }
         })
         .catch(function (error) {
             error.handleGlobally && error.handleGlobally();
         });
    }

    sendUpdate(field){
        let datas = new FormData();
        datas.append('id', field.id);
        datas.append('sObs', field.sObs);
        datas.append('tObs', field.tObs);
         axios({
             method: 'post',
             url: global.site +'/monthlyForm/updateItem.php',
             data: datas
         })
         .then(res =>  {
             if(res.data["success"]){
                 this.setState({reportId:0});
                 if(this.props.onClose !== undefined){
                    this.props.onClose();
                 }
             }
         })
         .catch(function (error) {
             error.handleGlobally && error.handleGlobally();
         });

    }

    onViewForm(id){
         window.open(global.site + '/monthlyForm/edit.php?id='+id, "_blank");
    }


    updateReport(fields){
         fields.map((field)=>(
             this.sendUpdate(field)
         ))
        let datas = new FormData();
        datas.append('id', this.state.reportId);
         axios({
             method: 'post',
             url: global.site +'/monthlyForm/update.php',
             data: datas
         })
         .then(res =>  {
             if(res.data["success"]){
                 this.setState({reportId:0, updateReport:false});
             }
         })
         .catch(function (error) {
             error.handleGlobally && error.handleGlobally();
         });
    }

    deleteReport(ids){
         let datas = new FormData();
         datas.append("trainingId", this.props.trainingId);
         datas.append("studentId", this.props.studentId);
         datas.append("formProfile", this.props.formProfile);
         for(let i = 0; i < ids.length; i++){
            datas.append('ids[]', ids[i]);
        }

         axios({
             method: 'post',
             url: global.site +'/monthlyForm/delete.php',
             data: datas
         })
         .then(res =>  {
             if(res.data["success"]){
                 let reports = res.data["reports"]
                 this.setState({reports:reports});
             }
         })
         .catch(function (error) {
             error.handleGlobally && error.handleGlobally();
         });

    }

    getReport(id){
        axios.get(global.site +'/monthlyForm/get.php?id='+ id, {withCredentials:true})
            .then(res => {
                if(res.data["success"]){
                    this.itemFields = [];
                    let items = res.data["items"];
                    this.itemFields = items;
                    this.setState({updateReport:true, reportId:id});
                }

             })
             .catch(function(error) {
                error.handleGlobally && error.handleGlobally();
        })
    
    }

    getReports(){
        axios.get(global.site +'/monthlyForm/list.php?studentId=' + this.props.studentId + '&trainingId=' + this.props.trainingId + '&formProfile=' + this.props.formProfile, {withCredentials:true})
            .then(res => {
                const reports = res.data["forms"];
                this.setState({reports:reports, reportId:0});
             })
             .catch(function(error) {
                error.handleGlobally && error.handleGlobally();
        })
    }
    
    render(){
        return(
           <div>
               {this.reportId === undefined ?
                    <OlindaMuiTable
                        elements={this.state.reports}
                        columns = {this.reportColumns}
                        del={this.props.userProfile === 1}
                        force={true}
                        add={this.props.userProfile === 1}
                        readOnly={this.itemFields.length > 0 && this.itemFields[0]["valid"]}
                        modify = {this.state.itemId !== 0 && this.props.userProfile===3}
                        onAdd={this.addReport}
                        onDelete={this.deleteReport}
                        onEdit={this.editReport}
                    />
                   :
                   null
               }
               {this.state.updateReport ?
                    <OlindaMuiTableForm
                        columns={this.itemColumns}
                        elements={this.itemFields}
                        title="Fiche Navette"
                        del={this.props.userProfile === 3}
                        add={this.props.userProfile === 1}
                        readOnly={this.itemFields.length > 0 && this.itemFields[0]["valid"]}
                        modify = {this.state.itemId !== 0 && this.props.userProfile===3}
                        onCancel={this.cancelUpdateReport}
                        onValid={this.updateReport}
                    />
                    :
                    null
               }
               {this.state.updateDate ?
                     <OlindaMuiForm
                        fields={this.dateFields}
                        title={"Mise à jour de la date"}
                        onValid={this.onValidUpdateDate}
                        onCancel={this.onCancelUpdateDate}
                    />
                :
                null
                }
           </div>            
        ); 
    }

}

export default MonthlyForm
