import React from 'react'
import axios from 'axios'

import Typography from '@material-ui/core/Typography';
import OlindaMuiTable from '../components/table'
import OlindaMuiTableForm from '../components/tableForm'

class WeeklyReport extends React.Component{
    constructor(props){
        super(props);
        this.state={
            reports:[],
            reportId:0,
            readOnly:false,
            updateReport:false
        }
        this.dateFields=[{id:"date", type:"date",name:'Date', readOnly:false, value:''}];
        this.itemFields = [];
        this.itemColumns = [{id:'description', title:'Description', type:'label'}, 
                            {id:'value', title:'Evaluation', type:'common-select'}, 
                            {id:'comment', title:'Commentaire', type:'input'}];
        this.notes = [{id:0, name:""},
                      {id:1, name:"Très Insuffisant"},
                      {id:2, name:"Insuffisant"},
                      {id:3, name:"Correct"},
                      {id:4, name:"Satisfaisant"},
                      {id:5, name:"Supérieur"}]
        this.updateReport = this.updateReport.bind(this);
        this.cancelUpdateReport = this.cancelUpdateReport.bind(this);
        this.deleteReport = this.deleteReport.bind(this);
        this.editReport = this.editReport.bind(this);
        this.addReport = this.addReport.bind(this);
        this.onViewReport = this.onViewReport.bind(this);
        this.onUpdateAbsent = this.onUpdateAbsent.bind(this);

        this.reportColumns=[{id:'date', title:'Date', type:'date'},
                            {id:'absent', title:'Absent', type:'check', cb:this.onUpdateAbsent},
                            {id:'teacher', title:'Rempli par'},
                            {id:'pdf', title:'PDF', type:'pdf', cb:this.onViewReport}];
    }


    componentDidMount(){
        this.getReports();
    }

    editReport(id){
        this.getReport(id);
    }
    
    cancelUpdateReport(){
        this.setState({updateReport:false});
    }

    onViewReport(id){
         window.open(global.site + '/weeklyReport/edit.php?id='+id, "_blank");
    }

    onUpdateAbsent(event){
        let target=event.target;
        let datas = new FormData();
        datas.append('id', target.id);
        datas.append('absent', target.checked);
        datas.append("trainingId", this.props.trainingId);
        datas.append("studentId", this.props.studentId);
        axios({
             method: 'post',
             url: global.site +'/weeklyReport/updateAbsent.php',
             data: datas
         })
         .then(res =>  {
             if(res.data["success"]){
                 let reports=res.data["reports"];
                 this.setState({reportId:0, updateReport:false, reports:reports});
             }
         })
         .catch(function (error) {
             error.handleGlobally && error.handleGlobally();
         });

    }


    addReport(){
         let datas = new FormData();
         datas.append("trainingId", this.props.trainingId);
         datas.append("studentId", this.props.studentId);
         axios({
             method: 'post',
             url: global.site +'/weeklyReport/create.php',
             data: datas
         })
         .then(res =>  {
             if(res.data["success"]){
                 let reports = res.data["reports"]
                 this.setState({reports:reports,reportId:0});
             }
         })
         .catch(function (error) {
             error.handleGlobally && error.handleGlobally();
         });
    }

    sendUpdate(field){
        let datas = new FormData();
        datas.append('id', field.id);
        datas.append('value', field.value);
        datas.append('comment', field.comment);
         axios({
             method: 'post',
             url: global.site +'/weeklyReport/updateItem.php',
             data: datas
         })
         .then(res =>  {
             if(res.data["success"]){
                 this.setState({reportId:0});
             }
         })
         .catch(function (error) {
             error.handleGlobally && error.handleGlobally();
         });

    }

    updateReport(fields){
         fields.map((field)=>(
             this.sendUpdate(field)
         ))
        let datas = new FormData();
        datas.append('id', this.state.reportId);
        datas.append('studentId', this.props.studentId);
        datas.append('trainingId', this.props.trainingId);
        datas.append('date', this.dateFields[0].value);
        axios({
             method: 'post',
             url: global.site +'/weeklyReport/update.php',
             data: datas
         })
         .then(res =>  {
             if(res.data["success"]){
                 let reports=res.data["reports"];
                 this.setState({reportId:0, updateReport:false, reports:reports});
             }
         })
         .catch(function (error) {
             error.handleGlobally && error.handleGlobally();
         });
    }

    deleteReport(ids){
         let datas = new FormData();
         datas.append("trainingId", this.props.trainingId);
         datas.append("studentId", this.props.studentId);
         for(let i = 0; i < ids.length; i++){
            datas.append('ids[]', ids[i]);
        }

         axios({
             method: 'post',
             url: global.site +'/weeklyReport/delete.php',
             data: datas
         })
         .then(res =>  {
             if(res.data["success"]){
                 let reports = res.data["reports"]
                 this.setState({reports:reports,reportId:0});
             }
         })
         .catch(function (error) {
             error.handleGlobally && error.handleGlobally();
         });
    }

    getReport(id){
        axios.get(global.site +'/weeklyReport/get.php?id='+ id, {withCredentials:true})
            .then(res => {
                if(res.data["success"]){
                    this.itemFields = [];
                    this.dateFields[0].value = res.data["report"]["date"]
                    let items = res.data["report"]["items"];
                    this.itemFields = items;
                    let reportDate = new Date(res.data["report"]["date"]);
                    let now = new Date();
                    let diffDate = now - reportDate;
                    let readOnly = diffDate > 172800000;
                    this.setState({updateReport:true, reportId:id, readOnly:readOnly});
                }

             })
             .catch(function(error) {
                error.handleGlobally && error.handleGlobally();
        })
    
    }

    getReports(){
        axios.get(global.site +'/weeklyReport/list.php?studentId=' + this.props.studentId + '&trainingId=' + this.props.trainingId, {withCredentials:true})
            .then(res => {
                const reports = res.data["reports"];
                this.setState({reports:reports, reportId:0});
             })
             .catch(function(error) {
                error.handleGlobally && error.handleGlobally();
        })
    }
    
    render(){
        console.log(this.props.userProfile);
        return(
           <div style={{padding:'50px'}}>
               <OlindaMuiTable
                    elements={this.state.reports}
                    columns = {this.reportColumns}
                    del={this.props.userProfile === 1}
                    force={this.props.userProfile === 1}
                    add={this.props.userProfile === 1}
                    readOnly={this.props.userProfile !== 1}
                    onAdd={this.addReport}
                    onDelete={this.deleteReport}
                    onEdit={this.editReport}
                />
               {this.state.updateReport ?
                <OlindaMuiTableForm
                    columns={this.itemColumns}
                    elements={this.itemFields}
                    headerFields={this.dateFields}
                    title="Bilan quotidien"
                    modify = {this.state.itemId !== 0}
                    readOnly={this.props.userProfile !== 1 || this.state.readOnly}
                    onCancel={this.cancelUpdateReport}
                    onValid={this.updateReport}
                    selectValues = {this.notes}
                />
                :
                null
               }
           </div>            
        ); 
    }

}

export default WeeklyReport
