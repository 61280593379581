import React from 'react'
import axios from 'axios'

import { withStyles } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import PersonIcon from '@material-ui/icons/Person';

import {Link} from "react-router-dom";

import InfoMgmt from '../profile/infoMgmt.js'
import DocumentsMgmt from '../profile/documentsMgmt.js'
import Grid from '@material-ui/core/Grid';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Card from '@material-ui/core/Card';

import EmploymentsList from './employmentsList.js'
import TrainingsList from './trainingsList.js'
import Training from './training.js'

const useStyles = theme => ({
  root: {
    flexGrow: 1,
  },
  title: {
    background:'red',
    flex: 1,
  },
});

function TabPanel(props){
    const { children, value, index} = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-force-tabpanel-${index}`}
            aria-labelledby={`scrollable-force-tab-${index}`}
        >
            {value === index && (
                children
            )}
        </div>
    );
}

class StudentProfile extends React.Component{
    constructor(props){
        super(props);
        this.state={
            index:0,
            student:null
        }
        this.onEditTraining = this.onEditTraining.bind(this);
    }

    onEditTraining(id){
        this.setState({trainingId:id});
    }

    componentDidMount(){
        this.getStudent(this.props.studentId);
    }

    componentUpdated(props){
        if(props.studentId !== this.props.studentId){
            this.getStudent(this.props.studentId);
        }
    }

    createContactInfo(){
        return(
            <div>
            {this.state.student !== null ?
                <InfoMgmt
                    firstName={this.state.student === null ? '' : this.state.student.firstName}
                    lastName={this.state.student === null ? '' : this.state.student.lastName}
                    mail={this.state.student === null ? '' : this.state.student.mail}
                    phone={this.state.student === null ? '' : this.state.student.phone}
                    readOnly={this.props.readOnlyInfo}
                    profile={3}
                    id={this.state.student === null ? '' : this.state.student.id}
                />
                :
                null
            }
            {this.props.student !== undefined && false ?
                <InfoMgmt
                    firstName={this.props.student === null ? '' : this.props.student.firstName}
                    lastName={this.props.student === null ? '' : this.props.student.lastName}
                    mail={this.props.student === null ? '' : this.props.student.mail}
                    phone={this.props.student === null ? '' : this.props.student.phone}
                    readOnly={this.props.readOnlyInfo}
                    profile={3}
                    id={this.props.student === null ? '' : this.props.student.id}
                />
                :
                null
            }
            </div>
        );
    }
   

    createTrainingInfo(){
        return(
            <div>
            <Typography variant="h5">Formations Suivies</Typography>
            <TrainingsList
                studentId={this.props.studentId}
                trainingsList={this.props.trainings}
                edit = {this.onEditTraining}
                userProfile = {this.props.userProfile}
                userId = {this.props.userId}
            />
            </div>
        );
    }

    createSignature(){
        return(
            <div>
            <Typography variant="h5">Signature</Typography>
            <DocumentsMgmt
                userId={this.props.studentId}
                readOnly={this.props.readOnlyInfo}
                type={'student'}
                directory={'/student/student-' + this.props.studentId}
                signature={true}
            />
            </div>
        );
    }

    createEmployments(){
        return(
            <div>
                <Typography variant="h5">Suivi Professionnel</Typography>
                <EmploymentsList
                    studentId={this.state.student === null ? '' : this.state.student.id}
                />
            </div>
        );
    }

    onTabSelected(event, index){
        this.setState({index:index});
    }

    getStudent(id){
        axios.get(global.site +'/user/get.php?profile=3&id='+ id)
            .then(res => {
                if(res.data["success"]){
                    this.setState({student:res.data, studentId:id})
                }
             })
             .catch(function(error) {
                error.handleGlobally && error.handleGlobally();
        })
    }


    render(){
        const {classes}=this.props;
        return(
           <div>
                {this.props.trainingId === undefined || this.props.trainingId === 0 ?
                    <Typography variant="h5">
                        {this.state.student === null || this.props.userProfile==3 ? '' : this.state.student.firstName + ' ' + this.state.student.lastName}
                    </Typography>
                    :
                    null
                } 
                {this.props.trainingId !== undefined && this.props.trainingId !== 0 ?
                    <div>
                    <Training
                        studentId = {this.props.studentId}
                        studentFirstName = {this.state.student === null ? '' : this.state.student.firstName}
                        studentLastName = {this.state.student === null ? '' : this.state.student.lastName}
                        trainingId = {this.props.trainingId}
                        moduleId = {this.props.moduleId}
                        tutors = {this.props.tutors}
                        financals= {this.props.financals}
                        trainings = {this.props.trainings}
                        userProfile={this.props.userProfile}                        
                        userId={this.props.userId}
                        page={this.props.page}
                        windowWidth={this.props.windowWidth}
                        readOnly={this.props.userProfile != 1}
                    />
                    </div>
                    :

                    <Grid container spacing={6}>                    
                        {this.props.userProfile < 3 ?
                            <Grid item xs={12} sm={6}>
                                <Card style={{marginTop:'20px', padding:'10px'}}>
                                    {this.createContactInfo()}
                                </Card>
                            </Grid>
                            :
                            null
                        }
                        <Grid item xs={12} sm={this.props.userProfile < 3 ? 6 : 12}>                       
                            <Card style={{marginTop:'20px',padding:'10px'}}>
                                {this.createTrainingInfo()}
                            </Card>
                            {this.props.userProfile === 4 ?
                                this.createTrainingInfo()
                                :
                                null
                            }
                        </Grid>
                    </Grid>
                }
            </div>            
        ); 
    }

}

export default withStyles(useStyles)(StudentProfile)
