import React from 'react'
import axios from 'axios'
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActionArea from '@material-ui/core/CardActionArea';
import Button from '@material-ui/core/Button'
import { withRouter } from "react-router-dom";
import {Link} from "react-router-dom";

import OlindaMuiForm from '../components/form'
import OlindaMuiButton from '../components/button';

import qualiopi_blanc from '../logos/qualiopi_b.png'
import logo_fae from '../logos/fae.png'
import cpne from '../logos/cpne.png'
import ministere from '../logos/ministere.jpg'
import sport_occitanie from '../logos/sport_occitanie.jpg'
import ffe from '../logos/ffe.jpg'
import hippothese from '../logos/hippothese.jpg'



class TrainingStudentItem extends React.Component{
    constructor(props){
        super(props);
        this.state={
        }
        this.formLogos={"bp":[{logo:ministere, width:135, height:75}],
                        "cqp":[{logo:cpne, width:220, height:75}],
                        "ae":[{logo:ffe, width:90, height:75},
                             {logo:hippothese, width:120, height:75}]
                        }

    }

    formatDate(value){
         const date = new Date(value);
         return date.toLocaleDateString('fr-fr');
    }


    render(){
        var lTraining = this.props.training.name.toLowerCase();
        const keys = Object.keys(this.formLogos)
        var formLogos = undefined

        if(lTraining.includes("bp")){
            formLogos = this.formLogos["bp"];
        }
        else if(lTraining.includes("cqp")){
            formLogos = this.formLogos["cqp"];
        }
        else if(lTraining.includes("ae")){
            formLogos = this.formLogos["ae"];
        }

        let endDate = new Date(this.props.training.endDate);
        let now = new Date();
        let diffDate = now - endDate;
        let createGenerate = diffDate < 0;


        return(
            <div>
            <Card>
            <CardActionArea
                component={Link}
                to={'/student/'+this.props.studentId+'/training/' + this.props.training.id}
            >
            <CardHeader
                title={this.props.training.name}
                subheader={'du ' + this.formatDate(this.props.training.startDate) + ' au ' + this.formatDate(this.props.training.endDate)}
            />
            {formLogos !== undefined ?
                <div style={{margin:'2%'}}>
                {formLogos.map((logo)=>(
                    <img
                        src={logo.logo}
                        width={logo.width}
                        height={logo.height}
                    />
                ))}
                </div>
                :
                null
            }
            </CardActionArea>
            <CardContent>
                <Button component={Link} to={'/student/' + this.props.studentId + '/training/' + this.props.training.id + '/reportsDocs/'}>
                    {'Livret de Formation'}
                </Button>
                <Button component={Link} to={'/student/' + this.props.studentId + '/training/' + this.props.training.id + '/teachingDocs/'}>
                    {'Cours'}
                </Button>
                <Button component={Link} to={'/student/' + this.props.studentId + '/training/' + this.props.training.id + '/scheduler/'}>
                    {'Emploi du temps'}
                </Button>
            </CardContent>
        </Card>
        </div>
        );
    }
}

export default withRouter(TrainingStudentItem)

