import React from 'react'
import axios from 'axios'
import Typography from '@material-ui/core/Typography';
import OlindaMuiTable from '../components/table'
import TutoringForm from './tutoringForm.js'


class Tutoring extends React.Component{

    constructor(props){
        super(props);

        this.state = {
            tutorings:[],
            createTutoring:false,
            tutoringId:0
        }
        this.tutoringFields = [
            {id:'start', label:'Début', type:'date', value:''},
            {id:'end', label:'Fin', type:'date', value:''},
            {id:'days', label:'Jours', value:[]}
        ];

        this.tutoringColumns = [
            {id:'start', title:'Début', type:'date'},
            {id:'end', title:'Fin', type:'date'}
        ];

        this.onCreateTutoring = this.onCreateTutoring.bind(this);
        this.onEditTutoring = this.onEditTutoring.bind(this);
        this.onDeleteTutoring = this.onDeleteTutoring.bind(this);
        this.onValidCreateTutoring = this.onValidCreateTutoring.bind(this);
        this.onCancelCreateTutoring = this.onCancelCreateTutoring.bind(this);
    }

    componentDidUpdate(props){
        if(props.id !== this.props.id && this.props.id > 0){
            this.getTutorings();
        }
    }
    componentDidMount(){
        this.getTutorings();
    }

    onEditTutoring(id){
        this.getTutoring(id);
    }

    onCreateTutoring(){
        this.setState({createTutoring:true, tutoringId:0});
    }

    onCancelCreateTutoring(){
        this.setState({createTutoring:false});
    }

    onDeleteTutoring(ids){
        let datas = new FormData();
        for(let i = 0; i < ids.length; i++){
            datas.append('ids[]', ids[i]);
        }
        
        datas.append('id', this.props.id);
        datas.append('type', this.props.type);
        axios({
            method: 'post',
            url: global.site +'/tutoring/delete.php',
            data: datas
        })
        .then(res =>  {
            if(res.data["success"]){
                let tutorings = res.data["tutorings"];
                this.setState({createTutoring:false, tutorings:tutorings});
            }
        })
        .catch(function (error) {
            error.handleGlobally && error.handleGlobally();
        });
    }


    onValidCreateTutoring(fields, days){
        let datas = new FormData();
        fields.map((field)=>(
            datas.append(field.id, field.value)
        ))
        if(this.state.tutoringId !== 0){
            datas.append('tutoringId', this.state.tutoringId);
        }
        datas.append('type', this.props.type);
        for(let index = 0; index < days.length; index++){
            datas.append('days[]', days[index]);
        }
        datas.append('id', this.props.id);
        datas.append('type', this.props.type);
        axios({
            method: 'post',
            url: global.site +'/tutoring/create.php',
            data: datas
        })
        .then(res =>  {
            if(res.data["success"]){
                let tutorings = res.data["tutorings"];
                this.setState({createTutoring:false, tutorings:tutorings});
            }
        })
        .catch(function (error) {
            error.handleGlobally && error.handleGlobally();
        });
    }


    getTutorings()
    {
        axios.get(global.site +'/tutoring/list.php?type='+this.props.type+'&id=' + this.props.id)
            .then(res => {
                const tutorings = res.data["tutorings"];
                this.setState({tutorings:tutorings});
             })
             .catch(function(error) {
                error.handleGlobally && error.handleGlobally();
        })
    }

    getTutoring(id)
    {
        axios.get(global.site +'/tutoring/get.php?id=' + id)
            .then(res => {
                this.tutoringFields.map((field) => (
                    field.value = res.data[field.id]
                ))
                const tutoringId = res.data["id"];
                this.setState({createTutoring:true, tutoringId:tutoringId});
             })
             .catch(function(error) {
                error.handleGlobally && error.handleGlobally();
        })
    }

    render(){
        return (
            <div>
                <Typography variant="h5">Tutorat</Typography>
                <OlindaMuiTable
                    elements={this.state.tutorings}
                    columns={this.tutoringColumns}
                    del={true}
                    add={true}
                    onAdd={this.onCreateTutoring}
                    onEdit={this.onEditTutoring}
                    onDelete={this.onDeleteTutoring}
                />
            
            {this.state.createTutoring?
                <TutoringForm
                    fields={this.tutoringFields}
                    modify = {this.state.tutoringId !== 0}
                    onCancel={this.onCancelCreateTutoring}
                    onValid={this.onValidCreateTutoring}
                />
                :
                null
            }
            </div>

        );

    }

}

export default Tutoring;
