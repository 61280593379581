import React from 'react'
import axios from 'axios'

import OlindaMuiMediaList from '../components/mediaList'
import VideosForm from './videosForm'


class LinkedVideos extends React.Component{
    constructor(props){
        super(props);
        this.state={
            videos:[],
            addVideo:false,
        }

        /*Training part */
        this.formFields = [
            {id:'video', label:'Video', type:'linkedVideos', value:''},
        ];
        this.onAddVideo = this.onAddVideo.bind(this);
        this.onDeleteVideo = this.onDeleteVideo.bind(this);
        this.onCancelAddVideo = this.onCancelAddVideo.bind(this);
        this.onValidAddVideo = this.onValidAddVideo.bind(this);
    }

    /*Click on create button*/
    onAddVideo()
    {
        this.setState({addVideo:true});
    }
    
    /*Valid the creation or the edition of a training*/
    onValidAddVideo(videos)
    {
        let datas = new FormData();
        datas.append('type', this.props.type);
        for(let i = 0; i < videos.length; i++){            
            datas.append('videos[]', videos[i]);
        }
        datas.append('id', this.props.userId);
        axios({
            method: 'post',
            url: global.site +'/videos/add.php',
            data: datas
        })
        .then(res =>  {
            if(res.data["success"]){
                const videos = res.data['videos']
                this.setState({addVideo:false, videos:videos});
            }
        })
        .catch(function (error) {
            error.handleGlobally && error.handleGlobally();
        });
    }

    /*Cancel the creation or edition of a training*/
    onCancelAddVideo(){
        this.setState({addVideo:false});
    }
    
    onDeleteVideo(id)
    {
        let datas = new FormData();
        datas.append('type', this.props.type);
        datas.append('file', id);
        datas.append('id', this.props.userId);
        axios({
            method: 'post',
            url: global.site +'/videos/delete.php',
            data: datas
        })
        .then(res =>  {
            if(res.data["success"]){
                const videos = res.data['videos']
                this.setState({addVideo:false, videos:videos});
            }
        })
        .catch(function (error) {
            error.handleGlobally && error.handleGlobally();
        });
    }

    /* Valid the deletion of the training */
    onValidDeleteVideo()
    {
    }
    
    componentDidMount()
    {
        this.getVideos();
    }

    componentDidUpdate(props){
        if(props.userId !== this.props.userId){
            this.getVideos();            
        }
    }

    getVideos()
    {
        if(this.props.userId === 0){
            return;
        }
        axios.get(global.site +'/videos/list.php?type=' + this.props.type + '&id=' + this.props.userId)
            .then(res => {
                const videos = res.data["videos"];
                this.setState({videos:videos});
             })
             .catch(function(error) {
                error.handleGlobally && error.handleGlobally();
        })
    }
    
    render(){
        return(
            <div>
                <OlindaMuiMediaList
                    elements = {this.state.videos}
                    read = {false}
                    add = {this.props.add}
                    del={this.props.del}
                    edit={false}
                    field = 'name'
                    link = {true}
                    onAdd = {this.onAddVideo}
                    onShow = {this.onEditVideo}
                    onDelete = {this.onDeleteVideo}
                />

                {this.state.addVideo ? 
                    <VideosForm 
                        title = "Ajouter une vidéo"
                        onCancel={this.onCancelAddVideo} 
                        onValid={this.onValidAddVideo}/> 
                    : null}
            </div>
        );
    
    }
}

export default LinkedVideos

