import React from 'react'
import axios from 'axios'

import OlindaMuiForm from '../components/form'
import OlindaMuiTable from '../components/table'

class AdminDocuments extends React.Component{
    constructor(props){
        super(props);
        this.state={
            documents:[],
            addDocument:false,
        }

        
        /*Training part */
        this.formFields = [
            {id:'file', label:'Fichier', type:'file', value:'', file:null},
        ];
        this.onAddDocument = this.onAddDocument.bind(this);
        this.onDeleteDocument = this.onDeleteDocument.bind(this);
        this.onCancelAddDocument = this.onCancelAddDocument.bind(this);
        this.onValidAddDocument = this.onValidAddDocument.bind(this);
        this.onEditDocument = this.onEditDocument.bind(this);
        this.onUpdateTutorVisibility = this.onUpdateTutorVisibility.bind(this);
        this.onUpdateStudentVisibility = this.onUpdateStudentVisibility.bind(this);
        this.onUpdateStudentMandatory = this.onUpdateStudentMandatory.bind(this);
        
        this.tableFields = [
            {id:'name', title:'Nom', link:true},
            {id:'lastModified', title:'Mis à jour le', type:'date'},
            {id:'visibleForTutor', title:'Visible par les tuteurs', type:'check', cb:this.onUpdateTutorVisibility},
            {id:'visibleForStudent', title:'Visible par les stagiaires', type:'check', cb:this.onUpdateStudentVisibility},
            {id:'mandatoryForStudent', title:'A accepter par les stagiaires', type:'check', cb:this.onUpdateStudentMandatory}
        ]
    }

    /*Click on create button*/
    onAddDocument()
    {
        this.formFields = [
            {id:'file', label:'Fichier', type:'file', value:''}
        ];
        this.setState({addDocument:true});
    }

    updateVisiblity(id, check, profile){
        let datas = new FormData();
        datas.append('file', id);
        datas.append('visible', check);
        datas.append('profile', profile);
        axios({
            method: 'post',
            url: global.site +'/documents/updateVisibility.php',
            data: datas
        })
        .then(res =>  {
            if(res.data["success"]){
                const documents = res.data['documents']
                this.setState({documents:documents});
            }
        })
        .catch(function (error) {
            error.handleGlobally && error.handleGlobally();
        });

    }

    onUpdateTutorVisibility(event){
        let target=event.target;
        this.updateVisiblity(target.id, target.checked, 2);
    }
    
    onUpdateStudentVisibility(event){
        let target=event.target;
        this.updateVisiblity(target.id, target.checked, 3);
    }

    updateMandatory(id, check, profile){
        let datas = new FormData();
        datas.append('file', id);
        datas.append('mandatory', check);
        datas.append('profile', profile);
        datas.append('type', 'admin-documents');
        axios({
            method: 'post',
            url: global.site +'/documents/updateMandatory.php',
            data: datas
        })
        .then(res =>  {
            if(res.data["success"]){
                const documents = res.data['documents']
                this.setState({documents:documents});
            }
        })
        .catch(function (error) {
            error.handleGlobally && error.handleGlobally();
        });

    }

    onUpdateStudentMandatory(event){
        let target=event.target;
        this.updateMandatory(target.id, target.checked, 3);
    }

    onEditDocument(id){
        let documents = this.state.documents.filter(doc=>doc.id === id);
        if(documents.length > 0){
            window.open(documents[0].link, "_blank");
        }
    }
    
    /*Valid the creation or the edition of a training*/
    onValidAddDocument(fields)
    {
        let datas = new FormData();
        fields.map(field=>{
            if(field.type === 'file'){
                const file = field.file;
                const fileName = file.name;
                datas.append('file', file, fileName)
            }
            else{
                datas.append(field.id, field.value)
            }
        })
        datas.append('type', this.props.type);
        datas.append('id', this.props.userId);
        datas.append('directory', this.props.directory);
        if(this.props.signature !== undefined && this.props.signature){
            datas.append('signature', true);
        }
        axios({
            method: 'post',
            url: global.site +'/documents/add.php',
            data: datas
        })
        .then(res =>  {
            if(res.data["success"]){
                const documents = res.data['documents']
                this.setState({addDocument:false, documents:documents});
            }
        })
        .catch(function (error) {
            error.handleGlobally && error.handleGlobally();
        });
    }

    /*Cancel the creation or edition of a training*/
    onCancelAddDocument(){
        this.setState({addDocument:false});
    }
    
    onDeleteDocument(ids)
    {
        let datas = new FormData();
        datas.append('type', this.props.type);
        for(var index = 0; index < ids.length; index++){
            datas.append('filenames[]', ids[index])
        }
        datas.append('id', this.props.userId);
        datas.append('directory', this.props.directory);
        if(this.props.signature !== undefined && this.props.signature){
            datas.append('signature', true);
        }
        axios({
            method: 'post',
            url: global.site +'/documents/delete.php',
            data: datas
        })
        .then(res =>  {
            if(res.data["success"]){
                const documents = res.data['documents']
                this.setState({addDocument:false, documents:documents});
            }
        })
        .catch(function (error) {
            error.handleGlobally && error.handleGlobally();
        });
    }

    componentDidMount()
    {
        this.getDocuments();
    }

    getDocuments()
    {
        let request = global.site +'/documents/list.php?type=' + this.props.type + '&id=' + this.props.userId + '&directory=' + this.props.directory; 
        if(this.props.signature !== undefined && this.props.signature){
            request += '&signature=true';
        }
        axios.get(request)
            .then(res => {
                const documents = res.data["documents"];
                this.setState({documents:documents});
             })
             .catch(function(error){
                error.handleGlobally && error.handleGlobally();
        })
    }
    
    onUpdate(event, checked){
        let datas = new FormData();
        datas.append('mandatory', checked);
        datas.append('id', event.target.id);
        axios({
            method: 'post',
            url: global.site +'/documents/update.php',
            data: datas
        })
        .then(res =>  {
            if(res.data["success"]){
            }
        })
        .catch(function (error) {
            error.handleGlobally && error.handleGlobally();
        });
    }

    render(){
        return(
            <div>

                <OlindaMuiTable
                    elements = {this.state.documents}
                    columns = {this.tableFields}
                    add = {this.props.add}
                    del={this.props.del}
                    force={this.props.del}
                    searchColumn = 'name'
                    onAdd = {this.onAddDocument}
                    onDelete = {this.onDeleteDocument}                
                    onSelect = {this.props.onToggleCheck}
                />

                {this.state.addDocument ? 
                    <OlindaMuiForm 
                        fields={this.formFields}
                        title="Ajouter un document"
                        onCancel={this.onCancelAddDocument} 
                        onValid={this.onValidAddDocument}/> 
                    : null}
            </div>
        );
    
    }



}

export default AdminDocuments

