import React from 'react'
import axios from 'axios'

import { withRouter, Link } from "react-router-dom";
import Typography from '@material-ui/core/Typography';
import TutoringReport from './tutoringReport'


class PeriodicReportList extends React.Component{
    constructor(props){
        super(props);
        this.state={
            hideTeacher:false,
            hideTutor:false
        };
        this.hideTutor=this.hideTutor.bind(this);
        this.hideTeacher=this.hideTeacher.bind(this);
        this.showAll=this.showAll.bind(this);
    }

    hideTutor(){
        this.setState({hideTutor:true});
    }

    hideTeacher(){
        this.setState({hideTeacher:true});
    }

    showAll(){
        this.setState({hideTeacher:false, hideTutor:false});
    }

    render(){
        return(
            <div>
                {this.state.hideTeacher ? null:
                    <div>
                    <Typography variant="h7">{'Centre de Formation'}</Typography>
                    <TutoringReport
                        reportProfile={4}
                        reportId = {this.props.match.params.reportId}
                        studentId={this.props.studentId}
                        trainingId={this.props.trainingId}
                        userProfile={this.props.userProfile}
                        studentLastName = {this.props.studentLastName}
                        studentFirstName = {this.props.studentFirstName}
                        onEdit = {this.hideTutor}
                        onBack={this.showAll}
                    />
                    </div>
                }
                {this.state.hideTutor ? null:
                    <div>
                    <Typography variant="h7">{'Tuteur'}</Typography>
                    <TutoringReport
                        reportProfile={5}
                        userProfile={this.props.userProfile}
                        reportId = {this.props.match.params.reportId}
                        studentId={this.props.studentId}
                        trainingId={this.props.trainingId}
                        userProfile={this.props.userProfile}
                        studentLastName = {this.props.studentLastName}
                        studentFirstName = {this.props.studentFirstName}           
                        onEdit = {this.hideTeacher}
                        onBack={this.showAll}
                    />
                    </div>
                }
            </div>
        );
    
    }



}

export default withRouter(PeriodicReportList)

