import React from 'react'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Grid from '@material-ui/core/Grid';

import { createMuiTheme, withStyles } from '@material-ui/core/styles';
import { DatePicker,MuiPickersUtilsProvider } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns';
import frLocale from "date-fns/locale/fr";

const useStyles = theme => ({
  hourCell: {
    fontWeight: 'bold',
    background: 'blue',
    borderRadius: '5px',
    borderTop: '2px solid black',
    borderBottom: '2px solid black',
    padding:'1px',
  },
  eventCell: {
    fontWeight: 'bold',
    background: 'red',
    borderRadius: '5px',
    border: 'none',
    padding:'1px',
  },
  empty: {
    background: 'smokedWHite',
    borderRadius: '1px',
    borderRight: '1px solid black',
    padding:'1px',
  },
  emptyOdd: {
    background: 'smokedWHite',
    borderRadius: '1px',
    borderTop: '2px solid black',
    borderRight: '1px solid black',
    padding:'1px',
  },
  date:{
      padding:'50px',
      background: 'blue',
  },
});

const customTheme = createMuiTheme({
    overrides: {
        MuiPickersBasePicker:{
            pickerView:{
                backgroundColor:"black"
            }
        },
        MuiPickersDay: {
            day: {
                color: "light-gray",
                fontFamily: "\"Do Hyeon\", sans-serif",
                backgroundColor: "white",
                borderRadius:"0px",
            },
            container:{
                backgroundColor:"black"
            },
            daySelected: {
                backgroundColor: "",
                color:"light-gray"
            },
            dayDisabled: {
                color: "black",
            },
            current: {
                color: "",
            },
        },
    },
});


class OlindaMuiScheduler extends React.Component{

    constructor(props){
        super(props);
        this.state= {
            days:["Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi"],       
            dates:[],
            hours:[]
        }      
        this.computeDates(false);

        this.onDateSelected = this.onDateSelected.bind(this);
    }
   
    computeDates(refresh){
        var lastMonday = new Date(this.props.date);
        var currentDay = this.props.date.getDate();
        var currentDayNum = this.props.date.getDay();
        if(currentDayNum === 0){
            currentDayNum = 7;
        }
        var currentMonth = this.props.date.getMonth();
        lastMonday.setMonth(currentMonth);
        lastMonday.setDate(currentDay - currentDayNum + 1);
        let startHour = 8;
        let endHour = 18;
        
        let currentHour = startHour;
        while(currentHour < endHour){
            this.state.hours.push(currentHour.toString() + "h");
            currentHour ++;
        }
        this.spannedCells = [];
        this.spannedCells.push([]);
        this.spannedCells.push([]);
        this.spannedCells.push([]);
        this.spannedCells.push([]);
        this.spannedCells.push([]);
        this.skippedCells = [];
        this.skippedCells.push([]);
        this.skippedCells.push([]);
        this.skippedCells.push([]);
        this.skippedCells.push([]);
        this.skippedCells.push([]);
        this.dates = [];
        currentDay= lastMonday;
        var lastMondayStr = lastMonday.toISOString().substring(0,10);
        var currentDayStr = lastMonday.toISOString().substring(0,10);
        var datesStr = []; 
        for(var index = 0; index < 5; index++){
           
            var date = currentDay.getDate();
            var dateStr = date.toString();
            if(date <10){
                dateStr = "0" + dateStr;
            }

            var month = currentDay.getMonth() + 1;
            var monthStr = month.toString();
            if(month < 10){
                monthStr = "0" + monthStr;
            }

            datesStr.push(dateStr + "/"  + monthStr);
            currentDay.setDate(currentDay.getDate() + 1);
            currentDayStr = currentDay.toISOString().substring(0,10);
            this.dates.push(currentDay.getFullYear() + "-" + monthStr + "-" + dateStr);
        }
        if(refresh){
            this.setState({dates:datesStr});
        }
        else{
            this.state.dates=datesStr;
        }
        this.props.getEvents(lastMondayStr, currentDayStr);
    }

    componentDidUpdate(props){
        if(props.date != this.props.date){
            this.computeDates(true);
        }

    }

    createHeader(){
        let days = new Array();
        for(var index = 0; index < 5 ;index++){
            let day = this.state.days[index] + " " + this.state.dates[index];
            days.push(day);
        }
        return(
            <TableHead>
                <TableRow>
                    <TableCell>hh:mm</TableCell>
                    {
                        days.map((day) => (
                            <TableCell>{day}</TableCell>
                        ))
                    }
                </TableRow>
            </TableHead>
        );
    }

    processSpan(ev){
        let hoursStart= ev.start.split(":");
        let hoursEnd= ev.end.split(":");

        let startMn= parseInt(hoursStart[0])*60 + parseInt(hoursStart[1]);
        let endMn= parseInt(hoursEnd[0])*60 + parseInt(hoursEnd[1]);

        let startRow = (hoursStart[0] - 8) * 4;
        
        if(hoursStart[1] == 15){
            startRow++;
        }

        else if(hoursStart[1] == 30){
            startRow += 2;
        }
        
        else if(hoursStart[1] == 45){
            startRow += 3;
        }

        let nbSpan = (endMn - startMn) / 15;
        var dateIndex = -1;
        for(var index = 0; index < 5; index++){
            if(this.dates[index] === ev.date){
                dateIndex = index;
                break;
            }
        }


        if(dateIndex === -1){
            return;
        }
        this.spannedCells[dateIndex].push({start:startRow, span:nbSpan, id:ev.id, color:ev.color, title:ev.title});
        for(var index = 0;index < nbSpan; index++){
            this.skippedCells[dateIndex].push(startRow + index);
        }
    }

    onSelect(id)
    {
        this.props.onSelect(id);
    }

    createCell(hourIndex, dayIndex){
        const {classes} = this.props;
        let className = hourIndex % 4 == 0 ? classes.emptyOdd : classes.empty;
        for(var index = 0; index<this.spannedCells[dayIndex].length;index++){         
            let span = this.spannedCells[dayIndex][index];
            if(span.start === hourIndex){
                return(
                    <TableCell onClick={()=>this.onSelect(span.id)} style={{background:span.color}} className={classes.eventCell} rowSpan={span.span}>
                        {span.title}
                    </TableCell>
                );
            }
        }
        if(this.skippedCells[dayIndex].includes(hourIndex)){
            return;
        }
        return(
            <TableCell className={className}></TableCell>
        );
    }

    createDays(hourIndex){
        const days= [0,1,2,3,4];
        return(
            days.map((day) => (
                this.createCell(hourIndex,day)
            ))
        );
    }    
    
    onDateSelected(date){
        this.props.onDateSelected(date);
    }

    createRows(hour, index){
        const {classes} = this.props;
        if(hour){
            return(
                <TableRow style={{height:'15px'}}>
                    <TableCell className={classes.hourCell} rowSpan={4}>{hour}</TableCell>
                    {this.createDays(index)}
                </TableRow>
            );
        }
        else{
            return(
                <TableRow style={{height:'15px'}}>
                    {this.createDays(index)}
                </TableRow>
            );
        }
    }

    createBody(){
        this.spannedCells[0]=[];
        this.spannedCells[1]=[];
        this.spannedCells[2]=[];
        this.spannedCells[3]=[];
        this.spannedCells[4]=[];
        this.skippedCells[0]= [];
        this.skippedCells[1]= [];
        this.skippedCells[2]= [];
        this.skippedCells[3]= [];
        this.skippedCells[4]= [];

        this.props.events.map((event) => (
            this.processSpan(event)
        ));

        const {classes} = this.props;
        return(
                <TableBody style={{height:'200px'}}>
                    {this.createRows('8h', 0)}
                    {this.createRows(false, 1)}
                    {this.createRows(false, 2)}
                    {this.createRows(false, 3)}
                    {this.createRows('9h', 4)}
                    {this.createRows(false, 5)}
                    {this.createRows(false, 6)}
                    {this.createRows(false, 7)}
                    {this.createRows('10h', 8)}
                    {this.createRows(false, 9)}
                    {this.createRows(false, 10)}
                    {this.createRows(false, 11)}
                    {this.createRows('11h', 12)}
                    {this.createRows(false, 13)}
                    {this.createRows(false, 14)}
                    {this.createRows(false, 15)}
                    {this.createRows('12h', 16)}
                    {this.createRows(false, 17)}
                    {this.createRows(false, 18)}
                    {this.createRows(false, 19)}
                    {this.createRows('13h', 20)}
                    {this.createRows(false, 21)}
                    {this.createRows(false, 22)}
                    {this.createRows(false, 23)}
                    {this.createRows('14h', 24)}
                    {this.createRows(false, 25)}
                    {this.createRows(false, 26)}
                    {this.createRows(false, 27)}
                    {this.createRows('15h', 28)}
                    {this.createRows(false, 29)}
                    {this.createRows(false, 30)}
                    {this.createRows(false, 31)}
                    {this.createRows('16h', 32)}
                    {this.createRows(false, 33)}
                    {this.createRows(false, 34)}
                    {this.createRows(false, 35)}
                    {this.createRows('17h', 36)}
                    {this.createRows(false, 37)}
                    {this.createRows(false, 38)}
                    {this.createRows(false, 39)}
                </TableBody>
        );
    }

    render(){
        const {classes} = this.props;
        const {customTheme} = this.props;
        return(
            <Grid container>
                <Grid item xs={12} sm={3}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={frLocale} theme={customTheme}>
                        <DatePicker 
                            variant="static" 
                            value={this.props.date} 
                            onChange={this.onDateSelected} 
                            className={classes.date}
                            format="dd-MM-yyyy"
                        />
                    </MuiPickersUtilsProvider>
                </Grid>
                <Grid item xs={12} sm={9}>
                    <TableContainer>
                        <Table>
                        {this.createHeader()}
                        {this.createBody()}
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>
        );
    }

}

export default withStyles(useStyles)(OlindaMuiScheduler);
