import React from 'react'
import {Link} from "react-router-dom"
import axios from 'axios'
import Typography from '@material-ui/core/Typography';
import { withRouter } from "react-router-dom";

import OlindaMuiTable from '../components/table'
import Module from './module.js'
import Breadcrumbs from '@material-ui/core/Breadcrumbs'


class ModulesList extends React.Component{

    constructor(props){
        super(props);

        this.state = {
            modules:[],
            moduleId:0,
        }
        this.moduleFields = [
            {id:'name', label:'Nom', type:'input', value:'', mandatory:true},
            {id:'description', label:'Description', type:'input', value:'', mandatory:true},
            {id:'testDate', label:'Examen', type:'date', value:'', mandatory:true}
        ];

        this.moduleColumns = [
            {id:'name', title:'Nom'},
            {id:'testDate', title:'Examen'}
        ];

        this.getModule = this.getModule.bind(this);
    }

    componentDidMount(){
        this.props.moduleId !== undefined ?
            this.getModule(this.props.moduleId)
            :
            this.getModulesList();        
    }
   
    componentDidUpdate(props){
        if(this.props.moduleId !==  props.moduleId && this.props.moduleId !== undefined){
            this.getModule(this.props.moduleId)         
        }
        else if(this.props.moduleId === undefined && props.moduleId !== undefined){
            this.getModulesList();                    
        }
    }

    getModulesList(){
        axios.get(global.site +'/training/modulesList.php?trainingId='+ this.props.trainingId, {withCredentials:true})
            .then(res => {
                const modules = res.data["modules"];
                this.setState({modules:modules, moduleId:0});
             })
             .catch(function(error) {
                 this.setState({moduleId:0});
                error.handleGlobally && error.handleGlobally();
        })

    }

     getModule(id){
          axios.get(global.site +'/training/getModule.php?id='+id)
            .then(res => {
                if(res.data["success"]){
                    this.moduleFields.map((field) => (
                        field.value=res.data[field.id]
                    ))  
                    this.setState({moduleId:id, moduleName:res.data["name"]})
                }   
             })  
             .catch(function(error) {
                error.handleGlobally && error.handleGlobally();
         })  

     } 

     
    createModulesTab(){
        return(
            <div>
            <Typography variant="h5">Modules</Typography>
            <OlindaMuiTable
                elements={this.state.modules}
                columns = {this.moduleColumns}
                edit = {true}
                read = {false}
                del= {false}
                add= {false}
                href={'/student/' + this.props.studentId + '/training/' + this.props.studentTraining + '/module'}
            />
            </div>
        );
    }

    render(){
        return (
            <div>
            {this.state.moduleId === 0 ?               
                <div>
                    {this.createModulesTab()}
                </div>
                :
                null
            }
            {this.state.moduleId !== 0 ?                
                <div>
                    <Breadcrumbs>
                        <Link to={"/student/" + this.props.studentId + "/training/" + this.props.studentTraining}>{"Modules"}</Link>
                        <Typography variant="h5">{this.state.moduleName}</Typography>
                    </Breadcrumbs>
                    <Module
                        id={this.state.moduleId}
                        fields={this.moduleFields}
                        userProfile={this.props.userProfile}
                    />
                </div>
                :
                null
            }
            </div>
        );

    }

}

export default withRouter(ModulesList);
