import React from 'react'
import axios from 'axios'

import { withStyles } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';

import {Link} from "react-router-dom";

import OlindaMuiTable from '../components/table'

import InfoMgmt from '../profile/infoMgmt.js'
import Grid from '@material-ui/core/Grid';


const useStyles = theme => ({
  root: {
    flexGrow: 1,
  },
  title: {
    background:'red',
    flex: 1,
  },
});

class ExaminatorProfile extends React.Component{
    constructor(props){
        super(props);
        this.state={
            examinator:null,
            exams:[]
        }
        this.columns=[
            {id:'name', title:'Nom', type:'input'},
            {id:'training', title:'Formation', type:'input'},
            {id:'date', title:'Date', type:'date'}
        ];
    }

    componentDidMount(){
        this.getExams(this.props.examinatorId);
        this.getExaminator(this.props.examinatorId);
    }

    componentUpdated(props){
        if(props.examinatorId !== this.props.examinatorId){
            this.getExams(this.props.examinatorId);
            this.getExaminator(this.props.examinatorId);
        }
    }

    updateContact(fields){
        this.props.updateContact(fields);
    }

    createContactInfo(){
        return(
            <div>
            <InfoMgmt
                firstName={this.state.examinator === null ? '' : this.state.examinator.firstName}
                lastName={this.state.examinator === null ? '' : this.state.examinator.lastName}
                mail={this.state.examinator === null ? '' : this.state.examinator.mail}
                phone={this.state.examinator === null ? '' : this.state.examinator.phone}
                readOnly={this.props.readOnlyInfo}
                profile={5}
                id={this.state.examinator === null ? '' : this.state.examinator.id}
                updateContact = {this.updateContact}
            />
            </div>
        );
    }
   

    createExams(){
        return(
            <div>
            <Typography variant="h5">Examens</Typography>
            <OlindaMuiTable
                elements={this.state.exams}
                del={false}
                add={false}
                columns={this.columns}
                href={"/exam"}
            />
            </div>
        );
    }
    
    getExams(id){
        axios.get(global.site +'/exam/list.php?examinatorId='+ id)
            .then(res => {
                if(res.data["success"]){
                    this.setState({exams:res.data["exams"]})
                }
             })
             .catch(function(error) {
                error.handleGlobally && error.handleGlobally();
        })
    }

    getExaminator(id){
        axios.get(global.site +'/user/get.php?profile=5&id='+ id)
            .then(res => {
                if(res.data["success"]){
                    this.setState({examinator:res.data})
                }
             })
             .catch(function(error) {
                error.handleGlobally && error.handleGlobally();
        })
    }


    render(){
        const {classes}=this.props;
        return(
            <Grid container spacing={3}>
                <Grid item xs={12} sm={4}>
                    {this.createContactInfo()}
                </Grid>
                <Grid item xs={12} sm={4}>
                    {this.createExams()}
                </Grid>
            </Grid>
        ); 
    }

}

export default withStyles(useStyles)(ExaminatorProfile)
