import React from 'react'
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import SearchIcon from '@material-ui/icons/Search';
import InputBase from '@material-ui/core/InputBase';
import Chip from '@material-ui/core/Chip';

import InputLabel from '@material-ui/core/InputLabel';
import Rating from '@material-ui/lab/Rating';
import FormControl from '@material-ui/core/FormControl';
import Typography from '@material-ui/core/Typography';
import Select from '@material-ui/core/Select';


import { fade, withStyles } from '@material-ui/core/styles';
import {Link} from "react-router-dom"

import OlindaMuiConfirm from './confirm.js'


const useStyles = theme => ({
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
});

function format(value, column){
    if(column.type === 'date'){
        if(value === null){
            return '-';
        }
        else{
            const date = new Date(value);
            return date.toLocaleDateString('fr-fr');
        }
    }
    return value;
}


class OlindaMuiTable extends React.Component{

    constructor(props){
        super(props);
        this.state={
            del:false,
            selected:0,
            selectedList:[],
            search:''
        }
        this.onCheck = this.onCheck.bind(this);
        this.onCheckAll = this.onCheckAll.bind(this);
        this.onDelete = this.onDelete.bind(this);
        this.onValidDelete = this.onValidDelete.bind(this);
        this.onCancelDelete = this.onCancelDelete.bind(this);
        this.onAdd = this.onAdd.bind(this);
        this.onEdit = this.onEdit.bind(this);
        this.onSearch = this.onSearch.bind(this);
        this.onSelect = this.onSelect.bind(this);
    }

    onDelete(){
        this.setState({del:true});
    }

    onValidDelete(){
        this.props.onDelete(this.state.selectedList);
        this.setState({del:false});
        this.setSelected([]);
    }

    onCancelDelete(){
        this.setState({del:false});
    }

    onAdd(){
        if(this.props.addParameters !== undefined){
            this.props.onAdd(this.props.addParameters);
        }
        else{
            this.props.onAdd();
        }
    }
    
    onClick(id){
        this.props.onClick(id);
    }

    onEdit(id){
        this.props.onEdit(id);
    }
    
    onEditWithType(id, type, profile){
        this.props.onEdit(id, type, profile);
    }

    setSelected(ids){
        this.setState({selectedList:ids});
    }

    onSearch(event){
        let target = event.target;
        this.setState({search: target.value});
    }


    onCheckAll(event){
        if(event.target.checked){
            const newSelecteds = this.props.elements.map((element) => element.id);
            this.setSelected(newSelecteds);
            return;
        }
        this.setSelected([]);

    }

    onCheck(event){
        let target = event.target;
        let id = parseInt(target.id, 10);
        if(isNaN(id) || id.toString() !== target.id){
            id = target.id;
        }
        const selectedIndex = this.state.selectedList.indexOf(id);
        let newSelected = this.state.selectedList.slice();
        if(target.checked){
            if(selectedIndex === -1)
            {
                newSelected.push(id);
            }
        }
        else{
            if(selectedIndex !== -1){
                newSelected.splice(selectedIndex,1);
            }

        }
        this.setState({selectedList:newSelected})
    }

    onSelect(event){
        let target = event.target;
        this.props.onSelect(target.id, target.checked);
    }


    createToolbar(){
        const {classes} = this.props;
        return(
            <Toolbar>
                <div>
                {this.state.selectedList.length > 0 ? (                    
                    <IconButton aria-label="delete">
                        <DeleteIcon onClick={this.onDelete}/>
                    </IconButton>
                ):null}
                {this.props.add ?
                    <IconButton aria-label="delete">
                        <AddIcon  onClick={this.onAdd}/>
                    </IconButton>
                 : null}
                {this.props.searchColumn !== undefined?
                    <div className={classes.search}>
                        <div className={classes.searchIcon}>
                            <SearchIcon />
                        </div>
                        <InputBase
                            placeholder="Search…"
                            onChange = {this.onSearch}
                            classes={{
                                root: classes.inputRoot,
                                input: classes.inputInput,
                            }}
                            inputProps={{ 'aria-label': 'search' }}
                        />
                    </div>:
                        null
                }
                {this.props.boxFilters !== undefined ?
                    this.props.boxFilters.values.map((filter)=>(
                        <FormControlLabel
                            label={filter.name}
                            control={
                                <Checkbox
                                    checked={filter.checked}
                                    onChange={(event)=>this.props.boxFilters.cb(event,filter.id)}
                                />
                            }
                        />
                    ))
                    :
                    null
                }
                {this.props.filter !== undefined && this.props.filter.values !== undefined?
                    <FormControl
                        fullWidth={true}
                    >

                        <InputLabel>{this.props.filter.label}</InputLabel>
                        <Select
                            native
                            value={this.props.filter.value}
                            id={'filter'}
                            onChange={(e)=>this.props.filter.callback(e)}
                        >
                        {this.props.filter.values.map((option)=>(
                            <option value={option.id}>{option.name!==undefined?option.name:option.title}</option>
                        ))}
                        </Select>
                    </FormControl>
                    :
                    null
                }
                </div>
            </Toolbar>
        );
    }

    createHeader(){
        return(
            <TableHead>
                <TableRow>
                    {this.props.del && (this.props.force !== undefined && this.props.force)?
                    <TableCell padding="checkbox">
                        <Checkbox                            
                            onChange={this.onCheckAll}
                            checked = {this.props.elements.length > 0 && this.state.selectedList.length === this.props.elements.length}
                            indeterminate = {this.state.selectedList.length > 0 && this.state.selectedList.length < this.props.elements.length}
                        />
                    </TableCell>
                        :null}
                    {this.props.columns.map((column) => (
                        column.type == 'check' ?
                            <TableCell>{column.title}</TableCell>
                            :
                            <TableCell>{column.title}</TableCell>
                        
                    ))}
                    {this.props.check !== undefined ?
                            <TableCell>{this.props.check.title}</TableCell>
                            :
                            null
                    }
                    {(this.props.edit === undefined || this.props.edit) && (this.props.onEdit !== undefined || this.props.href !== undefined)?
                        <TableCell>{'Editer'}</TableCell>                
                         :
                         null
                    }
                </TableRow>
            </TableHead>
        );
    }
   
    createCheck(element){
        return(
            <Checkbox
                id={element.id}
                disabled={true}
                onChange={this.props.onSelect !== undefined ? this.onSelect : this.props.onCheck}
                checked={element.value}
            />
        );
    }
   
    createCell(column, element){
        if(column.type === 'check'){
            return(
                <TableCell style={{padding:'0px 0px 0px 0px'}}>
                <Checkbox
                    id={element.id}
                    onChange={(event)=>column.cb(event)}
                    disabled={column.readOnly || this.props.readOnly}
                    checked={element[column.id]}
                />
                </TableCell>
            );
        }
        else if(column.type === 'button'){
            return(
                <TableCell style={{padding:'0px 0px 0px 0px'}}>
                <Button
                    color="primary"
                    onClick={()=>column.cb(element.id)}
                >
                    {column.title}
                </Button>
                </TableCell> 
            );
        }
        else if(column.type === 'pdf'){
            return(
                <TableCell style={{padding:'0px 0px 0px 0px'}}>
                    <IconButton>
                        <PictureAsPdfIcon
                            id = {element.id}
                            onClick={()=>column.cb(element.id)}
                         />
                     </IconButton>
                </TableCell>
            );
        }
        else if(column.type === 'star'){
            return(
            <TableCell>
            <Rating
                margin="dense"
                value={element.[column.id]}
                name={element.id}
                max={3}
                readOnly={true}
            />

            </TableCell>
            );
        }
        else{
            return(
                <TableCell component="th" scope="row">
                    {column.link==undefined || !column.link ?
                        format(element.[column.id], column)
                        :
                        <Link 
                            to={{pathname:element.link}}
                            target={'_blank'}
                        >
                            {element.[column.id]}
                        </Link>
                    }
                </TableCell>
            );
        }
    }

    createLink(element){
        return(
            this.props.extraLink == undefined?
                <Link to={this.props.href + "/" + element.id}>
                    <EditIcon/>
                </Link>
                :
                <Link to={this.props.href + "/" + element.id + "/" +this.props.extraLink.key + "/" + element[this.props.extraLink.value]}>
                    <EditIcon/>
                </Link>
            
        )
    }

    createElement(element){
        return(
            <TableRow 
                key={element.id} 
                role="checkbox" 
                style={{background:element.color}} 
            >
                {this.props.del && (this.props.force !== undefined && this.props.force)?
                <TableCell padding="checkbox">
                    <Checkbox
                        id = {element.id}
                        onChange={this.onCheck}
                        checked={this.state.selectedList.indexOf(element.id) >= 0}
                    />
                </TableCell>
                    :
                null
                }
                {
                    this.props.columns.map((column) => (
                        this.createCell(column, element)
                    ))
                }
                {this.props.check !== undefined ?
                    <TableCell style={{padding:'0px 0px 0px 0px'}}>
                        {this.createCheck(element)}
                    </TableCell>
                    :
                    null
                }
                { ((this.props.edit === undefined || this.props.edit) && 
                   (this.props.onEdit !== undefined || this.props.href !== undefined)) || this.props.onSelect !== undefined
                        ?
                    <TableCell style={{padding:'0px 0px 0px 0px'}}>
                        {this.props.href !== undefined?
                            this.createLink(element)
                            :
                            <IconButton>
                                {this.props.onEdit !== undefined ?
                                <EditIcon
                                    id = {element.id}
                                    onClick={
                                        this.props.useType !==undefined && this.props.useType ? ()=>this.onEditWithType(element.id, element.type, element.profile) 
                                        : 
                                        ()=>this.onEdit(element.id)}
                                    />
                                    :
                                    null
                                }
                                {this.props.onSelect !== undefined ?
                                    <Checkbox
                                        id = {element.id}
                                        onChange={this.onSelect}
                                    />
                                    :
                                    null
                                }                                    
                            </IconButton>
                        }
                    </TableCell>
                    :
                    null
             }
            </TableRow>
        )
    }


    createBody(){
        let elements;
        if(this.props.searchColumn !== undefined){
            elements = this.props.elements.filter(element => element.[this.props.searchColumn].toLowerCase().includes(this.state.search.toLowerCase()));
        }
        else{
            elements = this.props.elements;
        }
        return(
            <TableBody>
                {elements.map((element) => (
                    this.createElement(element)
                ))}
            </TableBody>
        );
    }

    render(){
          return (
            <div>
            {this.props.del || this.props.add || this.props.searchColumn !== undefined ? this.createToolbar(): null}
            <TableContainer>
            <Table aria-label="simple table">
              {this.createHeader()}
              {this.createBody()}
            </Table>
            </TableContainer>   
            {this.state.del ?
                <OlindaMuiConfirm
                    title="Supression"
                    text={"Supprimer les élèments sélectionnés, êtes-vous sûr?"}
                    onValid = {this.onValidDelete}
                    onCancel = {this.onCancelDelete}
                />
                :null
                }

            </div>
        );

    }

}
export default withStyles(useStyles)(OlindaMuiTable)

