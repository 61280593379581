import axios from 'axios';

global.site='https://plateforme-fae.fr/api';
global.smallWidth=900;
axios.defaults.withCredentials=true;
global.disconnectedHandle=null;
global.backToHomeHandle = null;
const errorComposer = (error) => {
    return () => {
        const statusCode = error.response ? error.response.status : null;
        if (statusCode === 404) {
            console.log('The requested resource does not exist or has been deleted')
        }

        if (statusCode === 401) {
            global.disconnectedHandle();
        }
        else if (statusCode === 403) {
            global.backToHomeHandle();
        }
        else if (statusCode === 404) {
            global.unableToConnect();
        }
    }
}

axios.interceptors.response.use(undefined, function (error) {
    error.handleGlobally = errorComposer(error);

    return Promise.reject(error);
});
