import React from 'react'
import axios from 'axios'

import AddIcon from '@material-ui/icons/Add';
import IconButton from '@material-ui/core/IconButton';

import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { withRouter } from "react-router-dom";
import Grid from '@material-ui/core/Grid';

import OlindaMuiForm from '../components/form'
import OlindaMuiTable from '../components/table'
import TrainingItem from './trainingItem'
import TrainingBody from './trainingBody'

const useStyles = theme => ({
  addTraining: {
    align: 'right',
  },
    title : {
      flex:1,
  },
});

class Training extends React.Component{
    constructor(props){
        super(props);
        this.state={
            trainings:[],
            pastTrainings:[],
            calendars:[],
            trainingName:"",
            trainingId:0,
            createTraining:false,
            training:null
        }

        /*Training part */
        this.formFields = [
            {id:'name', label:'Nom', type:'input', value:'', mandatory:true},
            {id:'startDate', label:'Début', type:'date', value:'', mandatory:true},
            {id:'endDate', label:'Fin', type:'date', value:'', mandatory:true}
        ];

        this.columns=[
            {id:'name', title:'Nom'},
            {id:'startDate', title:'Du', type:'date'},
            {id:'endDate', title:'Au', type:'date'}
        ];

        this.onCreateTraining = this.onCreateTraining.bind(this);
        this.onValidCreateTraining = this.onValidCreateTraining.bind(this);
        this.onCancelCreateTraining = this.onCancelCreateTraining.bind(this);
        this.onDeleteTraining = this.onDeleteTraining.bind(this);
    }


    /*Click on create button*/
    onCreateTraining()
    {
        this.formFields = [
            {id:'name', label:'Nom', type:'input', value:'', mandatory:true},
            {id:'startDate', label:'Début', type:'date', value:'', mandatory:true},
            {id:'endDate', label:'Fin', type:'date', value:'', mandatory:true},
            {id:'calendarId', label:'Calendrier Google', type:'select', value:''}
        ];
        this.setState({createTraining:true});
    }
    
    /*Valid the creation or the edition of a training*/
    onValidCreateTraining(fields)
    {
        let datas = new FormData();
        fields.map((field)=>(
            datas.append(field.id, field.value)
        ))
        axios({
            method: 'post',
            url: global.site +'/training/create.php',
            data: datas
        })
        .then(res =>  {
            if(res.data["success"]){
                const trainings = res.data["trainings"];
                this.setState({trainings:trainings, trainingId:0, createTraining:false});
            }
        })
        .catch(function (error) {
            error.handleGlobally && error.handleGlobally();
        });
    }

    /*Cancel the creation or edition of a training*/
    onCancelCreateTraining(){
        this.setState({createTraining:false, createModule:false});
    }
    
    onDeleteTraining(ids){
        let datas = new FormData();
        for(let i = 0; i < ids.length; i++){
            datas.append('ids[]', ids[i]);
        }

        axios({
            method: 'post',
            url: global.site +'/training/delete.php',
            data: datas
        })
        .then(res =>  {
            if(res.data["success"]){
                let trainings = res.data["trainings"]
                this.setState({trainings:trainings});
            }
        })
        .catch(function (error) {
            error.handleGlobally && error.handleGlobally();
        });    
    }
    
    componentDidMount()
    {
        if(this.props.match.params.trainingId === undefined){
            this.getTrainingsList(this.props.running);
        }
        this.getCalendarsList();
    }

    componentDidUpdate(props){
        if(this.props.match.params.trainingId !== props.match.params.trainingId){
            if(this.props.match.params.trainingId === undefined){
                this.getTrainingsList(false);
            }
        }
    }

    getCalendarsList()
    {
        axios.get(global.site +'/google-api/getCalendars.php')
            .then(res => {
                const calendars = [{name:'', id:''}].concat(res.data["calendars"]);
                this.setState({calendars:calendars});
             })
             .catch(function(error) {
                error.handleGlobally && error.handleGlobally();
        })
    }

    getTrainingsList(running)
    {
        axios.get(global.site +'/training/list.php?running='+running, {withCredentials:true})
            .then(res => {
                if(res.data["success"]){
                    const trainings = res.data["trainings"];
                    this.setState({trainings:trainings, trainingId:0});
                }
             })
             .catch(function(error) {
                error.handleGlobally && error.handleGlobally();
        })
    }
    
    getTraining(id)
    {
        axios.get(global.site +'/training/get.php?id=' + id, {withCredentials:true})
            .then(res => {

                if(res.data["success"]){
                    this.setState({training:res.data, trainingId:id});
                }
             })
             .catch(function(error) {
                error.handleGlobally && error.handleGlobally();
        })
    }

    render(){
        const {classes} = this.props;
        if(this.props.match.params.trainingId !== undefined && this.state.trainingId !== this.props.match.params.trainingId){
            this.setState({trainingId:this.props.match.params.trainingId});
        }
        else if(this.props.match.params.trainingId === undefined && this.state.training !== null){
            this.setState({trainingId:0});
        }
        let size = 6;
        if(this.props.edit){
            size = 4;
        }
        return(
            <div>
                {this.state.trainingId === 0?
                    <div style={{padding:'0px'}}>
                    {this.props.edit ?
                        <div>
                        <Typography variant="h5" className={classes.title}>Formations</Typography>
                        <IconButton>
                            <AddIcon  onClick={this.onCreateTraining}/>
                        </IconButton>
                        </div>
                        :
                        null
                    }
                    <Grid container spacing = {3} style={{background:'transparent', marginTop:'10px'}}>
                        {this.state.trainings.map((training)=>(
                            <Grid style={{background:'transparent'}} item xs={12} sm={size}>
                                <TrainingItem
                                    training={training}
                                />
                            </Grid>
                        ))                                        
                        }
                    </Grid>
                    </div>
                    :
                    <div>
                        <TrainingBody
                            trainingId={this.state.trainingId}
                            calendars= {this.state.calendars}
                            userProfile={this.props.userProfile}
                            windowWidth={this.props.windowWidth}
                        />
                    </div>
                }
                {this.state.createTraining ? 
                    <OlindaMuiForm 
                        fields={this.formFields}
                        title="Formation"
                        calendarId={this.state.calendars}
                        onCancel={this.onCancelCreateTraining} 
                        onValid={this.onValidCreateTraining}/> 
                    : null}
            </div>

        );
    
    }
}

export default withRouter(withStyles(useStyles)(Training))

