import React from 'react'
import axios from 'axios'

import OlindaMuiForm from '../components/form'
import OlindaMuiTable from '../components/table'


class CommentsList extends React.Component{
    constructor(props){
        super(props);
        this.state={

            comments:[],
            commentId:0,
            createComment:false,
        }
        
        this.commentFields = [
              {id:'postDate', label:'Date', type:'date', value:'', hide:true},
              {id:'author', label:'Auteur', type:'input', value:'', hide:true},
              {id:'object', label:'Objet', type:'input', value:''},
              {id:'message', label:'Message', type:'multiline', value:''}
          ];


        this.onCreateComment = this.onCreateComment.bind(this);
        this.onValidCreateComment = this.onValidCreateComment.bind(this);
        this.onCancelCreateComment = this.onCancelCreateComment.bind(this);
        this.onEditComment = this.onEditComment.bind(this);
    }


    componentDidMount(){
        this.getComments();
    }

    onEditComment(id)
    {
        this.commentFields[0].hide = false;
        this.commentFields[1].hide = false;
        axios.get(global.site +'/comment/get.php?id=' + id)
            .then(res => {
                if(res.data["success"]){
                    this.commentFields.map((field) => (
                        field.value = res.data[field.id]
                    ))
                    this.setState({createComment:true, commentId:id});
                }
             })
             .catch(function(error) {
                error.handleGlobally && error.handleGlobally();
        })

    }
   
    getComments(){
        axios.get(global.site +'/comment/list.php?studentId=' + this.props.studentId +'&trainingId=' + this.props.trainingId)
            .then(res => {
                if(res.data["success"]){
                    const comments = res.data["comments"];
                    this.setState({comments:comments});
                }
             })
             .catch(function(error) {
                error.handleGlobally && error.handleGlobally();
        })
    }

    onCreateComment()
    {
        this.commentFields = [
            {id:'postDate', label:'Date', type:'date', value:'', hide:true},
            {id:'author', label:'Auteur', type:'input', value:'', hide:true},
            {id:'object', label:'Objet', type:'input', value:'', mandatory:true},
            {id:'message', label:'Message', type:'multiline', value:'', mandatory:true}
        ];
        this.setState({createComment:true, commentId:0});
    }
    
    onValidCreateComment(fields){
         let datas = new FormData();
         fields.map((field)=>(
             datas.append(field.id, field.value)
         ))
         if(this.props.studentId !== 0){
             datas.append("studentId", this.props.studentId);
         }
         datas.append("trainingId", this.props.trainingId);
         datas.append("studentFirstName", this.props.studentFirstName);
         datas.append("studentLastName", this.props.studentLastName);
         axios({
             withCredentials:true,
             method: 'post',
             url: global.site +'/comment/create.php',
             data: datas
         })
         .then(res =>  {
             if(res.data["success"]){
                 let comments = res.data["comments"]
                 this.setState({createComment:false, comments:comments});
             }
         })
         .catch(function (error) {
             error.handleGlobally && error.handleGlobally();
         });
        this.setState({createComment:false, commentId:0});

    }

    onCancelCreateComment(){
        this.setState({createComment:false, commentId:0});
    }

    render(){
        return(
            <div>
                <OlindaMuiTable
                    elements={this.state.comments}
                    columns = {[{"id":"postDate", "title":"Date"}, {"id":"object", "title":"Objet"}]}
                    del={false}
                    add={true}
                    onEdit = {this.onEditComment}
                    onAdd = {this.onCreateComment}
                />
            {this.state.createComment ?
                <OlindaMuiForm
                    fields={this.commentFields}
                    title="Signaler"
                    readOnly = {this.state.commentId !== 0}
                    onCancel={this.onCancelCreateComment}
                    onValid={this.onValidCreateComment}
                />
                :null
            }
            </div>

        );
    }
}

export default CommentsList
