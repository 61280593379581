import React from 'react'
import Checkbox from '@material-ui/core/Checkbox';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import VisibilityIcon from '@material-ui/icons/Visibility';
import Link from '@material-ui/core/Link';
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import ListItemText from '@material-ui/core/ListItemText'
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';

import SearchIcon from '@material-ui/icons/Search';
import InputBase from '@material-ui/core/InputBase';
import { fade, withStyles } from '@material-ui/core/styles';
import OlindaMuiConfirm from './confirm.js'

const useStyles = theme => ({
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },    
});



class OlindaMuiMediaList extends React.Component{

    constructor(props){
        super(props);
        this.state={
            del:false,
            delId:'',
            delName:'',
            search:''
        }
        this.onToggleCheck = this.onToggleCheck.bind(this);
        this.onDelete = this.onDelete.bind(this);
        this.onEdit = this.onEdit.bind(this);
        this.onAdd = this.onAdd.bind(this);
        this.onValidDelete = this.onValidDelete.bind(this);
        this.onCancelDelete = this.onCancelDelete.bind(this);
        this.onSearch = this.onSearch.bind(this);
    }


    onDelete(id, name){
        this.setState({del:true, delId:id, delName:name})
    }

    onValidDelete(id){
        this.props.onDelete(this.state.delId);
        this.setState({del:false, delId:'', delName:''})
    }

    onCancelDelete(){
        this.setState({del:false, delId:''})
    }

    onEdit(id){
        this.props.onEdit(id);
    }
    
    onShow(id){
        this.props.onShow(id);
    }

    onAdd(id){
        this.props.onAdd();
    }

    onToggleCheck(event){
        let target = event.target;
        this.props.onToggleCheck(target.id, target.checked);
    }

    onSearch(event){
        let target = event.target;
        this.setState({search: target.value});
    }

    createToolbar(){
        const {classes} = this.props;

        return(
            <Toolbar>
                <IconButton aria-label="delete">
                    <AddIcon  onClick={this.onAdd}/>
                </IconButton>
                <div className={classes.search}>
                    <div className={classes.searchIcon}>
                    <SearchIcon />
                    </div>
                    <InputBase
                        placeholder="Search…"
                        onChange = {this.onSearch}
                        classes={{
                            root: classes.inputRoot,
                            input: classes.inputInput,
                        }}
                        inputProps={{ 'aria-label': 'search' }}
                    />
                </div>            
            </Toolbar>
        );
    }

    insertLink(link, name)
    {
        return(
            <Link href={link}>{name}</Link>
        );
    }

    insertText(name)
    {
        return(
            <ListItemText primary={name}/>
        );
    }

    render(){

          const elements = this.props.elements.filter(element => element.name.includes(this.state.search));
          return (
            <div>
              {this.props.add ? this.createToolbar(): null}
                <List>
                {
                    elements.map((element)=>(
                        <ListItem key={element.id}>

                            <Card>
                                
                                <CardMedia
                                    component='iframe'
                                    title={element.name}
                                    src={element.link}
                                />
                            </Card>
                            <ListItemSecondaryAction>
                                    <div>
                                        {this.props.edit ?
                                            <IconButton edge="end" aria-label="comments">
                                                <EditIcon onClick={()=>this.onEdit(element.id)} />
                                            </IconButton>:null
                                        }
                                        {this.props.read ?
                                            <IconButton edge="end" aria-label="comments">
                                                <VisibilityIcon onClick={()=>this.onShow(element.id)} />
                                            </IconButton> : null
                                        }
                                        {this.props.del ?
                                            <IconButton edge="end" aria-label="comments">
                                                <DeleteIcon onClick={()=>this.onDelete(element.id, element.[this.props.field])} />
                                            </IconButton>: null
                                        }
                                        {this.props.check ?
                                                <Checkbox 
                                                    id={element.id}
                                                    onChange={this.onToggleCheck}
                                                />
                                                : null
                                        }
                                        
                                    </div>                                    
                            </ListItemSecondaryAction>
                        </ListItem>
                    ))
                }
                </List>
                {this.state.del ? 
                    <OlindaMuiConfirm 
                    title="Supression"
                    text={"Supprimer " + this.state.delName + ", êtes-vous sûr?"}
                    onValid = {this.onValidDelete} 
                    onCancel = {this.onCancelDelete}
                    />
                    :null
                }
            </div>              
        );

    }

}

export default withStyles(useStyles)(OlindaMuiMediaList)
