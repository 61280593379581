import React from 'react'
import Grid from '@material-ui/core/Grid';
import axios from 'axios'
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActionArea from '@material-ui/core/CardActionArea';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import OlindaMuiConfirm from '../components/confirm.js'
import OlindaMuiForm from '../components/form.js'

function formatDate(value){
    const date = new Date(value);

    return date.toLocaleDateString('fr-fr', {month:'long', year:'numeric'});
}



class Report extends React.Component{

    constructor(props){
        super(props);
        this.state={
            del:false,
            selected:0,
            selectedList:[],
            reports:[],
            reportPartId:0,
            reportProfile:0,
            readOnly:false,
            signedDate:'',
            author:'',
            reportId:0,
            updateDate:false,
            confirmSign:false
        }
        this.notes = [
            {id:0, name:''},
            {id:1, name:'Très Insuffisant'},
            {id:2, name:'Insuffisant'},
            {id:3, name:'Correct'},
            {id:4, name:'Satisfaisant'},
            {id:5, name:'Supérieur'}
        ]
        
        this.reportPartFields = [
            {id:'assiduity', label:'Assiduité', type:'select', value:1, 'mandatory':true},
            {id:'involvement', label:'Investissement', type:'select', value:1, 'mandatory':true},
            {id:'initiative', label:'Initiative', type:'select', value:1, 'mandatory':true},
            {id:'insertion', label:'Insertion', type:'select',value:1, 'mandatory':true},
            {id:'global', label:'Global', type:'select',value:1, 'mandatory':true},
            {id:'comment', label:'Commentaire', type:'multiline',value:''}
        ]
       
        this.dateFields = [
            {id:'date', label:'Date', type:'date'}
        ]

        this.onDelete = this.onDelete.bind(this);
        this.onValidDelete = this.onValidDelete.bind(this);
        this.onCancelDelete = this.onCancelDelete.bind(this);
        this.onAdd = this.onAdd.bind(this);
        this.onEditPart = this.onEditPart.bind(this);
        this.onValidReportPart = this.onValidReportPart.bind(this);
        this.onCancelReportPart = this.onCancelReportPart.bind(this);
        this.getReportPart = this.getReportPart.bind(this);
        this.onValidAndSign=this.onValidAndSign.bind(this);
        this.onEdit=this.onEdit.bind(this);
        this.onCancelSign = this.onCancelSign.bind(this);
        this.onValidSign = this.onValidSign.bind(this);

        this.onValidUpdateDate = this.onValidUpdateDate.bind(this);
        this.onCancelUpdateDate = this.onCancelUpdateDate.bind(this);
    }

    componentDidMount(){
        this.getReports();
    }

    onUpdateDate(id){
       this.setState({reportId:id, updateDate:true}); 
    }

    onValidUpdateDate(fields){
        let datas = new FormData();
        datas.append("date", fields[0].value);
        datas.append("reportId", this.state.reportId);
        datas.append("studentId", this.props.studentId);
        datas.append("trainingId", this.props.trainingId);
        axios({
            withCredentials:true,
            method: 'post',
            url: global.site +'/report/updateDate.php',
            data: datas
        })  
        .then(res =>  {
            if(res.data["success"]){
                let reports=res.data["reports"];
                this.setState({reportId:0, reports:reports, updateDate:false});
            }   
        })  
        .catch(function (error) {
            error.handleGlobally && error.handleGlobally();
        });     
    }

    onCancelUpdateDate(){
        this.setState({updateDate:false});
    }

    onEdit(id){
        window.open(global.site + '/report/edit.php?id='+id, "_blank");
        axios.get(global.site +'/report/edit.php?id=' + id )
            .then(res => {
                if(res.data["success"]){
                }
             })
             .catch(function(error){
                error.handleGlobally && error.handleGlobally();
        })

    }

    getReports(){
        axios.get(global.site +'/report/list.php?studentId=' + this.props.studentId +'&trainingId=' + this.props.trainingId )
            .then(res => {
                if(res.data["success"]){
                    const reports = res.data["reports"];
                    this.setState({reports:reports});
                }
             })
             .catch(function(error) {
                error.handleGlobally && error.handleGlobally();
        })
    }
    
    getReportPart(id, profile){
        axios.get(global.site +'/report/get.php?id=' + id )
        .then(res => {
            if(res.data["success"]){
                this.reportPartFields.map((field)=>(
                    field.value = res.data[field.id] === null && field.id !== "comment" ? 1 : res.data[field.id]
                ))
                this.reportPartFields.map((field)=>(
                    field.error = undefined
                ))
                if(res.data["signed"]){
                    this.setState({reportPartId:id, reportProfile:profile, readOnly:true, signedDate:res.data["signedDate"], author:res.data["author"]})
                }
                else{
                    this.setState({reportPartId:id, reportProfile:profile, readOnly: profile !== this.props.userProfile, signedDate:'', author:''})
                }
            }
         })
         .catch(function(error){
            error.handleGlobally && error.handleGlobally();
        })
    }
   
    onValidSign(){
        this.setState({confirmSign:false});
        this.validReportPart(true);
    }

    onValidReportPart(fields, sign){
        if(sign === true){
            this.setState({confirmSign:true});
            return;
        }
        this.validReportPart(false);
    }
    
    validReportPart(sign){
        let datas = new FormData();
        this.reportPartFields.map((field) =>(
            datas.append(field.id, field.value)
        ))
        if(sign){
            datas.append("sign", true);
        }
        datas.append("id", this.state.reportPartId);
        datas.append("firstName", this.props.studentFirstName);
        datas.append("lastName", this.props.studentLastName);
        axios({
            withCredentials:true,
            method: 'post',
            url: global.site +'/report/update.php',
            data: datas
        })
        .then(res =>  {
            if(res.data["success"]){
                let id=res.data["id"];
                let global=res.data["global"];
                let reports = this.state.reports.slice();
                for(let index = 0; index < reports.length; index++){
                    let report = reports[index];
                    if(report.teacherPartId === id){
                        report.teacherGlobal = global;
                        break;
                    }
                    else if(report.tutorPartId === id){
                        report.tutorGlobal = global;
                        break;
                    }
                }
                this.setState({reportPartId:0, reports:reports});
            }
        })
        .catch(function (error) {
            error.handleGlobally && error.handleGlobally();
        });
        
    }

    onCancelReportPart(){
        this.setState({reportPartId:0});
    }

    onSelected(id){
    }

    onDelete(){
        this.setState({del:true});
    }

    onValidDelete(){
         this.setState({del:false});
         let datas = new FormData();
         datas.append("trainingId", this.props.trainingId);
         datas.append("studentId", this.props.studentId);
         for(let i = 0; i < this.state.selectedList.length; i++){
            datas.append('ids[]', this.state.selectedList[i]);
        }

         axios({
             method: 'post',
             url: global.site +'/report/delete.php',
             data: datas
         })
         .then(res =>  {
             if(res.data["success"]){
                 let reports = res.data["reports"]
                 this.setState({reports:reports,reportPartId:0});
             }
         })
         .catch(function (error) {
             error.handleGlobally && error.handleGlobally();
         });

        this.setSelected([]);
    }

    onCancelDelete(){
        this.setState({del:false});
    }

    onCancelSign(){
        this.setState({confirmSign:false, reportId:0, reportPartId:0});
    }

    onAdd(){
        let datas = new FormData();
        datas.append("studentId", this.props.studentId);
        datas.append("trainingId", this.props.trainingId);
        axios({
            withCredentials:true,
            method: 'post',
            url: global.site +'/report/create.php',
            data: datas
        })
        .then(res =>  {
            if(res.data["success"]){
                let reports = res.data["reports"]
                this.setState({reports:reports});
            }
        })
        .catch(function (error) {
            error.handleGlobally && error.handleGlobally();
        });
    }

    onEditPart(id, profile){
        this.getReportPart(id, profile);
    }

    setSelected(ids){
        this.setState({selectedList:ids});
    }

    createReportItem(report){
        let colors=["red", "orange","green", "green", "green"];
        return(
            <Grid style={{background:'transparent'}} item xs={12} sm={3}>
            <Card>
                <CardHeader
                    title={'Bilan Mensuel'}
                    subheader={formatDate(report.date)}
                    action={
                            <IconButton>
                               <PictureAsPdfIcon
                                    id = {report.id}
                                    onClick={()=>this.onEdit(report.id)}
                                />
                            </IconButton>                
                    }
                >
                </CardHeader>
                <CardContent>
                    {this.props.userProfile === 1 && false ?
                        <Button color="primary" variant="contained" onClick={()=>this.onUpdateDate(report.id)}>
                            Changer la date
                        </Button>
                        :
                        null
                    }
                    <Box sx={{display:'flex'}}>
                        <Box  style={{border:'1px solid black',borderRadius:'5px',padding:'5px',background:colors[report.teacherGlobal - 1]}}>
                        <Typography style={{display:'flex'}}>
                            {'OF'}
                        </Typography>
                        
                        <Button color="primary" variant="contained" onClick={()=>this.onEditPart(report.teacherPartId, 1)}>
                            {this.props.userProfile ===1 ?
                                "Editer"
                                :
                                "Voir"
                            }
                        </Button>
                        </Box>
                        <Box style={{padding:'5px',background:colors[report.tutorGlobal - 1]}}>
                        <Typography>
                            {'Tuteur'}
                        </Typography>
                        <Button color="primary" variant="contained" onClick={()=>this.onEditPart(report.tutorPartId, 2)}>
                            {this.props.userProfile ===2 ?
                                "Editer"
                                :
                                "Voir"
                            }
                        </Button>
                        </Box>
                    </Box>
                </CardContent>
            </Card>
            </Grid>
        )
    }


    createToolbar(){
        return(
            <Toolbar>
                <div>
                {
                    this.state.selectedList.length > 0 ? (                    
                        <IconButton aria-label="delete">
                            <DeleteIcon  onClick={this.onDelete}/>
                        </IconButton>
                    )
                    :
                    null
                }
                {this.props.add ?
                    <IconButton aria-label="delete">
                        <AddIcon  onClick={this.onAdd}/>
                    </IconButton>
                 : null}
                </div>
            </Toolbar>
        );
    }

    onValidAndSign(){
        this.onValidReportPart(this.reportPartFields, true);
    }

    render(){
          return (
            <div>
            {this.props.del || this.props.add ? this.createToolbar(): null}
            <Grid container>
            {
                this.state.reports.map((report)=>(
                    this.createReportItem(report)
                ))
            }
            </Grid>
            {this.state.del ?
                <OlindaMuiConfirm
                    title="Supression"
                    text={"Supprimer les élèments sélectionnés, êtes-vous sûr?"}
                    onValid = {this.onValidDelete}
                    onCancel = {this.onCancelDelete}
                />
                :null
            }
            {this.state.reportPartId !==0 ?
                <OlindaMuiForm
                    fields={this.reportPartFields}
                    title={this.state.reportProfile === 1 ? "Evaluation Formateur" : "Evaluation Tuteur"}
                    subtitle={this.state.author.length > 0 && this.state.signedDate.length > 0 ? "Signé le " + formatDate(this.state.signedDate) + " par " + this.state.author : null}                    
                    assiduity={this.notes}
                    involvement={this.notes}
                    initiative={this.notes}
                    insertion={this.notes}
                    global={this.notes}
                    onCancel={this.onCancelReportPart}
                    onValid={this.onValidAndSign}
                    readOnly={this.state.readOnly}
                />
                :null
            }
            {this.state.updateDate ?
                <OlindaMuiForm
                    fields={this.dateFields}
                    title={"Mise à jour de la date"}
                    onValid={this.onValidUpdateDate}
                    onCancel={this.onCancelUpdateDate}
                />
                :
                null
            }
            {this.state.confirmSign ?
                    <OlindaMuiConfirm
                        text={"Le rapport ne sera plus modifiable après la validation, êtes-vous sûr de vouloir valider?"}
                        title={"Confirmation de signature"}
                        onCancel={this.onCancelSign}
                        onValid={this.onValidSign}
                    />
                    :
                    null
            }
            </div>
        );

    }

}

export default Report;
