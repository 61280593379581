import React from 'react'
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Rating from '@material-ui/lab/Rating';
import InputAdornment from '@material-ui/core/InputAdornment';
import Input from '@material-ui/core/Input';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { fade, withStyles } from '@material-ui/core/styles';


const useStyles = theme => ({
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
});

function format(value, column){
    if(column.type === 'date'){
        const date = new Date(value);
        return date.toLocaleDateString('fr-fr');
    }
    return value;
}


class ItemTable extends React.Component{

    constructor(props){
        super(props);
        this.onSetNote = this.onSetNote.bind(this);
    }

    onSetNote(event){
        this.props.onSetNote(event);
    }

    createHeader(){
        return(
            <TableHead>
                <TableRow>
                    {this.props.columns.map((column) => (
                        <TableCell>{column.title}</TableCell>
                    ))}
                </TableRow>
            </TableHead>
        );
    }
   
    createRadio(element){
        return(
            <Rating 
                name={element.id}
                value={element.result}
                onChange={(event)=>this.props.onSetNote(event)}
                max={3}
                readOnly={this.props.readOnly}
            />
        );       
    }

    createNote(element){
        return(
            <Input
                margin="dense"
                value={element.result}
                name={element.id}
                fullWidth
                endAdornment={<InputAdornment position="end">{'/' + element.max}</InputAdornment>}
                onChange={(e)=>this.onSetNote(e)}
                readOnly={this.props.readOnly}
            />
        );
    }
  
    createBody(){
        let elements = this.props.elements;
        return(
            <TableBody>
                {elements.map((element) => (
                    <TableRow key={element.id} role="checkbox" style={{background:element.color}}>
                        <TableCell>
                            {element.title}
                        </TableCell>
                        <TableCell>
                        {element.max !== null ? this.createNote(element) : this.createRadio(element)}
                        </TableCell>
                    </TableRow>
                ))}
            </TableBody>
        );
    }

    render(){
          return (
            <div>
            <TableContainer>
            <Table aria-label="simple table">
              {this.createHeader()}
              {this.createBody()}
            </Table>
            </TableContainer>   
            </div>
        );

    }

}
export default withStyles(useStyles)(ItemTable)

