import React from 'react'
import axios from 'axios'

import AddIcon from '@material-ui/icons/Add';


import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';

import OlindaMuiForm from '../components/form'
import OlindaMuiFlatForm from '../components/flatForm'
import OlindaMuiTable from '../components/table'
import OlindaMuiConfirm from '../components/confirm'

class TutoringReportItem extends React.Component{
    constructor(props){
        super(props);
        this.state={
            items:[],
            createItem:false,
            itemId:0,
            deleteThis:false
        }
        this.itemFields = [
            {id:'title', label:'Titre', type:'input', value:'', mandatory:true}
        ];

        if(this.props.hasNote === undefined || this.props.hasNote){
            this.itemFields.push({id:'max', label:'Nombre de points', type:'input', value:''});
        }

        this.commentFields=[
            {id:'comment', label:'Commentaires', type:'multiline', value:this.props.item.comment}
        ]

        this.results=[{id:0, name:''}, {id:1, name:'Acquis'}, {id:2, name:'Non Acquis'}];

        this.itemColumns = [
            {id:'title', title:'Titre'},
            {id:'max', title:'Nombre de points'}
        ];
        this.onCreateItem = this.onCreateItem.bind(this);
        this.onValidCreateItem = this.onValidCreateItem.bind(this);
        this.onCancelCreateItem = this.onCancelCreateItem.bind(this);
        this.onDeleteItem = this.onDeleteItem.bind(this);
        this.onEditItem = this.onEditItem.bind(this);
        this.onEditThis = this.onEditThis.bind(this);
        this.onDeleteThis = this.onDeleteThis.bind(this);
        this.onCancelDeleteThis = this.onCancelDeleteThis.bind(this);
        this.onValidDeleteThis = this.onValidDeleteThis.bind(this);

        this.onChangeResult = this.onChangeResult.bind(this);
        this.onUpdateComment = this.onUpdateComment.bind(this);
    }

    componentDidMount()
    {
        this.getItems();
    }

    getItems(){
        let request = global.site + '/tutoringReport/list.php?type=items&reportId=' + this.props.reportId + '&parentId=' + this.props.item.id;
        axios.get(request)
            .then(res => {
                const items = res.data["items"];
                this.setState({items:items});
             })
             .catch(function(error) {
                error.handleGlobally && error.handleGlobally();
        })
        
    }

    getItem(id){
        axios.get(global.site +'/tutoringReport/get.php?type=item&id='+ id)
            .then(res => {
                if(res.data["success"]){
                    this.itemFields[0].value = res.data["title"];
                    if(this.props.hasNote){
                        this.itemFields[1].value = res.data["max"];
                    }
                    this.setState({createItem:true , itemId:id});
                }
             })
             .catch(function(error) {
                error.handleGlobally && error.handleGlobally();
        })
    
    }

    onEditItem(id){
        this.getItem(id);
    }

    onValidCreateItem(fields){
        let datas = new FormData();
        fields.map((field)=>(
            datas.append(field.id, field.value)
        ))
        datas.append('type', 'item');
        datas.append('reportId', this.props.reportId);
        datas.append('parentId', this.props.item.id);
        if(this.state.itemId !== 0){
            datas.append('id', this.state.itemId);
        }
        axios({
            method: 'post',
            url: global.site +'/tutoringReport/create.php',
            data: datas
        })
        .then(res =>  {
            if(res.data["success"]){
                let items = res.data["items"];
                this.setState({createItem:false, items:items, itemId:0});
            }
        })
        .catch(function (error) {
            error.handleGlobally && error.handleGlobally();
        });
        
    }
    onCreateItem(){
        this.itemFields[0].value='';
        this.setState({createItem:true});
    }

    onCancelCreateItem(){
        this.setState({createItem:false, itemId:0});
    }

    onDeleteThis(){
        this.setState({deleteThis:true});
    }

    onEditThis(){
        this.props.onEditItem(this.props.item.id);
    }

    onCancelDeleteThis(){
        this.setState({deleteThis:false});
    }

    onValidDeleteThis(id){
        this.setState({deleteThis:false});
        this.props.onDeleteItem(id);
    }
    
    onDeleteItem(ids){
        let datas = new FormData();
        datas.append('reportId', this.props.reportId);
        for(let i = 0; i < ids.length; i++){
            datas.append('ids[]', ids[i]);
        }
        datas.append('type', 'item');
        if (this.props.deep !== undefined && this.props.deep > 0){
            datas.append('ids[]', ids);
        }
        datas.append('parentId', this.props.item.id);
        axios({
            method: 'post',
            url: global.site +'/tutoringReport/delete.php',
            data: datas
        })
        .then(res =>  {
            if(res.data["success"]){
                let items = res.data["items"]
                this.setState({items:items, itemId:0});
            }
        })
        .catch(function (error) {
            error.handleGlobally && error.handleGlobally();
        });

    }

    onUpdateItem(){
        let datas = new FormData();
        
        datas.append('title', this.itemFields[0].value)
        datas.append('type', 'item');
        datas.append('id', this.props.item.id);
        axios({
            method: 'post',
            url: global.site +'/tutoringReport/create.php',
            data: datas
        })
        .then(res =>  {
            if(res.data["success"]){
            }
        })
        .catch(function (error) {
            error.handleGlobally && error.handleGlobally();
        });
    
    }

    updateResult(id, result){
        let datas = new FormData();
        datas.append('id', id);
        datas.append('result', result);
        datas.append('type', 'item');
        axios({
            method: 'post',
            url: global.site+ '/tutoringReport/update.php',
            data:datas        
        })
        .then(res =>{
        })
        .catch(function (error) {
            error.handleGlobally && error.handleGlobally();
        });
    }

    onChangeResult(event){
        const itemId = parseInt(event.target.name);
        const result = parseInt(event.target.value);
        let items = this.state.items.slice();
        for (let item of items){
            if(item.id === itemId){
                item.result = result;
                break;
            }
        }
        this.updateResult(itemId, result);
        this.setState({items:items})
    }

    onUpdateComment(){
        const comment = this.commentFields[0].value;
        let datas = new FormData();
        datas.append('id', this.props.item.id);
        datas.append('comment', comment);
        datas.append('type', 'item');
        datas.append('reportId', this.props.reportId);
        axios({
            method: 'post',
            url: global.site+ '/tutoringReport/update.php',
            data:datas        
        })
        .then(res =>{
        })
        .catch(function (error) {
            error.handleGlobally && error.handleGlobally();
        });        
    }

    createComment(item){
        return(
            <TextField
                margin="dense"
                label={'Commentaire'}
                value={item.comment}
                fullWidth
                multiline
                rows={4}
                onChange={this.handleChange}
                InputLabelProps={{
                    shrink: true,
                }}
                InputProps={{
                    readOnly:this.props.readOnly,
                }}
            />
        );
    }

    render(){
        return(
            <div>
                {this.state.createItem ?
                    <OlindaMuiForm
                        title="Item d'évaluation"
                        fields={this.itemFields}
                        onValid={this.onValidCreateItem}
                        onCancel={this.onCancelCreateItem}
                    />
                    :null
                }
                {
                    this.state.deleteThis ?
                        <OlindaMuiConfirm
                            text={'Supprimer l\'item d\'évaluation?'}
                            onCancel = {this.onCancelDeleteThis}
                            onValid = {this.onValidDeleteThis}
                            id={this.props.item.id}
                        />
                        :
                        null
                }
                <div>
                    <Card>
                        <CardHeader title={this.props.item.title} subheader={this.props.item.description}
                            action={
                                <div>
                                <IconButton aria-label="settings">
                                    <DeleteIcon  onClick={()=>this.onDeleteThis()}/>
                                </IconButton>
                                <IconButton aria-label="settings">
                                    <EditIcon  onClick={()=>this.onEditThis()}/>
                                </IconButton>
                                </div>
                            }
                        />
                        <CardContent>
                           {this.props.userProfile === 1 && this.props.studentId === undefined 
                            && this.props.deep !== undefined && this.props.deep > 0   
                               ?
                                <IconButton aria-label="settings">
                                    <AddIcon  onClick={()=>this.onCreateItem()}/>
                                </IconButton>
                                :
                                null
                            }

                            {this.props.deep == undefined || this.props.deep ==0 ?
                                <OlindaMuiTable
                                    elements={this.state.items}
                                    columns={this.itemColumns}
                                    del={true}
                                    add={true}
                                    edit={true}
                                    onAdd={this.onCreateItem}
                                    onDelete={this.onDeleteItem}
                                    onEdit={this.onEditItem}
                                    onChange = {this.onChangeResult}
                                    readOnly={this.props.userProfile !== this.props.reportProfile && this.props.userProfile !== 1}
                                />
                                :
                                this.state.items.map((item) =>(
                                    <TutoringReportItem
                                        item={item}
                                        studentId={this.props.studentId}
                                        reportId={this.props.reportId}
                                        onDeleteItem = {this.onDeleteItem}
                                        onEditItem = {this.onEditItem}
                                        userProfile = {this.props.userProfile}
                                        reportProfile = {this.props.reportProfile}
                                        deep = {this.props.deep - 1}
                                        hasNote = {this.props.hasNote}
                                    />
                                ))
                            }

                        </CardContent>
                    </Card>
                </div>
            </div>
        );
    
    }



}

export default TutoringReportItem

