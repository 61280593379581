import React from 'react'
import axios from 'axios'
import {Image} from 'react'
import Chip from '@material-ui/core/Chip';

import OlindaMuiForm from '../components/form'
import OlindaMuiTable from '../components/table'

class Documents extends React.Component{
    constructor(props){
        super(props);
        this.state={
            documents:[],
            subjects:[],
            documentsList:[],
            addDocument:false,
            addSubject:false,
            addFilter:false
        }

        this.tableFields = [
            {id:'name', title:'Nom', link:true},
            {id:'lastModified', title:'Mis à jour le', type:'date'}
        ]

        if(this.props.type !== 'tutor' && this.props.type !== 'student' && this.props.type !== 'teacher'){
            this.tableFields.push({id:'owner', title:'Ajouté par'});
        }
       
        this.subjectsFilter=[]

        /*Training part */
        this.formFields = [
            {id:'file', label:'Fichier', type:'file', value:'', file:null, mandatory:true},
        ];


        this.subjectFields=[
            {id:'subjects', label:'Sujets', type:'check-box', values:[]}
        ]
        
        this.subjectFilterFields=[
            {id:'subjects', label:'Sujets', type:'check-box', values:[]}
        ]
        this.onAddDocument = this.onAddDocument.bind(this);
        this.onDeleteDocument = this.onDeleteDocument.bind(this);
        this.onCancelAddDocument = this.onCancelAddDocument.bind(this);
        this.onValidAddDocument = this.onValidAddDocument.bind(this);
        this.onCancelAddSubject = this.onCancelAddSubject.bind(this);
        this.onValidAddSubject = this.onValidAddSubject.bind(this);
        this.onEditDocument = this.onEditDocument.bind(this);
        this.onBoxFilter = this.onBoxFilter.bind(this);
        this.boxFilters={values:[], cb:this.onBoxFilter};
    }

    getSubjectsList(){
        axios.get(global.site +'/docSubject/docSubjectsList.php', {withCredentials:true})
            .then(res => {
                const subjects = res.data["subjects"];
                for(let index = 0; index < subjects.length; index++){
                    subjects[index]["name"] = subjects[index]["title"];
                }
                this.boxFilters["values"] = subjects;
                this.setState({subjects:subjects, subjectId:0});
             })
             .catch(function(error) {
                error.handleGlobally && error.handleGlobally();
        })
    }

    /*Click on create button*/
    onAddDocument()
    {
        this.formFields = [
            {id:'file', label:'Fichier', type:'file', value:'', file:null, mandatory:true}
        ];
        this.setState({addDocument:true});
    }

    onEditDocument(id){
        let documents = this.state.documents.filter(doc=>doc.id === id);
        if(documents.length > 0){
            let subjects = documents[0].subjects;
            let name=documents[0].name;
            this.subjectFields[0].values = [];
            for(let index = 0; index < subjects.length; index++){
                this.subjectFields[0].values.push(subjects[index].id)
            }
            this.setState({addSubject:true, documentFilename:name});
        }
    }

    onValidAddSubject(fields){
        let datas = new FormData();
        let subjects = this.subjectFields[0].values;
        for(let index = 0; index < subjects.length; index++){
            datas.append('subjects[]', subjects[index]);
        }
        datas.append('id', this.state.documentFilename);
        axios({
            method: 'post',
            url: global.site +'/documents/updateSubjects.php',
            data: datas
        })
        .then(res =>  {
            if(res.data["success"]){
                const documents = res.data['documents']
                this.setState({addSubject:false, documents:documents});
            }
        })
        .catch(function (error) {
            error.handleGlobally && error.handleGlobally();
        });

    }


    onCancelAddSubject(){
        this.setState({addSubject:false});
    }
    
    /*Valid the creation or the edition of a training*/
    onValidAddDocument(fields)
    {
        let datas = new FormData();
        fields.map(field=>{
            if(field.type === 'file'){
                const file = field.file;
                const fileName = file.name;
                datas.append('file', file, fileName)
            }
            else{
                datas.append(field.id, field.value)
            }
        })
        datas.append('type', this.props.type);
        datas.append('id', this.props.userId);
        datas.append('directory', this.props.directory);
        if(this.props.signature !== undefined && this.props.signature){
            datas.append('signature', true);
        }
        axios({
            method: 'post',
            url: global.site +'/documents/add.php',
            data: datas
        })
        .then(res =>  {
            if(res.data["success"]){
                const documents = res.data['documents']
                this.setState({addDocument:false, documents:documents, documentsList:documents});
            }
        })
        .catch(function (error) {
            error.handleGlobally && error.handleGlobally();
        });
    }

    /*Cancel the creation or edition of a training*/
    onCancelAddDocument(){
        this.setState({addDocument:false});
    }
    
    onDeleteDocument(ids)
    {
        let datas = new FormData();
        datas.append('type', this.props.type);
        for(var index = 0; index < ids.length; index++){
            datas.append('filenames[]', ids[index])
        }
        datas.append('id', this.props.userId);
        datas.append('directory', this.props.directory);
        if(this.props.signature !== undefined && this.props.signature){
            datas.append('signature', true);
        }
        axios({
            method: 'post',
            url: global.site +'/documents/delete.php',
            data: datas
        })
        .then(res =>  {
            if(res.data["success"]){
                const documents = res.data['documents']
                this.setState({addDocument:false, documents:documents, documentsList:documents});
            }
        })
        .catch(function (error) {
            error.handleGlobally && error.handleGlobally();
        });
    }

    onBoxFilter(event,filter){
        let checked=event.target.checked;
        let documentsList = [];
        let filterIndex = this.subjectsFilter.indexOf(filter);
        console.log(filterIndex);
        if(checked && filterIndex == -1){
            this.subjectsFilter.push(filter);
        }
        else if(!checked && filterIndex > -1){
            this.subjectsFilter.splice(filterIndex, 1);
        }
        console.log(this.subjectsFilter);

        for(filterIndex = 0; filterIndex < this.boxFilters.values.length; filterIndex++){
            if(this.boxFilters.values[filterIndex].id==filter){
                this.boxFilters.values[filterIndex]["checked"] = checked;
            }
        }
        if(this.subjectsFilter.length == 0){
            documentsList = this.state.documents;
        }
        else{
            for(let docIndex = 0; docIndex < this.state.documents.length; docIndex++){
                let doc = this.state.documents[docIndex];
                let themes = doc.subjects;
                for(let index=0; index < themes.length; index++){
                    let theme = themes[index]
                    let indexFound = this.subjectsFilter.indexOf(theme.id)
                    if(indexFound > -1){
                        documentsList.push(doc);
                        break;
                    }
                }
            }
        }
        this.setState({documentsList:documentsList});
    }

    componentDidMount()
    {
        this.getDocuments();
        this.getSubjectsList();
    }

    getDocuments()
    {
        let request = global.site +'/documents/list.php?type=' + this.props.type + '&id=' + this.props.userId + '&directory=' + this.props.directory; 
        if(this.props.trainingId !== undefined){
            request += '&trainingId=' + this.props.trainingId;
        }
        if(this.props.signature !== undefined && this.props.signature){
            request += '&signature=true';
        }
        axios.get(request)
            .then(res => {
                const documents = res.data["documents"];
                this.setState({documents:documents, documentsList:documents});
             })
             .catch(function(error){
                error.handleGlobally && error.handleGlobally();
        })
    }
    
    render(){
        return(
            <div>
                {this.props.signature && this.props.picture  ?
                    <img src={this.state.documents.length > 0 ? this.state.documents[0].link : null}/>
                    :
                    <div>

                    <OlindaMuiTable
                        elements = {this.state.documentsList}
                        columns = {this.tableFields}
                        add = {this.props.add}
                        del={this.props.del}
                        force={this.props.del}
                        searchColumn = 'name'
                        onAdd = {this.onAddDocument}                        
                        onDelete = {this.onDeleteDocument}                
                        onSelect = {this.props.onToggleCheck}
                        onEdit = {this.props.edit !== undefined && this.props.edit==false ? undefined : this.onEditDocument}
                        boxFilters={this.props.type=='teaching-documents' ? this.boxFilters : undefined}
                    />
                    </div>
                }

                {this.state.addDocument ? 
                    <OlindaMuiForm 
                        fields={this.formFields}
                        title={this.props.signature ? "Ajouter ma signature" : "Ajouter un document"}
                        onCancel={this.onCancelAddDocument} 
                        onValid={this.onValidAddDocument}/> 
                    : null
                }
                {this.state.addSubject ? 
                    <OlindaMuiForm 
                        fields={this.subjectFields}
                        subjects={this.state.subjects}
                        title={"Sujet liés au document"}
                        onCancel={this.onCancelAddSubject} 
                        onValid={this.onValidAddSubject}/> 
                    : null
                }
            </div>
        );
    
    }



}

export default Documents

