import React from 'react'

import Badge from '@material-ui/core/Badge';

import Button from '@material-ui/core/Button';
import Avatar from '@material-ui/core/Avatar';

import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardHeader from '@material-ui/core/CardHeader';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import BarChartIcon from '@material-ui/icons/BarChart';
import SearchIcon from '@material-ui/icons/Search';
import InputBase from '@material-ui/core/InputBase';

import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Typography from '@material-ui/core/Typography';
import Select from '@material-ui/core/Select';
import { withStyles } from '@material-ui/core/styles';

import {Link} from "react-router-dom"

import OlindaMuiConfirm from './confirm.js'


function formatDate(value){
   const date = new Date(value);
   return date.toLocaleDateString('fr-fr');
}

const styles = theme => ({
  ok: {
    backgroundColor: "green",
    color: "white"
  },
  warning: {
    backgroundColor: "orange",
    color: "white"
  },
  ko: {
    backgroundColor: "red",
    color: "white"
  },
  unknown: {
    backgroundColor: "gray",
    color: "white"
  },
});


class OlindaMuiCardList extends React.Component{

    constructor(props){
        super(props);
        this.state={
            del:false,
            search:''
        }
        this.onDelete = this.onDelete.bind(this);
        this.onValidDelete = this.onValidDelete.bind(this);
        this.onCancelDelete = this.onCancelDelete.bind(this);
        this.onAdd = this.onAdd.bind(this);
        this.onSearch = this.onSearch.bind(this);

        this.reasons=["En Cours", "Abandon", "Cerifié", "Non-Acquis"];
    }

    onDelete(){
        this.setState({del:true});
    }

    onValidDelete(){
        this.props.onDelete();
        this.setState({del:false});
    }

    onCancelDelete(){
        this.setState({del:false});
    }

    onAdd(){
        if(this.props.addParameters !== undefined){
            this.props.onAdd(this.props.addParameters);
        }
        else{
            this.props.onAdd();
        }
    }
    
    onClick(id){
        this.props.onClick(id);
    }


    onSearch(event){
        let target = event.target;
        this.setState({search: target.value});
    }


    onSelect(event){
        let target = event.target;
        this.props.onSelect(target.id, target.checked);
    }


    createToolbar(){
        const {classes} = this.props;
        return(
            <Toolbar>
                <div>
                {this.props.add ?
                    <IconButton aria-label="delete">
                        <AddIcon  onClick={this.onAdd}/>
                    </IconButton>
                 : null}
                {this.props.searchColumn !== undefined?
                    <div>
                        <div>
                            <SearchIcon />
                        </div>
                        <InputBase
                            placeholder="Search…"
                            onChange = {this.onSearch}
                            inputProps={{ 'aria-label': 'search' }}
                        />
                    </div>:
                        null
                }
                {this.props.filter !== undefined && this.props.filter.values !== undefined?
                    <FormControl
                        fullWidth={true}
                    >

                        <InputLabel>{this.props.filter.label}</InputLabel>
                        <Select
                            native
                            value={this.props.filter.value}
                            id={'filter'}
                            onChange={(e)=>this.props.filter.callback(e)}
                        >
                        {this.props.filter.values.map((option)=>(
                            <option value={option.id}>{option.name!==undefined?option.name:option.title}</option>
                        ))}
                        </Select>
                    </FormControl>
                    :
                    null
                }
                </div>
            </Toolbar>
        );
    }

    createHeader(element){
        return(
                <CardHeader
                    title={element['title']}
                    subheader={<div>
                                <Typography>{element['phone']}</Typography>
                                <Typography>{element["mail"]}</Typography>
                                <Typography>{element["organization"] !== 'undefined' && element["organization"] !== 'null' ? element["organization"] : null}</Typography>
                               </div>
                            }
                    avatar={element.avatar !== undefined && element.avatar.length > 0 && element.avatar[0].link !== undefined?
                                <Avatar
                                    src={element.avatar[0].link}
                                />
                                :
                                <Avatar>
                                    {element.firstName[0] + element.lastName[0]}
                                </Avatar>                                                        
                            }                    
                    action={this.props.profile==3 ? this.createTrainingReports(element) : null}
                >                  
                </CardHeader>
        );
    }

    createHeaderWithAction(element){
        return(
            <CardActionArea
                component={Link}
                to={'/student/'+element.id+'/training/' + element.trainingId}
            >
                {this.createHeader(element)}
            </CardActionArea>
        );
    }

    createTrainingReports(element){
        const { classes } = this.props;
        let classe = classes.unknown;
        if(element.color=='red'){
            classe = classes.ko; 
        }
        else if(element.color=='orange'){
            classe=classes.warning;
        }
        else if(element.color=='green'){
            classe=classes.ok
        }
        else{
            return null;
        }
        return(
            <CardActions>
            <Badge 
                color="red" 
                classes={{ badge: classe }}
                badgeContent=" "
            >
                <BarChartIcon color="action"/>
            </Badge>
            </CardActions>
        );
    }

    createElement(element){
        return(
            <Grid item xs={12} sm={4}>
            <Card>
                {this.props.profile==3 && this.props.link?
                    this.createHeaderWithAction(element)
                    :
                    this.createHeader(element)
                }
                <CardContent>
                    <Grid container>
                        <Grid item sx={6} sm={6}>
                    {this.props.profile==1 || this.props.profile == 2?
                        <Button 
                            color="primary" 
                            variant="contained"
                            component={Link}
                            to={this.props.href + '/' + element.id}
                        
                        >
                            {'Documents'}
                        </Button>
                        :
                        null
                    }
                    {this.props.profile==3 && !this.props.link?
                        <Button 
                            color="primary" 
                            variant="contained"
                            component={Link}
                            to={this.props.href + '/' + element.id}
                        
                        >
                            {'Formations'}
                        </Button>
                        :
                        null
                    }
                    {this.props.profile==3 && this.props.link?
                        <Typography>Statut :  {this.reasons[parseInt(element.reason)]}</Typography>
                        :
                        null
                    }
                    <Typography>
                        {'Dernière connexion le ' + formatDate(element['lastConnection'])}
                    </Typography>
                        </Grid>
                        <Grid item sx={6} sm={6}>
                            {element.signature !== undefined && element.signature.length > 0 && element.signature[0].link !== undefined ?
                            <img src={element.signature[0].link} width='100'/>
                            :
                            null
                            }
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
            </Grid>
        )
    
    }

    createElements(){
        let elements;
        if(this.props.searchColumn !== undefined){
            elements = this.props.elements.filter(element => element.[this.props.searchColumn].toLowerCase().includes(this.state.search.toLowerCase()));
        }
        else{
            elements = this.props.elements;
        }

        return(
            <Grid container spacing={3}>
            {elements.map((element)=>(
                this.createElement(element)
            ))}
            </Grid>

        );
        
    }

    render(){
          return (
            <div>
            {this.props.del || this.props.add || this.props.searchColumn !== undefined ? this.createToolbar(): null}
            {this.createElements()}
            {this.state.del ?
                <OlindaMuiConfirm
                    title="Supression"
                    text={"Supprimer les élèments sélectionnés, êtes-vous sûr?"}
                    onValid = {this.onValidDelete}
                    onCancel = {this.onCancelDelete}
                />
                :null
            }

            </div>
        );

    }

}
export default withStyles(styles)(OlindaMuiCardList)

