import React from 'react'
import axios from 'axios'


import OlindaMuiTable from '../components/table'
import DocumentsForm from './documentsForm.js'


class LinkedDocuments extends React.Component{
    constructor(props){
        super(props);
        this.state={
            documents:[],
            documentsList:[],
            subjects:[],
            addDocument:false,
        }

        /*Training part */
        this.formFields = [
            {id:'file', label:'Fichier', type:'linkedDocuments', value:'', file:null},
        ];

        this.tableFields = [
            {id:'name', title:'Nom', link:true},
            {id:'lastModified', title:'Mis à jour le', type:'date'},
            {id:'owner', title:'Ajouté par'}
        ]

        this.subjectsFilter=[]

        this.onAddDocument = this.onAddDocument.bind(this);
        this.onDeleteDocument = this.onDeleteDocument.bind(this);
        this.onCancelAddDocument = this.onCancelAddDocument.bind(this);
        this.onValidAddDocument = this.onValidAddDocument.bind(this);
        this.onEditDocument = this.onEditDocument.bind(this);
        this.onUpdateMandatory = this.onUpdateMandatory.bind(this);
        this.onUpdateInReport = this.onUpdateInReport.bind(this);
        if(this.props.type === 'admin-training' && this.props.add){
            this.tableFields.push({id:'mandatory', title:'A accepter', type:'check', cb:this.onUpdateMandatory});
            this.tableFields.push({id:'inReport', title:'Livret ', type:'check', cb:this.onUpdateInReport});
        }

        this.onBoxFilter = this.onBoxFilter.bind(this);
        this.boxFilters={values:[], cb:this.onBoxFilter};


    }

    getSubjectsList(){
        axios.get(global.site +'/docSubject/docSubjectsList.php', {withCredentials:true})
            .then(res => {
                const subjects = res.data["subjects"];
                for(let index = 0; index < subjects.length; index++){
                    subjects[index]["name"] = subjects[index]["title"];
                }
                this.boxFilters["values"] = subjects;
                this.setState({subjects:subjects});
             })
             .catch(function(error) {
                error.handleGlobally && error.handleGlobally();
        })
    }



    onBoxFilter(event,filter){
        let checked=event.target.checked;
        let documentsList = [];
        let filterIndex = this.subjectsFilter.indexOf(filter);
        console.log(filterIndex);
        if(checked && filterIndex == -1){
            this.subjectsFilter.push(filter);
        }
        else if(!checked && filterIndex > -1){
            this.subjectsFilter.splice(filterIndex, 1);
        }
        console.log(this.subjectsFilter);

        for(filterIndex = 0; filterIndex < this.boxFilters.values.length; filterIndex++){
            if(this.boxFilters.values[filterIndex].id==filter){
                this.boxFilters.values[filterIndex]["checked"] = checked;
            }
        }
        if(this.subjectsFilter.length == 0){
            documentsList = this.state.documents;
        }
        else{
            for(let docIndex = 0; docIndex < this.state.documents.length; docIndex++){
                let doc = this.state.documents[docIndex];
                let themes = doc.subjects;
                for(let index=0; index < themes.length; index++){
                    let theme = themes[index]
                    let indexFound = this.subjectsFilter.indexOf(theme.id)
                    if(indexFound > -1){
                        documentsList.push(doc);
                        break;
                    }
                }
            }
        }
        this.setState({documentsList:documentsList});
    }

    /*Click on create button*/
    onAddDocument()
    {
        this.setState({addDocument:true});
    }
   

    updateMandatory(id, check){
        let datas = new FormData();
        datas.append('file', id);
        datas.append('mandatory', check);
        datas.append('id', this.props.userId);
        datas.append('type', this.props.type);
        axios({
            method: 'post',
            url: global.site +'/documents/updateMandatory.php',
            data: datas
        })
        .then(res =>  {
            if(res.data["success"]){
                const documents = res.data['documents']
                this.setState({documents:documents, documentsList:documents});
            }
        })
        .catch(function (error) {
            error.handleGlobally && error.handleGlobally();
        });

    }

    onUpdateMandatory(event){
        let target=event.target;
        this.updateMandatory(target.id, target.checked);
    }
    
    updateInReport(id, check){
        let datas = new FormData();
        datas.append('file', id);
        datas.append('inReport', check);
        datas.append('id', this.props.userId);
        datas.append('type', this.props.type);
        axios({
            method: 'post',
            url: global.site +'/documents/updateInReport.php',
            data: datas
        })
        .then(res =>  {
            if(res.data["success"]){
                const documents = res.data['documents']
                this.setState({documents:documents, documentsList:documents});
            }
        })
        .catch(function (error) {
            error.handleGlobally && error.handleGlobally();
        });

    }
    
    onUpdateInReport(event){
        let target=event.target;
        this.updateInReport(target.id, target.checked);
    }

    /*Valid the creation or the edition of a training*/
    onValidAddDocument(files)
    {
        let datas = new FormData();
        datas.append('type', this.props.type);
        for(let i = 0; i < files.length; i++){            
            datas.append('files[]', files[i]);
        }
        if(this.props.mandatory !== undefined){
            datas.append('mandatory', this.props.mandatory);
        }
        datas.append('id', this.props.userId);
        axios({
            method: 'post',
            url: global.site +'/documents/add.php',
            data: datas
        })
        .then(res =>  {
            if(res.data["success"]){
                const documents = res.data['documents'];
                this.setState({addDocument:false, documents:documents, documentsList:documents});
            }
        })
        .catch(function (error) {
            error.handleGlobally && error.handleGlobally();
        });
    }

    /*Cancel the creation or edition of a training*/
    onCancelAddDocument(){
        this.setState({addDocument:false});
    }
    
    onEditDocument(id){
        let documents = this.state.documents.filter(doc=>doc.id === id);
        if(documents.length > 0){
            window.open(documents[0].link, "_blank");
        }
    }


    onDeleteDocument(ids)
    {
        let datas = new FormData();
        datas.append('type', this.props.type);
        for(var index = 0; index < ids.length; index++){
            datas.append('ids[]', ids[index])
        }
        datas.append('id', this.props.userId);
        axios({
            method: 'post',
            url: global.site +'/documents/delete.php',
            data: datas
        })
        .then(res =>  {
            if(res.data["success"]){
                const documents = res.data['documents'];
                this.setState({addDocument:false, documents:documents, documentsList:documents});
            }
        })
        .catch(function (error) {
            error.handleGlobally && error.handleGlobally();
        });
    }
    
    componentDidMount()
    {
        this.getSubjectsList();
        this.getDocuments();
    }

    componentDidUpdate(props){
        if(props.userId !== this.props.userId){
            this.getDocuments();            
        }
    }

    getDocuments()
    {
        if(this.props.userId === 0){
            return;
        }
        let request = global.site +'/documents/list.php?type=' + this.props.type + '&id=' + this.props.userId;
        if(this.props.mandatory !== undefined){
            request += '&mandatory=' + this.props.mandatory;
        }
        if(this.props.financal !== undefined){
            request += '&financal=' + this.props.financal;
        }
        axios.get(request)
            .then(res => {
                const documents = res.data["documents"];
                this.setState({documents:documents, documentsList:documents});
             })
             .catch(function(error){
                error.handleGlobally && error.handleGlobally();
        })
    }
    
    render(){
        return(
            <div>
                <OlindaMuiTable
                    elements = {this.state.documentsList}
                    columns = {this.tableFields}
                    add = {this.props.add}
                    del={this.props.del}
                    force={true}
                    searchColumn = 'name'
                    onAdd = {this.onAddDocument}
                    onDelete = {this.onDeleteDocument}
                    onSelect = {this.props.onToggleCheck}
                    boxFilters={this.props.type=='teaching-training' ? this.boxFilters : undefined}
                />
            
                {this.state.addDocument ? 
                    <DocumentsForm 
                        title = "Ajouter un document"
                        type={this.props.docType}
                        directory={this.props.directory}
                        onCancel={this.onCancelAddDocument} 
                        onValid={this.onValidAddDocument}/> 
                    : null}
            </div>
        );
    
    }
}

export default LinkedDocuments

