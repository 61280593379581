import React from 'react'
import axios from 'axios'


import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import LinkedDocuments from '../documents/linkedDocuments'
import LinkedVideos from '../videos/linkedVideos'
import ModuleItem from './moduleItem'
import OlindaMuiFlatForm from '../components/flatForm'
import Grid from '@material-ui/core/Grid';

class Module extends React.Component{
    constructor(props){
        super(props);
        this.handleUpdateInfo = this.handleUpdateInfo.bind(this);
    }

    onBackToModulesList(){
        this.props.backToList();
    }

    handleUpdateInfo(){
         let datas = new FormData();
         this.props.fields.map((field)=>(
             datas.append(field.id, field.value)
         ))  
         datas.append('id', this.props.id);
         axios({
             withCredentials:true,
             method: 'post',
             url: global.site +'/training/updateModule.php',
             data: datas
         })  
         .then(res =>  {
             if(res.data["success"]){
             }
         })  
         .catch(function (error) {
             error.handleGlobally && error.handleGlobally();
         });
    }
    

    createModuleInfo(){
        return(
            <Card>
                <CardHeader
                    title="Information"
                />
                <CardContent>
                    <OlindaMuiFlatForm
                        fields={this.props.fields}
                        buttonTitle="Mettre à jour"
                        onValid={this.handleUpdateInfo}
                        readOnly={this.props.userProfile !== 1}
                    />
                </CardContent>
            </Card>           
        );
    }
   
    createDocuments(){
        return(
            <Card>
                <CardHeader
                    title="Documents liés au module"
                />
                <CardContent>
                    <LinkedDocuments
                        type='module'
                        docType='teaching-documents'
                        directory='/documents/teaching/'
                        userId= {this.props.id}
                        del={this.props.userProfile === 1}
                        add={this.props.userProfile === 1}
                    />

                </CardContent>
            </Card>
        );
    }

    createVideos(){
        return(
            <Card>
                <CardHeader
                    title="Vidéos liées au module"
                />
                <CardContent>
                    <LinkedVideos
                        type='module'
                        userId= {this.props.id}
                        del={this.props.userProfile === 1}
                        add={this.props.userProfile === 1}
                    />

                </CardContent>
            </Card>
        );
    }
    
    render(){
        return(
           <div style={{padding:'50px'}}>
               <Grid container spacing={4}>
                    <Grid item xs={12} sm={4}>
                        {this.createModuleInfo()}
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        {this.createDocuments()}
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        {this.createVideos()}
                    </Grid>
                </Grid>
                {this.props.userProfile === 1?
                    <ModuleItem
                        moduleId={this.props.id}
                    />
                    :
                    null
                }
            </div>            
        ); 
    }

}

export default Module
