import React from 'react'
import axios from 'axios'

import { withRouter, Link } from "react-router-dom";
import Typography from '@material-ui/core/Typography';
import TutoringReport from './tutoringReport'


class CertificationReportList extends React.Component{
    constructor(props){
        super(props);
        this.state={
            hide:false,
        };
        this.hide=this.hide.bind(this);
        this.show=this.show.bind(this);
    }

    hide(){
        this.setState({hide:true});
    }

    show(){
        this.setState({hide:false});
    }

    render(){
        return(
            <div>
                <Typography variant="h7">{'Evaluations Certificatives'}</Typography>
                <TutoringReport
                    reportProfile={6}
                    reportId = {this.props.match.params.reportId}
                    studentId={this.props.studentId}
                    trainingId={this.props.trainingId}
                    userProfile={this.props.userProfile}
                    studentLastName = {this.props.studentLastName}
                    studentFirstName = {this.props.studentFirstName}
                    onEdit = {this.hide}
                    onBack={this.show}
                />
            </div>
        );
    
    }



}

export default withRouter(CertificationReportList)

