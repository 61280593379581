import React from 'react'
import axios from 'axios'

import { withRouter, Link } from "react-router-dom";

import ELearningForm from './eLearningForm.js'
import OlindaMuiTable from '../components/table'
import OlindaMuiForm from '../components/form'

class ELearnings extends React.Component{
    constructor(props){
        super(props);
        this.state={
            eLearningsList:[],
            eLearnings:[],
            createELearning:false,
            eLearningId:0
        }
        this.eLearningFields = [
            {id:'id', label:'Titre', type:'select', value:'', mandatory:true},
            {id:'limitDate', label:'A faire avant', type:'date', value:'', mandatory:true}
        ];


       this.eLearningColumns = [
            {id:'title', title:'Titre'},
            {id:'limitDate', title:'A faire avant', type:'date'},
            {id:'doneDate', title:'Fait le', type:'date'},
            {id:'note', title:'Note', type:'star'}
        ];
        this.onCreateELearning = this.onCreateELearning.bind(this);
        this.onValidCreateELearning = this.onValidCreateELearning.bind(this);
        this.onCancelCreateELearning = this.onCancelCreateELearning.bind(this);
        this.onEditELearning = this.onEditELearning.bind(this);
        this.onCancelELearning = this.onCancelELearning.bind(this);
        this.onValidELearning = this.onValidELearning.bind(this);

    }

    componentDidMount()
    {
        this.getELearnings();
        this.getELearningsForStudent();
    }

    onEditELearning(id){
        this.setState({eLearningId:id});
    }
    
    onCancelELearning(id){
        this.setState({eLearningId:0});
    }

    onValidELearning(){

        let datas = new FormData();
        datas.append('eLearningId', this.state.eLearningId);
        datas.append('studentId', this.props.studentId);
        datas.append('trainingId', this.props.trainingId);
        axios({
            method: 'post',
            url: global.site +'/elearning/validELearning.php',
            data: datas
        })
        .then(res =>  {
            if(res.data["success"]){
                const eLearnings = res.data["eLearnings"];
                this.setState({eLearningsList:eLearnings, eLearningId:0});
            }
        })
        .catch(function (error) {
            error.handleGlobally && error.handleGlobally();
        });
        
        this.setState({eLearningId:0});
    }
    
    getELearnings(){
        let request = global.site + '/elearning/list.php';
        axios.get(request)
            .then(res => {
                const eLearnings = res.data["eLearnings"];
                if(eLearnings.length > 0){
                    this.eLearningFields[0].value=eLearnings[0].id;
                }
                this.setState({eLearnings:eLearnings, eLearningId:0});
             })
             .catch(function(error) {
                error.handleGlobally && error.handleGlobally();
        }) 
    }

    getELearningsForStudent(){
        let request = global.site + '/elearning/listForStudent.php?studentId='+this.props.studentId + '&trainingId='+this.props.trainingId;
        axios.get(request)
            .then(res => {
                const eLearnings = res.data["eLearnings"];
                this.setState({eLearningsList:eLearnings, eLearningId:0});
             })
             .catch(function(error) {
                error.handleGlobally && error.handleGlobally();
        }) 
    }
    
    onValidCreateELearning(fields){
        let datas = new FormData();
        fields.map((field)=>(
            datas.append(field.id, field.value)
        ))
        datas.append('studentId', this.props.studentId);
        datas.append('trainingId', this.props.trainingId);
        axios({
            method: 'post',
            url: global.site +'/elearning/createForStudent.php',
            data: datas
        })
        .then(res =>  {
            if(res.data["success"]){
                let eLearnings = res.data["eLearnings"];
                this.setState({createELearning:false, eLearningsList:eLearnings});
            }
        })
        .catch(function (error) {
            error.handleGlobally && error.handleGlobally();
        });        
    }

    onCreateELearning(){
        this.setState({createELearning:true});
    }

    onCancelCreateELearning(){
        this.setState({createELearning:false});
    }
    
    render(){
        return(
            <div>
                {this.state.createELearning ?
                    <OlindaMuiForm
                        title="Evaluation En Ligne"
                        fields={this.eLearningFields}
                        onValid={this.onValidCreateELearning}
                        onCancel={this.onCancelCreateELearning}
                        id={this.state.eLearnings}
                    />
                    :null
                }
                {this.state.eLearningId !== 0?
                    <ELearningForm
                        eLearningId={this.state.eLearningId}
                        onCancel={this.onCancelELearning}
                        onValid={this.onValidELearning}
                        readOnly = {this.props.userProfile !== 3}
                    />
                        :
                    null
                }
                <OlindaMuiTable
                    elements={this.state.eLearningsList}
                    columns={this.eLearningColumns}
                    del={this.props.userProfile === 1}
                    force={false}
                    add={this.props.userProfile === 1 || (this.props.userProfile === this.props.reportProfile && this.props.userProfile !== 4)}
                    readOnly={this.props.userProfile !== 1 && this.props.userProfile !== this.props.reportProfile}
                    onAdd={this.onCreateELearning}
                    onEdit={this.onEditELearning}
                />
                
                
            </div>
        );
    
    }

}

export default withRouter(ELearnings)

