import React from 'react'
import axios from 'axios'


import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography';

import WeeklyItem from '../weeklyReport/weeklyItem.js'
import MonthlyFormItem from '../monthlyForm/monthlyFormItem.js'
import FinancalItem from '../financal/financalItem.js'
import DocSubject from '../docSubject/docSubject.js'


class Settings extends React.Component{
    constructor(props){
        super(props);

    }

    onInitializeGoogleApi(){
        let datas = new FormData();
        axios({
            method: 'post',
            url: global.site +'/google-api/initialize.php',
            data: datas
        })
        .then(res =>  {
            if(res.data["url"]){
                window.open(res.data["url"], "_blank");
            }
        })
        .catch(function (error) {
            error.handleGlobally && error.handleGlobally();
        });
    }

    render(){
        return(
            <div>
                <Typography variant="h5">Gestion Google</Typography>
                <Button contained="primary" onClick={()=>this.onInitializeGoogleApi()}>
                    Autoriser
                </Button>
                <WeeklyItem/>
                <MonthlyFormItem/>
                <FinancalItem/>
                <DocSubject/>
            </div>
        );
    
    }



}

export default Settings

