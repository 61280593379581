import React from 'react'
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

class OlindaMuiConfirm extends React.Component{
    constructor(props){
        super(props);
        this.onValid = this.onValid.bind(this);
        this.onCancel = this.onCancel.bind(this);
    }

    onCancel() {
        this.props.onCancel();
    };

    onValid() {
        this.props.onValid(this.props.id);
    };

    render(){
        return (
        <div>
        <Dialog fulWidth fullHeight open={true} onClose={()=>this.onCancel()} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">{this.props.title}</DialogTitle>
            <DialogContent>
                {this.props.text}
            </DialogContent>
            <DialogActions>
            <Button onClick={()=>this.onCancel()} color="primary">
                Annuler
            </Button>
            <Button onClick={()=>this.onValid()} color="primary">
                Valider
            </Button>
            </DialogActions>
        </Dialog>
        </div>
  );
  }
}


export default OlindaMuiConfirm;
