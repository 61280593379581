import React from 'react'
import axios from 'axios'

import Typography from '@material-ui/core/Typography';

import OlindaMuiTable from '../components/table'
import OlindaMuiForm from '../components/form'

class MonthlyFormItem extends React.Component{
    constructor(props){
        super(props);
        this.state={
            items:[],
            itemId:0,
            createItem:false
        }

        this.createItem = this.createItem.bind(this);
        this.deleteItem = this.deleteItem.bind(this);
        this.validCreateItem = this.validCreateItem.bind(this);
        this.cancelCreateItem = this.cancelCreateItem.bind(this);
        this.editItem = this.editItem.bind(this);

        this.itemFields=[{id:'title', label:'Titre', type:'input', value:'', mandatory:true}];
        this.itemColumns=[{id:'title', title:'Titre'}];
    }


    componentDidMount(){
        this.getItemsList();
    }

    createItem(){
        this.setState({createItem:true, itemId:0});
    }
    
    cancelCreateItem(){
        this.setState({createItem:false, itemId:0});
    }

    editItem(id){
        this.getItem(id);
    }

    validCreateItem(fields){
         let datas = new FormData();
         let url = 'createMonthlyFormItem.php'
         fields.map((field)=>(
             datas.append(field.id, field.value)
         ))
         if(this.state.itemId !== 0){
             datas.append("id", this.state.itemId);
             url='updateMonthlyFormItem.php'
         }
         axios({
             method: 'post',
             url: global.site +'/monthlyForm/' + url,
             data: datas
         })
         .then(res =>  {
             if(res.data["success"]){
                 let items = res.data["items"]
                 this.setState({createItem:false, items:items, itemId:0});
             }
         })
         .catch(function (error) {
             error.handleGlobally && error.handleGlobally();
         });
        
    }

    deleteItem(ids){
        let datas = new FormData();
        for(let i = 0; i < ids.length; i++){
            datas.append('ids[]', ids[i]);
        }
        axios({
            method: 'post',
            url: global.site +'/monthlyForm/deleteMonthlyFormItem.php',
            data: datas
        })
        .then(res =>  {
            if(res.data["success"]){
                let items = res.data["items"]
                this.setState({items:items});
            }
        })
        .catch(function (error) {
            error.handleGlobally && error.handleGlobally();
        });    
    }

    getItem(id){
        axios.get(global.site +'/monthlyForm/getMonthlyFormItem.php?id='+ id, {withCredentials:true})
            .then(res => {
                if(res.data["success"]){
                    this.itemFields[0].value=res.data["title"]
                    this.setState({createItem:true, itemId:id})
                }
             })
             .catch(function(error) {
                error.handleGlobally && error.handleGlobally();
        })
    
    }

    getItemsList(){
        axios.get(global.site +'/monthlyForm/monthlyFormItemsList.php', {withCredentials:true})
            .then(res => {
                const items = res.data["items"];
                this.setState({items:items, itemId:0});
             })
             .catch(function(error) {
                error.handleGlobally && error.handleGlobally();
        })
    }
    
    render(){
        return(
           <div style={{padding:'50px'}}>
               <Typography variant="h6">{"Items des fiches navettes"}</Typography>
               <OlindaMuiTable
                    elements={this.state.items}
                    columns = {this.itemColumns}
                    del={true}
                    add={true}
                    onAdd={this.createItem}
                    onDelete={this.deleteItem}
                    onEdit={this.editItem}
                />
               {this.state.createItem ?
                <OlindaMuiForm
                    fields={this.itemFields}
                    title={"Item de fiche navette"}
                    modify = {this.state.itemId !== 0}
                    onCancel={this.cancelCreateItem}
                    onValid={this.validCreateItem}
                />
                :
                null
               }
           </div>            
        ); 
    }

}

export default MonthlyFormItem
