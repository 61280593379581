import React from 'react'
import axios from 'axios'

import Grid from '@material-ui/core/Grid';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Card from '@material-ui/core/Card';

import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit'
import InfoMgmt from './infoMgmt.js'
import DocumentsMgmt from './documentsMgmt.js'
import OlindaMuiForm from '../components/form.js'
import FormationInfo from './formationInfo.js'

class Profile extends React.Component{
    constructor(props){
        super(props);


        this.state={
            user:this.props.user,
            modify:false
        };

        this.fields=[
            {id:'firstName', label:'Prénom', type:'input', value:this.props.user.firstName, mandatory:true},
            {id:'lastName', label:'Nom', type:'input', value:this.props.user.lastName, mandatory:true},
            {id:'mail', label:'Mail', type:'input', value:this.props.user.mail, mandatory:true},
            {id:'phone', label:'Téléphone', type:'input', value:this.props.user.phone}
        ]

        if(this.props.user.profile === 2){
            this.fields.push({id:'organization', label:'Structure', type:'input', value:this.props.user.organization});
        }
        this.onModify = this.onModify.bind(this)
        this.onCancel = this.onCancel.bind(this)
        this.handleUpdateContact = this.handleUpdateContact.bind(this);
    }

    onModify(){
        this.setState({modify:true});
    }

    onCancel(){
        this.setState({modify:false});
    }

    updateContact(fields){
        let datas = new FormData();
        fields.map((field)=>(
            datas.append(field.id, field.value)
        ))
        datas.append('id', this.props.user.id);
        datas.append('profile', this.props.user.profile);
        axios({
            withCredentials:true,
            method: 'post',
            url: global.site +'/user/updateContact.php',
            data: datas
        })
        .then(res =>  {
            if(res.data["success"]){
                let organization = null;
                if(this.props.profile === 2){
                    this.fields[4].value = res.data["organization"];
                }
                this.fields[0].value = res.data['firstName'];
                this.fields[1].value = res.data['lastName'];
                this.fields[2].value = res.data['mail'];
                this.fields[3].value = res.data['phone'];
                this.setState({modify:false, user:res.data});
            }
        })
        .catch(function (error) {
            error.handleGlobally && error.handleGlobally();
        });
    }
    
    handleUpdateContact(){
        this.updateContact(this.fields);
    }



    render(){
        let documentsDir = '';
        if(this.props.user.profile === 1){
            documentsDir = '/teacher/teacher-';
        }
        else if(this.props.user.profile === 2){
            documentsDir = '/tutor/tutor-';
        }
        else{
            documentsDir = 'student/student-';
        }
        if(this.state.user == undefined){
            return(null);
        }
        documentsDir += this.state.user.id;
        let element = this.state.user;
        return(
            <div>
            <Card style={{marginTop:'20px',padding:'10px'}}>
                <CardHeader
                    title={element.firstName + ' ' + element.lastName} 
                    avatar={element.avatar !== undefined && element.avatar.length > 0 && element.avatar[0].link !== undefined?
                        <Avatar
                            src={element.avatar[0].link}
                        />
                        :
                        <Avatar>
                            {element.firstName[0] + element.lastName[0]}
                        </Avatar>
                    }
                    action={this.props.readOnly != undefined && !this.props.readOnly ? 
                            <IconButton>
                                <EditIcon
                                    onClick={this.onModify}
                                /> 
                            </IconButton>
                            : 
                            null
                            }
                    subheader={<div>
                                   <Typography>{element['phone']}</Typography>
                                   <Typography>{element["mail"]}</Typography>
                                   <Typography>{element["organization"]}</Typography>
                                </div>
                             }
                />
                <CardContent>
                    <Typography variant="h5">{'Mes Documents'}</Typography>
                    <DocumentsMgmt 
                        userId={element.id}
                        readOnly={this.props.readOnly}
                        type={this.props.type}
                        directory={documentsDir}
                     />
                </CardContent>
            </Card>
            {this.state.modify?
                <OlindaMuiForm
                    fields = {this.fields}
                    onCancel={this.onCancel}
                    onValid={this.handleUpdateContact}
                />
                :
                null
            }

            </div>
        ); 
    }

}

export default Profile
