import React from 'react'
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

class OlindaMuiInfo extends React.Component{
    constructor(props){
        super(props);
        this.onOk = this.onOk.bind(this);
    }

    onOk() {
        this.props.onOk();
    };

    render(){
        return (
        <div>
        <Dialog fulWidth fullHeight open={true} onClose={()=>this.onCancel()} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">{this.props.title}</DialogTitle>
            <DialogContent>
                <div dangerouslySetInnerHTML={{__html:this.props.text}}/>
            </DialogContent>
            <DialogActions>
            <Button onClick={()=>this.onOk()} color="primary">
                OK
            </Button>
            </DialogActions>
        </Dialog>
        </div>
  );
  }
}


export default OlindaMuiInfo;
