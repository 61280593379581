import React from 'react'


import { withRouter } from "react-router-dom";
import User from '../user/user.js'

class Teacher extends React.Component{
    constructor(props){
        super(props);
        this.state={
            teacherId:0
        }
    }

    
    render(){

        return(
            <User
                profile={1}
                userProfile={this.props.userProfile}
                userId = {this.props.match.params.teacherId}
            />
        );
    
    }

}

export default withRouter(Teacher)
