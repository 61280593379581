import React from 'react'
import Grid from '@material-ui/core/Grid';
import {Link} from "react-router-dom"
import Breadcrumbs from '@material-ui/core/Breadcrumbs'
import { withStyles } from '@material-ui/core/styles';
import axios from 'axios'
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import ScheduleIcon from '@material-ui/icons/Schedule';
import SettingsIcon from '@material-ui/icons/Settings';
import PersonIcon from '@material-ui/icons/Person';
import HomeIcon from '@material-ui/icons/Home';
import Typography from '@material-ui/core/Typography';
import OlindaMuiList from '../components/list'
import OlindaMuiTable from '../components/table'
import OlindaMuiForm from '../components/form'
import OlindaMuiScheduler from '../components/scheduler/scheduler'
import OlindaMuiConfirm from '../components/confirm.js'
import Student from '../student/student.js'
import LinkedDocuments from '../documents/linkedDocuments'
import Module from './module.js'
import OlindaMuiFlatForm from '../components/flatForm.js'
import OlindaMuiInfo from '../components/info.js'
import Tutoring from '../tutoring/tutoring.js'
import Button from '@material-ui/core/Button';
import TrainingMenu from './trainingMenu.js'

import qualiopi_blanc from '../logos/qualiopi_b.png'
import logo_fae from '../logos/fae.png' 
import cpne from '../logos/cpne.png'
import ministere from '../logos/ministere.jpg'
import sport_occitanie from '../logos/sport_occitanie.jpg'
import ffe from '../logos/ffe.jpg'
import hippothese from '../logos/hippothese.jpg'
import ouspins from '../logos/ouspins.png'


const useStyles = theme => ({
  root: {
    flexGrow: 1,
  },
  title: {
    background:'red',
    flex: 1,
  },
});

function TabPanel(props){
    const { children, value, index} = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-force-tabpanel-${index}`}
            aria-labelledby={`scrollable-force-tab-${index}`}
        >
            {value === index && (
                children
            )}
        </div>
    );
}

class TrainingBody extends React.Component{

    constructor(props){
        super(props);

        this.state = {
            index:'info',
            documents:[],
            events:[],
            date: new Date(),
            startDate:'',
            endDate:'',
            modules:[],
            calendars:[],
            teachers:[],
            examinatorsList:[],
            studentsList:[],
            holidays:[],
            tutoringReportsList:[],
            trainingReportsList:[],
            periodicTrainingReportsList:[],
            periodicTutoringReportsList:[],
            examsList:[],
            eLearningsList:[],
            moduleId:0,
            moduleName:'',
            createModule:false,
            holidayId:0,
            createHoliday:false,
            createReports:false,
            createTutoringReports:false,
            createTrainingReports:false,
            createCertificationReports:false,
            createPeriodicTrainingReports:false,
            createPeriodicTutoringReports:false,
            createExam:false,
            createELearning:false,
            createMonthlyForm:false,
            examId:0,
            fillReport:false,
            fillTutoringReport:false,
            fillTrainingReport:false,
            fillCertificate:false,
            fillTutoringReportProfile:0,
            showInfo:false,
            infoText:'',
            modifyTraining:false,
            training:null
        }
        
        this.trainingEditFields = [
            {id:'name', label:'Nom', type:'input', value:'', mandatory:true},
            {id:'startDate', label:'Début', type:'date', value:'', mandatory:true},
            {id:'endDate', label:'Fin', type:'date', value:'', mandatory:true},
            {id:'mail', label:'Contact', type:'mail', value:'',mandatory:true},
            {id:'calendarId', label:'Calendrier Google', type:'select', value:''},
        ]
        this.trainingFields = [
            {id:'name', label:'Nom', type:'input', value:''},
            {id:'startDate', label:'Début', type:'date', value:''},
            {id:'endDate', label:'Fin', type:'date', value:''},
            {id:'mail', label:'Contact', type:'input', value:''},
            {id:'calendar', label:'Calendrier', type:'input', value:''}

        ];

        this.holidayFields = [
            {id:'name', label:'Nom', type:'input', value:'', mandatory:true},
            {id:'start', label:'Début', type:'date', value:'', mandatory:true},
            {id:'end', label:'Fin', type:'date', value:'', mandatory:true}
        ];

        this.holidayColumns = [
            {id:'name', title:'Nom'},
            {id:'start', title:'Début'},
            {id:'end', title:'Fin'}
        ];

        this.moduleColumns = [
            {id:'name', title:'Nom'},
            {id:'startDate', title:'Début'},
            {id:'endDate', title:'Fin'}
        ];

        this.examColumns = [
            {id:'name', title:'Nom'},
            {id:'date', title:'Date', type:'date'}
        ];

        this.moduleFields = [
              {id:'name', label:'Nom', type:'input', value:''},
              {id:'description', label:'Description', type:'input', value:''},
              {id:'testDate', label:'Date d\'examen', type:'date', value:''},
              {id:'color', label:'Couleur', type:'input', value:''}
          ];
       
        this.reportFields=[
            {id:'date', label:'Date', type:'date', value:''}
        ];
        
        this.certificateFields=[
            {id:'startDate', label:'Date de Début', type:'date', value:''},
            {id:'endDate', label:'Date de Fin', type:'date', value:''}
        ];
        
        this.trainingReportFields=[
            {id:'date', label:'Date', type:'date', value:'', mandatory:true},
            {id:'id', label: 'Evaluation', type:'select', value:'', mandatory:true}
        ]
        
        this.tutoringReportFields=[
            {id:'date', label:'Date', type:'date', value:'', mandatory:true},
            {id:'id', label: 'Evaluation', type:'select', value:'', mandatory:true}
        ]
        
        this.certificationReportFields=[
            {id:'date', label:'Date', type:'date', value:'', mandatory:true},
            {id:'id', label: 'Evaluation', type:'select', value:'', mandatory:true}
        ]
        
        this.periodicTrainingReportFields=[
            {id:'date', label:'Date', type:'date', value:'', mandatory:true},
            {id:'id', label: 'Evaluation', type:'select', value:'', mandatory:true}
        ]
        
        this.periodicTutoringReportFields=[
            {id:'date', label:'Date', type:'date', value:'', mandatory:true},
            {id:'id', label: 'Evaluation', type:'select', value:'', mandatory:true}
        ]

        this.eLearningFields = [
            {id:'eLearningId', label:'Titre', type:'select', value:'', mandatory:true},
            {id:'limitDate', label:'A faire avant', type:'date', value:'', mandatory:true}
        ];
        
        this.schedulerFileFields = [
            {id:'schedulefile', label:'Fichier', type:'file', value:'', mandatory:true},
        ];

        this.examFields = [
            {id:'name', label:'Nom', type:'input', value:''},
            {id:'date', label:'Date', type:'date', value:''},
            {id:'id', label: 'Evaluation', type:'select', value:'', mandatory:true},
            {id:'examinators', label: 'Examinateurs', type:'check-box', values:[]},
            {id:'students', label: 'Stagiaires', type:'check-box', values:[]}
        ];

        this.formLogos={"bp":[{logo:ministere, width:178, height:100}],
                        "cqp":[{logo:cpne, width:294, height:100}],
                        "ae":[{logo:ffe, width:123, height:100},
                             {logo:hippothese, width:157, height:100}],
                        "ae-ouspins":[{logo:ffe, width:123, height:100},
                             {logo:ouspins, width:250, height:100}]
                        }




        this.onCreateModule = this.onCreateModule.bind(this);
        this.onEditModule = this.onEditModule.bind(this);
        this.onValidCreateModule = this.onValidCreateModule.bind(this);
        this.onCancelCreateModule = this.onCancelCreateModule.bind(this);
        this.onDeleteModule = this.onDeleteModule.bind(this);
        this.onModifyTraining = this.onModifyTraining.bind(this);
        this.onValidModifyTraining = this.onValidModifyTraining.bind(this);
        this.onCancelModifyTraining = this.onCancelModifyTraining.bind(this);
        
        this.handleUpdateSchedulerFile = this.handleUpdateSchedulerFile.bind(this);
        this.handleRemoveSchedulerFile = this.handleRemoveSchedulerFile.bind(this);

        this.getModule = this.getModule.bind(this);
        this.onCreateHoliday = this.onCreateHoliday.bind(this);
        this.onEditHoliday = this.onEditHoliday.bind(this);
        this.onDeleteHoliday = this.onDeleteHoliday.bind(this);
        this.onValidCreateHoliday = this.onValidCreateHoliday.bind(this);
        this.onCancelCreateHoliday = this.onCancelCreateHoliday.bind(this);

        this.onCreateExam = this.onCreateExam.bind(this);
        this.onValidCreateExam = this.onValidCreateExam.bind(this);
        this.onCancelCreateExam = this.onCancelCreateExam.bind(this);
        this.onEditExam = this.onEditExam.bind(this);
        this.getExam = this.getExam.bind(this);

        this.onCreateELearning = this.onCreateELearning.bind(this);
        this.onValidCreateELearning = this.onValidCreateELearning.bind(this);
        this.onCancelCreateELearning = this.onCancelCreateELearning.bind(this);
        this.getELearnings = this.getELearnings.bind(this)

        this.onCreateMonthlyForm = this.onCreateMonthlyForm.bind(this);
        this.onValidCreateMonthlyForm = this.onValidCreateMonthlyForm.bind(this);
        this.onCancelCreateMonthlyForm = this.onCancelCreateMonthlyForm.bind(this);
        
        this.onEditCourse = this.onEditCourse.bind(this);
        this.onOkInfo = this.onOkInfo.bind(this);
        this.onDateSelected = this.onDateSelected.bind(this);
        this.getEvents = this.getEvents.bind(this);

        this.onValidGenerateReports = this.onValidGenerateReports.bind(this);
        this.onCancelGenerateReports = this.onCancelGenerateReports.bind(this);
        
        this.onValidGenerateTutoringReports = this.onValidGenerateTutoringReports.bind(this);
        this.onCancelGenerateTutoringReports = this.onCancelGenerateTutoringReports.bind(this);
        
        this.onValidGenerateTrainingReports = this.onValidGenerateTrainingReports.bind(this);
        this.onCancelGenerateTrainingReports = this.onCancelGenerateTrainingReports.bind(this);
        
        this.onValidFillTutoringReports = this.onValidFillTutoringReports.bind(this);
        this.onCancelFillTutoringReports = this.onCancelFillTutoringReports.bind(this);
        
        this.onValidGenerateCertificationReports = this.onValidGenerateCertificationReports.bind(this);
        this.onCancelGenerateCertificationReports = this.onCancelGenerateCertificationReports.bind(this);
        
        this.onValidFillReports = this.onValidFillReports.bind(this);
        this.onCancelFillReports = this.onCancelFillReports.bind(this);
        
        this.onValidCertificates = this.onValidCertificates.bind(this);
        this.onCancelCertificates = this.onCancelCertificates.bind(this);
        
        this.onValidGeneratePeriodicTrainingReports = this.onValidGeneratePeriodicTrainingReports.bind(this);
        this.onCancelGeneratePeriodicTrainingReports = this.onCancelGeneratePeriodicTrainingReports.bind(this);
        
        this.onValidGeneratePeriodicTutoringReports = this.onValidGeneratePeriodicTutoringReports.bind(this);
        this.onCancelGeneratePeriodicTutoringReports = this.onCancelGeneratePeriodicTutoringReports.bind(this);

        this.onTabSelected = this.onTabSelected.bind(this);
    }

    

    componentDidUpdate(props){
        if(props.trainingId !== this.props.trainingId && this.props.trainingId > 0 && this.props.userProfile !== 5){
            this.getModulesList();
            this.getHolidays();
            this.getExams();
            this.getExaminatorsList();
            this.getStudentsList();
            this.getEvents(this.state.startDate,this.state.endDate);
            this.getTraining(this.props.trainingId);
        }
        else{
            this.state.moduleId=0;
        }
    }
    componentDidMount(){
        if(this.props.userProfile !== 5){
            this.getModulesList();
            this.getHolidays();
            this.getExams();
            this.getEvents(this.state.startDate,this.state.endDate);
            this.getTutoringReports(1);
            this.getTutoringReports(2);
            this.getTutoringReports(3);
            this.getTutoringReports(4);
            this.getTutoringReports(5);
            this.getExaminatorsList();
            this.getStudentsList();
            this.getELearnings();
            this.getTraining(this.props.trainingId);
        }
    }

    onModifyTraining(){
        this.setState({modifyTraining:true});
    }

    onCancelModifyTraining(){
        this.setState({modifyTraining:false})
    }

    getTraining(){
         axios.get(global.site +'/training/get.php?id=' + this.props.trainingId, {withCredentials:true})
            .then(res => {

                if(res.data["success"]){

                    let calendars = this.props.calendars;
                    let calendar = undefined;

                     for(let index = 0; index < calendars.length;index++){
                        if(calendars[index].id == res.data.calendarId){
                            calendar = calendars[index].name;
                            break;
                        }
                     }

                    this.trainingEditFields = [
                        {id:'name', label:'Nom', type:'input', value:res.data.name, mandatory:true},
                        {id:'startDate', label:'Début', type:'date', value:res.data.startDate, mandatory:true},
                        {id:'endDate', label:'Fin', type:'date', value:res.data.endDate, mandatory:true},
                        {id:'mail', label:'Contact', type:'mail', value:res.data.mail,mandatory:true},
                        {id:'calendarId', label:'Calendrier Google', type:'select', value:res.data.calendarId}
                    ];
                    this.trainingFields = [
                        {id:'name', label:'Nom', type:'input', value:res.data.name},
                        {id:'startDate', label:'Début', type:'date', value:res.data.startDate},
                        {id:'endDate', label:'Fin', type:'date', value:res.data.endDate},
                        {id:'mail', label:'Contact', type:'input', value:res.data.mail},
                        {id:'calendarId', label:'Calendrier Google', type:'input', value:calendar},
                    ];
                    this.setState({training:res.data});
                }
             })
             .catch(function(error) {
                error.handleGlobally && error.handleGlobally();
        })

    }

    onEditCourse(id){
        for(let index = 0; index < this.state.events.length; index++){
            let currentEvent = this.state.events[index];
            if(currentEvent.id === id){
                this.setState({showInfo:true, infoText:currentEvent.description});        
            }
        }
    }
   
    onCreateExam(){
        this.examFields[0].value = '';
        this.examFields[1].value = '';
        this.examFields[2].value = this.state.trainingReportsList[0].id;
        this.examFields[3].values = [];
        this.setState({createExam:true});
    }

    onEditExam(id){
        this.getExam(id);
    }

    getExam(id){
       axios.get(global.site +'/exam/get.php?id='+ id)
            .then(res => {
                if(res.data["success"]){
                    let exam = res.data["exam"];
                    this.examFields[0].value=exam["name"]
                    this.examFields[1].value=exam["date"]
                    this.examFields[2].value=exam["testId"]
                    if(exam["testId"] === null){                        
                        this.examFields[2].value=this.state.trainingReportsList[0].id;
                    }
                    this.examFields[3].values=exam["examinators"]
                    this.examFields[4].values=exam["students"]
                    this.setState({createExam:true, examId:id});
                }
             })
             .catch(function(error) {
                error.handleGlobally && error.handleGlobally();
        })

    }

    onValidCreateExam(fields){
        let datas = new FormData();
        datas.append('trainingId', this.props.trainingId);
        datas.append('name', fields[0].value);
        datas.append('date', fields[1].value);
        datas.append('testId', fields[2].value);
        if(this.state.examId !== 0){
            datas.append('examId', this.state.examId);
        }
        let examinators = fields[3].values;
        for(let index = 0; index < examinators.length; index++){
            datas.append('examinators[]', examinators[index]);
        }
        let students = fields[4].values;
        for(let index = 0; index < students.length; index++){
            datas.append('students[]', students[index]);
        }
        axios({
             withCredentials:true,
             method: 'post',
             url: global.site +'/exam/create.php',
             data: datas
        })
        .then(res =>  {
            if(res.data["success"]){
                this.setState({createExam:false, examsList:res.data["exams"], examId:0});
            }
        })
        .catch(function (error) {
            error.handleGlobally && error.handleGlobally();
        });

    }


    onCancelCreateExam(){
        this.setState({createExam:false, examId:0});
    }

    getELearnings(){
        let request = global.site + '/elearning/list.php';
        axios.get(request)
            .then(res => {
                const eLearnings = res.data["eLearnings"];
                if(eLearnings.length > 0){
                    this.eLearningFields[0].value=eLearnings[0].id;
                }
                this.setState({eLearningsList:eLearnings});
             })
             .catch(function(error) {
                error.handleGlobally && error.handleGlobally();
        })    
    }

    onCreateELearning(){
        this.setState({createELearning:true});
    }

    onValidCreateELearning(fields){
        let datas = new FormData();
        fields.map((field)=>(
            datas.append(field.id, field.value)
        ))
        datas.append('trainingId', this.props.trainingId);
        axios({
            method: 'post',
            url: global.site +'/elearning/createForTraining.php',
            data: datas
        })
        .then(res =>  {
            if(res.data["success"]){
                this.setState({createELearning:false});
            }
        })
        .catch(function (error) {
            error.handleGlobally && error.handleGlobally();
        });

    }

    onCancelCreateELearning(){
        this.setState({createELearning:false});
    }

    onCreateMonthlyForm(){
        this.setState({createMonthlyForm:true});
    }

    onValidCreateMonthlyForm(fields){
        let datas = new FormData();
        fields.map((field)=>(
            datas.append(field.id, field.value)
        ))
        datas.append('trainingId', this.props.trainingId);
        axios({
            method: 'post',
            url: global.site +'/monthlyForm/createForTraining.php',
            data: datas
        })
        .then(res =>  {
            if(res.data["success"]){
                this.setState({createMonthlyForm:false});
            }
        })
        .catch(function (error) {
            error.handleGlobally && error.handleGlobally();
        });

    }

    onCancelCreateMonthlyForm(){
        this.setState({createMonthlyForm:false});
    }


    onValidFillTutoringReports(){
        this.onCheckTutoringReports();
    }

    onCancelFillTutoringReports(){
        this.setState({fillTutoringReport:false})
    }
    
    onValidFillReports(){
        this.onSignReports();
    }

    onCancelFillReports(){
        this.setState({fillReport:false})
    }

    onValidCertificates(fields){
         let datas = new FormData();
         datas.append('trainingId', this.props.trainingId);
         datas.append('startDate', fields[0].value);
         datas.append('endDate', fields[1].value);
         axios({
             withCredentials:true,
             method: 'post',
             url: global.site +'/training/certificate.php',
             data: datas
         })
         .then(res =>  {
             if(res.data["success"]){
                 this.setState({fillCertificate:false});
             }
         })
         .catch(function (error) {
             error.handleGlobally && error.handleGlobally();
         });
    
    }

    onCancelCertificates(){
        this.setState({fillCertificate:false})
    }


    onGenerateTutoringReports(){
        this.setState({createTutoringReports:true});
    }
   
    onGeneratePeriodicTrainingReports(){
        this.setState({createPeriodicTrainingReports:true});
    }
    
    onGeneratePeriodicTutoringReports(){
        this.setState({createPeriodicTutoringReports:true});
    }

    onGenerateTrainingReports(){
        this.setState({createTrainingReports:true});
    }
    
    onGenerateCertificationReports(){
        this.setState({createCertificationReports:true});
    }
    
    onGenerateReports(){
        this.setState({createReports:true});
    }
    
    onFillCertificate(){
        this.setState({fillCertificate:true});
    }
    
    onCancelGenerateReports(){
        this.setState({createReports:false});
    }

    onValidGenerateReports(fields){
         let datas = new FormData();
         datas.append('trainingId', this.props.trainingId);
         datas.append('date', fields[0].value);
         axios({
             withCredentials:true,
             method: 'post',
             url: global.site +'/report/generate.php',
             data: datas
         })
         .then(res =>  {
             if(res.data["success"]){
                 this.setState({createReports:false});
             }
         })
         .catch(function (error) {
             error.handleGlobally && error.handleGlobally();
         });
    }
    
    onCancelGenerateTutoringReports(){
        this.setState({createTutoringReports:false});
    }
    
    onValidGenerateTutoringReports(fields){
         let datas = new FormData();
         datas.append('trainingId', this.props.trainingId);
         datas.append('date', fields[0].value);
         datas.append('profile', 2);
         datas.append('id', fields[1].value);
         axios({
             withCredentials:true,
             method: 'post',
             url: global.site +'/tutoringReport/generate.php',
             data: datas
         })
         .then(res =>  {
             if(res.data["success"]){
                 this.setState({createTutoringReports:false});
             }
         })
         .catch(function (error) {
             error.handleGlobally && error.handleGlobally();
         });
    }
    
    onCancelGenerateTrainingReports(){
        this.setState({createTrainingReports:false});
    }
    
    onValidGenerateTrainingReports(fields){
         let datas = new FormData();
         datas.append('trainingId', this.props.trainingId);
         datas.append('date', fields[0].value);
         datas.append('profile', 1);
         datas.append('id', fields[1].value);
         axios({
             withCredentials:true,
             method: 'post',
             url: global.site +'/tutoringReport/generate.php',
             data: datas
         })
         .then(res =>  {
             if(res.data["success"]){
                 this.setState({createTrainingReports:false});
             }
         })
         .catch(function (error) {
             error.handleGlobally && error.handleGlobally();
         });
    }

    onCancelGeneratePeriodicTrainingReports(){
        this.setState({createPeriodicTrainingReports:false});
    }

    onValidGeneratePeriodicTrainingReports(fields){
         let datas = new FormData();
         datas.append('trainingId', this.props.trainingId);
         datas.append('date', fields[0].value);
         datas.append('profile', 4);
         datas.append('id', fields[1].value);
         axios({
             withCredentials:true,
             method: 'post',
             url: global.site +'/tutoringReport/generate.php',
             data: datas
         })
         .then(res =>  {
             if(res.data["success"]){
                 this.setState({createPeriodicTrainingReports:false});
             }
         })
         .catch(function (error) {
             error.handleGlobally && error.handleGlobally();
         });
    }
  
    onCancelGeneratePeriodicTutoringReports(){
        this.setState({createPeriodicTutoringReports:false});
    }

    onValidGeneratePeriodicTutoringReports(fields){
         let datas = new FormData();
         datas.append('trainingId', this.props.trainingId);
         datas.append('date', fields[0].value);
         datas.append('profile', 5);
         datas.append('id', fields[1].value);
         axios({
             withCredentials:true,
             method: 'post',
             url: global.site +'/tutoringReport/generate.php',
             data: datas
         })
         .then(res =>  {
             if(res.data["success"]){
                 this.setState({createPeriodicTutoringReports:false});
             }
         })
         .catch(function (error) {
             error.handleGlobally && error.handleGlobally();
         });
    }


    onCancelGenerateCertificationReports(){
        this.setState({createCertificationReports:false});
    }

    onValidGenerateCertificationReports(fields){
         let datas = new FormData();
         datas.append('trainingId', this.props.trainingId);
         datas.append('date', fields[0].value);
         datas.append('profile', 3);
         datas.append('id', fields[1].value);
         axios({
             withCredentials:true,
             method: 'post',
             url: global.site +'/tutoringReport/generate.php',
             data: datas
         })
         .then(res =>  {
             if(res.data["success"]){
                 this.setState({createCertificationReports:false});
             }
         })
         .catch(function (error) {
             error.handleGlobally && error.handleGlobally();
         });
    }


    onGenerateWeeklyReports(){
         let datas = new FormData();
         datas.append('trainingId', this.props.trainingId);
         axios({
             withCredentials:true,
             method: 'post',
             url: global.site +'/weeklyReport/generate.php',
             data: datas
         })
         .then(res =>  {
             if(res.data["success"]){
             }
         })
         .catch(function (error) {
             error.handleGlobally && error.handleGlobally();
         });
    }
    
    onSignReports(){
         let datas = new FormData();
         datas.append('trainingId', this.props.trainingId);
         axios({
             withCredentials:true,
             method: 'post',
             url: global.site +'/report/checkSign.php',
             data: datas
         })
         .then(res =>  {
             if(res.data["success"]){
                 this.setState({fillReport:false});
             }
         })
         .catch(function (error) {
             error.handleGlobally && error.handleGlobally();
         });
    }
    
    onCheckReports(){
         let datas = new FormData();
         datas.append('trainingId', this.props.trainingId);
         axios({
             withCredentials:true,
             method: 'post',
             url: global.site +'/report/check.php',
             data: datas
         })
         .then(res =>  {
             if(res.data["success"]){
                 this.setState({fillReport:false})
             }
         })
         .catch(function (error) {
             error.handleGlobally && error.handleGlobally();
         });
    }
    
    onCheckTutoringReports(){
         let datas = new FormData();
         datas.append('trainingId', this.props.trainingId);
         datas.append('profile', this.state.fillTutoringReportProfile);
         axios({
             withCredentials:true,
             method: 'post',
             url: global.site +'/tutoringReport/check.php',
             data: datas
         })
         .then(res =>  {
             if(res.data["success"]){
                 this.setState({fillTutoringReport:false})
             }
         })
         .catch(function (error) {
             error.handleGlobally && error.handleGlobally();
         });
    }

    askFillReports(profile){
        this.setState({fillReport:true, fillTutoringReportProfile:profile})
    }
    
    askFillTutoringReports(profile){
        this.setState({fillTutoringReport:true, fillTutoringReportProfile:profile})
    }
    
    getTutoringReports(profile){
        let request = global.site + '/tutoringReport/list.php?type=report&profile=' + profile;
        axios.get(request)
            .then(res => {
                const reports = res.data["reports"];
                if(profile ==1){
                    if(reports.length > 0){
                        this.trainingReportFields[1].value=reports[0].id;
                        this.examFields[2].value = reports[0].id;
                    }
                    this.setState({trainingReportsList:reports});                
                }
                if(profile == 2){
                    if(reports.length > 0){
                        this.tutoringReportFields[1].value=reports[0].id;
                    }
                    this.setState({tutoringReportsList:reports});                
                }
                if(profile == 3){
                    if(reports.length > 0){
                        this.certificationReportFields[1].value=reports[0].id;
                    }
                    this.setState({certificationReportsList:reports});                
                }
                if(profile == 4){
                    if(reports.length > 0){
                        this.periodicTrainingReportFields[1].value=reports[0].id;
                    }
                    this.setState({periodicTrainingReportsList:reports});                
                }
                if(profile == 5){
                    if(reports.length > 0){
                        this.periodicTutoringReportFields[1].value=reports[0].id;
                    }
                    this.setState({periodicTutoringReportsList:reports});                
                }
             })
             .catch(function(error) {
                error.handleGlobally && error.handleGlobally();
        })
    }




    getEvents(startDate, endDate)
    {
        
        if(this.props.trainingId === undefined || this.props.trainingId === 0){
            return;
        }
        if(startDate === '' || endDate === ''){
            return;
        }
        this.setState({startDate:startDate, endDate:endDate}); 
        axios.get(global.site +'/event/list.php?type=training&id=' + this.props.trainingId + '&startDate=' + startDate + '&endDate=' + endDate, {withCredentials:true})
            .then(res => {
                const events = res.data["events"];
                this.setState({events:events});
             })
             .catch(function(error) {
                error.handleGlobally && error.handleGlobally();
        })
    }

    getEvent(id){
        axios.get(global.site +'/event/get.php?id=' + id)
            .then(res => {
                if(res.data["success"]){
                    this.courseFields.map((field) =>(
                        field.value = res.data[field.id]
                    ))
                    this.setState({createCourse:true, courseId:id})
                }
             })
             .catch(function(error) {
                error.handleGlobally && error.handleGlobally();
        })

    }

    getModulesList(){
        axios.get(global.site +'/training/modulesList.php?trainingId='+ this.props.trainingId, {withCredentials:true})
            .then(res => {
                const modules = res.data["modules"];
                this.setState({modules:modules, moduleId:0});
             })
             .catch(function(error) {
                error.handleGlobally && error.handleGlobally();
        })
    }
    
    getExaminatorsList()
    {
        axios.get(global.site +'/user/list.php?profile=5', {withCredentials:true})
            .then(res => {
                const examinators = res.data["users"];
                for(let index = 0;index < examinators.length; index++){
                    examinators[index]["name"] = examinators[index].firstName + ' ' + examinators[index].lastName;
                }
                this.setState({examinatorsList:examinators});
             })
             .catch(function(error) {
                error.handleGlobally && error.handleGlobally();
        })
    }
    
    getStudentsList()
    {
        axios.get(global.site +'/user/list.php?profile=5&trainingId=' + this.props.trainingId, {withCredentials:true})
            .then(res => {
                const students = res.data["users"];
                for(let index = 0;index < students.length; index++){
                    students[index]["name"] = students[index].firstName + ' ' + students[index].lastName;
                }
                this.setState({studentsList:students});
             })
             .catch(function(error) {
                error.handleGlobally && error.handleGlobally();
        })
    }


    getTeachersList()
    {
        axios.get(global.site +'/teacher/list.php?byName=true', {withCredentials:true})
            .then(res => {
                const teachers = [{name:'', id:0}].concat(res.data["teachers"]);
                this.setState({teachers:teachers});
             })
             .catch(function(error) {
                error.handleGlobally && error.handleGlobally();
        })
    }

    onValidModifyTraining(fields){
         let datas = new FormData();
         fields.map((field)=>(
             datas.append(field.id, field.value)
         ))  
         datas.append('id', this.props.trainingId);
         axios({
             withCredentials:true,
             method: 'post',
             url: global.site +'/training/updateTraining.php',
             data: datas
         })  
         .then(res =>  {
             if(res.data["success"]){
                    let calendars = this.props.calendars;
                    let calendar = undefined;

                     for(let index = 0; index < calendars.length;index++){
                        if(calendars[index].id == res.data.calendarId){
                            calendar = calendars[index].name;
                            break;
                        }
                     }
                    this.trainingEditFields = [
                        {id:'name', label:'Nom', type:'input', value:res.data.name, mandatory:true},
                        {id:'startDate', label:'Début', type:'date', value:res.data.startDate, mandatory:true},
                        {id:'endDate', label:'Fin', type:'date', value:res.data.endDate, mandatory:true},
                        {id:'mail', label:'Contact', type:'mail', value:res.data.mail,mandatory:true},
                        {id:'calendarId', label:'Calendrier Google', type:'select', value:res.data.calendarId}
                    ];
                    this.trainingFields = [
                        {id:'name', label:'Nom', type:'input', value:res.data.name},
                        {id:'startDate', label:'Début', type:'date', value:res.data.startDate},
                        {id:'endDate', label:'Fin', type:'date', value:res.data.endDate},
                        {id:'mail', label:'Contact', type:'input', value:res.data.mail},
                        {id:'calendar', label:'Calendrier Google', type:'input', value:calendar},
                    ];
                 this.setState({training:res.data, modifyTraining:false});
             }   
         })  
         .catch(function (error) {
             error.handleGlobally && error.handleGlobally(); 
         });  
    }

    handleUpdateSchedulerFile(fields){
        let datas = new FormData();
        this.schedulerFileFields.map(field=>{
            if(field.type === 'file'){
                const file = field.file;
                const fileName = file.name;
                datas.append('file', file, fileName)
            }
            else{
                datas.append(field.id, field.value)
            }
        })
        datas.append('type', 'scheduler');
        datas.append('id', this.props.trainingId);
        axios({
            method: 'post',
            url: global.site +'/documents/add.php',
            data: datas
        })
        .then(res =>  {
            if(res.data["success"]){
                const documents = res.data['documents']
                this.setState({addDocument:false, documents:documents});
            }
        })
        .catch(function (error) {
            error.handleGlobally && error.handleGlobally();
        });
    }

    handleRemoveSchedulerFile(){
        let datas = new FormData();
        datas.append('type', 'scheduler');
        datas.append('id', this.props.trainingId);
        axios({
            method: 'post',
            url: global.site +'/documents/delete.php',
            data: datas
        })
        .then(res =>  {
            if(res.data["success"]){
            }
        })
        .catch(function (error) {
            error.handleGlobally && error.handleGlobally();
        });
    
    }


    createLogo(logo, width, height){
        return(
            <Grid item xs={12} sm="auto">
                <img src={logo} alt="Qualiopi" width={width} height={height}/>
            </Grid>
        );
    }


    createInfoTab(){
        return(
            <div>
            <Typography variant="h5">Détails</Typography>
            <OlindaMuiFlatForm
                fields={this.trainingFields}
                onValid = {this.handleUpdateTraining}
                buttonTitle="Modifier"
                onModify={this.onModifyTraining}
            />
            </div>
        );
    }


    createDocumentsTab()
    {
        return(
            <div>
            <Typography variant="h5">Documents de formation</Typography>
            <LinkedDocuments
                type='teaching-training'
                docType='teaching-documents'
                directory='/documents/teaching/'
                userId= {this.props.trainingId}
                add={true}
                del={true}
            />
            </div>
        );
    }

    createAdminDocumentsTab()
    {
        return(
            <div>
            <Typography variant="h5">Documents Administratifs</Typography>
            <LinkedDocuments
                type='admin-training'
                docType='admin-documents'
                directory='/documents/admin/'
                userId= {this.props.trainingId}
                add={true}
                del={true}
                force={true}
            />
            </div>
        );
    }
    
    onDateSelected(date){
        this.setState({date:date});
    }

    onOkInfo(){
        this.setState({showInfo:false});
    }

    createScheduleTab(){
        return(
            <div >
               <Typography variant="h5">Emploi du temps</Typography>
               <div style={{marginBottom:"10px"}}>
               <OlindaMuiFlatForm
                    mb="10px"
                    fields={this.schedulerFileFields}
                    calendarId={this.props.calendars}
                    onValid = {this.handleUpdateSchedulerFile}
                    buttonTitle="Mettre à jour"
                    buttons={[{label:'Supprimer', action:this.handleRemoveSchedulerFile}]}
                />
                </div >
                <div>
                {this.state.training.schedulerFile !== undefined && this.state.training.schedulerFile.length > 0 ? 
                    <a href={this.state.training.schedulerFile}>Emploi du temps</a> 
                    : 
                    null
                }
                {this.state.training.calendarId !== undefined && this.state.training.calendarId.length > 0 ?
                    <OlindaMuiScheduler 
                        onDateSelected={this.onDateSelected} 
                        date={this.state.date} 
                        getEvents={this.getEvents} 
                        events={this.state.events} 
                        onSelect={this.onEditCourse}
                    />
                    :
                    null
                }
                {this.state.showInfo ?
                    <OlindaMuiInfo
                        title={"Description"}
                        text={this.state.infoText}
                        onOk = {this.onOkInfo}
                    />
                    :
                    null
                }
                </div >
            </div>
        );
    }

    onCreateModule(){
        this.moduleFields = [
              {id:'name', label:'Nom', type:'input', value:'', mandatory:true},
              {id:'description', label:'Description', type:'input', value:'', mandatory:true},
              {id:'testDate', label:'Date d\'examen', type:'date', value:'', mandatory:true},
              {id:'color', label:'Couleur', type:'input', value:''}
          ];
         this.setState({createModule:true, moduleId:0});
     }

     onDeleteModule(id){
        let datas = new FormData();
        if(this.props.trainingId !== 0){
            datas.append("trainingId", this.props.trainingId);
        }
        else{
            return;
        }
        datas.append("id", id);
        axios({
            method: 'post',
            url: global.site +'/training/deleteModule.php',
            data: datas
        })
        .then(res =>  {
            if(res.data["success"]){
                let modules = res.data["modules"]
                this.setState({createModule:false, modules:modules});
            }
        })
        .catch(function (error) {
            error.handleGlobally && error.handleGlobally();
        });
     }

     onEditModule(id){
        this.getModule(id);
        this.setState({moduleId:id});
     }

     getModule(id){
          axios.get(global.site +'/training/getModule.php?id='+id)
            .then(res => {
                if(res.data["success"]){
                    this.moduleFields.map((field) => (
                        field.value=res.data[field.id]
                    ))  
                    this.setState({moduleId:id, moduleName:res.data["name"]})
                }   
             })  
             .catch(function(error) {
                error.handleGlobally && error.handleGlobally();
         })  

     } 

 
     onCancelCreateModule(){
         this.setState({createModule:false, moduleId:0});
     }

     onValidCreateModule(fields){
 
         let datas = new FormData();
         fields.map((field)=>(
             datas.append(field.id, field.value)
         ))
         if(this.props.trainingId !== 0){
             datas.append("trainingId", this.props.trainingId);
         }
         if(this.state.moduleId !== 0){
             datas.append("id", this.state.moduleId);
         }
         axios({
             method: 'post',
             url: global.site +'/training/createModule.php',
             data: datas
         })
         .then(res =>  {
             if(res.data["success"]){
                 let modules = res.data["modules"]
                 this.setState({createModule:false, modules:modules, moduleId:0});
             }
         })
         .catch(function (error) {
             error.handleGlobally && error.handleGlobally();
         });
     } 
     
    createModulesTab(){
        return(
            <div>
            <Typography variant="h5">Modules</Typography>
            <OlindaMuiList
                elements={this.state.modules}
                field='name'
                edit = {true}
                read = {false}
                del= {true}
                add= {true}
                onEdit = {this.onEditModule}
                onDelete = {this.onDeleteModule}
                onAdd = {this.onCreateModule}
            />
            </div>
        );
    }


    createStudentsTab(){
        return(
        <div style={{marginTop:'10px'}}>
            <Student
                trainingId={this.props.trainingId}
                readOnly={true}               
                student={this.state.student}
            />
        </div>
        );
    }

    onEditHoliday(id){
        this.getHoliday(id);
    }

    onCreateHoliday(){
        this.setState({createHoliday:true, holidayId:0});
    }

    onCancelCreateHoliday(){
        this.setState({createHoliday:false});
    }

    onDeleteHoliday(ids){
        let datas = new FormData();
        for(let i = 0; i < ids.length; i++){
            datas.append('ids[]', ids[i]);
        }

        datas.append('trainingId', this.props.trainingId);
        axios({
            method: 'post',
            url: global.site +'/holiday/delete.php',
            data: datas
        })
        .then(res =>  {
            if(res.data["success"]){
                let holidays = res.data["holidays"];
                this.setState({createHoliday:false, holidays:holidays});
            }
        })
        .catch(function (error) {
            error.handleGlobally && error.handleGlobally();
        });
    }



    onValidCreateHoliday(fields){
        let datas = new FormData();
        fields.map((field)=>(
            datas.append(field.id, field.value)
        ))
        if(this.state.holidayId !== 0){
            datas.append('id', this.state.holidayId);
        }
        datas.append('trainingId', this.props.trainingId);
        axios({
            method: 'post',
            url: global.site +'/holiday/create.php',
            data: datas
        })
        .then(res =>  {
            if(res.data["success"]){
                let holidays = res.data["holidays"];
                this.setState({createHoliday:false, holidays:holidays});
            }
        })
        .catch(function (error) {
            error.handleGlobally && error.handleGlobally();
        });
    }

    getHolidays()
    {
        axios.get(global.site +'/holiday/list.php?trainingId=' + this.props.trainingId)
            .then(res => {
                const holidays = res.data["holidays"];
                this.setState({holidays:holidays});
             })
             .catch(function(error) {
                error.handleGlobally && error.handleGlobally();
        })
    }
    
    getExams()
    {
        axios.get(global.site +'/exam/list.php?trainingId=' + this.props.trainingId)
            .then(res => {
                const exams = res.data["exams"];
                this.setState({examsList:exams});
             })
             .catch(function(error) {
                error.handleGlobally && error.handleGlobally();
        })
    }

    getHoliday(id)
    {
        axios.get(global.site +'/holiday/get.php?id=' + id +'&trainingId=' + this.props.trainingId)
            .then(res => {
                this.holidayFields.map((field) => (
                    field.value = res.data[field.id]
                ))
                const holidayId = res.data["id"];
                this.setState({createHoliday:true, holidayId:holidayId});
             })
             .catch(function(error) {
                error.handleGlobally && error.handleGlobally();
        })
    }

    createHolidaysTab(){
        return(
            <div>
                <Typography variant="h5">Vacances</Typography>
                <OlindaMuiTable
                    elements={this.state.holidays}
                    columns={this.holidayColumns}
                    del={true}
                    add={true}
                    onAdd={this.onCreateHoliday}
                    onEdit={this.onEditHoliday}
                    onDelete={this.onDeleteHoliday}
                />
            </div>
        );
    }
    
    createExamsTab(){
        return(
            <div>
                <Typography variant="h5">{'Sessions d\'examen'}</Typography>
                <OlindaMuiTable
                    elements={this.state.examsList}
                    columns={this.examColumns}
                    del={true}
                    add={true}
                    onAdd={this.onCreateExam}
                    onEdit={this.onEditExam}
                    onDelete={this.onDeleteExam}
                />
            </div>
        );
    }

    createGenerateMonthlyReportTab(){
        return(
            <div>
            <Typography variant="h5">Bilans Mensuels</Typography>
            <div style={{margin:'3px'}}>
                <Button color="primary" variant="contained" onClick={()=>this.onGenerateReports()}>Générer</Button>
            </div>
            <div style={{margin:'3px'}}>
                <Button color="primary" variant="contained" onClick={()=>this.onCheckReports()}>Relancer les signatures</Button>
                {false ? <Button color="primary" variant="contained" onClick={()=>this.askFillReports()}>Signatures automatiques</Button> : null}
            </div>
            </div>
        );
    }
    
    createGenerateDailyReportTab(){
        return(
            <div>
            <Typography variant="h5">Bilans Quotidiens</Typography>
            <div style={{margin:'3px'}}>
                <Button color="primary" variant="contained" onClick={()=>this.onGenerateWeeklyReports()}>Générer</Button>
            </div>
            {false ?
             <div>
            <Typography variant="h5">Fin de Formation</Typography>
            <div style={{margin:'3px'}}>
                <Button color="primary" variant="contained" onClick={()=>this.onFillCertificate()}>Remplir les fins</Button>
            </div>
             </div>
                :
                null
            }
            </div>
        );
    }
    
    createGeneratePeriodicReportTab(){
        return(
            <div>
            <Typography variant="h5">Bilans Periodiques Tuteur</Typography>
            <div style={{margin:'3px'}}>
                <Button color="primary" variant="contained" onClick={()=>this.onGeneratePeriodicTutoringReports()}>Générer</Button>
                {false?
                    <Button color="primary" variant="contained" onClick={()=>this.askFillTutoringReports(5)}>Remplir</Button>
                    :
                    null}
            </div>
            <Typography variant="h5">Bilans Périodiques OF</Typography>
            <div style={{margin:'3px'}}>
                <Button color="primary" variant="contained" onClick={()=>this.onGeneratePeriodicTrainingReports()}>Générer</Button>
            </div>
            </div>
        );
    }

    createGenerateELearningTab(){
        return(
            <div>
            <Typography variant="h5">Evaluations en Ligne</Typography>
            <div style={{margin:'3px'}}>
                <Button color="primary" variant="contained" onClick={()=>this.onCreateELearning()}>Générer</Button>
            </div>
            </div>
        );
    }

    createGenerateMonthlyFormTab(){
        return(
            <div>
            <Typography variant="h5">FichesNavettes</Typography>
            <div style={{margin:'3px'}}>
                <Button color="primary" variant="contained" onClick={()=>this.onCreateMonthlyForm()}>Générer</Button>
            </div>
            </div>
        );
    }


    
    createGenerateTutoringReportTab(){
        return(
            <div>
            <Typography variant="h5">Evaluations Tuteur</Typography>
            <div style={{margin:'3px'}}>
                <Button color="primary" variant="contained" onClick={()=>this.onGenerateTutoringReports()}>Générer</Button>
            {false?
                <Button color="primary" variant="contained" onClick={()=>this.askFillTutoringReports(2)}>Remplir</Button>
                :
                null
            }
            </div>
            <Typography variant="h5">Evaluations OF</Typography>
            <div style={{margin:'3px'}}>
                <Button color="primary" variant="contained" onClick={()=>this.onGenerateTrainingReports()}>Générer</Button>
            {false ? <Button color="primary" variant="contained" onClick={()=>this.askFillTutoringReports(1)}>Remplir</Button> : null}
            </div>
            <Typography variant="h5">Evaluations Sommative</Typography>
            <div style={{margin:'3px'}}>
                <Button color="primary" variant="contained" onClick={()=>this.onGenerateCertificationReports()}>Générer</Button>
            {false ? <Button color="primary" variant="contained" onClick={()=>this.askFillTutoringReports(3)}>Remplir</Button> : null}
            </div>
            </div>
        );
    }

    createTutoringsTab(){
        return(
            <div>
                <Tutoring
                    type={'training'}
                    id={this.props.trainingId}
                />
            </div>
        );
    }
    
    createSettingsTab(){
        return(
            <div>
                {this.createExamsTab()}
                {this.createTutoringsTab()}
                {this.createHolidaysTab()}
            </div>
        );
    }

    createHomeTab(){
        let createGenerate = true;
        if (this.state.training !== null){
            let endDate = new Date(this.state.training.endDate);
            let now = new Date();
            let diffDate = now - endDate;
            //createGenerate = diffDate < 0;
        }
        return(
            <div>
            <Grid container spacing = {3} style={{background:'transparent'}}>
                <Grid style={{background:'transparent'}} item xs={12} sm={6}>
                    {this.createInfoTab()}
                </Grid>
                <Grid item xs={12} sm={6}>
                    {this.createModulesTab()}
                </Grid>
            </Grid>
            {createGenerate ?
                <Grid container spacing = {3} style={{background:'transparent'}}>
                    <Grid item xs={12} sm={4}>
                        {this.state.training !== null ? this.createGenerateMonthlyReportTab(): null}
                    </Grid>
                    <Grid item xs={12} sm={4}>
                       {this.state.training !== null ?this.createGenerateDailyReportTab() : null}
                    </Grid>
                    <Grid item xs={12} sm={4}>
                         {this.state.training !== null ? this.createGenerateTutoringReportTab(): null}
                    </Grid>
                    <Grid item xs={12} sm={4}>
                         {this.state.training !== null ? this.createGenerateELearningTab() : null}
                    </Grid>
                    <Grid item xs={12} sm={4}>
                         {this.state.training !== null ? this.createGenerateMonthlyFormTab() : null}
                    </Grid>
                    {this.state.training !== null && this.state.training.name.startsWith("AE") ?
                        <Grid item xs={12} sm={4}>
                             {this.createGeneratePeriodicReportTab()}
                        </Grid>
                        :
                        null
                    }
                </Grid>
                :
                null
            }
            </div>
        );
    }


    onTabSelected(index){
        this.setState({index:index});
    }

    render(){
        const {classes}=this.props;
        var formLogos = undefined
        if(this.state.training !== null && this.state.training.name !== undefined){
            var lTraining = this.state.training.name.toLowerCase();
            const keys = Object.keys(this.formLogos)
            if(lTraining.includes("bp")){
                formLogos = this.formLogos["bp"];
            }
            else if(lTraining.includes("cqp")){
                formLogos = this.formLogos["cqp"];
            }
            else if(lTraining.includes("ae") && lTraining.includes("ous pins")){
                formLogos = this.formLogos["ae-ouspins"];
            }
            else if(lTraining.includes("ae")){
                formLogos = this.formLogos["ae"];
            }
            /*
            for(let index = 0; index < keys.length; index++){
                if(trainingL.includes(keys[index])){
                    formLogos = this.formLogos[keys[index]];
                    break;
                }
            }*/
        }


        let fillTutoringTitles = ["Evaluations Formatives OF", "Evaluation Formatives Tuteurs", "Evaluations Sommatives"]
        return (
            <div>
            {this.state.modifyTraining ?
                <OlindaMuiForm
                    fields = {this.trainingEditFields}
                    calendarId={this.props.calendars}
                    onCancel={this.onCancelModifyTraining}
                    onValid={this.onValidModifyTraining}
                />
                :
                null
            }
            {this.state.moduleId === 0 && this.props.userProfile === 1 ?               
                <div>
                <AppBar position="static" color="default">
                    <Typography className={classes.title} variant="h5">{this.state.trainingName}</Typography>
                    <TrainingMenu
                        handlePage = {this.onTabSelected}
                        windowWidth={this.props.windowWidth}
                    />
                </AppBar>
                <Grid container spacing = {3} style={{background:'transparent'}}>                                    
                {
                   formLogos != undefined ?
                        formLogos.map((value, index)=>
                            this.createLogo(value.logo, value.width, value.height)
                        )
                    :
                    null
                }
                </Grid>
                <div style={{paddingTop:'50px'}}>
                    {this.state.index == 'info' ?
                        this.createHomeTab()
                        :
                        null
                    }
                    {this.state.index == 'teachingDocs' ?
                        this.createDocumentsTab()
                        : 
                        null
                    }
                    {this.state.index == 'adminDocs' ?
                        this.createAdminDocumentsTab()
                        : 
                        null
                    }
                    {this.state.index == 'students' ?
                        this.createStudentsTab()
                        :
                        null
                    }
                    {this.state.index == 'scheduler' ?
                        this.createScheduleTab()
                        :
                        null
                    }
                    {
                        this.state.index=='settings' ?                           
                            this.createSettingsTab()
                        :
                        null
                    }
                </div>
                </div>
                :
                null
            }
            {this.props.userProfile === 5 && this.state.training !== null?
                    this.createStudentsTab()
                    :
                    null
            }
            {this.state.moduleId !== 0 ?                
                <div>
                    <Breadcrumbs>
                        <Link to={"/training/" + this.props.trainingId}>{this.state.training.name}</Link>
                        <Typography variant="h5">{this.state.moduleName}</Typography>
                    </Breadcrumbs>
                    <Module
                        id={this.state.moduleId}
                        fields={this.moduleFields}
                        userProfile={this.props.userProfile}
                    />
                </div>
                :
                null
            }
             {this.state.createReports ? 
                 <OlindaMuiForm 
                    fields={this.reportFields}
                    title="Bilans Mensuels"
                    onCancel={this.onCancelGenerateReports} 
                    onValid={this.onValidGenerateReports}/> 
                 : 
                     null
             }
             {this.state.fillCertificate ? 
                 <OlindaMuiForm 
                    fields={this.certificateFields}
                    title="Fin de Formation"
                    onCancel={this.onCancelCertificates} 
                    onValid={this.onValidCertificates}/> 
                 : 
                     null
             }
             {this.state.createTutoringReports ? 
                 <OlindaMuiForm 
                    fields={this.tutoringReportFields}
                    title="Evaluation Tuteur"
                    onCancel={this.onCancelGenerateTutoringReports} 
                    onValid={this.onValidGenerateTutoringReports} 
                    id={this.state.tutoringReportsList}/>

                  : 
                     null
             }
             {this.state.createELearning ? 
                 <OlindaMuiForm 
                    fields={this.eLearningFields}
                    title="Evaluation en Ligne"
                    onCancel={this.onCancelCreateELearning} 
                    onValid={this.onValidCreateELearning} 
                    eLearningId={this.state.eLearningsList}/>

                  : 
                     null
             }
             {this.state.createMonthlyForm ?
                 <OlindaMuiForm
                    fields={this.reportFields}
                    title="Fiche Navette"
                    onCancel={this.onCancelCreateMonthlyForm}
                    onValid={this.onValidCreateMonthlyForm}
                 />
                  :
                  null
             }

             {this.state.createPeriodicTrainingReports ? 
                 <OlindaMuiForm 
                    fields={this.periodicTrainingReportFields}
                    title="Bilan Periodique OF"
                    onCancel={this.onCancelGeneratePeriodicTrainingReports} 
                    onValid={this.onValidGeneratePeriodicTrainingReports} 
                    id={this.state.periodicTrainingReportsList}/>

                  : 
                     null
             }
             {this.state.createPeriodicTutoringReports ? 
                 <OlindaMuiForm 
                    fields={this.periodicTutoringReportFields}
                    title="Bilan Periodique Tuteur"
                    onCancel={this.onCancelGeneratePeriodicTutoringReports} 
                    onValid={this.onValidGeneratePeriodicTutoringReports} 
                    id={this.state.periodicTutoringReportsList}/>

                  : 
                     null
             }
             {this.state.createCertificationReports ? 
                 <OlindaMuiForm 
                    fields={this.certificationReportFields}
                    title="Evaluations Sommatives"
                    onCancel={this.onCancelGenerateCertificationReports} 
                    onValid={this.onValidGenerateCertificationReports} 
                    id={this.state.certificationReportsList}/>

                  : 
                     null
             }
             {this.state.fillReport?
                <OlindaMuiConfirm
                    title="Bilans Mensuels"
                    text={"Remplir automatique les bilans mensuels, êtes-vous sûr?"}
                    onValid = {this.onValidFillReports}
                    onCancel = {this.onCancelFillReports}
                />
                :
                null
             }
             {this.state.fillTutoringReport?
                <OlindaMuiConfirm
                    title={fillTutoringTitles[this.state.fillTutoringReportProfile - 1]}
                    text={"Remplir les évaluations, êtes-vous sûr?"}
                    onValid = {this.onValidFillTutoringReports}
                    onCancel = {this.onCancelFillTutoringReports}
                />
                :
                null
             }
             {this.state.createExam ? 
                 <OlindaMuiForm 
                    fields={this.examFields}
                    title="Session d'examen"
                    onCancel={this.onCancelCreateExam} 
                    onValid={this.onValidCreateExam} 
                    id={this.state.trainingReportsList}
                    examinators={this.state.examinatorsList}
                    students={this.state.studentsList}
                    modify={this.state.examId > 0}
                   />

                  : 
                     null
             }
             {this.state.createTrainingReports ? 
                 <OlindaMuiForm 
                    fields={this.trainingReportFields}
                    title="Evaluation OF"
                    onCancel={this.onCancelGenerateTrainingReports} 
                    onValid={this.onValidGenerateTrainingReports} 
                    id={this.state.trainingReportsList}/>

                  : 
                     null
             }
             {this.state.createModule ? 
                 <OlindaMuiForm 
                    fields={this.moduleFields}
                    title="Module"
                    onCancel={this.onCancelCreateModule} 
                    onValid={this.onValidCreateModule}/> 
                 : 
                     null
             }
            {this.state.createHoliday?
                <OlindaMuiForm
                    fields={this.holidayFields}
                    title="Vacances"
                    modify = {this.state.holidayId !== 0}
                    onCancel={this.onCancelCreateHoliday}
                    onValid={this.onValidCreateHoliday}
                />
                :
                null
            }
            </div>
        );

    }

}

export default withStyles(useStyles)(TrainingBody);
