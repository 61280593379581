import React from 'react'

import Typography from '@material-ui/core/Typography';


import {withRouter} from "react-router-dom";

import User from '../user/user.js'
import ExaminatorProfile from './examinatorProfile.js'

class Examinator extends React.Component{
    constructor(props){
        super(props);
    }

    
    render(){
        return(
            <div>
                {this.props.match.params.examinatorId === undefined?
                    <div>
                    <User
                        profile={5}
                        userProfile={this.props.userProfile}
                    />
                    </div>
                    :
                    <div>
                    <ExaminatorProfile
                        examinatorId={this.props.match.params.examinatorId}
                        userProfile={this.props.userProfile}
                    />
                    </div>
                }
            </div>
        );
    
    }

}

export default withRouter(Examinator)
