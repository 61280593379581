import React from 'react'
import axios from 'axios'
import AddIcon from '@material-ui/icons/Add';
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';


import OlindaMuiForm from '../components/form'
import OlindaMuiTable from '../components/table'
import TrainingStudentItem from './trainingStudentItem.js'


class TrainingsList extends React.Component{
    constructor(props){
        super(props);
        this.state={
            trainings:[],
            trainingId:0,
            createTraining:false            
        }
        
        this.startTrainingFields = [
              {id:'trainingId', label:'Formation', type:'select', value:'', mandatory:true}
          ];
        
        this.trainingColumns = [
            {id:'training', title:'Formation'},
            {id:'startDate', title:'Début'},
            {id:'endDate', title:'Fin'},
        ];
        if(this.props.userProfile === 1){
            this.trainingColumns.push({id:'nbComments', title:'Commentaires'});
        }
        this.onCreateTraining = this.onCreateTraining.bind(this);
        this.onValidCreateTraining = this.onValidCreateTraining.bind(this);
        this.onCancelCreateTraining = this.onCancelCreateTraining.bind(this);
        this.onEditTraining = this.onEditTraining.bind(this);
        this.onDeleteTraining = this.onDeleteTraining.bind(this);
    }

    componentDidMount(){
        this.getTrainings();
    }

    onCreateTraining(){
        this.setState({createTraining:true, trainingId:0});
    }

    onValidCreateTraining(fields){
         let datas = new FormData();
         fields.map((field)=>(
             datas.append(field.id, field.value)
         ))
         if(this.props.id !== 0){
             datas.append("studentId", this.props.studentId);
         }
         axios({
             withCredentials:true,
             method: 'post',
             url: global.site +'/student/createTrainingInfo.php',
             data: datas
         })
         .then(res =>  {
             if(res.data["success"]){
                 let trainings = res.data["trainings"]
                 this.setState({createTraining:false, trainings:trainings});
             }
         })
         .catch(function (error) {
             error.handleGlobally && error.handleGlobally();
         });
    }

    onCancelCreateTraining(){
        this.setState({createTraining:false, trainingId:0});
    }

    onEditTraining(id){
        this.props.edit(id);
    }

    onDeleteTraining(ids){
        let datas = new FormData();
        for(let i = 0; i < ids.length; i++){
            datas.append('ids[]', ids[i]);
        }
        datas.append('studentId', this.props.studentId);
        axios({
            method: 'post',
            url: global.site +'/student/deleteTraining.php',
            data: datas
        })
        .then(res =>  {
            if(res.data["success"]){
                let trainings = res.data["trainings"];
                this.setState({createTraining:false, trainings:trainings});
            }
        })
        .catch(function (error) {
            error.handleGlobally && error.handleGlobally();
        });

    }

    getTraining(id){
        axios.get(global.site +'/student/getTraining.php?id=' + id)
            .then(res => {
                if(res.data["success"]){
                    this.trainingFields.map((field) => (
                        field.value = res.data[field.id]
                    ))
                    this.setState({createTraining:true, trainingId:id});
                }
             })
             .catch(function(error) {
                error.handleGlobally && error.handleGlobally();
        })
    }

    getTrainings(){
        axios.get(global.site +'/student/trainingsList.php?studentId=' + this.props.studentId + '&profile=' + this.props.userProfile + '&userId=' + this.props.userId)
            .then(res => {
                if(res.data["success"]){
                    const trainings = res.data["trainings"];
                    this.setState({trainings:trainings});
                }
             })
             .catch(function(error) {
                error.handleGlobally && error.handleGlobally();
        })
    }
    render()
    {
        return(
            <div>
                {this.props.userProfile === 1 ?
                    <IconButton>
                        <AddIcon  onClick={this.onCreateTraining}/>
                    </IconButton>
                    :
                    null
                }
                <Grid container spacing = {3} style={{background:'transparent', marginTop:'10px'}}>
                    {this.state.trainings.map((training)=>(
                        <Grid style={{background:'transparent'}} item xs={12} sm={6}>
                        <TrainingStudentItem
                            studentId={this.props.studentId}
                            training={training}
                        />
                        </Grid>
                    ))
                    }
                </Grid>
            {this.state.createTraining ?
                <OlindaMuiForm
                    fields={this.startTrainingFields}
                    title="Ajouter une formation"
                    onCancel={this.onCancelCreateTraining}
                    onValid={this.onValidCreateTraining}
                    trainingId={this.props.trainingsList}
                />
                :null
            }
            </div>
        );
    }
}

export default TrainingsList
