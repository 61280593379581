import React from 'react'
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import Videos from './videos.js';

class VideosForm extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            fields:this.props.fields,
            selected:[]
        }

        this.handleChange = this.handleChange.bind(this);
    }

    onCancel() {
        this.props.onCancel();
    };

    onValid() {
        this.props.onValid(this.state.selected);
    };

    handleChange(id, checked){
        let files;
        if(checked){
            files = this.state.selected.slice();
            files.push(id);
        }
        else{
            files = this.state.selected.filter(sel => sel !== id);
        }
        this.setState({selected:files});
    }
    
    render(){
        return (
            <div>
            <Dialog fulWidth fullHeight open={true} onClose={()=>this.onCancel()} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">{this.props.title}</DialogTitle>
            <DialogContent>
                <Videos
                    type='videos'
                    check={true}
                    onToggleCheck={this.handleChange}
                />
            </DialogContent>
            <DialogActions>
            <Button onClick={()=>this.onCancel()} color="primary">
                Annuler
            </Button>
            <Button onClick={()=>this.onValid()} color="primary">
                Valider
            </Button>
            </DialogActions>
        </Dialog>
        </div>
  );
    }
}


export default VideosForm;
